import styled from 'styled-components';

const Header = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const UserInformationPanel = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  font-family: 'Roboto', sans-serif;
  position: relative;
  flex-direction: column;

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
`;

const Panel = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid rgba(102, 111, 232, 0.08);
  box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
  border-radius: 3px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  & > .left {
    display: flex;
    min-width: 250px;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    .avatar {
      border-radius: 10px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      border-radius: 10px;
      border: 2px solid gray;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .name {
      margin-top: 5px;
      font-size: 18px;
      font-weight: 600;
    }

    .role {
      margin-top: 2px;
      font-size: 14px;
      font-weight: 600;
      color: #666fe8;
    }
  }

  & > .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    .information {
      display: flex;
      flex-direction: column;
      width: 50%;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .one-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 12px;

        .left {
          font-size: 16px;
          font-weight: 600;
          width: 180px;
        }

        .right {
          font-size: 16px;
          margin-left: 20px;
          overflow-wrap: break-word;
          max-width: 50%;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      width: 50%;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .one-row {
        width: fit-content;
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;

        .change-password {
          width: fit-content;
          border-bottom: 1px solid #666fe8;
          color: #666fe8;

          &:hover {
            color: #c58af9;
            border-bottom: 1px solid #c58af9;
          }
        }

        .more-setting {
          width: fit-content;
          border-bottom: 1px solid #666fe8;
          color: #666fe8;

          &:hover {
            color: #c58af9;
            border-bottom: 1px solid #c58af9;
          }
        }
      }
    }
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  .MuiButton-root ~ .MuiButton-root {
    margin-left: 16px;
  }
`;

export { UserInformationPanel, Content, Panel, ActionButtonContainer, Header, Title };
