import { ThunkAction } from 'redux-thunk';
import { axios, axios2 } from '~/plugins';
import { StationConfigActionType } from '~/store/ducks/stationConfig/types';
import { RootAction, RootState } from '~/store/types';
import {
  IStationAssociation,
  IStationAssociationResponse,
  IStationConfigRequest,
  IStationConfigResponse,
} from '~/types/';

export const getListStationConfig =
  (stationId?: string): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT });
      const res = await axios.get<IStationConfigResponse>(
        `/station_config${stationId ? `?station_id=${stationId}` : ''}`,
      );
      dispatch({
        type: StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_SUCCESS,
        payload: { listStationConfig: res.data.Data },
      });
    } catch (error) {
      dispatch({
        type: StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_FAIL,
        payload: { error: error as Error },
      });
    }
  };

export const getListStationConfigMapping =
  (stationId: string): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationConfigActionType.GET_STATION_CONFIG_MAPPING });
      const response = await axios.get<IStationConfigResponse>('/station_config', {
        params: { station_id: stationId },
      });
      const responseConfig = await axios.get<IStationAssociationResponse>(
        '/station_config_association/' + stationId,
      );
      const stationConfigDefault = response.data.Data;
      const stationDataConfig = responseConfig.data.Data;
      const StationConfigMapping: IStationAssociation[] = stationConfigDefault.map((item) => {
        const findItem = stationDataConfig.find((data) => data.key === item.key);
        if (findItem) return { ...findItem, station_config_id: item.id } as IStationAssociation;
        return { ...item, value: '', reason: '', isCreate: true } as IStationAssociation;
      });
      dispatch({
        type: StationConfigActionType.GET_STATION_CONFIG_MAPPING_SUCCESS,
        payload: { listStationConfigMapping: StationConfigMapping },
      });
    } catch (error) {
      dispatch({
        type: StationConfigActionType.GET_STATION_CONFIG_MAPPING_FAIL,
        payload: { error: error as Error },
      });
    }
  };

export const clearListStationConfig =
  (): ThunkAction<void, RootState, undefined, RootAction> => (dispatch) => {
    dispatch({
      type: StationConfigActionType.GET_STATION_CONFIG_MAPPING_SUCCESS,
      payload: { listStationConfigMapping: [] },
    });
  };

export const createStationConfig = async (stationConfig: IStationConfigRequest) => {
  const res = await axios2.post(`/station_config`, {
    data: [stationConfig],
  });
  return res.data;
};

export const updateStationConfig = async (stationConfig: IStationConfigRequest) => {
  const res = await axios2.put(`/station_config`, {
    data: [stationConfig],
  });
  return res.data;
};

export const deleteStationConfig = async (id: string) => {
  const res = await axios2.delete(`/station_config?ids=${id}`);
  return res.data;
};
