import MuiChip, { ChipProps } from '@mui/material/Chip';
import * as React from 'react';
import styled, { css } from 'styled-components';

type ChipConfigProps = ChipProps & {
  type: 'success' | 'warning';
  label: string;
};

const Chip: React.FC<ChipConfigProps> = ({ label, type }) => {
  return <StyledChip type={type} label={label} />;
};

const setVariantStyle = (type: ChipConfigProps['type']) => {
  switch (type) {
    case 'success': {
      return css`
        background: rgba(75, 198, 69, 0.2);
        border: 1px solid rgba(75, 198, 69, 0.2);
        color: #339335;
        border-radius: 12px;
        padding: 5px 30px;
      `;
    }

    case 'warning': {
      return css`
        background: rgba(150, 150, 150, 0.2);
        border: 1px solid rgba(150, 150, 150, 0.2);
        color: #2e384d;
        border-radius: 12px;
        padding: 5px 30px;
      `;
    }

    default: {
      return;
    }
  }
};

type IStyledChip = Pick<ChipConfigProps, 'type'>;

const StyledChip = styled(MuiChip)<IStyledChip>`
  ${(props) => setVariantStyle(props.type)}
  width: 140px;
`;

export default Chip;
