import { AnalyticAction, AnalyticActionType, AnalyticState } from '~/store/ducks/analytic/types';

const initialState: AnalyticState = {
  error: undefined,
  listAnalytic: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 1,
      total: 1,
      totalPage: 1,
    },
  },
};

export default function analyticReducer(
  state = initialState,
  action: AnalyticAction,
): AnalyticState {
  switch (action.type) {
    case AnalyticActionType.GET_LIST_ANALYTIC_SUCCESS: {
      return {
        ...state,
        listAnalytic: {
          list: action.payload.listAnalytic.list,
          pagination: action.payload.listAnalytic.pagination,
        },
      };
    }

    case AnalyticActionType.GET_LIST_ANALYTIC_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
