import { IRoleRequest, IRoleResponse, IListRoleResponse } from '~/types/';

export enum RoleActionType {
  GET_LIST_ROLE_REQUEST = 'role/GET_LIST_ROLE_REQUEST',
  GET_LIST_ROLE_SUCCESS = 'role/GET_LIST_ROLE_SUCCESS',
  GET_LIST_ROLE_FAIL = 'role/GET_LIST_ROLE_FAIL',
  CREATE_ROLE_REQUEST = 'role/CREATE_ROLE_REQUEST',
  CREATE_ROLE_SUCCESS = 'role/CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAIL = 'role/CREATE_ROLE_FAIL',
}

export type RoleInfo = IRoleRequest;

export interface ListRole {
  list?: IRoleResponse[];
  pagination?: {
    total?: number;
    current?: number;
    pageSize?: number;
  };
}

export interface GetListRolesRequest {
  type: RoleActionType.GET_LIST_ROLE_REQUEST;
}

export interface GetListRoleSuccess {
  type: RoleActionType.GET_LIST_ROLE_SUCCESS;
  payload: IListRoleResponse;
}

export interface GetListRoleFail {
  type: RoleActionType.GET_LIST_ROLE_FAIL;
  payload: {
    error: boolean;
    message: string;
  };
}

// export interface CreateRoleRequest {
//   type: RoleActionType.CREATE_ROLE_REQUEST,
//   payload: RoleInfo
// }

export type RoleAction = GetListRolesRequest | GetListRoleSuccess | GetListRoleFail;

export interface RoleState {
  listRole: ListRole;
}
