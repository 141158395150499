import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '~/store/types';
import { getListRolesRequest } from './actions';
import { IListRoleRequest } from '~/types';

interface FuncType {
  roleState: RootState['role'];
  dispatchGetListRoles: (params?: IListRoleRequest) => Promise<void>;
}

const useRoleStore = (): FuncType => {
  const dispatch = useDispatch();

  const roleState = useSelector<RootState, RootState['role']>((state) => {
    return state.role;
  });

  const dispatchGetListRoles = React.useCallback(
    async (params?: IListRoleRequest) => {
      await dispatch(getListRolesRequest(params));
    },
    [dispatch],
  );

  return {
    roleState,
    dispatchGetListRoles,
  };
};

export default useRoleStore;
