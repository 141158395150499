import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ISampleInfo } from '~/types';
import { tuple } from '~/utils';

interface LineSeries {
  date: number;
  value: number;
}

interface AmChartColumnProps {
  data: LineSeries[];
  chartInfo: ISampleInfo;
  type: number;
}

function maybeDisposeRoot(divId: string) {
  am5.array.each(am5.registry.rootElements, function (root) {
    if (root.dom.id == divId) {
      root.dispose();
    }
  });
}

const ColumnChart = ({ data, chartInfo, type = 0 }: AmChartColumnProps) => {
  const { t } = useTranslation();
  const TimeUnitArrays = tuple('second', 'minute', 'hour', 'day');

  React.useEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    maybeDisposeRoot(`${chartInfo.id}`);
    const root = am5.Root.new(`${chartInfo.id}`);

    //Disable amChart Logo
    if (root._logo) {
      root._logo.dispose();
    }

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
      }),
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
      }),
    );
    cursor.lineY.set('visible', false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: TimeUnitArrays[type],
          count: 1,
        },
        groupData: true,
        start: TimeUnitArrays[type] === 'second' ? 0.9 : 0.5,
        tooltipDateFormat: 'yyyy/MM/dd HH:mm:ss',
        renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 60 }),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    // const label1 = am5.Label.new(root, {
    //   rotation: 0,
    //   text: chartInfo.unit,
    //   y: am5.p50,
    //   centerX: am5.p50,
    // });

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );
    // yAxis.children.unshift(label1);

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}',
        }),
      }),
    );
    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });

    // Set data
    series.data.setAll(data);

    // Create modal for a "no data" note
    const modal = am5.Modal.new(root, {
      content: 'The chart has no data',
    });

    series.events.on('datavalidated', function (ev) {
      if (ev.target.data.length < 1) {
        // Show modal
        modal.open();
      } else {
        // Hide modal
        modal.close();
      }
    });

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [TimeUnitArrays, chartInfo.id, data, type]);

  return (
    <AmChartStationContainer id={chartInfo.id}>
      <TitleChart>
        {t('chart')} {chartInfo.name} {chartInfo.unit ? `(Đơn vị: ${chartInfo.unit})` : ''}
      </TitleChart>
    </AmChartStationContainer>
  );
};

const AmChartStationContainer = styled.div`
  width: 100%;
  height: 500px;
  max-width: 100%;
  /* margin-bottom: 40px; */

  &:last-child {
    margin-bottom: 0;
  }

  & .am5-modal {
    z-index: 10;
  }
`;

const TitleChart = styled.h3`
  /* margin-bottom: 15px; */
  /* padding: 8px; */
`;

export default React.memo(ColumnChart);
