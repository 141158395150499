import { createTheme } from '@mui/material/styles';
import { BackGroundColor, TextColor } from '~/constants';

export const theme = createTheme({
  palette: {
    primary: {
      main: BackGroundColor.Primary,
      light: BackGroundColor.PrimaryHover,
      dark: BackGroundColor.Dark,
    },
    secondary: {
      main: BackGroundColor.Secondary,
      contrastText: TextColor.Secondary,
    },
    error: {
      main: BackGroundColor.Error,
      contrastText: TextColor.Error,
    },
  },
});
