import { ISampleInfo } from '~/types/';

export enum SampleInfoActionType {
  GET_LIST_SAMPLE_INFO = 'sampleInfo/GET_LIST_SAMPLE_INFO',
  GET_LIST_SAMPLE_INFO_SUCCESS = 'sampleInfo/GET_LIST_SAMPLE_INFO_SUCCESS',
  GET_LIST_SAMPLE_INFO_FAIL = 'sampleInfo/GET_LIST_SAMPLE_INFO_FAIL',
}

export interface GetListSampleInfo {
  type: SampleInfoActionType.GET_LIST_SAMPLE_INFO;
}

export interface GetListSampleInfoSuccess {
  type: SampleInfoActionType.GET_LIST_SAMPLE_INFO_SUCCESS;
  payload: {
    listSampleInfo: ISampleInfo[];
  };
}

export interface GetListSampleInfoFail {
  type: SampleInfoActionType.GET_LIST_SAMPLE_INFO_FAIL;
  payload: {
    error: Error;
  };
}

export type SampleInfoAction = GetListSampleInfo | GetListSampleInfoSuccess | GetListSampleInfoFail;

export interface SampleInfoState {
  error?: Error;
  listSampleInfo: ISampleInfo[];
}
