import vi from 'date-fns/locale/vi';
import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdEvent as EventIcon } from 'react-icons/md';
import styled from 'styled-components';
import { GrayColors } from '~/constants';

registerLocale('vi', vi);

interface Props {
  value: string | null;
  width?: string;
  readOnly?: boolean;
  disabled?: boolean;
  inline?: boolean;
  handleChange?: (date: Date | null) => void;
}

const DatePickerMonthInline = ({
  value,
  width,
  readOnly = false,
  disabled = false,
  inline = true,
  handleChange,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState(value ? new Date(value) : null);

  React.useEffect(() => {
    if (value && Date.parse(value)) {
      setSelected(new Date(value));
    } else {
      setSelected(null);
    }
  }, [value]);

  const handleSelectedChange = (date: Date | null) => {
    setSelected(date);
    handleChange && handleChange(date);
  };

  return (
    <Container width={width} readOnly={readOnly} disabled={disabled}>
      <Icon>
        <EventIcon />
      </Icon>
      <DatePicker
        locale='vi'
        selected={selected}
        dateFormat='MM/yyyy'
        showMonthYearPicker
        readOnly={readOnly}
        disabled={disabled}
        inline={inline}
        strictParsing
        onChange={handleSelectedChange}
      />
    </Container>
  );
};

const Container = styled.div<Pick<Props, 'width' | 'disabled' | 'readOnly'>>`
  max-width: ${({ width }) => width ?? 'none'};
  width: 100%;
  position: relative;
  background-color: ${({ disabled, readOnly }) => (!readOnly && disabled ? GrayColors.G1 : '#fff')};

  .react-datepicker__input-container {
    width: inherit;
  }

  .react-datepicker {
    width: 100%;
    border-radius: unset;
    border: unset;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month-text {
    width: calc((100% - 12px) / 3);
    line-height: 35px;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  padding: 0 6px;
  color: ${GrayColors.G5};
  font-size: 20px;

  & > svg {
    font-size: inherit;
  }
`;

export default DatePickerMonthInline;
