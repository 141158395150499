import { rgba } from 'polished';

const VioletColor = {
  900: '#545DD3',
  800: '#6169E0',
  700: '#666FE8',
  500: '#C7CAF3',
};

const GrayColor = {
  900: '#2E384D',
  800: '#8798AD',
  700: '#bfc5d2',
  200: rgba('#c5cad6', 0.3),
  100: '#FAFAFA',
};

const RedColor = {
  800: '#FA4747',
} as const;

const FontSize = {
  XXL: '2rem',
  XL: '1.7rem',
  L: '1.5rem',
  M: '1.3rem',
  S: '1rem',
} as const;

const Round = {
  XXL: '100px',
  XL: '20px',
  L: '10px',
  M: '5px',
  S: '3px',
  XS: '2px',
} as const;

export const Space = {
  XXL: '32px',
  XL: '24px',
  L: '20px',
  M: '15px',
  S: '12px',
  XS: '10px',
  XXS: '8px',
  X2S: '7px',
  X3S: '4px',
  X4S: '2px',
} as const;

export const BackGroundColor = {
  Primary: VioletColor[700],
  PrimaryHover: VioletColor[800],
  Dark: VioletColor[900],
  Secondary: GrayColor[100],
  SecondaryHover: GrayColor[200],
  Error: RedColor[800],
  Disable: VioletColor[500],
  Success: '#4BC645',
} as const;

export const TextColor = {
  Primary: GrayColor[100],
  Secondary: GrayColor[800],
  Disable: GrayColor[900],
  Dark: VioletColor[800],
  Error: GrayColor[100],
  Hidden: GrayColor[200],
  Normal: '#44474E',
  Disabled: '#767685',
};

export const BorderColor = {
  Primary: VioletColor[900],
  Secondary: GrayColor[700],
  Dark: '#C6C6D3',
  PrimaryFocus: '#A7D5E7',
};

export const BrandColors: { [s: string]: string } = {
  primary: '#00386B',
  secondary: '#66ACFF',
  alert: '#C10000',
  todo: '#ff911a',
  success: '#66ACFF',
  connect: '#74ACF8',
  exclamation: '#FFE9E8',
  under: '#12376A',
} as const;

export const GrayColors: { [s: string]: string } = {
  G8: '#333333',
  G7: '#515151',
  G6: '#808080',
  G5: '#999999',
  G4: '#B1B1B1',
  G3: '#E0E0E0',
  G2: '#EAEAEA',
  G1: '#F7F7F7',
} as const;

export const PhaseColors: { [s: string]: string } = {
  nurturing: '#1448A5',
  qualification: '#1448A5',
  closing: '#5476E2',
  onboarding: '#D376D1',
  adoption: '#D376D1',
  expansion: '#FF8383',
  recycling: '#B1B1B1',
  event: '#515151',
  disconnect: '#C02620',
  connect: '#74ACF8',
  errorRecordHistory: '#ffe5e5',
  end: '#808080',
} as const;

export const InputRound = Round.M;

export const InputFontSize = FontSize.S;
