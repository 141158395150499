import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import TextButton from '~/components/atoms/Button';
import Empty from '~/components/atoms/Empty';
import DateInput from '~/components/atoms/Input/DateInput';
import { moment } from '~/plugins';
import { getDataOfSensor } from '~/store/ducks/station/actions';
import { useToastStore } from '~/store/hooks';
import StickyHeadTable from './StickyHeadTable';

const generateColumns = (arr: any[]) => {
  if (!arr) return [];

  const newArrays = arr.map((one) => {
    return {
      id: one.id,
      label: one.name,
      numeric: true,
      disablePadding: false,
    };
  });

  newArrays.unshift({
    id: 'time',
    label: 'Thời gian',
    numeric: false,
    disablePadding: false,
  });

  return newArrays;
};

const generateRows = (timeArray: any[], data: any) => {
  const listRows: any[] = [];
  for (const time of timeArray) {
    const tempRow: any = { time: moment(time).utcOffset(7).format('YYYY-MM-DD HH:mm:ss') };

    for (const [key, value] of Object.entries(data)) {
      const sensorId = key;
      const valueByTime = Number((value as any)[time]?.toFixed(2));
      if (valueByTime) {
        tempRow[sensorId] = valueByTime;
      } else {
        tempRow[sensorId] = '-';
      }
    }

    listRows.push(tempRow);
  }

  return listRows;
};

// B1: Lấy list available để generate ra list columns
// B2: Cho chạy for lấy data của từng column. Rồi combine lại dựa trên time
export interface DataPropsType {
  children?: React.ReactNode | React.ReactNode[];
  listSensorAvailables: any;
  stationId: string;
  onTimeStartChange: (time: any) => void;
  onTimeEndChange: (time: any) => void;
  timeStart: any;
  timeEnd: any;
}

const Data = (props: DataPropsType) => {
  const {
    listSensorAvailables,
    stationId,
    onTimeStartChange,
    onTimeEndChange,
    timeStart,
    timeEnd,
  } = props;
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const { dispatchCreateToast } = useToastStore();

  const handleUpdateData = useCallback(() => {
    if (moment(timeEnd)?.diff(moment(timeStart)) < 0) {
      return dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: 'Ngày kết thúc phải lớn hơn hoặc bằng Ngày bắt đầu',
      });
    }

    const columnsTmp = generateColumns(listSensorAvailables);
    setColumns(columnsTmp);
    const listPromises: any[] = [];

    for (let i = 0; i < listSensorAvailables.length; i++) {
      const sensorId = listSensorAvailables[i].id;
      if (!sensorId) return;

      const promise = getDataOfSensor({ stationId, sensorId, timeStart, timeEnd }).then(
        (response: any) => {
          const result = response.Data;
          if (!result) return;
          else {
            const listData: any = {};
            for (const element of result) {
              if (
                typeof element.SamplingTime === 'string' &&
                typeof element.SensorData !== 'undefined'
              ) {
                listData[element.SamplingTime] = element.SensorData;
              }
            }

            return {
              sensorId,
              value: listData,
            };
          }
        },
      );

      listPromises.push(promise);
    }

    Promise.all(listPromises).then((values) => {
      if (!values?.length) return;
      const valueSensors: any = {};
      values.forEach((one) => {
        const sensorId = one.sensorId;
        if (!sensorId) return;

        valueSensors[sensorId] = one.value;
      });

      const listTime = Object.keys(values[0]?.value);
      const rowsTmp = generateRows(listTime, valueSensors);
      setRows(rowsTmp);
    });
  }, [dispatchCreateToast, listSensorAvailables, stationId, timeEnd, timeStart]);

  useEffect(() => {
    handleUpdateData();
  }, [listSensorAvailables, handleUpdateData]);

  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      onTimeStartChange(date);
    },
    [onTimeStartChange],
  );

  const handleEndDateChange = useCallback(
    (date: Date | null) => {
      onTimeEndChange(date);
    },
    [onTimeEndChange],
  );

  return (
    <DataElement>
      <div className='controller1'>
        <div className='input-start-time'>
          <DateInput
            showTimeSelect={true}
            value={timeStart}
            width='180px'
            handleChange={handleStartDateChange}
            maxDate={moment().format()}
            placeholder='Ngày bắt đầu'
          />
        </div>
        <div className='input-end-time'>
          <DateInput
            showTimeSelect={true}
            value={timeEnd}
            width='180px'
            handleChange={handleEndDateChange}
            maxDate={moment().format()}
            placeholder='Ngày kết thúc'
          />
        </div>
        {/* <div className='load-data'>
          <TextButton color='primary' variant='contained' onClick={handleUpdateData}>
            Tải dữ liệu
          </TextButton>
        </div> */}
        <div className='report-data'>
          <TextButton color='primary' variant='contained' disabled={true}>
            Báo cáo số liệu
          </TextButton>
        </div>
      </div>
      {/* <div className='controller2'>
        <div className='export-data'>
          <div className='excel'></div>
          <div className='pdf'></div>
          <div className='print'></div>
        </div>
      </div> */}
      <div className='table'>
        {rows?.length > 0 ? (
          <StickyHeadTable data={{ columns, rows }} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </DataElement>
  );
};

export default Data;

const DataElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .controller1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > div {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 4px;
    }

    .load-data,
    .report-data {
      white-space: nowrap;

      & * {
        font-size: 14px;
      }
    }
  }

  .controller2 {
  }

  .table {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;
