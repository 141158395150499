import { IAuthLoginResponse, IAuthRefreshTokenResponse } from '~/types/';

export enum AuthActionType {
  AUTH_LOGIN_REQUEST = 'auth/AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_SUCCESS = 'auth/AUTH_LOGIN_SUCCESS',
  AUTH_LOGIN_FAIL = 'auth/AUTH_LOGIN_FAIL',
  AUTH_REGISTER_REQUEST = 'auth/AUTH_REGISTER_REQUEST',
  AUTH_REGISTER_SUCCESS = 'auth/AUTH_REGISTER_SUCCESS',
  AUTH_REGISTER_FAIL = 'auth/AUTH_REGISTER_FAIL',
  AUTH_CLEAN_ERROR_INFO = 'auth/AUTH_CLEAN_ERROR_INFO',
  AUTH_SET_KEEP_LOGIN = 'auth/AUTH_SET_KEEP_LOGIN',
  // AUTH_SET_AUTH_TOKEN = 'auth/SET_AUTH_TOKEN',
  // AUTH_REFRESH_TOKEN = 'auth/REFRESH_TOKEN',
  AUTH_LOGOUT = 'auth/LOGOUT',
  AUTH_RESET_STATUS = 'auth/RESET_STATUS',
  AUTH_RERESH_TOKEN_REQUEST = 'auth/AUTH_RERESH_TOKEN_REQUEST',
  AUTH_RERESH_TOKEN_SUCCESS = 'auth/AUTH_RERESH_TOKEN_SUCCESS',
  AUTH_RERESH_TOKEN_FAIL = 'auth/AUTH_RERESH_TOKEN_FAIL',
}

export interface FormValuesLogin {
  username: string;
  password: string;
}

export interface FormValuesRegister {
  username: string;
  fullname: string;
  phone: string;
  address: string;
  email: string;
  password: string;
  rePassword: string;
}

export interface UserAuth extends IAuthLoginResponse {
  access_token?: string;
  token_type?: string;
  expires_at?: number;
  refresh?: string;
  role_name?: string;
  role_slug?: string;
  real_name?: string;
  user_id?: string;
}

export interface UserAuthError {
  error: boolean;
  message?: string;
}

// action
export interface AuthLoginRequest {
  type: AuthActionType.AUTH_LOGIN_REQUEST;
  payload: FormValuesLogin;
}

export interface AuthLoginSuccess {
  type: AuthActionType.AUTH_LOGIN_SUCCESS;
  payload: IAuthLoginResponse;
}

export interface AuthLoginFail {
  type: AuthActionType.AUTH_LOGIN_FAIL;
  payload: UserAuthError;
}

export interface AuthLogout {
  type: AuthActionType.AUTH_LOGOUT;
}

export interface AuthCleanErrorInfo {
  type: AuthActionType.AUTH_CLEAN_ERROR_INFO;
}

export interface AuthSetKeepLogin {
  type: AuthActionType.AUTH_SET_KEEP_LOGIN;
  payload: boolean;
}

export interface AuthRefreshTokenRequest {
  type: AuthActionType.AUTH_RERESH_TOKEN_REQUEST;
}

export interface AuthRefreshTokenSuccess {
  type: AuthActionType.AUTH_RERESH_TOKEN_SUCCESS;
  payload: IAuthRefreshTokenResponse;
}

export interface AuthRefreshTokenFail {
  type: AuthActionType.AUTH_RERESH_TOKEN_FAIL;
}

export type AuthAction =
  | AuthSetKeepLogin
  | AuthLoginRequest
  | AuthLoginSuccess
  | AuthLoginFail
  | AuthLogout
  | AuthRefreshTokenRequest
  | AuthRefreshTokenSuccess
  | AuthRefreshTokenFail
  | AuthCleanErrorInfo;

export interface AuthState {
  authLogin: UserAuth;
  authError: UserAuthError;
  keepLogin: boolean;
}
