import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { TiTimes } from 'react-icons/ti';
import styled from 'styled-components';
import Input from './Input';
import TextButton from '~/components/atoms/Button';
import { authForgotPasswordGetOtp, authForgotPasswordVerifyOtp } from '~/store/actions';
import { validateEmail, validateOnlyNumber } from '~/assets/validate';
import CircularProgress from '@mui/material/CircularProgress';
import { useToastStore } from '~/store/hooks';
import { v4 as uuidv4 } from 'uuid';

export interface ChangePasswordPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onShow?: any;
}

const ChangePassword = (props: ChangePasswordPropsType) => {
  const { onShow } = props;
  const [email, setEmail] = useState<string>('');
  const [emailValidState, setEmailValidState] = useState<boolean>(true);
  const [otp, setOtp] = useState<string>('');
  const [otpValidState, setOtpValidState] = useState<boolean>(true);
  const [step, setStep] = useState(1);
  const [pendingTimeoutSendOtpState, setPendingTimeoutSendOtpState] = useState(false);
  const [pendingAuthOtpState, setPendingAuthOtpState] = useState(false);

  const changePasswordPanelRef = useRef<any>(null);
  const contentRef = useRef(null);
  const intervalCounterRef = useRef<any>(null);
  const [countdown, setCountDown] = useState(60);

  const { dispatchCreateToast } = useToastStore();

  useEffect(() => {
    if (pendingTimeoutSendOtpState) {
      let countdown_tmp = 60;
      intervalCounterRef.current = setInterval(() => {
        countdown_tmp--;
        setCountDown(countdown_tmp);

        if (countdown_tmp <= 0) {
          clearInterval(intervalCounterRef?.current);
          setPendingTimeoutSendOtpState(false);
        }
      }, 1000);

      return () => {
        clearInterval(intervalCounterRef?.current);
      };
    }
  }, [pendingTimeoutSendOtpState]);

  useEffect(() => {
    if (changePasswordPanelRef?.current && contentRef?.current) {
      changePasswordPanelRef.current.onclick = (e: any) => {
        if (e.target == changePasswordPanelRef.current) onShow(false);
      };
    }
  }, [onShow]);

  const fetchGetOtp = useCallback(async () => {
    const resp = await authForgotPasswordGetOtp({
      email,
    });

    if (resp) return true;
    else return false;
  }, [email]);

  const fetchVerifyOtp = useCallback(async () => {
    const resp = await authForgotPasswordVerifyOtp({
      email,
      otp: otp,
    });

    if (resp) return true;
    else return false;
  }, [email, otp]);

  const _handleChangeInput = useCallback(
    (e: any) => {
      e.preventDefault();
      const name = e?.target?.name || '';
      const value = e?.target?.value || '';

      switch (name) {
        case 'email': {
          setEmail(value);

          if (!emailValidState) {
            setEmailValidState(true);
          }

          break;
        }
        case 'code': {
          setOtp(value);

          if (!otpValidState) {
            setOtpValidState(true);
          }

          break;
        }
      }
    },
    [otpValidState, emailValidState],
  );

  const handleClose = useCallback(() => {
    if (onShow) {
      onShow(false);
    }
  }, [onShow]);

  const handleClickSendVerifyCode = useCallback(async () => {
    const validateEmailStatus = validateEmail(email);
    setEmailValidState(validateEmailStatus);

    if (validateEmailStatus) {
      setStep(2);
      setPendingTimeoutSendOtpState(true);

      try {
        const response = await fetchGetOtp();
        if (!response) {
          setEmailValidState(false);
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    }
  }, [email, fetchGetOtp, dispatchCreateToast]);

  const handleClickEnterVerifyCode = useCallback(async () => {
    const validateOtpStatus = validateOnlyNumber(otp);
    setOtpValidState(validateOtpStatus);

    if (validateOtpStatus) {
      try {
        setPendingAuthOtpState(true);
        const response = await fetchVerifyOtp();
        setPendingAuthOtpState(false);

        if (response) {
          setStep(3);
        } else {
          setEmailValidState(false);
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    }
  }, [otp, fetchVerifyOtp, dispatchCreateToast]);

  const contentData = useMemo(() => {
    switch (step) {
      case 1: {
        return {
          title: 'Nhận mã xác thực',
          describe: 'Vui lòng nhập email để nhận mã xác thực',
          submitBtn: (
            <TextButton
              color='primary'
              variant='contained'
              onClick={() => {
                handleClickSendVerifyCode();
              }}
            >
              Gửi mã xác thực
            </TextButton>
          ),
          inputList: [
            {
              name: 'email',
              title: 'Nhập địa chỉ email của bạn',
              require: true,
              value: email,
              validate: emailValidState,
              onchange: _handleChangeInput,
              extendValidate: undefined,
            },
          ],
        };
      }
      case 2: {
        return {
          title: 'Nhập mã xác thực',
          describe: (
            <>
              <span>{`Mã xác thực đã được gửi tới email của bạn.`}</span>
              <br />
              <span>Không nhận được mã xác thực? </span>
              {pendingTimeoutSendOtpState ? (
                <span> Gửi lại sau: 00:{countdown}</span>
              ) : (
                <span
                  className='re-send'
                  onClick={() => {
                    handleClickSendVerifyCode();
                  }}
                >
                  Gửi lại
                </span>
              )}
            </>
          ),
          submitBtn: (
            <>
              {pendingAuthOtpState ? (
                <TextButton
                  color='primary'
                  variant='contained'
                  startIcon={<CircularProgress size={20} color='inherit' />}
                >
                  <span color='gray'>Đang chờ xác thực</span>
                </TextButton>
              ) : (
                <TextButton
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    handleClickEnterVerifyCode();
                  }}
                >
                  Xác thực ngay
                </TextButton>
              )}
            </>
          ),
          // submitText: !pendingAuthOtpState ? 'Xác thực ngay' : 'Đang chờ xác thực',
          inputList: [
            {
              name: 'code',
              title: 'Nhập mã xác thực của bạn',
              require: true,
              value: otp,
              validate: otpValidState,
              onchange: _handleChangeInput,
              extendValidate: undefined,
            },
          ],
        };
      }
      case 3: {
        return {
          title: 'Mật khẩu mới đã được gửi về email',
          describe:
            'Mật khẩu mới đã được gửi về địa chỉ email của bạn, vui lòng kiểm tra và đăng nhập',
          submitBtn: (
            <TextButton color='primary' variant='contained' onClick={handleClose}>
              Đăng nhập ngay
            </TextButton>
          ),
        };
      }
    }
  }, [
    step,
    email,
    _handleChangeInput,
    otp,
    otpValidState,
    emailValidState,
    handleClickSendVerifyCode,
    handleClickEnterVerifyCode,
    pendingTimeoutSendOtpState,
    countdown,
    pendingAuthOtpState,
    handleClose,
  ]);

  return (
    <AddPanelElement ref={changePasswordPanelRef}>
      <ContentElement>
        <div className='header'>
          <div className='left'>
            <div className='title'>Khôi phục mật khẩu</div>
          </div>
          <div className='right'>
            <div className='close-btn' onClick={handleClose}>
              <TiTimes size='26px' />
            </div>
          </div>
        </div>
        <div className='description'>
          Để giữ tài khoản của bạn được an toàn, Trạm Quan Trắc muốn chắc rằng thực sự là bạn đang
          đăng nhập
        </div>
        <div className='body'>
          <div className='content'>
            <div className='title'>{contentData?.title}</div>
            <div className='description'>{contentData?.describe}</div>
            {contentData?.inputList?.map((one, index) => (
              <Input
                title={one.title}
                key={index}
                name={one.name}
                require={one.require}
                value={one.value}
                onChange={one.onchange}
                validate={one.validate}
                extendValidate={one.extendValidate}
              />
            ))}
          </div>
        </div>
        <div className='footer'>
          {step !== 3 ? (
            <span className='login-now' onClick={handleClose}>
              Đăng nhập ngay?
            </span>
          ) : (
            <div></div>
          )}
          {contentData?.submitBtn}
        </div>
      </ContentElement>
    </AddPanelElement>
  );
};

const AddPanelElement = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: changeTransition 0.5s ease alternate forwards;

  @keyframes flyUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes changeTransition {
    0% {
      background: transparent;
    }
    100% {
      background: rgba(0, 0, 0, 0.6);
    }
  }
`;

const ContentElement = styled.div`
  background: white;
  color: rgb(49, 49, 49);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  height: 350px;
  animation: flyUp 0.5s ease alternate forwards;

  & > .header {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 17px;
      font-weight: 600;
      margin-left: 8px;
      color: #313131;
    }

    .close-btn {
      padding: 0 5px;
      cursor: pointer;
    }
  }

  & > .description {
    width: 100%;
    font-size: 13px;
    text-align: center;
    color: #8d9aa9;
  }

  & > .body {
    width: 100%;
    height: 100%;
    padding: 10px;

    & > .content {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
      width: 100%;
      height: 100%;

      & > .title {
        font-weight: 600;
        font-size: 14px;
        text-align: start;
      }

      & > .description {
        margin-top: 8px;
        font-size: 13px;
        color: #8d9aa9;
        text-align: start;

        .re-send {
          color: #666fe8;
          cursor: pointer;

          &:hover {
            color: #666fe8;
          }
        }
      }
    }
  }

  & > .footer {
    padding: 10px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    padding: 20px;

    & > * {
      font-size: 14px;
    }

    & > span.login-now {
      color: #8d9aa9;
      cursor: pointer;

      &:hover {
        color: #666fe8;
      }
    }
  }
`;

export default ChangePassword;
