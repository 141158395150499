/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { validateEmpty, validatePassword, validateSpecialCharecters } from '~/assets/validate';
import { useAuthInfoStore } from '~/store/hooks';
import { getInputType } from '~/plugins/input';
import LoginBackground from '~/assets/img/login-background.jpg';
import CheckBox from './components/CheckBox';
import ChangePasswordPanel from './components/ForgotPasswordPanel';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameValidState, setUsernameValidState] = useState(true);
  const [passwordValidState, setPasswordValidState] = useState(true);
  const [showForgotPasswordPanel, setShowForgotPasswordPanel] = useState<boolean>(false);

  const { t } = useTranslation();

  const {
    authState,
    dispatchAuthLoginRequest,
    dispatchAuthCleanErrorInfo,
    dispatchAuthSetKeepLogin,
  } = useAuthInfoStore();
  const navigate = useNavigate();

  const handleChangeUsername = useCallback(
    (e: any) => {
      const usernameValue = e?.target?.value || '';
      setUsername(usernameValue);

      if (!usernameValidState) {
        setUsernameValidState(true);
      }
    },
    [usernameValidState, setUsername, setUsernameValidState],
  );

  const handleChangePassword = useCallback(
    (e: any) => {
      const passwordValue = e.target.value;

      setPassword(passwordValue);

      if (!passwordValidState) {
        setPasswordValidState(true);
      }
    },
    [setPasswordValidState, setPassword, passwordValidState],
  );

  const handleSubmit = useCallback(() => {
    const validateUsernameStatus = validateEmpty(username) && validateSpecialCharecters(username);
    const validatePasswordStatus = validatePassword(password);

    setUsernameValidState(validateUsernameStatus);
    setPasswordValidState(validatePasswordStatus);

    if (validateUsernameStatus && validatePasswordStatus) {
      dispatchAuthLoginRequest({
        username,
        password,
      });
    }
  }, [dispatchAuthLoginRequest, username, password]);

  useEffect(() => {
    dispatchAuthCleanErrorInfo();
  }, []);

  useEffect(() => {
    const handleClickEnter = (e: any) => {
      e.preventDefault();
      if (e.key === 'Enter' || e.keyCode === 13) {
        handleSubmit();
      }
    };

    document.addEventListener('keyup', handleClickEnter);

    return () => {
      document.removeEventListener('keyup', handleClickEnter);
    };
  }, [handleSubmit]);

  useEffect(() => {
    if (authState?.authError?.error && (username || password)) {
      dispatchAuthCleanErrorInfo();
    }
  }, [username, password]);

  useEffect(() => {
    if (authState?.authLogin?.access_token) {
      navigate('/');
    }
  }, [authState?.authLogin?.access_token]);

  const handleTickKeepLogin = useCallback(
    (e: any) => {
      const checked = e.target.checked;
      dispatchAuthSetKeepLogin(checked);
    },
    [dispatchAuthSetKeepLogin],
  );

  const handleShowForgotPasswordPanel = (visible: boolean) => {
    setShowForgotPasswordPanel(visible);
  };

  const inputList = useMemo(
    () => [
      {
        name: 'username',
        title: 'Username',
        require: true,
        value: username,
        onchange: handleChangeUsername,
        validate: usernameValidState,
      },
      {
        name: 'password',
        title: 'Password',
        require: true,
        value: password,
        onchange: handleChangePassword,
        validate: passwordValidState,
      },
    ],
    [username, password, handleChangeUsername, handleChangePassword],
  );

  if (!authState?.authLogin?.access_token) {
    return (
      <LoginContainer>
        <LeftWrapper />
        <RightWrapper>
          <Form>
            <div className='title'>TRẠM QUAN TRẮC ADMIN</div>
            <LoginForm>
              {inputList.map((one, index) => (
                <div className={`${one.name}`} key={index}>
                  <div className='header'>
                    <span>{t(`input.${one.name}`)}</span>
                    <span className='require-btn'>{one.require ? 'Yêu cầu' : 'Tùy chọn'}</span>
                  </div>
                  <div className='body'>
                    <label htmlFor={`${one.name}-input`}>
                      <input
                        type={getInputType(one.name)}
                        name={one.name}
                        id={`${one.name}-input`}
                        spellCheck='false'
                        value={one.value}
                        onChange={one.onchange}
                      />
                    </label>
                  </div>
                  <div className='footer'>
                    <div className={`warning ${one.validate ? 'hidden' : 'visible'}`}>
                      Vui lòng nhập lại: {t(`input.${name}`)}.
                    </div>
                  </div>
                </div>
              ))}
            </LoginForm>
            <Extend>
              <div className='remember-me'>
                <CheckBox
                  title='Giữ đăng nhập'
                  value='rememberMe'
                  onChange={handleTickKeepLogin}
                  checked={authState.keepLogin}
                />
              </div>
              <div
                className='forgot-password'
                onClick={() => {
                  handleShowForgotPasswordPanel(true);
                }}
              >
                Quên mật khẩu?
              </div>
            </Extend>
            <SignIn>
              <button className='sign-in-btn ripple' onClick={handleSubmit}>
                Đăng nhập
              </button>
            </SignIn>
          </Form>
        </RightWrapper>
        {showForgotPasswordPanel && <ChangePasswordPanel onShow={handleShowForgotPasswordPanel} />}
      </LoginContainer>
    );
  } else return <></>;
};

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
`;

const LeftWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #eef1f6 url(${LoginBackground}) center center no-repeat;
  background-size: cover;
`;

const RightWrapper = styled.div`
  min-width: 584px;
  width: 40%;
  height: 100%;

  background: #ffffff;
  border: 1px solid rgba(102, 111, 232, 0.08);
  box-shadow: -10px 5px 10px rgba(46, 91, 255, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.div`
  width: 320px;
  & > .title {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 600;
  }
`;

const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 8px;
  overflow: hidden;

  .error-message {
    animation: flyDown 0.5s ease 0s 1 alternate forwards;
    color: #d63649;
  }

  .password,
  .username {
    width: 320px;
    height: fit-content;
    padding: 8px 0;
    overflow: visible;

    .header {
      z-index: 2;
      display: flex;
      justify-content: space-between;

      span {
        color: #8d9aa9;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.125714px;
      }

      padding: 8px 0;

      .require-btn {
        margin-right: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        letter-spacing: -0.125714px;
        color: #8d9aa9;
      }
    }

    .body {
      width: 100%;
      z-index: 2;

      label {
        background: #fafafa;
        border: 1px solid #bfc5d2;
        border-radius: 5px;
        width: 100%;
        height: 40px;
        overflow: hidden;
        padding: 6px 12px 10px 12px;
        transition: 0.3s;
        display: block;

        &:focus-within {
          background-color: #e8e9f7;
        }

        input {
          font-style: normal;
          line-height: 21px;
          // display: flex;
          // align-items: center;
          color: #2e384d;
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          outline: none;
        }
      }
    }

    .footer {
      z-index: 1;

      div.require {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        span {
          font-size: 12px;
          display: flex;

          svg {
            margin-right: 8px;
          }
        }
      }

      div.warning {
        animation: flyUp 0.5s ease 0s 1 alternate forwards;
        margin-left: 5px;
        color: #d63649;
        font-size: 12px;
      }
    }
  }

  .password {
    .header {
      display: flex;
      justify-content: space-between;

      .forgot-password-btn {
        margin-right: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        letter-spacing: -0.125714px;
        color: #666fe8;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Extend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  .remember-me {
  }

  .forgot-password {
    font-size: 12px;
    cursor: pointer;
    color: #8d9aa9;

    &:hover {
      color: #666fe8;
    }
  }
`;

const SignIn = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 40px;

  .sign-in-btn {
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 40px;
    color: white;
    background-color: #666fe8;
    border: 1px solid #545dd3;
    box-shadow: -5px 5px 5px rgba(46, 91, 255, 0.08);
    border-radius: 4px;
    font-size: 16px;
  }
`;

export default memo(Login);
