import * as React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { MdClose as CloseIcon } from 'react-icons/md';
import { GrayColors, MODAL_LAYER_ID } from '~/constants';
import Portal from '~/components/atoms/Portal';
import IconButton from '~/components/atoms/Button/IconButton';

type Props = React.PropsWithChildren<{
  opened: boolean;
  width?: string;
  height?: string;
  handleClose?: () => void;
}>;

const ModalContainer = ({ children, opened, width, height, handleClose }: Props): JSX.Element => {
  const nodeRef = React.useRef(null);
  return (
    <Portal targetID={MODAL_LAYER_ID}>
      <CSSTransition nodeRef={nodeRef} in={opened} classNames='fade' timeout={400} unmountOnExit>
        <Aside>
          <Mask onClick={handleClose} />
          <Container width={width} height={height}>
            {handleClose && (
              <CloseButton>
                <IconButton textColor='#fff' bgColor={GrayColors.G7} handleClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </CloseButton>
            )}
            {children}
          </Container>
        </Aside>
      </CSSTransition>
    </Portal>
  );
};

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: visible;
  overflow-y: auto;
  z-index: 10002;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const Mask = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: default;
`;

const Container = styled.div<Pick<Props, 'width' | 'height'>>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => (width ? width : 'auto')};
  height: ${({ height }) => (height ? height : 'auto')};
  max-width: 96vw;
  max-height: 96vh;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 100001;
`;

export default ModalContainer;
