import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import { moment } from '~/plugins';
import * as React from 'react';

interface Data {
  name: string;
  key: string;
  unit: string;
  create_at: any;
  id: string;
}

function createData(id: string, name: string, key: string, unit: string, create_at?: any): Data {
  return {
    id,
    name,
    key,
    unit,
    create_at,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Cảm biến',
  },
  // {
  //   id: 'key',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Key',
  // },
  {
    id: 'unit',
    numeric: true,
    disablePadding: false,
    label: 'Đơn vị',
  },
  // {
  //   id: 'create_at',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Ngày tạo',
  // },
];

interface EnhancedTableHeadProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ fontWeight: '600' }}
          >
            {headCell.label}
            {headCell.id === 'name' ? ` (Đã chọn ${numSelected})` : ''}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export interface EnhancedTablePropsType {
  children?: React.ReactNode | React.ReactNode[];
  data: any[];
  selected: readonly string[];
  onChangeSelectedSensor: (data: readonly string[]) => void;
}

export default function EnhancedTable(props: EnhancedTablePropsType) {
  const { data, selected, onChangeSelectedSensor } = props;
  const [rows, setRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (data && Array.isArray(data)) {
      const rows_tmp: any[] = [];
      data.forEach((one) => {
        rows_tmp.push(createData(one.id, one.name, one.key, one.unit, one.created_at));
      });
      setRows(rows_tmp);
    }
  }, [data]);

  const handleSelectAllClick = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.id);
        onChangeSelectedSensor(newSelected);
        return;
      }
      onChangeSelectedSensor([]);
    },
    [onChangeSelectedSensor, rows],
  );

  const handleClick = React.useCallback(
    (event: React.MouseEvent<unknown>, id: string) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: readonly string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      onChangeSelectedSensor(newSelected);
    },
    [onChangeSelectedSensor, selected],
  );

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 0;

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        height: '100%',
        paddingLeft: '5px',
        paddingBottom: '15px',
      }}
    >
      <TableContainer sx={{ maxHeight: '100%' }}>
        <Table
          sx={{ minWidth: 750, maxHeight: '100%' }}
          stickyHeader
          aria-label='sticky table'
          size='medium'
        >
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row.id)}
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component='th'
                    id={labelId}
                    scope='row'
                    padding='none'
                    sx={{ width: '250px' }}
                  >
                    {row.name}
                  </TableCell>
                  {/* <TableCell align='center' sx={{ width: '250px' }}>
                    {row.key}
                  </TableCell> */}
                  <TableCell align='center' sx={{ width: '250px' }}>
                    {row.unit}
                  </TableCell>
                  {/* <TableCell align='center' sx={{ width: '250px' }}>
                    {moment(row.create_at)?.format('YYYY-MM-DD HH:mm:ss')}
                  </TableCell> */}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
