import { TextFieldProps } from '@mui/material/TextField';
import { Control, useController } from 'react-hook-form';
import InputForm from '~/components/atoms/FormFields/InputForm';

export type InputFieldProps = TextFieldProps & {
  name: string;
  control: Control<any>;
};

const InputField = (props: InputFieldProps): JSX.Element => {
  const { name, control, ...otherProps } = props;
  const {
    field: { value, onChange, onBlur, ref },
    fieldState: { invalid, error },
  } = useController({ name, control });
  return (
    <InputForm
      value={value}
      invalid={invalid}
      error={error}
      onChange={onChange}
      onBlur={onBlur}
      comRef={ref}
      {...otherProps}
    />
  );
};

export default InputField;
