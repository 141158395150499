import {
  PermissionState,
  PermissionAction,
  PermissionActionType,
} from '~/store/ducks/permission/types';

const initState: PermissionState = {
  listPermissions: {
    list: [],
    pagination: {
      total: 0,
      current: 0,
      pageSize: 0,
    },
  },
};

export default function PermissionReducer(
  state = initState,
  action: PermissionAction,
): PermissionState {
  switch (action.type) {
    case PermissionActionType.GET_LIST_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        listPermissions: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
