import { ThunkAction } from 'redux-thunk';
import { axios } from '~/plugins';
import { axios2 } from '~/plugins';
import { SampleInfoActionType } from '~/store/ducks/sampleInfo/types';
import { RootAction, RootState } from '~/store/types';
import { IListSampleInfoResponse, ISampleInfoRequest } from '~/types/';

export const getListSampleInfo =
  (stationId?: string): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: SampleInfoActionType.GET_LIST_SAMPLE_INFO });
      const res = await axios.get<IListSampleInfoResponse>('/sample_info', {
        params: {
          station_id: stationId,
        },
      });
      dispatch({
        type: SampleInfoActionType.GET_LIST_SAMPLE_INFO_SUCCESS,
        payload: { listSampleInfo: res.data.Data },
      });
    } catch (error) {
      dispatch({
        type: SampleInfoActionType.GET_LIST_SAMPLE_INFO_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const createSampleInfo = async (sampleInfo: ISampleInfoRequest) => {
  const res = await axios2.post(`/sample_info`, {
    data: [sampleInfo],
  });

  return res.data;
};

export const updateSampleInfo = async (sampleInfo: ISampleInfoRequest) => {
  const res = await axios2.put(`/sample_info`, sampleInfo);

  return res.data;
};

export const deleteSampleInfo = async (id: string) => {
  const res = await axios2.delete(`/sample_info?id=${id}`);

  return res.data;
};
