import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { FiPlus } from 'react-icons/fi';

import TextButton from '~/components/atoms/Button';
import DataTable from '~/components/molecules/DataTable';
import CustomItemHistoryModal from '~/components/organism/Modal/CustomItemHistoryModal';
import useHistoryTable from '~/pages/Station/StationHistory/constants';
import { useLoading } from '~/hooks';
import { useStationStore } from '~/store/hooks';
import { IStationHistoryParamsRequestList } from '~/types';
import { stationHistoryDeleteLoadingModule, stationHistoryListLoadingModule } from '~/utils';
import { useSearchParams } from 'react-router-dom';
import { TableConfigs } from '~/constants';

export interface HistoryPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const History = (props: HistoryPropsType) => {
  const { onConfirmDelete } = props;
  const { t } = useTranslation();
  const { stationId } = useParams();
  const isLoading = useLoading(stationHistoryListLoadingModule);
  const isLoadingStationHistoryDelete = useLoading(stationHistoryDeleteLoadingModule);
  const {
    columns,
    isModalHistoryOpen,
    itemEdit,
    handleUpdateStationHistory,
    handleCloseModalUpdate,
  } = useHistoryTable({ onConfirmDelete });

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { stationState, dispatchGetListStationHistory } = useStationStore();
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useMemo(() => {
    if (page) setCurrentPage(Number(page));
    else setCurrentPage(1);
  }, [page]);

  const handleChangePage = React.useCallback(
    (page: number) => {
      setCurrentPage(page);
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleAddHistory = React.useCallback(() => {
    handleUpdateStationHistory(null);
  }, [handleUpdateStationHistory]);

  const fetchStationHistoryList = React.useCallback(
    async (params: IStationHistoryParamsRequestList) => {
      await dispatchGetListStationHistory(params);
    },
    [dispatchGetListStationHistory],
  );

  const fetchStationHistoryListCallBack = React.useCallback(() => {
    if (stationId) {
      fetchStationHistoryList({
        station_id: stationId,
        current: currentPage,
        pageSize: TableConfigs.defaultPageSize,
      });
    }
  }, [currentPage, stationId, fetchStationHistoryList]);

  React.useEffect(() => {
    if (stationId) {
      fetchStationHistoryList({
        station_id: stationId,
        current: currentPage,
        pageSize: TableConfigs.defaultPageSize,
      });
    }
  }, [currentPage, stationId, isLoadingStationHistoryDelete, fetchStationHistoryList]);

  return (
    <React.Fragment>
      <HistoryContainer>
        <Title>{t('sidebar.history')}</Title>
        <HistoryHeader>
          <ActionButtonContainer>
            <TextButton
              color='primary'
              variant='contained'
              startIcon={<FiPlus />}
              onClick={handleAddHistory}
            >
              {t('history.action.add_history')}
            </TextButton>
          </ActionButtonContainer>
        </HistoryHeader>
        <DataTable
          isFetching={isLoading}
          data={stationState.listHistory.list}
          columns={columns}
          totalPage={stationState.listHistory.pagination.totalPage || 1}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
        />
      </HistoryContainer>
      {isModalHistoryOpen && (
        <CustomItemHistoryModal
          opened={isModalHistoryOpen}
          itemEdit={itemEdit}
          fetchStationHistoryListCallBack={fetchStationHistoryListCallBack}
          handleClose={handleCloseModalUpdate}
        />
      )}
    </React.Fragment>
  );
};

const HistoryContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;

const Title = styled.div`
  color: #455560;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .MuiButton-root ~ .MuiButton-root {
    margin-left: 16px;
  }
`;

export default History;
