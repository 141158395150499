import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TiTimes } from 'react-icons/ti';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import TextButton from '~/components/atoms/Button';
import { useToastStore } from '~/store/hooks';
import Input from './Input';

import { createSampleInfo, createStationConfig } from '~/store/actions';

import { validateEmpty } from '~/assets/validate';

// import '../styles/SettingsPanel.scss';

export interface AddPanelPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onShow?: any;
  type?: string;
  fetchListView?: any;
  fetchListStationConfig?: any;
}

const AddPanel = (props: AddPanelPropsType) => {
  const { onShow, type, fetchListView, fetchListStationConfig } = props;

  const [key, setKey] = useState('');
  const [name, setName] = useState('');
  const [priority, setPriority] = useState('');
  const [unit, setUnit] = useState('');
  const [keyValidateState, setKeyValidateState] = useState(true);
  const [nameValidateState, setNameValidateState] = useState(true);
  const [priorityValidateState, setPriorityValidateState] = useState(true);
  const [unitValidateState, setUnitValidateState] = useState(true);

  const settingsStationPanelRef = useRef<any>(null);
  const contentRef = useRef(null);

  const { dispatchCreateToast } = useToastStore();

  useEffect(() => {
    if (settingsStationPanelRef?.current && contentRef?.current) {
      settingsStationPanelRef.current.onclick = (e: any) => {
        if (e.target == settingsStationPanelRef.current) onShow(false);
      };
    }
  }, [onShow]);

  const _handleChangeInput = (e: any) => {
    e.preventDefault();
    const name = e?.target?.name;
    const value = e?.target?.value;

    if (!value || !name) return;

    switch (name) {
      case 'key': {
        setKey(value);

        if (!keyValidateState) {
          setKeyValidateState(true);
        }

        break;
      }
      case 'name': {
        setName(value);

        if (!nameValidateState) {
          setNameValidateState(true);
        }

        break;
      }
      case 'priority': {
        setPriority(value);

        if (!priorityValidateState) {
          setPriorityValidateState(true);
        }

        break;
      }
      case 'unit': {
        setUnit(value);

        if (!unitValidateState) {
          setUnitValidateState(true);
        }

        break;
      }
    }
  };

  const handleClose = useCallback(() => {
    if (onShow) {
      onShow(false);
    }
  }, [onShow]);

  const inputList1 = [
    {
      name: 'name',
      title: 'Name',
      require: true,
      value: name,
      validate: nameValidateState,
      onchange: _handleChangeInput,
    },
    {
      name: 'key',
      title: 'Key',
      require: true,
      value: key,
      validate: keyValidateState,
      onchange: _handleChangeInput,
    },
    {
      name: 'unit',
      title: 'Unit',
      require: true,
      value: unit,
      validate: unitValidateState,
      onchange: _handleChangeInput,
    },
    {
      name: 'priority',
      title: 'Priority',
      require: true,
      value: priority,
      validate: priorityValidateState,
      onchange: _handleChangeInput,
    },
  ];

  const inputList2 = [
    {
      name: 'name',
      title: 'Name',
      require: true,
      value: name,
      validate: nameValidateState,
      onchange: _handleChangeInput,
    },
    {
      name: 'key',
      title: 'Key',
      require: true,
      value: key,
      validate: keyValidateState,
      onchange: _handleChangeInput,
    },
  ];

  const handleCreateView = useCallback(async () => {
    const validateName = validateEmpty(name);
    const validateKey = validateEmpty(key);
    const validatePriority = validateEmpty(priority);
    const validateUnit = validateEmpty(unit);

    setNameValidateState(validateName);
    setKeyValidateState(validateKey);
    setUnitValidateState(validateUnit);
    setPriorityValidateState(validatePriority);

    if (!(validateName || validateKey || validateUnit || validatePriority)) return;

    try {
      const resp = await createSampleInfo({
        name,
        key,
        unit,
        priority: parseInt(priority) ? parseInt(priority) : 0,
      });

      if (resp) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Tạo view thành công!',
        });

        fetchListView();
        handleClose();
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [fetchListView, dispatchCreateToast, name, unit, key, priority, handleClose]);

  const handleCreateStationConfig = useCallback(async () => {
    const validateName = validateEmpty(name);
    const validateKey = validateEmpty(key);

    setNameValidateState(validateName);
    setKeyValidateState(validateKey);

    if (!(validateName || validateKey)) return;

    try {
      const resp = await createStationConfig({
        name,
        key,
      });

      if (resp) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Thêm cấu hình trạm thành công!',
        });

        fetchListStationConfig();
        handleClose();
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [fetchListStationConfig, dispatchCreateToast, name, key, handleClose]);

  return (
    <AddPanelElement ref={settingsStationPanelRef}>
      <ContentElement>
        <div className='header'>
          <div className='left'>
            <div className='title'>{type === 'view' ? 'Tạo view' : 'Tạo cấu hình trạm'}</div>
          </div>
          <div className='right'>
            <div className='close-btn' onClick={handleClose}>
              <TiTimes size='26px' />
            </div>
          </div>
        </div>
        <div className='body'>
          <div className='col'>
            {type === 'view'
              ? inputList1.map((one, index) => (
                  <Input
                    title={one.title}
                    key={index}
                    name={one.name}
                    require={one.require}
                    defaultValue={one.value}
                    onChange={one.onchange}
                    validate={one.validate}
                  />
                ))
              : inputList2.map((one, index) => (
                  <Input
                    title={one.title}
                    key={index}
                    name={one.name}
                    require={one.require}
                    defaultValue={one.value}
                    onChange={one.onchange}
                    validate={one.validate}
                  />
                ))}
          </div>
        </div>
        <div className='footer'>
          <TextButton
            color='primary'
            variant='contained'
            onClick={() => {
              if (type === 'view') {
                handleCreateView();
              } else if (type === 'config') {
                handleCreateStationConfig();
              }
            }}
          >
            {type === 'view' ? 'Tạo view' : 'Tạo cấu hình trạm'}
          </TextButton>
          <TextButton
            color='inherit'
            variant='contained'
            onClick={() => {
              handleClose();
            }}
          >
            Huỷ bỏ
          </TextButton>
        </div>
      </ContentElement>
    </AddPanelElement>
  );
};

const AddPanelElement = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: changeTransition 0.5s ease alternate forwards;

  @keyframes flyUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes changeTransition {
    0% {
      background: transparent;
    }
    100% {
      background: rgba(0, 0, 0, 0.6);
    }
  }
`;

const ContentElement = styled.div`
  background: white;
  color: rgb(49, 49, 49);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  min-height: 450px;
  max-height: fit-content;
  animation: flyUp 0.5s ease alternate forwards;

  & > .header {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 17px;
      font-weight: 600;
      margin-left: 8px;
      color: #313131;
    }

    .close-btn {
      padding: 0 5px;
      cursor: pointer;
    }
  }

  & > .body {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    & > .col {
      width: 100%;
      height: 100%;

      & > .title {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }

      & > .content {
        padding: 8px;
        width: 100%;
        height: 100%;

        & > .table {
          width: 100%;
          height: 100%;
          background: #fafafa;
          border-radius: 5px;
          padding: 0 10px;
          overflow-x: hidden;
          overflow-y: overlay;

          & > .one-row {
            display: flex;
            color: #8d9aa9;
            justify-content: space-between;
            padding: 10px;
            font-size: 13px;
            align-items: center;
            border-bottom: 0.2px solid rgba(173, 173, 173, 0.5);
          }
        }
      }
    }
  }

  & > .footer {
    padding: 10px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > * {
      font-size: 14px;
    }

    & > *:first-child {
      margin-right: 10px;
    }
  }
`;

export default AddPanel;
