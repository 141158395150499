import Tooltip from '@mui/material/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import i18next from 'i18next';
import * as React from 'react';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import styled from 'styled-components';

import { moment } from '~/plugins';
import { useStationStore } from '~/store/hooks';
import { IStationHistory } from '~/types';

const ActionRow = styled.div`
  display: flex;
  justify-content: center;

  & > .action {
    cursor: pointer;
    margin-right: 16px;
    &:hover {
      color: #666fe8;
    }
  }
`;

export interface useHistoryPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const useHistoryTable = (props: useHistoryPropsType) => {
  const { onConfirmDelete } = props;
  const { dispatchDeleteStationHistory } = useStationStore();
  const [isModalHistoryOpen, setIsModalHistoryOpen] = React.useState<boolean>(false);
  const [itemEdit, setItemEdit] = React.useState<IStationHistory | null>(null);

  const handleCloseModalUpdate = React.useCallback(() => {
    setIsModalHistoryOpen(false);
  }, []);

  const handleUpdateStationHistory = React.useCallback((item: IStationHistory | null) => {
    setIsModalHistoryOpen(true);
    setItemEdit(item);
  }, []);

  const handleDeleteStationHistory = React.useCallback(
    async (item: IStationHistory) => {
      const resultConfirmDelete = await onConfirmDelete(undefined);
      if (!resultConfirmDelete) return;

      dispatchDeleteStationHistory(item.id);
    },
    [dispatchDeleteStationHistory, onConfirmDelete],
  );

  const columns = React.useMemo<ColumnDef<IStationHistory>[]>(() => {
    return [
      {
        accessorKey: 'title',
        header: i18next.t('history.table.name'),
      },
      {
        accessorKey: 'description',
        header: i18next.t('history.table.description'),
      },
      {
        accessorKey: 'time_start',
        header: i18next.t('history.table.created_at'),
        cell: (row) => {
          const created_at = row.getValue() as string;
          return moment(created_at).format('YYYY/MM/DD');
        },
      },
      {
        accessorKey: 'time_end',
        header: i18next.t('history.table.updated_at'),
        cell: (row) => {
          const created_at = row.getValue() as string;
          return moment(created_at).format('YYYY/MM/DD');
        },
      },
      {
        accessorKey: '',
        header: i18next.t('history.table.action'),
        cell: ({ row: { original } }) => {
          return (
            <ActionRow>
              <Tooltip title='Cập nhật lịch sử'>
                <span className='action'>
                  <FiEdit3 size='20px' onClick={() => handleUpdateStationHistory(original)} />
                </span>
              </Tooltip>

              <Tooltip title='Xóa lịch sử'>
                <span className='action'>
                  <FiTrash2
                    size='20px'
                    onClick={() => {
                      handleDeleteStationHistory(original);
                    }}
                  />
                </span>
              </Tooltip>
            </ActionRow>
          );
        },
      },
    ];
  }, [handleDeleteStationHistory, handleUpdateStationHistory]);
  return {
    columns,
    isModalHistoryOpen,
    itemEdit,
    handleUpdateStationHistory,
    handleCloseModalUpdate,
  };
};

export default useHistoryTable;
