import Tooltip from '@mui/material/Tooltip';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiTrash2 } from 'react-icons/fi';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { AddUserIcon, TrashIcon } from '~/assets/Icon';
import {
  validateEmail,
  validateEmpty,
  validateOnlyNumber,
  validateSpecialCharecters,
} from '~/assets/validate';
import { RootPath } from '~/configs';
import { listStatus } from '~/configs/variables';
import { getDetailRoleRequest } from '~/store/ducks/role/actions';
import useRoleStore from '~/store/ducks/role/hook';
import {
  addStationForUser,
  deleteStationForUser,
  getUserById,
  updateUserRequest,
} from '~/store/ducks/user/actions';
import { useStationStore, useToastStore, useAuthInfoStore } from '~/store/hooks';
import { IStationParamsRequestList } from '~/types';
import AddMenu from '../components/AddMenu';
import ChangePasswordPanel from '../components/ChangePasswordPanel';
import Input from '../components/Input';
import SelectMenu from '../components/SelectMenu';
import SettingsStationPanel from '../components/SettingsStationPanel';
import { deleteUserRequest } from '~/store/ducks/user/actions';

export interface EditUserPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const EditUser = (props: EditUserPropsType) => {
  const { onConfirmDelete } = props;
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [userType, setUserType] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [fullname, setFullname] = useState('');
  const [status, setStatus] = useState('');
  const [usernameValidState, setUsernameValidState] = useState(true);
  const [emailValidState, setEmailValidState] = useState(true);
  const [phoneValidState, setPhoneValidState] = useState(true);
  const [addressValidState, setAddressValidState] = useState(true);
  const [fullnameValidState, setFullnameValidState] = useState(true);
  const [userTypeValidState, setUserTypeValidState] = useState(true);
  const [initStatus, setInitStatus] = useState({
    key: '',
    value: '',
  });
  const [initRole, setInitRole] = useState({
    key: '',
    value: '',
  });
  const [userDataInit, setUserDataInit] = useState<any>(null);
  const [listRoles, setListRoles] = useState([] as any[]);
  const [listSubUsers, setListSubUsers] = useState<any[]>([]);
  const [listStationInit, setListStationInit] = useState([] as any[]);
  const [listStationSelected, setListStationSelected] = useState([] as any[]);
  const [showStationSettingPanelState, setShowStationSettingPanelState] = useState<any>({
    visible: false,
    stationId: '',
    stationName: '',
  });
  const [showChangePasswordPanel, setShowChangePasswordPanel] = useState<boolean>(false);

  const { authState } = useAuthInfoStore();
  const { roleState, dispatchGetListRoles } = useRoleStore();
  const { stationState, dispatchGetListStation } = useStationStore();

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatchCreateToast } = useToastStore();

  const listStations = useMemo(() => {
    return stationState?.listStation?.list?.map((one) => {
      return { id: '', key: one.id, value: one.name };
    });
  }, [stationState?.listStation?.list]);

  const fetchListRole = useCallback(async () => {
    await dispatchGetListRoles();
  }, [dispatchGetListRoles]);

  const fetchListStation = useCallback(
    async (params: IStationParamsRequestList) => {
      await dispatchGetListStation(params);
    },
    [dispatchGetListStation],
  );

  // const fetchRoleDetail = useCallback(async () => {
  //   const role = listRoles.find((one: any) => one?.key === userType);

  //   if (!role) return;
  //   const detailRole = await getDetailRoleRequest(role?.id || '');

  //   if (detailRole && detailRole?.permissions) {
  //     const list = detailRole?.permissions?.map((one) => {
  //       return one.slug;
  //     });
  //     setListPermissions(list);
  //   }
  // }, [listRoles, userType]);

  const fetchUserById = useCallback(async () => {
    try {
      const resp = await getUserById(id || '');

      if (resp) {
        setUserDataInit(resp);
        const roleId = resp?.role?.id;

        if (roleId) {
          const detailRole = await getDetailRoleRequest(roleId);

          if (detailRole && detailRole?.permissions) {
            setInitRole({
              key: detailRole?.slug,
              value: detailRole?.name,
            });
          }
        }
      } else {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Lấy thông tin user thất bại!',
        });
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: 'Lấy thông tin user thất bại!',
      });
    }
  }, [id, dispatchCreateToast]);

  const handleDeleteUser = useCallback(
    async (id: string) => {
      const resultConfirmDelete = await onConfirmDelete(undefined);
      if (!resultConfirmDelete) return;

      try {
        const resp = await deleteUserRequest(id);

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Xoá user thành công!',
          });

          fetchUserById();
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast, fetchUserById, onConfirmDelete],
  );

  useEffect(() => {
    if (userDataInit) {
      setUsername(userDataInit?.user_name as string);
      setAddress(userDataInit?.address as string);
      setEmail(userDataInit?.email as string);
      setPhone(userDataInit?.phone as string);
      setFullname(userDataInit?.real_name as string);
      // setStatus(userDataInit?.status as string);
      setListStationInit(userDataInit?.stations || []);
      setListSubUsers(userDataInit?.sub_users || []);
      const status_tmp = listStatus.find((one) => {
        if (one.key == userDataInit?.role?.status) {
          return true;
        } else return false;
      });
      if (status_tmp) {
        setInitStatus(status_tmp);
      }
    }
  }, [userDataInit]);

  useEffect(() => {
    fetchUserById();
  }, [id, fetchUserById]);

  useEffect(() => {
    fetchListRole();
  }, [fetchListRole]);

  useEffect(() => {
    fetchListStation({ text: '', current: 1, pageSize: 100 });
  }, [fetchListStation]);

  // useEffect(() => {
  //   fetchRoleDetail();
  // }, [userType, fetchRoleDetail]);

  useEffect(() => {
    if (listStations?.length > 0 && listStationInit?.length > 0) {
      const arrSelected: any[] = [];
      listStations.forEach((one: any) => {
        const info = listStationInit.find((two) => two.station_id === one.key);
        if (info) {
          arrSelected.push({
            ...one,
            ...info,
          });
        }
      });
      setListStationSelected(arrSelected);
    } else {
      setListStationSelected([]);
    }
  }, [listStationInit, listStations]);

  useEffect(() => {
    if (authState?.authLogin?.user_id === id) {
      const obj = {
        id: uuidv4(),
        key: authState?.authLogin?.role_slug,
        value: authState?.authLogin?.role_name,
      };

      setListRoles([obj]);
    } else {
      if (roleState?.listRole?.list) {
        const list = roleState?.listRole?.list?.map((one) => {
          const obj = {
            id: one.id,
            key: one.slug,
            value: one.name,
          };
          return obj;
        });

        setListRoles(list);
      }
    }
  }, [
    roleState?.listRole,
    authState?.authLogin?.role_slug,
    id,
    authState?.authLogin?.user_id,
    authState?.authLogin?.role_name,
  ]);

  const _handleShowSettingStationPanel = (
    visible: boolean,
    stationId?: string,
    stationName?: string,
  ) => {
    setShowStationSettingPanelState({
      visible,
      stationId,
      stationName,
    });
  };

  const _handleChangeEmail = (e: any) => {
    const emailValue = e?.target?.value || '';
    setEmail(emailValue);

    if (!emailValidState) {
      setEmailValidState(true);
    }
  };

  const _handleChangeUsername = (e: any) => {
    const value = e?.target?.value || '';
    setUsername(value);

    if (!usernameValidState) {
      setUsernameValidState(true);
    }
  };
  const _handleChangePhone = (e: any) => {
    const value = e?.target?.value || '';
    setPhone(value);

    if (!phoneValidState) {
      setPhoneValidState(true);
    }
  };
  const _handleChangeAddress = (e: any) => {
    const value = e?.target?.value || '';
    setAddress(value);

    if (!addressValidState) {
      setAddressValidState(true);
    }
  };

  const _handleChangeFullname = (e: any) => {
    const value = e?.target?.value || '';
    setFullname(value);

    if (!fullnameValidState) {
      setFullnameValidState(true);
    }
  };

  const _handleShowChangePasswordPanel = (visible: boolean) => {
    setShowChangePasswordPanel(visible);
  };

  const _handleChangeUserType = (type: any) => {
    setUserType(type?.key);

    if (!userTypeValidState) {
      setUserTypeValidState(true);
    }
  };

  const _handleChangeStatus = (type: any) => {
    setStatus(type?.key);
  };

  const _handleClickCancelBtn = useCallback(() => {
    if (authState?.authLogin?.user_id === id) {
      navigate('/profile');
    } else {
      navigate(RootPath.User);
    }
  }, [authState?.authLogin?.user_id, id, navigate]);

  const _handleClickUpdateUserBtn = useCallback(
    async (data?: any) => {
      let validateState = 0;

      if (!data) {
        const validateEmailStatus = validateEmail(email);
        const validateUsernameStatus =
          validateEmpty(username) && validateSpecialCharecters(username);
        const validatePhoneStatus = validateEmpty(phone) && validateOnlyNumber(phone);
        const validateAddressStatus = validateEmpty(address);
        const validateFullnameStatus =
          validateEmpty(fullname) && validateSpecialCharecters(fullname);
        const validateUserTypeStatus = validateEmpty(userType);

        setEmailValidState(validateEmailStatus);
        setUsernameValidState(validateUsernameStatus);
        setPhoneValidState(validatePhoneStatus);
        setAddressValidState(validateAddressStatus);
        setFullnameValidState(validateFullnameStatus);
        setUserTypeValidState(validateUserTypeStatus);

        if (
          validateEmailStatus &&
          validateUsernameStatus &&
          validatePhoneStatus &&
          validateAddressStatus &&
          validateFullnameStatus &&
          validateUserTypeStatus
        ) {
          validateState = 1;
        } else {
          validateState = 0;
        }

        data = {
          id,
          user_name: username,
          real_name: fullname,
          phone,
          password: '',
          address,
          email,
          status: parseInt(status),
          user_role: userType,
        };
      } else if (data?.password) {
        if (
          userDataInit?.user_name &&
          userDataInit?.real_name &&
          userDataInit?.phone &&
          userDataInit?.address &&
          userDataInit?.email &&
          typeof userDataInit?.role?.status !== undefined &&
          initRole?.key
        ) {
          validateState = 2;

          data = {
            id,
            user_name: userDataInit?.user_name,
            real_name: userDataInit?.real_name,
            phone: userDataInit?.phone,
            address: userDataInit?.address,
            email: userDataInit?.email,
            status: userDataInit?.role?.status,
            password: data?.password,
            user_role: initRole?.key,
          };
        } else {
          validateState = 0;
        }
      }

      if (validateState && data) {
        try {
          const resp = await updateUserRequest(data);

          if (resp) {
            if (validateState === 1) {
              dispatchCreateToast({
                id: uuidv4(),
                toastType: 'success',
                message: 'Cập nhật user thành công!',
              });
            } else if (validateState === 2) {
              dispatchCreateToast({
                id: uuidv4(),
                toastType: 'success',
                message: 'Thay đổi mật khẩu thành công!',
              });
            }
          }
        } catch (exception) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'failed',
            message: exception as string,
          });
        }
      } else {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Vui lòng kiểm tra lại thông tin!',
        });
      }
    },
    [
      address,
      dispatchCreateToast,
      email,
      fullname,
      id,
      phone,
      status,
      userDataInit,
      userType,
      username,
      initRole?.key,
    ],
  );

  const handleAddStation = useCallback(
    async (stationId: string) => {
      try {
        const resp = await addStationForUser({
          user_id: id || '',
          station_id: stationId,
        });

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Thêm trạm thành công!',
          });

          fetchUserById();
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast, fetchUserById, id],
  );

  const handleDeleteStation = useCallback(
    async (relationId: string) => {
      try {
        const resultConfirmDelete = await onConfirmDelete(undefined);
        if (!resultConfirmDelete) return;

        const resp = await deleteStationForUser(relationId);

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Xóa trạm thành công!',
          });

          fetchUserById();
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast, fetchUserById, onConfirmDelete],
  );

  const inputList1 = [
    {
      name: 'username',
      title: 'Username',
      require: true,
      value: username,
      onchange: _handleChangeUsername,
      validate: usernameValidState,
    },
    {
      name: 'email',
      title: 'E-mail',
      require: true,
      value: email,
      onchange: _handleChangeEmail,
      validate: emailValidState,
    },
    {
      name: 'user-type',
      title: 'User type',
      require: true,
      value: userType,
      onchange: _handleChangeUserType,
      validate: true,
      body: (
        <SelectMenu
          width='100%'
          height='40px'
          data={listRoles}
          onChange={_handleChangeUserType}
          init={initRole}
        />
      ),
    },
    {
      name: 'status',
      title: 'Account Status',
      require: true,
      value: status,
      onchange: _handleChangeStatus,
      validate: true,
      body: (
        <SelectMenu
          width='100%'
          height='40px'
          data={listStatus}
          onChange={_handleChangeStatus}
          init={initStatus}
        />
      ),
    },
  ];

  const inputList2 = [
    {
      name: 'fullname',
      title: 'Fullname',
      require: true,
      value: fullname,
      onchange: _handleChangeFullname,
      validate: fullnameValidState,
    },
    {
      name: 'phone',
      title: 'Phone Number',
      require: true,
      value: phone,
      onchange: _handleChangePhone,
      validate: phoneValidState,
    },
    {
      name: 'address',
      title: 'Address',
      require: true,
      value: address,
      onchange: _handleChangeAddress,
      validate: addressValidState,
    },
    {
      name: 'token',
      title: 'Token',
      require: true,
      value: userDataInit?.secret_key,
      validate: true,
      disabled: true,
      copyBtn: true,
    },
  ];

  // const inputList3 = [

  // ];

  return (
    <EditUserContainer>
      <Header>
        <Title>{t('user.title.edit_user')}</Title>
        <div className='controls'>
          <div className='left'></div>

          <div className='right'>
            <div
              className='save ripple'
              onClick={() => {
                _handleClickUpdateUserBtn();
              }}
            >
              <div className='icon'>
                <AddUserIcon color='#fff' />
              </div>
              <span>{t('user.action.save_user')}</span>
            </div>
            <div className='cancel ripple' onClick={_handleClickCancelBtn}>
              <div className='icon'>
                <TrashIcon color='#fff' />
              </div>
              <span>{t('user.action.cancel')}</span>
            </div>
          </div>
        </div>
      </Header>
      <Content>
        <div className='user-details'>
          <div className='header'>
            <div className='title'>{t('user.title.user_information')}:</div>
          </div>
          <div className='body'>
            <div className='col col1'>
              <div className='sex'></div>
              {inputList1.map((one, index) => (
                <Input
                  title={one.title}
                  key={index}
                  name={one.name}
                  require={one.require}
                  value={one.value}
                  onChange={one.onchange}
                  validate={one.validate}
                  body={one.body}
                  // extendValidate={one.extendValidate}
                />
              ))}
              <div
                className='change-password'
                onClick={() => {
                  _handleShowChangePasswordPanel(true);
                }}
              >
                <span>Thay đổi mật khẩu ?</span>
              </div>
            </div>
            <div className='col col2'>
              {inputList2.map((one, index) => (
                <Input
                  title={one.title}
                  key={index}
                  name={one.name}
                  require={one.require}
                  value={one.value}
                  onChange={one.onchange}
                  validate={one.validate}
                  disabled={one.disabled}
                  copyBtn={one.copyBtn}
                  // body={one.body}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='exras'>
          <div className='sub-user-wrapper'>
            <div className='title'>Danh sách người dùng cấp dưới: </div>
            <div className='content'>
              <div className='table'>
                <Table>
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Tài khoản</th>
                      <th>Cài đặt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listSubUsers?.map((one) => (
                      <tr key={one.id}>
                        <td>{one.email}</td>
                        <td>{one.user_name}</td>
                        <td>
                          <div className='controls'>
                            <Tooltip title='Cập nhật thông tin'>
                              <Link to={`/users/edit/${one.id}`}>
                                <div className='icon'>
                                  <AiOutlineEdit size={17} />
                                </div>
                              </Link>
                            </Tooltip>
                            <Tooltip title='Xoá người dùng'>
                              <div
                                className='icon'
                                onClick={() => {
                                  handleDeleteUser(one.id || '');
                                }}
                              >
                                <FiTrash2 size={17} />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className='list-station-wrapper'>
            <div className='title'>Danh sách trạm:</div>
            <div className='content'>
              <AddMenu
                width='100%'
                height='48px'
                data={listStations as any}
                listSelected={listStationSelected}
                onSelected={handleAddStation}
              />
              <div className='list-stations'>
                {listStationSelected?.map((one) => (
                  <div className='one-station' key={one.key}>
                    <div className='name'>{one?.value}</div>
                    <div className='controls'>
                      <div className='icon'>
                        <AiOutlineEdit
                          size={17}
                          onClick={() => {
                            _handleShowSettingStationPanel(true, one?.key, one?.value);
                          }}
                        />
                      </div>
                      <div className='icon'>
                        <FiTrash2
                          size={17}
                          onClick={() => {
                            handleDeleteStation(one.id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Content>
      {showStationSettingPanelState?.visible && (
        <SettingsStationPanel
          onShow={_handleShowSettingStationPanel}
          stationId={showStationSettingPanelState?.stationId}
          userId={id}
          stationName={showStationSettingPanelState?.stationName}
        />
      )}
      {showChangePasswordPanel && (
        <ChangePasswordPanel
          onShow={_handleShowChangePasswordPanel}
          initData={userDataInit}
          onUpdate={_handleClickUpdateUserBtn}
        />
      )}
    </EditUserContainer>
  );
};

const EditUserContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-family: 'Roboto', sans-serif;
  position: relative;
  flex-direction: column;

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3;
    padding-left: 8px;
    color: #8798ad;

    &:hover {
      color: #666fe8;
    }
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const Header = styled.div`
  margin-bottom: 24px;

  & > .controls {
    display: flex;
    justify-content: space-between;

    & > .right {
      display: flex;
      flex-direction: row;

      & > div {
        margin: 0 8px;

        .icon {
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          margin-right: 5px;
        }
      }

      .save {
        cursor: pointer;
        color: white;
        background: #666fe8;
        border: 1px solid #545dd3;
        border-radius: 3px;
        min-width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      .cancel {
        cursor: pointer;
        min-width: 120px;
        color: #fff;
        background: #fa4747;
        border: 1px solid rgba(102, 111, 232, 0.08);
        box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;

  & > .user-details {
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;

    & > .header {
      .title {
        font-weight: 600;
        font-size: 16px;
      }
    }

    & > .body {
      width: 100%;
      height: 100%;
      display: flex;
      border: 1px solid rgba(102, 111, 232, 0.08);
      box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
      border-radius: 3px;
      margin-top: 10px;

      & > .col {
        width: 50%;
        height: 100%;
        padding: 12px;

        .sex {
          width: 100%;
        }
      }

      & > .col.col1 {
        .change-password {
          margin-top: 10px;
          width: fit-content;

          span {
            font-weight: 600;
            font-size: 13px;
            color: #666fe8;
            cursor: pointer;
            border-bottom: 1px solid #666fe8;

            &:hover {
              color: #c58af9;
              border-bottom: 1px solid #c58af9;
            }
          }
        }
      }
    }
  }

  .exras {
    min-width: 300px;
    width: 100%;
    min-height: 100%;
    margin-left: 20px;
    padding: 8px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    display: flex;
    flex-direction: column;

    & > .sub-user-wrapper {
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 8px;

      .title {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 600;
      }

      .content {
        box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
        border: 1px solid rgba(102, 111, 232, 0.08);
        padding: 8px;
        border-radius: 3px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        .table {
          width: 100%;
          height: 100%;
          overflow-x: hidden;
          overflow-y: overlay;
          border-radius: 3px;
        }
      }
    }

    & > .list-station-wrapper {
      padding-top: 8px;
      width: 100%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 600;
      }

      .content {
        border: 1px solid rgba(102, 111, 232, 0.08);
        box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;

        .list-stations {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: overlay;
          padding-top: 5px;

          .one-station {
            width: 100%;
            padding: 8px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .name {
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: start;
              overflow: hidden;
              max-width: 300px;
              /* text-overflow: ellipsis; */
              overflow-wrap: anywhere;
              white-space: break-spaces;
            }

            .controls {
              padding-left: 8px;
              display: flex;
              flex-direction: row;

              & > svg {
                margin-left: 8px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
`;

const Table = styled.table`
  width: 100%;
  height: 100%;

  thead {
    tr {
      th {
        position: sticky;
        top: 0px;
        padding: 8px;
        background: #fafafa;
      }
    }
  }

  tbody {
    vertical-align: top;
  }

  tr {
    vertical-align: top;

    & > th {
      padding: 8px;
      text-align: center;
      min-width: 90px;
      color: #8798ad;
      font-size: 14px;
    }

    & > td {
      padding: 8px;
      text-align: center;
      font-size: 13px;
      min-width: 100px;
      height: 50px;
      font-size: 13px;
      max-width: 200px;
      overflow: hidden;
      overflow-wrap: anywhere;
      white-space: break-spaces;

      .controls {
        display: flex;
        align-items: center;
        justify-content: center;

        & > .icon {
          margin: 0 8px;
        }
      }
    }
  }
`;

export default EditUser;
