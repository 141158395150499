import * as React from 'react';
import styled from 'styled-components';
import { BrandColors } from '~/constants';
import {
  MdClose as CloseIcon,
  MdDescription as DescriptionIcon,
  MdWarning as WarningIcon,
} from 'react-icons/md';
import { useDropzone } from 'react-dropzone';

const MimeTypesMapKeys = {
  doc: 'doc',
  docx: 'docx',
  pdf: 'pdf',
  png: 'png',
  jpeg: 'jpeg',
  jpg: 'jpg',
  rar: 'rar',
  zip: 'zip',
  xls: 'xls',
  xlsx: 'xlsx',
  csv: 'csv',
} as const;

type MimeTypesMapKey = typeof MimeTypesMapKeys[keyof typeof MimeTypesMapKeys];

type MimeTypesMapType = {
  [key in MimeTypesMapKey]: {
    mimeType: string;
  };
};

interface Props {
  noClick?: boolean;
  noKeyboard?: boolean;
  maxFiles?: number;
  accept: MimeTypesMapKey[];
  title?: string;
  selectFilesTitle?: string;
  additionalTitle?: string;
  handleGetUploadFile: any;
  file: any;
}

const MimeTypesMapType: MimeTypesMapType = {
  doc: { mimeType: 'application/msword' },
  docx: {
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  pdf: { mimeType: 'application/pdf' },
  png: { mimeType: 'image/png' },
  jpeg: { mimeType: 'image/jpeg' },
  jpg: { mimeType: 'image/jpg' },
  rar: { mimeType: 'application/vnd.rar' },
  zip: { mimeType: 'application/zip' },
  xls: { mimeType: 'application/vnd.ms-excel' },
  csv: { mimeType: 'text/csv' },
  xlsx: {
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
};

const UploadFile = ({
  handleGetUploadFile,
  noClick,
  noKeyboard,
  maxFiles,
  accept,
  title = 'Kéo và thả các tệp đã chuẩn bị',
  selectFilesTitle = 'Chọn tệp tin',
  additionalTitle = 'Vui lòng',
  file,
}: Props): JSX.Element => {
  const [fileUpload, setFileUpload] = React.useState<any>(file);

  const acceptType = React.useMemo<string>(() => {
    const cloneList = [...new Set(accept)];
    const listAcceptType: string[] = [];
    cloneList.forEach((type) => {
      if (MimeTypesMapType[type]) {
        listAcceptType.push(MimeTypesMapType[type].mimeType);
      }
    });
    return listAcceptType.join(', ');
  }, [accept]);

  const supportedFormatsTitle = React.useMemo<string>(() => {
    const cloneList = [...new Set(accept)];
    return `Các định dạng được hỗ trợ: ${cloneList.join(', ')}`;
  }, [accept]);

  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      setFileUpload([...fileUpload, ...acceptedFiles]);
      handleGetUploadFile([...fileUpload, ...acceptedFiles]);
    },
    [fileUpload, handleGetUploadFile],
  );

  const { getRootProps, getInputProps, open, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      noClick: noClick,
      noKeyboard: noKeyboard,
      maxFiles: maxFiles,
      accept: acceptType,
    });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const handleDeleteImported = (fileDeleted: any) => () => {
    const cloneFiles = [...fileUpload];
    cloneFiles.splice(cloneFiles.indexOf(fileDeleted), 1);
    setFileUpload(cloneFiles);
    handleGetUploadFile(cloneFiles);
  };

  const importedFiles = fileUpload.map((file: any) => (
    <div className='importFile' key={file.name}>
      <Close onClick={handleDeleteImported(file)}>
        <CloseIcon className={'icon-delete'} fontSize={'large'} />
      </Close>
      <ImportedArea>
        <DescriptionIcon className={'icon'} fontSize={'large'} />
        <div className={'description'}>
          <p>{file.name} </p>
        </div>
      </ImportedArea>
    </div>
  ));

  return (
    <ImportDialog className='container'>
      {fileUpload.length === 0 && (
        <DropzoneContainer {...getRootProps({ style })} className='dropzone-container'>
          <input {...getInputProps()} />
          <DropzoneArea>
            {(isDragAccept || !isDragActive) && (
              <DescriptionIcon className={'icon'} fontSize={'large'} />
            )}
            {isDragReject && isDragActive && (
              <WarningIcon className={'iconError'} fontSize={'default'} color={'error'} />
            )}
            <div className={'description'}>
              {(isDragAccept || !isDragActive) && (
                <p>
                  {title} {additionalTitle}{' '}
                  <strong onClick={open} className='openImportDialog'>
                    {selectFilesTitle}
                  </strong>
                </p>
              )}
              {isDragReject && isDragActive && (
                <strong className='dragReject' style={{ color: 'red' }}>
                  Định dạng tệp không được hỗ trợ.
                </strong>
              )}
              <p>{supportedFormatsTitle}</p>
            </div>
          </DropzoneArea>
        </DropzoneContainer>
      )}

      {fileUpload.length > 0 && <FileImported>{importedFiles}</FileImported>}
    </ImportDialog>
  );
};

const ImportDialog = styled.div`
  flex: 1 1 auto;
  line-height: 1.12;

  & > div {
    justify-content: center;
  }

  .openImportDialog {
    color: ${BrandColors.secondary};
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const DropzoneArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & .icon {
    display: inline-flex;
    margin-right: 5px;
    font-size: 50px;
    color: #8a8686;
  }
  & .iconError {
    display: inline;
    font-size: 30px;
    margin-right: 10px;
    color: #df1515;
  }

  & .dragReject {
    margin-top: 15px;
    display: block;
  }
`;

const DropzoneContainer = styled.div`
  width: 100%;
`;

const Close = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  background: #fafafa;
  cursor: pointer;
  & > img {
    display: block;
  }
  .icon-delete {
    font-size: 20px;
  }
`;

const FileImported = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: rgb(238, 238, 238);
  border-style: dashed;
  background-color: #fafafa;
  color: rgb(189, 189, 189);
  outline: none;
  transition: border 0.24s ease-in-out 0s;
  width: 100%;
  position: relative;
`;

const ImportedArea = styled(DropzoneArea)`
  .description {
    display: inline-flex;
  }
`;

const baseStyle = {
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
} as React.CSSProperties;

const activeStyle = {
  borderColor: '#2196f3',
} as React.CSSProperties;

const acceptStyle = {
  backgroundColor: `#d8e6f6`,
  borderColor: `${BrandColors.secondary}`,
} as React.CSSProperties;

const rejectStyle = {
  backgroundColor: `#ffe9e9`,
  borderColor: '#ff1744',
  width: '100%',
} as React.CSSProperties;

export default React.memo(UploadFile);
