import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import TextButton from '~/components/atoms/Button';
import DatePickerMonthInput from '~/components/atoms/Input/DatePickerMonthInput';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import ModalContainer from '~/components/molecules/Container/ModalContainer';
import { BrandColors } from '~/constants';
import { moment as dayjs } from '~/plugins';
import { useToastStore } from '~/store/hooks';

interface Props {
  opened: boolean;
  width?: string;
  height?: string;
  handleClose: () => void;
  handleDownLoadReport: (
    startMonthDate: string,
    endMonthDate: string,
    handleCloseModal: () => void,
  ) => void;
}

const CustomSelectTimeRangeModal = ({
  opened = false,
  width = '545px',
  height = '508px',
  handleClose,
  handleDownLoadReport,
}: Props) => {
  const { t } = useTranslation();
  const { dispatchCreateToast } = useToastStore();

  const currentMonthYear = dayjs().format('YYYY-MM');
  const [startMonthDate, setStartMonthDate] = React.useState<string | null>(
    dayjs(currentMonthYear).toString(),
  );
  const [endMonthDate, setEndMonthDate] = React.useState<string | null>(null);
  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const startIcon = React.useMemo(() => {
    if (isLoading) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
  }, [isLoading]);

  const handleStartMonthChange = React.useCallback(
    (date: Date | null) => {
      if (endMonthDate && dayjs(endMonthDate).valueOf() >= dayjs(date).valueOf()) {
        setStartMonthDate(dayjs(date).toString());
        setDisableSubmit(false);
      } else if (endMonthDate && dayjs(endMonthDate).valueOf() < dayjs(date).valueOf()) {
        setDisableSubmit(true);
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Tháng kết thúc phải lớn hơn hoặc bằng tháng bắt đầu',
        });
      } else if (!endMonthDate) {
        setStartMonthDate(dayjs(date).toString());
      }
    },
    [dispatchCreateToast, endMonthDate],
  );

  const handleEndMonthChange = React.useCallback(
    (date: Date | null) => {
      if (date && dayjs(date)?.diff(dayjs(startMonthDate), 'days') >= 0) {
        setEndMonthDate(dayjs(date).toString());
        setDisableSubmit(false);
      } else if (date && dayjs(date)?.diff(dayjs(startMonthDate), 'days') < 0) {
        setDisableSubmit(true);
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Tháng kết thúc phải lớn hơn hoặc bằng tháng bắt đầu',
        });
      }
    },
    [dispatchCreateToast, startMonthDate],
  );

  const handleCloseModal = React.useCallback(() => {
    handleClose();
    setTimeout(() => {
      setStartMonthDate(dayjs().toString());
      setEndMonthDate(dayjs().subtract(1, 'month').toString());
      setDisableSubmit(false);
      setIsLoading(false);
    }, 300);
  }, [handleClose]);

  const handleSubmit = React.useCallback(() => {
    if (handleDownLoadReport) {
      setIsLoading(true);
      handleDownLoadReport(
        dayjs(startMonthDate).format('YYYY-MM'),
        dayjs(endMonthDate).format('YYYY-MM'),
        handleCloseModal,
      );
    }
  }, [startMonthDate, endMonthDate, handleDownLoadReport, handleCloseModal]);

  return (
    <ModalContainer opened={opened} width={width} height={height} handleClose={handleCloseModal}>
      <Title>Chọn thời gian export</Title>
      <Container>
        <Section>
          <RowItem>
            <Label>Tháng bắt đầu</Label>
            <DatePickerMonthInput
              selectsStart={true}
              value={startMonthDate}
              placeholder='Tháng bắt đầu'
              handleChange={handleStartMonthChange}
            />
          </RowItem>

          <RowItem>
            <Label>Tháng kết thúc</Label>
            <DatePickerMonthInput
              selectsStart={true}
              value={endMonthDate}
              placeholder='Tháng kết thúc'
              handleChange={handleEndMonthChange}
            />
          </RowItem>
        </Section>
        <Bottom>
          <TextButton size='small' variant='outlined' onClick={handleCloseModal}>
            {t('history.action.cancel')}
          </TextButton>
          <TextButton
            size='small'
            startIcon={startIcon}
            variant='contained'
            color='primary'
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            Download
          </TextButton>
        </Bottom>
      </Container>
    </ModalContainer>
  );
};

const Title = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${BrandColors.under};
  background: #e0e0e0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-bottom: 1px solid #b1b1b2;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(508px - 35px);
`;

const Section = styled.div`
  flex: 1;
  height: calc(100% - 48px);
  padding: 24px;
  padding-right: 14px;
  overflow-y: scroll;
`;

const RowItem = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  color: #333333;
`;

const Bottom = styled.div`
  height: 48px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #b1b1b2;
`;

export default React.memo(CustomSelectTimeRangeModal);
