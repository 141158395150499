import Tooltip from '@mui/material/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import i18next from 'i18next';
import * as React from 'react';
import { FiEdit3, FiTrash2 } from 'react-icons/fi';
import styled from 'styled-components';

import Chip from '~/components/atoms/Chip';
import { moment } from '~/plugins';
import { useStationStore } from '~/store/hooks';
import { IStationDevice } from '~/types';

const ActionRow = styled.div`
  display: flex;
  justify-content: center;

  & > .action {
    cursor: pointer;
    margin-right: 16px;
    &:hover {
      color: #666fe8;
    }
  }
`;

export interface useDevicePropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const useDeviceTable = (props: useDevicePropsType) => {
  const { onConfirmDelete } = props;
  const { dispatchDeleteStationDevice } = useStationStore();
  const [isModalDeviceOpen, setIsModalDeviceOpen] = React.useState<boolean>(false);
  const [itemEdit, setItemEdit] = React.useState<IStationDevice | null>(null);

  const handleCloseModalUpdate = React.useCallback(() => {
    setIsModalDeviceOpen(false);
  }, []);

  const handleUpdateStationDevice = React.useCallback((item: IStationDevice | null) => {
    setIsModalDeviceOpen(true);
    setItemEdit(item);
  }, []);

  const handleDeleteStationDevice = React.useCallback(
    async (item: IStationDevice) => {
      const resultConfirmDelete = await onConfirmDelete(undefined);
      if (!resultConfirmDelete) return;

      dispatchDeleteStationDevice(item.id);
    },
    [dispatchDeleteStationDevice, onConfirmDelete],
  );

  const columns = React.useMemo<ColumnDef<IStationDevice>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: i18next.t('device.table.name'),
      },
      {
        accessorKey: 'serial_number',
        header: i18next.t('device.table.serial'),
      },
      {
        accessorKey: 'description',
        header: i18next.t('device.table.description'),
      },
      {
        accessorKey: 'time_start',
        header: i18next.t('device.table.created_at'),
        cell: (row) => {
          const created_at = row.getValue() as string;
          return moment(created_at).format('YYYY/MM/DD');
        },
      },
      {
        accessorKey: 'time_end',
        header: i18next.t('device.table.updated_at'),
        cell: (row) => {
          const created_at = row.getValue() as string;
          return moment(created_at).format('YYYY/MM/DD');
        },
      },
      {
        accessorKey: 'status',
        header: i18next.t('device.table.status'),
        cell: (row) => {
          const status = row.getValue();
          if (status) return <Chip type='success' label='Active' />;
          return <Chip type='warning' label='InActive' />;
        },
      },
      {
        accessorKey: '',
        header: i18next.t('device.table.action'),
        cell: ({ row: { original } }) => {
          return (
            <ActionRow>
              <Tooltip title='Cập nhật thiết bị'>
                <span className='action'>
                  <FiEdit3 size='20px' onClick={() => handleUpdateStationDevice(original)} />
                </span>
              </Tooltip>

              <Tooltip title='Xóa thiết bị'>
                <span className='action'>
                  <FiTrash2
                    size='20px'
                    onClick={() => {
                      handleDeleteStationDevice(original);
                    }}
                  />
                </span>
              </Tooltip>
            </ActionRow>
          );
        },
      },
    ];
  }, [handleDeleteStationDevice, handleUpdateStationDevice]);
  return {
    columns,
    isModalDeviceOpen,
    itemEdit,
    handleUpdateStationDevice,
    handleCloseModalUpdate,
  };
};

export default useDeviceTable;
