import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TiTimes } from 'react-icons/ti';
import styled from 'styled-components';
import TextButton from '~/components/atoms/Button';
import CheckBox from './CheckBox';
import { useSampleInfoStore, useStationConfigStore } from '~/store/hooks';
import {
  getListViewOfStation,
  addViewForStation,
  deleteStationPermission,
  getListConfigOfStation,
  addConfigForStation,
} from '~/store/ducks/user/actions';
// import { getListStationConfigByStationId, addListStationConfigByStationId, deleteListStationConfigByStationId } from '~/store/ducks/stationConfig/actions';
import { useToastStore } from '~/store/hooks';
import { v4 as uuidv4 } from 'uuid';
import { IAddConfigForStationRequest, IAddViewForStationRequest } from '~/types';

// import '../styles/SettingsPanel.scss';

export interface SettingsStationPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onShow?: any;
  stationId?: string;
  stationName?: string;
  userId?: string;
}

const SettingsStationPanel = (props: SettingsStationPropsType) => {
  const { onShow, stationId, userId, stationName } = props;

  const settingsStationPanelRef = useRef<any>(null);
  const contentRef = useRef(null);

  const { sampleInfoState, dispatchListGetSampleInfo } = useSampleInfoStore();
  const { stationConfigState, dispatchGetListStationConfig } = useStationConfigStore();
  const { dispatchCreateToast } = useToastStore();

  const [listViewInit, setListViewInit] = useState<any[]>([]);
  const [listConfigInit, setListConfigInit] = useState<any[]>([]);

  const [listViewSelected, setListViewSelected] = useState<any[]>([]);
  const [listConfigSelected, setListConfigSelected] = useState<any[]>([]);

  const fetchListView = useCallback(async () => {
    await dispatchListGetSampleInfo(stationId);
  }, [dispatchListGetSampleInfo, stationId]);

  const fetchListStationConfig = useCallback(async () => {
    await dispatchGetListStationConfig(stationId);
  }, [dispatchGetListStationConfig, stationId]);

  const fetchListViewOfStation = useCallback(async () => {
    try {
      const resp = await getListViewOfStation({
        user_id: userId || '',
        station_id: stationId || '',
      });

      if (resp) {
        setListViewInit(resp?.Data);
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [setListViewInit, dispatchCreateToast, userId, stationId]);

  const fetchListConfigOfStation = useCallback(async () => {
    try {
      const resp = await getListConfigOfStation({
        user_id: userId || '',
        station_id: stationId || '',
      });

      if (resp) {
        setListConfigInit(resp?.Data);
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [setListConfigInit, dispatchCreateToast, stationId, userId]);

  const fetchAddViewForStation = useCallback(
    async (data: IAddViewForStationRequest) => {
      try {
        const resp = await addViewForStation(data);

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Lưu danh sách view thành công!',
          });
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast],
  );

  const fetchAddConfigForStation = useCallback(
    async (data: IAddConfigForStationRequest) => {
      try {
        const resp = await addConfigForStation(data);

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Lưu danh sách cấu hình thành công!',
          });
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast],
  );

  const fetchDeleteStationPermission = useCallback(
    async (deletedId: string) => {
      try {
        const resp = await deleteStationPermission(deletedId);

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Lưu danh sách cấu hình thành công!',
          });
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast],
  );

  useEffect(() => {
    fetchListView();
    fetchListStationConfig();
    fetchListViewOfStation();
    fetchListConfigOfStation();
  }, [fetchListView, fetchListStationConfig, fetchListConfigOfStation, fetchListViewOfStation]);

  useEffect(() => {
    if (listViewInit?.length > 0) {
      setListViewSelected(listViewInit);
    }
  }, [listViewInit, setListViewSelected]);

  useEffect(() => {
    if (listConfigInit?.length > 0) {
      setListConfigSelected(listConfigInit);
    }
  }, [listConfigInit, setListConfigSelected]);

  useEffect(() => {
    if (settingsStationPanelRef?.current && contentRef?.current) {
      settingsStationPanelRef.current.onclick = (e: any) => {
        if (e.target == settingsStationPanelRef.current) onShow(false);
      };
    }
  }, [onShow]);

  const handleClose = useCallback(() => {
    if (onShow) {
      onShow(false);
    }
  }, [onShow]);

  const handleTickView = useCallback(
    (e: any) => {
      if (!e) return;
      const checked = e.target.checked;
      const id = e.target.value;
      let listViewSelected_tmp = [...listViewSelected];
      if (checked) {
        listViewSelected_tmp.push({
          views: sampleInfoState?.listSampleInfo?.find((one) => one.id === id),
        });
      } else {
        listViewSelected_tmp = listViewSelected_tmp.filter((one) => one.views.id !== id);
      }

      setListViewSelected(listViewSelected_tmp);
    },
    [listViewSelected, setListViewSelected, sampleInfoState?.listSampleInfo],
  );

  const handleTickConfig = useCallback(
    (e: any) => {
      if (!e) return;
      const checked = e.target.checked;
      const id = e.target.value;
      let listConfigSelected_tmp = [...listConfigSelected];
      if (checked) {
        listConfigSelected_tmp.push({
          config: stationConfigState?.stationListConfig?.find((one) => one.id === id),
        });
      } else {
        listConfigSelected_tmp = listConfigSelected_tmp.filter((one) => one.config.id !== id);
      }

      setListConfigSelected(listConfigSelected_tmp);
    },
    [listConfigSelected, setListConfigSelected, stationConfigState?.stationListConfig],
  );

  const handleClickSave = useCallback(async () => {
    let listViewAdded = listViewSelected.filter(
      (one) => !listViewInit?.find((two) => two.views.id === one.views.id),
    );
    listViewAdded = listViewAdded?.map((one) => {
      return {
        view_id: one.views.id,
        station_id: stationId,
        user_id: userId,
      };
    });
    if (listViewAdded?.length > 0) {
      console.log('view add', listViewAdded);
      await fetchAddViewForStation({
        data: listViewAdded,
      });
    }

    let listConfigAdded = listConfigSelected.filter(
      (one) => !listConfigInit?.find((two) => two.config.id === one.config.id),
    );
    listConfigAdded = listConfigAdded?.map((one) => {
      return {
        station_id: stationId,
        user_id: userId,
        config_id: one.config.id,
      };
    });
    if (listConfigAdded?.length > 0) {
      console.log('config add', listConfigAdded);
      await fetchAddConfigForStation({
        data: listConfigAdded,
      });
    }

    const listViewDeleted = listViewInit.filter(
      (one) => !listViewSelected?.find((two) => two.views.id === one.views.id),
    );
    const listConfigDeleted = listConfigInit.filter(
      (one) => !listConfigSelected?.find((two) => two.config.id === one.config.id),
    );

    let listDeleted = listViewDeleted.concat(listConfigDeleted);
    listDeleted = listDeleted.map((one) => one.id);
    if (listDeleted?.length > 0) {
      console.log('list deleted', listDeleted);
      await fetchDeleteStationPermission(listDeleted.join(','));
    }

    fetchListViewOfStation();
    fetchListConfigOfStation();
  }, [
    listViewSelected,
    listViewInit,
    fetchAddViewForStation,
    userId,
    stationId,
    fetchAddConfigForStation,
    fetchDeleteStationPermission,
    listConfigInit,
    listConfigSelected,
    fetchListConfigOfStation,
    fetchListViewOfStation,
  ]);

  return (
    <SettingsPanelElement ref={settingsStationPanelRef}>
      <ContentElement>
        <div className='header'>
          <div className='left'>
            <div className='title'>Cấu hình {stationName}</div>
          </div>
          <div className='right'>
            <div className='close-btn' onClick={handleClose}>
              <TiTimes size='26px' />
            </div>
          </div>
        </div>
        <div className='body'>
          <div className='col'>
            <div className='title'>Hiển thị</div>
            <div className='content'>
              <div className='table'>
                {sampleInfoState?.listSampleInfo?.map((one) => (
                  <div className='one-row' key={one.id}>
                    <CheckBox
                      title={one.name}
                      value={one.id}
                      onChange={handleTickView}
                      checked={
                        listViewSelected?.find((two) => two?.views?.id === one.id) ? true : false
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='col'>
            <div className='title'>Cài đặt</div>
            <div className='content'>
              <div className='table'>
                {stationConfigState?.stationListConfig?.map((one) => (
                  <div className='one-row' key={one.id}>
                    <CheckBox
                      title={one.name}
                      value={one.id}
                      onChange={handleTickConfig}
                      checked={
                        listConfigSelected?.find((two) => two?.config?.id === one.id) ? true : false
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='footer'>
          <TextButton
            color='primary'
            variant='contained'
            onClick={() => {
              handleClickSave();
            }}
          >
            Lưu cài đặt
          </TextButton>
          <TextButton
            color='inherit'
            variant='contained'
            onClick={() => {
              handleClose();
            }}
          >
            Huỷ bỏ
          </TextButton>
        </div>
      </ContentElement>
    </SettingsPanelElement>
  );
};

const SettingsPanelElement = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: changeTransition 0.5s ease alternate forwards;

  @keyframes flyUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes changeTransition {
    0% {
      background: transparent;
    }
    100% {
      background: rgba(0, 0, 0, 0.6);
    }
  }
`;

const ContentElement = styled.div`
  background: white;
  color: rgb(49, 49, 49);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 660px;
  height: 450px;
  animation: flyUp 0.5s ease alternate forwards;

  .header {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 17px;
      font-weight: 600;
      margin-left: 8px;
      color: #313131;
    }

    .close-btn {
      padding: 0 5px;
      cursor: pointer;
    }
  }

  & > .body {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    & > .col {
      width: 50%;
      height: 100%;

      & > .title {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }

      & > .content {
        padding: 8px;
        width: 100%;
        height: 100%;

        & > .table {
          width: 100%;
          height: 100%;
          background: #fafafa;
          border-radius: 5px;
          padding: 0 10px;
          overflow-x: hidden;
          overflow-y: overlay;

          & > .one-row {
            display: flex;
            color: #8d9aa9;
            justify-content: space-between;
            padding: 10px;
            font-size: 13px;
            align-items: center;
            border-bottom: 0.2px solid rgba(173, 173, 173, 0.5);
          }
        }
      }
    }
  }

  .footer {
    padding: 10px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > * {
      font-size: 14px;
    }

    & > *:first-child {
      margin-right: 10px;
    }
  }
`;

export default SettingsStationPanel;
