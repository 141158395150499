import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import styled, { createGlobalStyle } from 'styled-components';

import ToastList from '~/components/molecules/ToastList';
import { MODAL_LAYER_ID, ZIndexes } from '~/constants';
import { I18Next } from '~/locales';
import { AppRoutes } from '~/router/Routes';
import { persistor, store } from '~/store/ducks/rootStore';
import { theme } from '~/utils';

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <I18nextProvider i18n={I18Next}>
                <GlobalStyle />
                <AppRoutes />
                <ToastList />
                <ModalLayer id={MODAL_LAYER_ID} />
              </I18nextProvider>
            </BrowserRouter>
          </StyledEngineProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  .esri-attribution__powered-by {
    display: none;
  }
  
  .esri-attribution__sources {
    display: none;
  }
  /* .esri-component.esri-attribution.esri-widget {
    display: none;
  } */
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;

  &::-webkit-scrollbar{
    background:transparent;
    height:8px;
    width:8px
  }

  &::-webkit-scrollbar-track{
    margin:3px 0
  }

  &::-webkit-scrollbar-thumb{
    border:none;
    box-shadow:none;
    background:#dadce0;
    border-radius:4px;
    min-height:40px
  }

  &:hover::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb:hover{
    background:#bdc1c6
  }

  *::-webkit-scrollbar-thumb:active{
    background:#9aa0a6
  }

  *::-webkit-scrollbar{
    background:transparent;
    height:8px;
    width:8px
  }

  *::-webkit-scrollbar-track{
    margin:3px 0
  }

  *::-webkit-scrollbar-thumb{
    border:none;
    box-shadow:none;
    background:#dadce0;
    border-radius:4px;
    min-height:40px
  }

  *:hover::-webkit-scrollbar-thumb,::-webkit-scrollbar-thumb:hover{
    background:#bdc1c6
  }

  *::-webkit-scrollbar-thumb:active{
    background:#9aa0a6
  }

  span {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
  }
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: #666fe8;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
} */

// fade
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: all .2s ease-in-out;
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  transition: all .2s ease-in-out;
  opacity: 0;
}

// slide
.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0%);
  transition: transform 800ms ease-in-out;
}

.slide-exit {
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(100%);
  transition: transform 800ms ease-in-out;
}
`;

const ContextLayerBase = styled.aside`
  position: fixed;
  right: 0px;
  top: 0px;
  visibility: hidden;
`;

const ModalLayer = styled(ContextLayerBase)`
  z-index: ${ZIndexes.MODAL_CONTEXT};
`;
