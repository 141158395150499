import { saveAs } from 'file-saver';
import _ from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { axios } from '~/plugins';
import { StationActionType } from '~/store/ducks/station/types';
import { createToast } from '~/store/ducks/toast/actions';
import { getSampleInfoList } from '~/store/selectors';
import { RootAction, RootState } from '~/store/types';
import { moment } from '~/plugins';
import {
  IGetStationResponse,
  IStation,
  IStationAssociationResponse,
  IStationChartResponse,
  IStationConfigUpdateRequest,
  IStationDetail,
  IStationDevice,
  IStationDeviceAddRequest,
  IStationDeviceParamsRequestList,
  IStationHistory,
  IStationHistoryAddRequest,
  IStationHistoryParamsRequestList,
  IStationParamsRequestList,
  IStationReportData,
  IStationReportMonthly,
  IStationRequestData,
  IStationThresholdResponse,
  IStationThresholdUpdateRequest,
  IGetMostRecentDataOfSensor,
  ISensorReportData,
  IGetAllStationResponse,
  IDownloadSensorReportData,
} from '~/types';

export const getListStation =
  (params: IStationParamsRequestList): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.GET_LIST_STATION });

      const res = await axios.get<IGetStationResponse>('/station', { params });
      const totalPage = Math.ceil(res.data.pagination.total / res.data.pagination.pageSize);
      res.data.pagination.totalPage = totalPage;
      dispatch({
        type: StationActionType.GET_LIST_STATION_SUCCESS,
        payload: { listStation: res.data },
      });
    } catch (error) {
      dispatch({
        type: StationActionType.GET_LIST_STATION_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const getAllListStation =
  (): ThunkAction<void, RootState, undefined, RootAction> => async (dispatch) => {
    try {
      dispatch({ type: StationActionType.GET_ALL_LIST_STATION });
      const res = await axios.get<IGetAllStationResponse>('/station?is_map=true');

      dispatch({
        type: StationActionType.GET_ALL_LIST_STATION_SUCCESS,
        payload: {
          listStation: {
            list: res.data.list,
            pagination: res.data.pagination,
            more_info: res.data.more_info,
          },
        },
      });
    } catch (error) {
      dispatch({
        type: StationActionType.GET_ALL_LIST_STATION_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const getStationDetail =
  (stationId: IStation['id']): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.GET_STATION_DETAIL });
      const res = await axios.get<IStationDetail>('/station/' + stationId);

      dispatch({
        type: StationActionType.GET_STATION_DETAIL_SUCCESS,
        payload: { stationDetail: res.data },
      });
    } catch (error) {
      dispatch({
        type: StationActionType.GET_STATION_DETAIL_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const getStationChartInfo =
  (params: IStationRequestData): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch, getState) => {
    dispatch({ type: StationActionType.GET_STATION_CHART_INFO });
    const sampleInfoList = getSampleInfoList(getState().sampleInfo);
    const sampleInfo = sampleInfoList.find((item) => item.id === params.sample_name_id);
    if (sampleInfo) {
      const res = await axios.get<IStationChartResponse>('/view', { params });
      dispatch({
        type: StationActionType.GET_STATION_CHART_INFO_SUCCESS,
        payload: {
          listChartDetail: {
            data: res.data.Data,
            sampleInfo: sampleInfo,
            type: res.data.Type,
          },
        },
      });
    }
  };

export const getStationConfigData =
  (stationId: IStation['id']): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.GET_STATION_DATA_CONFIG });

      const res = await axios.get<IStationAssociationResponse>(
        '/station_config_association/' + stationId,
      );
      dispatch({
        type: StationActionType.GET_STATION_DATA_CONFIG_SUCCESS,
        payload: { listConfigData: res.data.Data },
      });
    } catch (error) {
      dispatch({
        type: StationActionType.GET_STATION_DATA_CONFIG_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const updateStationDataConfig =
  (params: IStationConfigUpdateRequest[]): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.UPDATE_STATION_DATA_CONFIG });
      const res = await axios.put('/station_config_association', { data: params });
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.UPDATE_STATION_DATA_CONFIG_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Lưu cấu hình trạm thành công',
          }),
        );
      }
    } catch (error) {
      dispatch({
        type: StationActionType.UPDATE_STATION_DATA_CONFIG_FAIL,
        payload: { error: error as Error },
      });
    }
  };

export const getStationThreshold =
  (stationId: IStation['id']): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.GET_STATION_THRESHOLD });

      const res = await axios.get<IStationThresholdResponse>('/station_threshold', {
        params: { station_id: stationId },
      });

      dispatch({
        type: StationActionType.GET_STATION_THRESHOLD_SUCCESS,
        payload: { listThresholdData: res.data.Data },
      });
    } catch (error) {
      dispatch({
        type: StationActionType.GET_STATION_THRESHOLD_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const updateStationThreshold =
  (params: IStationThresholdUpdateRequest[]): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.UPDATE_STATION_THRESHOLD });
      const res = await axios.put('/station_threshold', { data: params });
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.UPDATE_STATION_THRESHOLD_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Lưu ngưỡng trạm thành công',
          }),
        );
      }
    } catch (error) {
      dispatch({
        type: StationActionType.UPDATE_STATION_THRESHOLD_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const updateStation =
  (params: IStationDetail): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.UPDATE_STATION });
      const res = await axios.put('/station', params);
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.UPDATE_STATION_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Cập nhật trạm thành công',
          }),
        );
      }
    } catch (error) {
      dispatch({
        type: StationActionType.UPDATE_STATION_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const createStation =
  (params: IStationDetail): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.CREATE_STATION });
      const res = await axios.post<{ id: string }>('/station', params);
      if (res.data.id.length > 0) {
        const toastId = uuidv4();
        dispatch({
          type: StationActionType.CREATE_STATION_SUCCESS,
          payload: { stationId: res.data.id },
        });
        dispatch(
          createToast({
            id: toastId,
            toastType: 'success',
            message: 'Tạo mới trạm thành công',
          }),
        );
      }
    } catch (error) {
      const toastId = uuidv4();
      dispatch({
        type: StationActionType.CREATE_STATION_FAIL,
        payload: { error: error as Error },
      });
      dispatch(
        createToast({
          id: toastId,
          toastType: 'failed',
          message: 'Tạo mới trạm không thành công',
        }),
      );
    }
  };

export const deleteStation =
  (stationId: IStation['id']): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.DELETE_STATION });
      const res = await axios.delete('/station', {
        params: { id: stationId },
      });
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.DELETE_STATION_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Xóa trạm thành công',
          }),
        );
      }
    } catch (error) {
      const toastId = uuidv4();
      dispatch({
        type: StationActionType.DELETE_STATION_FAIL,
        payload: {
          error: error as Error,
        },
      });
      dispatch(
        createToast({
          id: toastId,
          toastType: 'failed',
          message: 'Xóa trạm không thành công',
        }),
      );
    }
  };

export const getStationDevice =
  (params: IStationDeviceParamsRequestList): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.GET_LIST_STATION_DEVICE });
      const res = await axios.get('/sensor', { params });
      const totalPage = Math.ceil(res.data.pagination.total / res.data.pagination.pageSize);
      res.data.pagination.totalPage = totalPage;
      dispatch({
        type: StationActionType.GET_LIST_STATION_DEVICE_SUCCESS,
        payload: { listDevice: res.data },
      });
    } catch (error) {
      dispatch({
        type: StationActionType.GET_LIST_STATION_DEVICE_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const createStationDevice =
  (
    params: IStationDeviceAddRequest,
    handleCallbackFunction: () => void,
  ): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.CREATE_STATION_DEVICE });
      const res = await axios.post('/sensor', params);
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.CREATE_STATION_DEVICE_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Tạo thành công',
          }),
        );
        handleCallbackFunction();
      }
    } catch (error) {
      dispatch({
        type: StationActionType.CREATE_STATION_DEVICE_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const updateStationDevice =
  (
    params: IStationDeviceAddRequest,
    handleCallbackFunction: () => void,
  ): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.CREATE_STATION_DEVICE });
      const res = await axios.put('/sensor', params);
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.CREATE_STATION_DEVICE_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Cập nhật thành công',
          }),
        );
        handleCallbackFunction();
      }
    } catch (error) {
      dispatch({
        type: StationActionType.CREATE_STATION_DEVICE_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const deleteStationDevice =
  (stationDeviceId: IStationDevice['id']): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.DELETE_STATION_DEVICE });
      const res = await axios.delete('/sensor', {
        params: { id: stationDeviceId },
      });
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.DELETE_STATION_DEVICE_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Xóa thiết bị thành công',
          }),
        );
      }
    } catch (error) {
      dispatch({
        type: StationActionType.DELETE_STATION_DEVICE_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const getStationHistory =
  (params: IStationHistoryParamsRequestList): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.GET_LIST_STATION_HISTORY });
      const res = await axios.get('/station_log', { params });
      const totalPage = Math.ceil(res.data.pagination.total / res.data.pagination.pageSize);
      res.data.pagination.totalPage = totalPage;
      dispatch({
        type: StationActionType.GET_LIST_STATION_HISTORY_SUCCESS,
        payload: { listHistory: res.data },
      });
    } catch (error) {
      dispatch({
        type: StationActionType.GET_LIST_STATION_DEVICE_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const createStationHistory =
  (
    params: IStationHistoryAddRequest,
    handleCallbackFunction: () => void,
  ): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.CREATE_STATION_HISTORY });
      const res = await axios.post('/station_log', params);
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.CREATE_STATION_HISTORY_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Tạo thành công',
          }),
        );
        handleCallbackFunction();
      }
    } catch (error) {
      dispatch({
        type: StationActionType.CREATE_STATION_HISTORY_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const updateStationHistory =
  (
    params: IStationHistoryAddRequest,
    handleCallbackFunction: () => void,
  ): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.CREATE_STATION_HISTORY });
      const res = await axios.put('/station_log', params);
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.CREATE_STATION_HISTORY_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Cập nhật thành công',
          }),
        );
        handleCallbackFunction();
      }
    } catch (error) {
      dispatch({
        type: StationActionType.CREATE_STATION_HISTORY_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const deleteStationHistory =
  (stationHistoryId: IStationHistory['id']): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.DELETE_STATION_HISTORY });
      const res = await axios.delete('/station_log', {
        params: { id: stationHistoryId },
      });
      if (res.data.status === 'OK') {
        dispatch({ type: StationActionType.DELETE_STATION_DEVICE_SUCCESS });
        dispatch(
          createToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Xóa lịch sử trạm thành công',
          }),
        );
      }
    } catch (error) {
      dispatch({
        type: StationActionType.DELETE_STATION_HISTORY_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const downloadFileReportStation =
  (station: IStation): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      const response = await axios.get('/report/station', {
        params: {
          id: station.id,
        },
        responseType: 'blob',
      });
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Download thành công',
        }),
      );
      saveAs(response.data, 'Report_station_' + station.name + '.pdf');
    } catch (error) {
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Download không thành công',
        }),
      );
    }
  };

export const downloadFileReportStationMonthly =
  (
    params: IStationReportMonthly,
    handleResetTable: () => void,
  ): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationActionType.DOWNLOAD_FILE_STATION_MONTHLY });
      const response = await axios.get('/report/monthly', {
        params: {
          station_ids: params.stationIds,
          date_time: params.dateTime,
        },
        responseType: 'blob',
      });
      saveAs(response.data, 'Report_station_monthly' + '.pdf');
      handleResetTable();
      dispatch({ type: StationActionType.DOWNLOAD_FILE_STATION_MONTHLY_SUCCESS });
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Download thành công',
        }),
      );
    } catch (error) {
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Download không thành công',
        }),
      );
      dispatch({
        type: StationActionType.DOWNLOAD_FILE_STATION_MONTHLY_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const downloadFileReportStationData =
  (
    params: IStationReportData,
    handleCloseModal: () => void,
  ): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      const response = await axios.get('/report/data', {
        params: {
          station_id: params.stationId,
          date_time_start: params.timeStart,
          date_time_end: params.timeEnd,
        },
        responseType: 'blob',
      });
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Download thành công',
        }),
      );
      saveAs(response.data, 'Report_station_data' + '.pdf');
      handleCloseModal();
    } catch (error) {
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Download không thành công',
        }),
      );
    }
  };

export const downloadSensorChartReportData =
  (params: IDownloadSensorReportData): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      const response = await axios.get('/view/export', {
        params: {
          station_id: params.stationId,
          sample_name_id: params.sensorId,
          time_start: params.time_start,
          time_end: params.time_end,
        },
        responseType: 'blob',
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Download thành công',
        }),
      );

      const contentDisposition = response?.headers['content-disposition'];
      const match = contentDisposition?.match(/filename="(.*?)"/);
      const filename = match
        ? match[1]
        : 'Report_sensor_' + params.sensorId + '_of_station_' + params.stationId + '.xls';

      saveAs(response.data, filename);
    } catch (error) {
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Download không thành công',
        }),
      );
    }
  };

export const downloadSensorReportData =
  (params: IDownloadSensorReportData): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      const paramsApi = (() => {
        if (params.type === 'year' || params.type === 'month') {
          return {
            station_id: params.stationId,
            sample_name_id: params.sensorId,
            type: params.type,
            [params.type]: params.time,
          };
        } else if (params.type === 'short_term') {
          return {
            station_id: params.stationId,
            sample_name_id: params.sensorId,
            type: params.type,
            time_start: params.time_start,
            time_end: params.time_end,
          };
        }
      })();

      const response = await axios.get('/view/statistic', {
        params: paramsApi,
        responseType: 'blob',
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Download thành công',
        }),
      );

      const contentDisposition = response?.headers['content-disposition'];
      const match = contentDisposition?.match(/filename="(.*?)"/);
      const filename = match
        ? match[1]
        : 'Report_sensor_' + params.sensorId + '_of_station_' + params.stationId + '.xls';

      saveAs(response.data, filename);
    } catch (error) {
      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Download không thành công',
        }),
      );
    }
  };

export const getMostRecentDataOfSensor = async (stationId: string, sensorId: string) => {
  const timeStart = moment().subtract(24, 'hour').format();
  const timeEnd = moment().format();

  const res = await axios.get<IGetMostRecentDataOfSensor>('/view', {
    params: {
      station_id: stationId,
      sample_name_id: sensorId,
      time_start: timeStart,
      time_end: timeEnd,
      count: 1,
    },
  });

  return res.data;
};

export const getDataOfSensor = async (params: ISensorReportData) => {
  const res = await axios.get<IGetMostRecentDataOfSensor>('/view', {
    params: {
      station_id: params.stationId,
      sample_name_id: params.sensorId,
      time_start: params.timeStart,
      time_end: params.timeEnd,
    },
  });

  return res.data;
};
