import CircularProgress from '@mui/material/CircularProgress';

interface CircularProgressProps {
  size: number;
  color: string;
}

const CircularProgressLoading = ({ size, color }: CircularProgressProps) => {
  return <CircularProgress size={size} sx={{ color: color }} />;
};

export default CircularProgressLoading;
