import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createToast, deleteToast } from '~/store/ducks/toast/actions';
import { Toast } from '~/store/ducks/toast/types';
import { RootState } from '~/store/types';

interface FuncType {
  toastStore: RootState['toast'];
  dispatchCreateToast: (toast: Toast) => void;
  dispatchDeleteToast: (toastId: Toast['id']) => void;
}

const useToastStore = (): FuncType => {
  const dispatch = useDispatch();

  const toastStore = useSelector<RootState, RootState['toast']>((state) => {
    return state.toast;
  });

  const dispatchCreateToast = React.useCallback(
    (toast: Toast) => {
      dispatch(createToast(toast));
    },
    [dispatch],
  );

  const dispatchDeleteToast = React.useCallback(
    (toastId: Toast['id']) => {
      dispatch(deleteToast(toastId));
    },
    [dispatch],
  );

  return {
    toastStore,
    dispatchCreateToast,
    dispatchDeleteToast,
  };
};

export default useToastStore;
