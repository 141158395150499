import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export interface ConfirmDeleteDialogPropsType {
  children?: React.ReactNode | React.ReactNode[];
  deleteName?: string;
  onConfirm?: any;
  open?: boolean;
}

export default function ConfirmDeleteDialog(props: ConfirmDeleteDialogPropsType) {
  const { deleteName, onConfirm, open } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleConfirm = React.useCallback(
    (confirm: boolean) => {
      onConfirm(confirm);
    },
    [onConfirm],
  );

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open || false}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>
          Bạn có chắc muốn xóa{deleteName ? ` ${deleteName}` : ''} không?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vui lòng xác nhận để đảm bảo dữ liệu không bị xoá ngoài ý muốn
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleConfirm(false);
            }}
          >
            Hủy bỏ
          </Button>
          <Button
            onClick={() => {
              handleConfirm(true);
            }}
            autoFocus
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
