import { AuthState, AuthAction, AuthActionType } from '~/store/ducks/auth/types';

const initState: AuthState = {
  authLogin: {
    access_token: '',
    token_type: '',
    expires_at: 0,
    refresh: '',
    role_name: '',
    role_slug: '',
    user_id: '',
  },
  authError: {
    error: false,
    message: '',
  },
  keepLogin: false,
};

export default function AuthReducer(state = initState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionType.AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        authLogin: action.payload,
      };
    }

    case AuthActionType.AUTH_RERESH_TOKEN_SUCCESS: {
      return {
        ...state,
        authLogin: { ...state.authLogin, ...action.payload },
      };
    }

    case AuthActionType.AUTH_SET_KEEP_LOGIN: {
      return {
        ...state,
        keepLogin: action.payload,
      };
    }

    case AuthActionType.AUTH_LOGIN_FAIL: {
      return {
        ...state,
        authError: action.payload,
      };
    }

    case AuthActionType.AUTH_LOGOUT: {
      return {
        ...state,
        authLogin: initState.authLogin,
        authError: initState.authError,
      };
    }

    case AuthActionType.AUTH_CLEAN_ERROR_INFO: {
      return {
        ...state,
        authError: initState.authError,
      };
    }

    default: {
      return state;
    }
  }
}
