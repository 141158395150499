import { Outlet } from 'react-router-dom';
import BasicLayout from '~/layouts/layout';

const RequireAuth = () => {
  return (
    <BasicLayout>
      <Outlet />
    </BasicLayout>
  );
};

export default RequireAuth;
