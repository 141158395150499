import { ILocation } from '~/types';

export const axiosTimeout = 600000;

export const StationPermission = {
  SupperAdmin: 'sa',
  Admin: 'admin',
  User: 'user',
} as const;

export const locationDefault: ILocation = {
  Lon: 107.5053541,
  Lat: 16.4534606,
};
