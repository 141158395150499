import { saveAs } from 'file-saver';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FaShareSquare } from 'react-icons/fa';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AmChartStation from '~/components/atoms/AmChartStation';
import ColumnChartStation from '~/components/atoms/AmChartStation/ColumnChart';
import TextButton from '~/components/atoms/Button';
import Empty from '~/components/atoms/Empty';
import DateInput from '~/components/atoms/Input/DateInput';
import SelectInput, { IFormOption } from '~/components/atoms/Input/SelectInput';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import { TextColor } from '~/constants';
import { useLoading } from '~/hooks';
import { axios, moment as dayjs } from '~/plugins';
import { useSampleInfoStore, useStationStore, useToastStore } from '~/store/hooks';
import { getListChartDetail, getSampleInfoList, getSensorAvailable } from '~/store/selectors';
import { listChartDetailLoadingModule } from '~/utils';
import { useSearchParams } from 'react-router-dom';
import { ISampleInfo } from '~/types';

type ChartInfoProps = {
  renderToggleMap: JSX.Element;
};

const ChartInfo = ({ renderToggleMap }: ChartInfoProps): JSX.Element => {
  const keyAmountOfRain = 'rafa';
  const { t } = useTranslation();
  const { stationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDateParam = searchParams.get('time_start');
  const endDateParam = searchParams.get('time_end');
  const sampleNameId = searchParams.get('sample_name_id');

  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [defaultValue, setDefaultValue] = React.useState<IFormOption | null>(null);
  const [loadingExportCSV, setLoadingExportCSV] = React.useState<boolean>(false);

  const { stationState, dispatchGetStationChartInfo } = useStationStore();
  const { sampleInfoState, dispatchListGetSampleInfo } = useSampleInfoStore();
  const { dispatchCreateToast } = useToastStore();
  const sampleInfoList = getSampleInfoList(sampleInfoState);
  const listSensorAvailable = getSensorAvailable(stationState);
  const listChartDetail = getListChartDetail(stationState);
  const loadingDetailChartStation = useLoading(listChartDetailLoadingModule);

  const stationChartOptions = React.useMemo(() => {
    const data: IFormOption[] = [];
    listSensorAvailable.forEach((item) => {
      const temp = sampleInfoList.find((info) => info.id === item);
      temp && data.push({ label: temp.name, value: temp.key });
    }) as unknown as IFormOption[];
    return data;
  }, [listSensorAvailable, sampleInfoList]);

  const isValidStartDate = React.useMemo(() => {
    if (dayjs(startDateParam).isValid()) return true;
    return false;
  }, [startDateParam]);

  const isValidEndDate = React.useMemo(() => {
    if (dayjs(endDateParam).isValid()) return true;
    return false;
  }, [endDateParam]);

  React.useMemo(() => {
    if (startDateParam && isValidStartDate) setStartDate(startDateParam);
    else setStartDate(dayjs().subtract(12, 'hour').format());
  }, [isValidStartDate, startDateParam]);

  React.useMemo(() => {
    if (endDateParam && isValidEndDate) setEndDate(endDateParam);
    else setEndDate(dayjs().format());
  }, [endDateParam, isValidEndDate]);

  React.useMemo(() => {
    if (stationChartOptions.length > 0) {
      if (sampleNameId) {
        const data: ISampleInfo[] = [];
        listSensorAvailable.forEach((item) => {
          const temp = sampleInfoList.find((info) => info.id === item);
          temp && data.push(temp);
        });
        const temp = data.find((info) => info.id === sampleNameId);
        temp && setDefaultValue({ label: temp.name, value: temp.key });
      } else {
        setDefaultValue(stationChartOptions[0]);
      }
    }
  }, [listSensorAvailable, sampleInfoList, sampleNameId, stationChartOptions]);

  const renderEmpty = React.useMemo(() => {
    if (loadingDetailChartStation) {
      return (
        <LoadingSpinSpinning>
          <LoadingSpinDot>
            <CircularProgressLoading size={28} color='#666FE8' />
          </LoadingSpinDot>
        </LoadingSpinSpinning>
      );
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }, [loadingDetailChartStation]);

  const renderAmChart = React.useMemo(() => {
    if (!listChartDetail) return renderEmpty;
    const lineSeriesData = listChartDetail.data
      .map((item) => {
        return {
          date: new Date(item.SamplingTime).getTime(),
          value: item.SensorData,
        };
      })
      .reverse();
    if (defaultValue?.value === keyAmountOfRain) {
      return (
        <ColumnChartStation
          type={listChartDetail.type}
          data={lineSeriesData}
          chartInfo={listChartDetail.sampleInfo}
        />
      );
    }
    return (
      <AmChartStation
        type={listChartDetail.type}
        data={lineSeriesData}
        chartInfo={listChartDetail.sampleInfo}
      />
    );
  }, [defaultValue?.value, listChartDetail, renderEmpty]);

  const startIconComponent = React.useMemo(() => {
    if (loadingExportCSV) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
    return <FaShareSquare />;
  }, [loadingExportCSV]);

  const sampleInfo = React.useMemo<ISampleInfo | undefined>(() => {
    if (defaultValue) return sampleInfoList.find((item) => item.key === defaultValue.value);
    return undefined;
  }, [defaultValue, sampleInfoList]);

  const handleStartDateChange = React.useCallback(
    (date: Date | null) => {
      setStartDate(dayjs(date).toString());
      sampleInfo && searchParams.set('sample_name_id', sampleInfo.id);
      searchParams.set('time_start', dayjs(date).format());
      searchParams.set('time_end', dayjs(endDate).format());
      setSearchParams(searchParams);
    },
    [endDate, sampleInfo, searchParams, setSearchParams],
  );

  const handleEndDateChange = React.useCallback(
    (date: Date | null) => {
      setEndDate(dayjs(date).toString());
      sampleInfo && searchParams.set('sample_name_id', sampleInfo.id);
      searchParams.set('time_start', dayjs(startDate).format());
      searchParams.set('time_end', dayjs(date).format());
      setSearchParams(searchParams);
    },
    [sampleInfo, searchParams, setSearchParams, startDate],
  );

  const onChangeSelect = React.useCallback(
    (option: IFormOption) => {
      setDefaultValue(option);
      const temp = sampleInfoList.find((info) => info.key === option.value);
      temp && searchParams.set('sample_name_id', temp.id);
      searchParams.set('time_start', dayjs(startDate).format());
      searchParams.set('time_end', dayjs(endDate).format());
      setSearchParams(searchParams);
    },
    [endDate, sampleInfoList, searchParams, setSearchParams, startDate],
  );

  const handleExportCSV = React.useCallback(() => {
    (async () => {
      if (stationId && sampleInfo) {
        try {
          setLoadingExportCSV(true);
          const { data } = await axios.get('/view/export', {
            params: {
              station_id: stationId,
              sample_name_id: sampleInfo.id,
              time_start: dayjs(startDate).format(),
              time_end: dayjs(endDate).format(),
            },
          });
          const blob = new Blob([data], { type: data.type });
          saveAs(blob, `station-${sampleInfo.id}.csv`);
        } catch (error) {
          return dispatchCreateToast({
            id: uuidv4(),
            toastType: 'failed',
            message: t('station.action.export_csv') + 'thất bại',
          });
        } finally {
          setLoadingExportCSV(false);
        }
      }
    })();
  }, [dispatchCreateToast, endDate, sampleInfo, startDate, stationId, t]);

  const fetchSampleInfoList = React.useCallback(async () => {
    if (stationId) {
      await dispatchListGetSampleInfo(stationId);
    }
  }, [dispatchListGetSampleInfo, stationId]);

  React.useEffect(() => {
    fetchSampleInfoList();
  }, [fetchSampleInfoList]);

  React.useEffect(() => {
    if (dayjs(endDate).valueOf() < dayjs(startDate).valueOf()) {
      return dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: 'Ngày kết thúc phải lớn hơn hoặc bằng Ngày bắt đầu',
      });
    }
    if (sampleInfo && stationId) {
      const params = {
        station_id: stationId,
        sample_name_id: sampleInfo.id,
        time_start: dayjs(startDate).format(),
        time_end: dayjs(endDate).format(),
      };
      dispatchGetStationChartInfo(params);
    }
  }, [dispatchCreateToast, dispatchGetStationChartInfo, endDate, sampleInfo, startDate, stationId]);

  return (
    <ChartInfoWrapper>
      <ChartInfoAction>
        <RowItem>
          <Title>Ngày bắt đầu</Title>
          <DateInput
            showTimeSelect={true}
            value={startDate}
            width='200px'
            handleChange={handleStartDateChange}
            placeholder='Ngày bắt đầu'
          />
        </RowItem>

        <RowItem>
          <Title>Ngày kết thúc</Title>
          <DateInput
            showTimeSelect={true}
            value={endDate}
            width='200px'
            handleChange={handleEndDateChange}
            placeholder='Ngày kết thúc'
          />
        </RowItem>

        <RowItem>
          <Title>Loại biểu đồ</Title>
          <SelectInput
            placeholder='Biểu đồ'
            placeholderFontSize='16px'
            selected={defaultValue}
            options={stationChartOptions}
            handleChange={onChangeSelect}
          />
        </RowItem>

        <RowItem>
          <Title></Title>
          <TextButton
            color='primary'
            variant='contained'
            startIcon={startIconComponent}
            disabled={loadingExportCSV}
            onClick={handleExportCSV}
          >
            {t('station.action.export_csv')}
          </TextButton>
        </RowItem>
        <RowItem>
          <Title></Title>
          {renderToggleMap}
        </RowItem>
      </ChartInfoAction>
      <ChartInfoContainer>{renderAmChart}</ChartInfoContainer>
    </ChartInfoWrapper>
  );
};

const ChartInfoWrapper = styled.div`
  margin-top: 16px;
`;

const ChartInfoAction = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;

  & > div {
    margin-right: 12px;
  }
`;

const RowItem = styled.div``;

const Title = styled.div`
  color: ${TextColor.Disable};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 16px;
`;

const ChartInfoContainer = styled.div`
  background: #fff;
  margin-top: 40px;
  min-height: 280px;
  max-height: 500px;
  padding-bottom: 24px;
  position: relative;
`;

const LoadingSpinSpinning = styled.div`
  display: block;
  height: 100%;
  left: 0;
  max-height: 400px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
`;

const LoadingSpinDot = styled.span`
  left: 50%;
  margin: -10px;
  position: absolute;
  top: 50%;
`;

export default ChartInfo;
