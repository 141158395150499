import {
  Toast,
  ToastActionType,
  ToastDeleteItemAction,
  ToastMergeItemAction,
} from '~/store/ducks/toast/types';

export const createToast = (toast: Toast): ToastMergeItemAction => {
  return {
    type: ToastActionType.TOAST_MERGE_ITEM,
    payload: {
      toast,
    },
  };
};

export const deleteToast = (toastId: Toast['id']): ToastDeleteItemAction => {
  return {
    type: ToastActionType.TOAST_DELETE_ITEM,
    payload: {
      toastId,
    },
  };
};
