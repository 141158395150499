import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearListStationConfig,
  getListStationConfig,
  getListStationConfigMapping,
} from '~/store/ducks/stationConfig/actions';
import { RootState } from '~/store/types';

interface FuncType {
  stationConfigState: RootState['stationConfig'];
  dispatchGetListStationConfig: (stationId?: string) => Promise<void>;
  dispatchGetListStationConfigMapping: (stationIdd: string) => Promise<void>;
  dispatchClearListStationConfig: () => void;
}

const useStationConfigStore = (): FuncType => {
  const dispatch = useDispatch();

  const stationConfigState = useSelector<RootState, RootState['stationConfig']>((state) => {
    return state.stationConfig;
  });

  const dispatchGetListStationConfig = React.useCallback(
    async (stationId?: string) => {
      await dispatch(getListStationConfig(stationId));
    },
    [dispatch],
  );

  const dispatchGetListStationConfigMapping = React.useCallback(
    async (stationId: string) => {
      await dispatch(getListStationConfigMapping(stationId));
    },
    [dispatch],
  );

  const dispatchClearListStationConfig = React.useCallback(() => {
    dispatch(clearListStationConfig());
  }, [dispatch]);

  return {
    stationConfigState,
    dispatchGetListStationConfig,
    dispatchGetListStationConfigMapping,
    dispatchClearListStationConfig,
  };
};

export default useStationConfigStore;
