import {
  IGetAllStationResponse,
  IGetStationResponse,
  ILocation,
  IPagination,
  IStation,
  IStationAssociation,
  IStationChartDataDetail,
  IStationDetail,
  IStationDevice,
  IStationDeviceResponse,
  IStationHistory,
  IStationHistoryResponse,
  IStationThreshold,
} from '~/types/';

export enum StationActionType {
  GET_LIST_STATION = 'station/GET_LIST_STATION',
  GET_LIST_STATION_SUCCESS = 'station/GET_LIST_STATION_SUCCESS',
  GET_LIST_STATION_FAIL = 'station/GET_LIST_STATION_FAIL',
  GET_ALL_LIST_STATION = 'station/GET_ALL_LIST_STATION',
  GET_ALL_LIST_STATION_SUCCESS = 'station/GET_ALL_LIST_STATION_SUCCESS',
  GET_ALL_LIST_STATION_FAIL = 'station/GET_ALL_LIST_STATION_FAIL',
  GET_STATION_DETAIL = 'station/GET_STATION_DETAIL',
  GET_STATION_DETAIL_SUCCESS = 'station/GET_STATION_DETAIL_SUCCESS',
  GET_STATION_DETAIL_FAIL = 'station/GET_STATION_DETAIL_FAIL',
  GET_STATION_CHART_INFO = 'station/GET_STATION_CHART_INFO',
  GET_STATION_CHART_INFO_SUCCESS = 'station/GET_STATION_CHART_INFO_SUCCESS',
  GET_STATION_CHART_INFO_FAIL = 'station/GET_STATION_CHART_INFO_FAIL',
  GET_STATION_DATA_CONFIG = 'station/GET_STATION_DATA_CONFIG',
  GET_STATION_DATA_CONFIG_SUCCESS = 'station/GET_STATION_DATA_CONFIG_SUCCESS',
  GET_STATION_DATA_CONFIG_FAIL = 'station/GET_STATION_DATA_CONFIG_FAIL',
  UPDATE_STATION_DATA_CONFIG = 'station/UPDATE_STATION_DATA_CONFIG',
  UPDATE_STATION_DATA_CONFIG_SUCCESS = 'station/UPDATE_STATION_DATA_CONFIG_SUCCESS',
  UPDATE_STATION_DATA_CONFIG_FAIL = 'station/UPDATE_STATION_DATA_CONFIG_FAIL',
  GET_STATION_THRESHOLD = 'station/GET_STATION_THRESHOLD',
  GET_STATION_THRESHOLD_SUCCESS = 'station/GET_STATION_THRESHOLD_SUCCESS',
  GET_STATION_THRESHOLD_FAIL = 'station/GET_STATION_THRESHOLD_FAIL',
  UPDATE_STATION_THRESHOLD = 'station/UPDATE_STATION_THRESHOLD',
  UPDATE_STATION_THRESHOLD_SUCCESS = 'station/UPDATE_STATION_THRESHOLD_SUCCESS',
  UPDATE_STATION_THRESHOLD_FAIL = 'station/UPDATE_STATION_THRESHOLD_FAIL',
  UPDATE_STATION = 'station/UPDATE_STATION',
  UPDATE_STATION_SUCCESS = 'station/UPDATE_STATION_SUCCESS',
  UPDATE_STATION_FAIL = 'station/UPDATE_STATION_FAIL',
  CREATE_STATION = 'station/CREATE_STATION',
  CREATE_STATION_SUCCESS = 'station/CREATE_STATION_SUCCESS',
  CREATE_STATION_FAIL = 'station/CREATE_STATION_FAIL',
  DELETE_STATION = 'station/DELETE_STATION',
  DELETE_STATION_SUCCESS = 'station/DELETE_STATION_SUCCESS',
  DELETE_STATION_FAIL = 'station/DELETE_STATION_FAIL',
  GET_LIST_STATION_DEVICE = 'station/GET_LIST_STATION_DEVICE',
  GET_LIST_STATION_DEVICE_SUCCESS = 'station/GET_LIST_STATION_DEVICE_SUCCESS',
  GET_LIST_STATION_DEVICE_FAIL = 'station/GET_LIST_STATION_DEVICE_FAIL',
  CREATE_STATION_DEVICE = 'station/CREATE_STATION_DEVICE',
  CREATE_STATION_DEVICE_SUCCESS = 'station/CREATE_STATION_DEVICE_SUCCESS',
  CREATE_STATION_DEVICE_FAIL = 'station/CREATE_STATION_DEVICE_FAIL',
  DELETE_STATION_DEVICE = 'station/DELETE_STATION_DEVICE',
  DELETE_STATION_DEVICE_SUCCESS = 'station/DELETE_STATION_DEVICE_SUCCESS',
  DELETE_STATION_DEVICE_FAIL = 'station/DELETE_STATION_DEVICE_FAIL',
  GET_LIST_STATION_HISTORY = 'station/GET_LIST_STATION_HISTORY',
  GET_LIST_STATION_HISTORY_SUCCESS = 'station/GET_LIST_STATION_HISTORY_SUCCESS',
  GET_LIST_STATION_HISTORY_FAIL = 'station/GET_LIST_STATION_HISTORY_FAIL',
  CREATE_STATION_HISTORY = 'station/CREATE_STATION_HISTORY',
  CREATE_STATION_HISTORY_SUCCESS = 'station/CREATE_STATION_HISTORY_SUCCESS',
  CREATE_STATION_HISTORY_FAIL = 'station/CREATE_STATION_HISTORY_FAIL',
  DELETE_STATION_HISTORY = 'station/DELETE_STATION_HISTORY',
  DELETE_STATION_HISTORY_SUCCESS = 'station/DELETE_STATION_HISTORY_SUCCESS',
  DELETE_STATION_HISTORY_FAIL = 'station/DELETE_STATION_HISTORY_FAIL',
  DOWNLOAD_FILE_STATION_MONTHLY = 'station/DOWNLOAD_FILE_STATION_MONTHLY',
  DOWNLOAD_FILE_STATION_MONTHLY_SUCCESS = 'station/DOWNLOAD_FILE_STATION_MONTHLY_SUCCESS',
  DOWNLOAD_FILE_STATION_MONTHLY_FAIL = 'station/DOWNLOAD_FILE_STATION_MONTHLY_FAIL',
}

export interface GetListStation {
  type: StationActionType.GET_LIST_STATION;
}

export interface GetListStationSuccess {
  type: StationActionType.GET_LIST_STATION_SUCCESS;
  payload: {
    listStation: {
      list: IStation[];
      pagination: IPagination;
    };
  };
}

export interface GetListStationFail {
  type: StationActionType.GET_LIST_STATION_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetAllListStation {
  type: StationActionType.GET_ALL_LIST_STATION;
}

export interface GetAllListStationSuccess {
  type: StationActionType.GET_ALL_LIST_STATION_SUCCESS;
  payload: {
    listStation: {
      list: IStation[];
      pagination: IPagination;
      more_info: ILocation;
    };
  };
}

export interface GetAllListStationFail {
  type: StationActionType.GET_ALL_LIST_STATION_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetStationDetail {
  type: StationActionType.GET_STATION_DETAIL;
}

export interface GetStationDetailSuccess {
  type: StationActionType.GET_STATION_DETAIL_SUCCESS;
  payload: {
    stationDetail: IStationDetail;
  };
}

export interface GetStationDetailFail {
  type: StationActionType.GET_STATION_DETAIL_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetStationChartInfo {
  type: StationActionType.GET_STATION_CHART_INFO;
}

export interface GetStationChartInfoSuccess {
  type: StationActionType.GET_STATION_CHART_INFO_SUCCESS;
  payload: {
    listChartDetail: IStationChartDataDetail;
  };
}

export interface GetStationChartInfoFail {
  type: StationActionType.GET_STATION_CHART_INFO_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetStationDataConfig {
  type: StationActionType.GET_STATION_DATA_CONFIG;
}

export interface GetStationDataConfigSuccess {
  type: StationActionType.GET_STATION_DATA_CONFIG_SUCCESS;
  payload: {
    listConfigData: IStationAssociation[];
  };
}

export interface GetStationDataConfigFail {
  type: StationActionType.GET_STATION_DATA_CONFIG_FAIL;
  payload: {
    error: Error;
  };
}

export interface UpdateStationDataConfig {
  type: StationActionType.UPDATE_STATION_DATA_CONFIG;
}

export interface UpdateStationDataConfigSuccess {
  type: StationActionType.UPDATE_STATION_DATA_CONFIG_SUCCESS;
}

export interface UpdateStationDataConfigFail {
  type: StationActionType.UPDATE_STATION_DATA_CONFIG_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetStationThreshold {
  type: StationActionType.GET_STATION_THRESHOLD;
}

export interface GetStationThresholdSuccess {
  type: StationActionType.GET_STATION_THRESHOLD_SUCCESS;
  payload: {
    listThresholdData: IStationThreshold[];
  };
}

export interface GetStationThresholdFail {
  type: StationActionType.GET_STATION_THRESHOLD_FAIL;
  payload: {
    error: Error;
  };
}

export interface UpdateStationThreshold {
  type: StationActionType.UPDATE_STATION_THRESHOLD;
}

export interface UpdateStationThresholdSuccess {
  type: StationActionType.UPDATE_STATION_THRESHOLD_SUCCESS;
}

export interface UpdateStationThresholdFail {
  type: StationActionType.UPDATE_STATION_THRESHOLD_FAIL;
  payload: {
    error: Error;
  };
}

export interface UpdateStation {
  type: StationActionType.UPDATE_STATION;
}

export interface UpdateStationSuccess {
  type: StationActionType.UPDATE_STATION_SUCCESS;
}

export interface UpdateStationFail {
  type: StationActionType.UPDATE_STATION_FAIL;
  payload: {
    error: Error;
  };
}

export interface CreateStation {
  type: StationActionType.CREATE_STATION;
}

export interface CreateStationSuccess {
  type: StationActionType.CREATE_STATION_SUCCESS;
  payload: {
    stationId: string;
  };
}

export interface CreateStationFail {
  type: StationActionType.CREATE_STATION_FAIL;
  payload: {
    error: Error;
  };
}

export interface DeleteStation {
  type: StationActionType.DELETE_STATION;
}

export interface DeleteStationSuccess {
  type: StationActionType.DELETE_STATION_SUCCESS;
}

export interface DeleteStationFail {
  type: StationActionType.DELETE_STATION_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetListStationDevice {
  type: StationActionType.GET_LIST_STATION_DEVICE;
}

export interface GetListStationDeviceSuccess {
  type: StationActionType.GET_LIST_STATION_DEVICE_SUCCESS;
  payload: {
    listDevice: {
      list: IStationDevice[];
      pagination: IPagination;
    };
  };
}

export interface GetListStationDeviceFail {
  type: StationActionType.GET_LIST_STATION_DEVICE_FAIL;
  payload: {
    error: Error;
  };
}

export interface CreateStationDevice {
  type: StationActionType.CREATE_STATION_DEVICE;
}

export interface CreateStationDeviceSuccess {
  type: StationActionType.CREATE_STATION_DEVICE_SUCCESS;
}

export interface CreateStationDeviceFail {
  type: StationActionType.CREATE_STATION_DEVICE_FAIL;
  payload: {
    error: Error;
  };
}

export interface DeleteStationDevice {
  type: StationActionType.DELETE_STATION_DEVICE;
}

export interface DeleteStationDeviceSuccess {
  type: StationActionType.DELETE_STATION_DEVICE_SUCCESS;
}

export interface DeleteStationDeviceFail {
  type: StationActionType.DELETE_STATION_DEVICE_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetListStationHistory {
  type: StationActionType.GET_LIST_STATION_HISTORY;
}

export interface GetListStationHistorySuccess {
  type: StationActionType.GET_LIST_STATION_HISTORY_SUCCESS;
  payload: {
    listHistory: {
      list: IStationHistory[];
      pagination: IPagination;
    };
  };
}

export interface GetListStationHistoryFail {
  type: StationActionType.GET_LIST_STATION_HISTORY_FAIL;
  payload: {
    error: Error;
  };
}

export interface CreateStationHistory {
  type: StationActionType.CREATE_STATION_HISTORY;
}

export interface CreateStationHistorySuccess {
  type: StationActionType.CREATE_STATION_HISTORY_SUCCESS;
}

export interface CreateStationHistoryFail {
  type: StationActionType.CREATE_STATION_HISTORY_FAIL;
  payload: {
    error: Error;
  };
}

export interface DeleteStationHistory {
  type: StationActionType.DELETE_STATION_HISTORY;
}

export interface DeleteStationHistorySuccess {
  type: StationActionType.DELETE_STATION_DEVICE_SUCCESS;
}

export interface DeleteStationHistoryFail {
  type: StationActionType.DELETE_STATION_HISTORY_FAIL;
  payload: {
    error: Error;
  };
}

export interface DownLoadFileStationMonthly {
  type: StationActionType.DOWNLOAD_FILE_STATION_MONTHLY;
}

export interface DownLoadFileStationMonthlySuccess {
  type: StationActionType.DOWNLOAD_FILE_STATION_MONTHLY_SUCCESS;
}

export interface DownLoadFileStationMonthlyFail {
  type: StationActionType.DOWNLOAD_FILE_STATION_MONTHLY_FAIL;
}

export type StationAction =
  | GetListStation
  | GetListStationSuccess
  | GetListStationFail
  | GetAllListStation
  | GetAllListStationSuccess
  | GetAllListStationFail
  | GetStationDetail
  | GetStationDetailSuccess
  | GetStationDetailFail
  | GetStationChartInfo
  | GetStationChartInfoSuccess
  | GetStationChartInfoFail
  | GetStationDataConfig
  | GetStationDataConfigSuccess
  | GetStationDataConfigFail
  | UpdateStationDataConfig
  | UpdateStationDataConfigSuccess
  | UpdateStationDataConfigFail
  | GetStationThreshold
  | GetStationThresholdSuccess
  | GetStationThresholdFail
  | UpdateStationThreshold
  | UpdateStationThresholdSuccess
  | UpdateStationThresholdFail
  | UpdateStation
  | UpdateStationSuccess
  | UpdateStationFail
  | CreateStation
  | CreateStationSuccess
  | CreateStationFail
  | DeleteStation
  | DeleteStationSuccess
  | DeleteStationFail
  | GetListStationDevice
  | GetListStationDeviceSuccess
  | GetListStationDeviceFail
  | CreateStationDevice
  | CreateStationDeviceSuccess
  | CreateStationDeviceFail
  | DeleteStationDevice
  | DeleteStationDeviceSuccess
  | DeleteStationDeviceFail
  | GetListStationHistory
  | GetListStationHistorySuccess
  | GetListStationHistoryFail
  | CreateStationHistory
  | CreateStationHistorySuccess
  | CreateStationHistoryFail
  | DeleteStationHistory
  | DeleteStationHistorySuccess
  | DeleteStationHistoryFail
  | DownLoadFileStationMonthly
  | DownLoadFileStationMonthlySuccess
  | DownLoadFileStationMonthlyFail;

export interface StationState {
  error?: Error;
  listStation: IGetStationResponse;
  listAllStation: IGetAllStationResponse;
  listDevice: IStationDeviceResponse;
  listHistory: IStationHistoryResponse;
  listChartDetail: IStationChartDataDetail | null;
  listConfigData: IStationAssociation[];
  listThresholdData: IStationThreshold[];
  stationDetail: IStationDetail | null;
  stationCreate: string;
}
