export enum RootPath {
  Login = '/login',
  Register = '/register',
  Home = '/',
  SystemInformation = '/system-information',
  User = '/users',
  Role = '/roles',
  About = '/about',
  Stations = 'stations',
  Settings = 'settings',
  Analytics = 'analytics',
  Profile = 'profile',
}

export enum SubPath {
  listUser = 'list-user',
  addUser = 'add-user',
  editUser = 'edit/:id',
  listRole = 'list-role',
  addRole = 'add-role',
  editRole = 'edit/:id',
  listStation = 'list-station',
  stationMap = 'station-map',
  addStation = 'create',
  detailStation = 'detail/:stationId',
  updateStation = 'update/:stationId',
  deviceStation = 'device/:stationId',
  historyStation = 'history/:stationId',
}
