export const listRoles = [
  {
    key: 'sa',
    value: 'Super Admin',
  },
  {
    key: 'admin',
    value: 'Admin',
  },
  {
    key: 'user',
    value: 'User',
  },
];

export const listStatus = [
  {
    key: '1',
    value: 'Active',
  },
  {
    key: '2',
    value: 'Inactive',
  },
  {
    key: '3',
    value: 'Expired',
  },
];

export const listPermissions = [
  {
    key: 'user::read',
    value: 'Đọc dữ liệu',
  },
  {
    key: 'user::control',
    value: 'Điều khiển',
  },
  {
    key: 'user::export',
    value: 'Xuất dữ liệu',
  },
  {
    key: 'user::delete',
    value: 'Xoá dữ liệu',
  },
];
