import { IServerConfigData } from '~/types';

export enum ServerConfigActionType {
  GET_SERVER_CONFIG = 'server/GET_SERVER_CONFIG',
  GET_SERVER_CONFIG_SUCCESS = 'server/GET_SERVER_CONFIG_SUCCESS',
  GET_SERVER_CONFIG_FAIL = 'server/GET_SERVER_CONFIG_FAIL',
}

export interface GetServerConfig {
  type: ServerConfigActionType.GET_SERVER_CONFIG;
}

export interface GetServerConfigSuccess {
  type: ServerConfigActionType.GET_SERVER_CONFIG_SUCCESS;
  payload: {
    serverConfig: IServerConfigData;
  };
}

export interface GetServerConfigFail {
  type: ServerConfigActionType.GET_SERVER_CONFIG_FAIL;
  payload: {
    error: boolean;
    message: string;
  };
}

export type ServerConfigAction = GetServerConfig | GetServerConfigSuccess | GetServerConfigFail;

export interface ServerConfigState {
  serverConfig: IServerConfigData;
}
