import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  validateEmail,
  validateEmpty,
  validateOnlyNumber,
  validatePassword,
  validateSpecialCharecters,
} from '~/assets/validate';
import ExtendValidate from '../components/ExtendValidate';
import { RootPath } from '~/configs';
import { listStatus } from '~/configs/variables';
import { AddUserIcon, TrashIcon } from '~/assets/Icon';
import SelectMenu from '../components/SelectMenu';
import { createUserRequest } from '~/store/ducks/user/actions';
import useRoleStore from '~/store/ducks/role/hook';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useToastStore } from '~/store/hooks';
import Input from '../components/Input';

const AddUser = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [usertype, setUsertype] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [fullname, setFullname] = useState('');
  const [status, setStatus] = useState('');
  const [usernameValidState, setUsernameValidState] = useState(true);
  const [emailValidState, setEmailValidState] = useState(true);
  const [phoneValidState, setPhoneValidState] = useState(true);
  const [addressValidState, setAddressValidState] = useState(true);
  const [fullnameValidState, setFullnameValidState] = useState(true);
  const [passwordValidState, setPasswordValidState] = useState(true);
  const [usertypeValidState, setUsertypeValidState] = useState(true);
  const [listRoles, setListRoles] = useState([] as any);
  // const [listPermissions, setListPermissions] = useState([] as any);

  const { roleState, dispatchGetListRoles } = useRoleStore();
  // const [statusValidState, setStatusValidState] = useState(true);

  const navigate = useNavigate();

  const { dispatchCreateToast } = useToastStore();

  const { t } = useTranslation();

  const fetchListRole = useCallback(async () => {
    await dispatchGetListRoles();
  }, [dispatchGetListRoles]);

  // const fetchRoleDetail = useCallback(async () => {
  //   const role = listRoles.find((one: any) => one?.key === usertype);

  //   if (!role) return;
  //   const detailRole = await getDetailRoleRequest(role?.id || '');

  //   if (detailRole && detailRole?.permissions) {
  //     const list = detailRole?.permissions?.map((one) => {
  //       return one.slug;
  //     });
  //     setListPermissions(list);
  //   }
  // }, [listRoles, usertype]);

  useEffect(() => {
    fetchListRole();
  }, [fetchListRole]);

  // useEffect(() => {
  //   fetchRoleDetail();
  // }, [usertype, fetchRoleDetail]);

  useEffect(() => {
    if (roleState?.listRole?.list) {
      const list = roleState?.listRole?.list?.map((one) => {
        const obj = {
          id: one.id,
          key: one.slug,
          value: one.name,
        };
        return obj;
      });

      setListRoles(list);
    }
  }, [roleState?.listRole]);

  const _handleChangeEmail = (e: any) => {
    const emailValue = e?.target?.value || '';
    setEmail(emailValue);

    if (!emailValidState) {
      setEmailValidState(true);
    }
  };

  const _handleChangeUsername = (e: any) => {
    const value = e?.target?.value || '';
    setUsername(value);

    if (!usernameValidState) {
      setUsernameValidState(true);
    }
  };
  const _handleChangePhone = (e: any) => {
    const value = e?.target?.value || '';
    setPhone(value);

    if (!phoneValidState) {
      setPhoneValidState(true);
    }
  };
  const _handleChangeAddress = (e: any) => {
    const value = e?.target?.value || '';
    setAddress(value);

    if (!addressValidState) {
      setAddressValidState(true);
    }
  };

  const _handleChangeFullname = (e: any) => {
    const value = e?.target?.value || '';
    setFullname(value);

    if (!fullnameValidState) {
      setFullnameValidState(true);
    }
  };

  const _handleChangePassword = (e: any) => {
    const passwordValue = e.target.value;

    setPassword(passwordValue);

    if (!passwordValidState) {
      setPasswordValidState(true);
    }
  };

  const _handleChangeUsertype = (type: any) => {
    setUsertype(type?.key);

    if (!usertypeValidState) {
      setUsertypeValidState(true);
    }
  };

  const _handleChangeStatus = (type: any) => {
    setStatus(type?.key);
  };

  const _handleClickCancelBtn = () => {
    navigate(RootPath.User);
  };

  const _handleClickCreateUserBtn = async () => {
    const validateEmailStatus = validateEmail(email);
    const validatePasswordStatus = validatePassword(password);
    const validateUsernameStatus = validateEmpty(username) && validateSpecialCharecters(username);
    const validatePhoneStatus = validateEmpty(phone) && validateOnlyNumber(phone);
    const validateAddressStatus = validateEmpty(address);
    const validateFullnameStatus = validateEmpty(fullname) && validateSpecialCharecters(fullname);
    const validateUsertypeStatus = validateEmpty(usertype);

    setEmailValidState(validateEmailStatus);
    setPasswordValidState(validatePasswordStatus);
    setUsernameValidState(validateUsernameStatus);
    setPhoneValidState(validatePhoneStatus);
    setAddressValidState(validateAddressStatus);
    setFullnameValidState(validateFullnameStatus);
    setUsertypeValidState(validateUsertypeStatus);

    if (
      validateEmailStatus &&
      validatePasswordStatus &&
      validateUsernameStatus &&
      validatePhoneStatus &&
      validateAddressStatus &&
      validateFullnameStatus
    ) {
      try {
        const resp = await createUserRequest({
          user_name: username,
          real_name: fullname,
          password,
          address,
          phone,
          email,
          status: parseInt(status),
          user_role: usertype,
        });

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Thêm user thành công!',
          });

          navigate(RootPath.User);
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    }
  };

  const inputList1 = [
    {
      name: 'username',
      title: 'Username',
      require: true,
      value: username,
      onchange: _handleChangeUsername,
      validate: usernameValidState,
    },
    {
      name: 'email',
      title: 'E-mail',
      require: true,
      value: email,
      onchange: _handleChangeEmail,
      validate: emailValidState,
    },
    {
      name: 'password',
      title: 'Password',
      require: true,
      value: password,
      onchange: _handleChangePassword,
      validate: passwordValidState,
      extendValidate: <ExtendValidate type='password' value={password} />,
    },
  ];

  const inputList2 = [
    {
      name: 'status',
      title: 'Account Status',
      require: true,
      value: status,
      onchange: _handleChangeStatus,
      validate: true,
      body: (
        <SelectMenu
          width='100%'
          height='40px'
          data={listStatus}
          onChange={_handleChangeStatus}
          init={listStatus[0]}
        />
      ),
    },
    {
      name: 'fullname',
      title: 'Fullname',
      require: true,
      value: fullname,
      onchange: _handleChangeFullname,
      validate: fullnameValidState,
    },
    {
      name: 'phone',
      title: 'Phone Number',
      require: true,
      value: phone,
      onchange: _handleChangePhone,
      validate: phoneValidState,
    },
    {
      name: 'address',
      title: 'Address',
      require: true,
      value: address,
      onchange: _handleChangeAddress,
      validate: addressValidState,
    },
  ];

  const inputList3 = [
    {
      name: 'user-type',
      title: 'User type',
      require: true,
      value: usertype,
      onchange: _handleChangeUsertype,
      validate: true,
      body: (
        <SelectMenu
          width='100%'
          height='40px'
          data={listRoles}
          onChange={_handleChangeUsertype}
          init={listRoles[0]}
        />
      ),
    },
  ];

  return (
    <AddUserContainer>
      <Header>
        <Title>{t('user.title.add_user')}</Title>
        <div className='controls'>
          <div className='left'></div>

          <div className='right'>
            <div
              className='save ripple'
              onClick={() => {
                _handleClickCreateUserBtn();
              }}
            >
              <div className='icon'>
                <AddUserIcon color='#fff' />
              </div>
              <span>{t('user.action.add_user')}</span>
            </div>
            <div className='cancel ripple' onClick={_handleClickCancelBtn}>
              <div className='icon'>
                <TrashIcon color='#fff' />
              </div>
              <span>{t('user.action.cancel')}</span>
            </div>
          </div>
        </div>
      </Header>
      <Content>
        <div className='user-details'>
          <div className='header'>
            <div className='title'>{t('user.title.user_information')}:</div>
          </div>
          <div className='body'>
            <div className='col col1'>
              <div className='sex'></div>
              {inputList1.map((one, index) => (
                <Input
                  title={one.title}
                  key={index}
                  name={one.name}
                  require={one.require}
                  value={one.value}
                  onChange={one.onchange}
                  validate={one.validate}
                  extendValidate={one.extendValidate}
                />
              ))}
            </div>
            <div className='col col2'>
              {inputList2.map((one, index) => (
                <Input
                  title={one.title}
                  key={index}
                  name={one.name}
                  require={one.require}
                  value={one.value}
                  onChange={one.onchange}
                  validate={one.validate}
                  body={one.body}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='exras'>
          <div className='header'>
            <div className='title'>{t('user.title.user_permissions')}:</div>
          </div>
          <div className='body'>
            <div className='role-wrapper'>
              {inputList3.map((one, index) => (
                <Input
                  title={one.title}
                  key={index}
                  name={one.name}
                  require={one.require}
                  value={one.value}
                  onChange={one.onchange}
                  validate={one.validate}
                  body={one.body}
                />
              ))}
              {/* <div className='list-permissions'>
                <div className='one-permission'>
                  <div className='name'>
                    <span>Đọc dữ liệu</span>
                  </div>
                  {listPermissions?.includes('user::read') ? (
                    <TiTick size='20px' />
                  ) : (
                    <TiTimes size='20px' />
                  )}
                </div>
                <div className='one-permission'>
                  <div className='name'>
                    <span>Điều khiển</span>
                  </div>
                  {listPermissions?.includes('user::control') ? (
                    <TiTick size='20px' />
                  ) : (
                    <TiTimes size='20px' />
                  )}
                </div>
                <div className='one-permission'>
                  <div className='name'>
                    <span>Xuất dữ liệu</span>
                  </div>
                  {listPermissions?.includes('user::export') ? (
                    <TiTick size='20px' />
                  ) : (
                    <TiTimes size='20px' />
                  )}
                </div>
                <div className='one-permission'>
                  <div className='name'>
                    <span>Xoá dữ liệu</span>
                  </div>
                  {listPermissions?.includes('user::delete') ? (
                    <TiTick size='20px' />
                  ) : (
                    <TiTimes size='20px' />
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Content>
    </AddUserContainer>
  );
};

const AddUserContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-family: 'Roboto', sans-serif;
  position: relative;
  flex-direction: column;

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const Header = styled.div`
  margin-bottom: 24px;

  & > .controls {
    display: flex;
    justify-content: space-between;

    .right {
      display: flex;
      flex-direction: row;

      & > div {
        margin: 0 8px;

        .icon {
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          margin-right: 5px;
        }
      }

      .save {
        cursor: pointer;
        color: white;
        background: #666fe8;
        border: 1px solid #545dd3;
        border-radius: 3px;
        min-width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      .cancel {
        cursor: pointer;
        min-width: 120px;
        color: #fff;
        background: #fa4747;
        border: 1px solid rgba(102, 111, 232, 0.08);
        box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;

  & > .user-details {
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;

    & > .header {
      .title {
        font-weight: 600;
        font-size: 16px;
      }
    }

    & > .body {
      width: 100%;
      height: 100%;
      display: flex;
      border: 1px solid rgba(102, 111, 232, 0.08);
      box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
      border-radius: 3px;
      margin-top: 10px;

      & > .col {
        width: 50%;
        height: 100%;
        padding: 12px;

        .sex {
          width: 100%;
        }
      }
    }
  }

  .exras {
    min-width: 300px;
    width: 100%;
    min-height: 100%;
    margin-left: 20px;
    padding: 8px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    display: flex;
    flex-direction: column;

    & > .header {
      .title {
        font-weight: 600;
        font-size: 16px;
      }
    }

    & > .body {
      width: 100%;
      height: 100%;
      border: 1px solid rgba(102, 111, 232, 0.08);
      box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
      border-radius: 3px;
      margin-top: 10px;
      padding: 8px;
      overflow: hidden;

      & > .role-wrapper {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        .list-permissions {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: overlay;

          .one-permission {
            width: 100%;
            padding: 8px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .name {
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: start;
            }

            .controls {
              display: flex;
              flex-direction: row;

              & > svg {
                margin-left: 8px;
                cursor: pointer;
              }
            }
          }
        }
      }

      & > .list-station-wrapper {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .list-stations {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: overlay;

          .one-station {
            width: 100%;
            padding: 8px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .name {
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: start;
            }

            .controls {
              display: flex;
              flex-direction: row;

              & > svg {
                margin-left: 8px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
`;

export default AddUser;
