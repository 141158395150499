import styled from 'styled-components';

const ProfilePageElement = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
`;

const Header = styled.div`
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

export { Header, Title, ProfilePageElement };
