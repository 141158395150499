import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { BiLogOut } from 'react-icons/bi';
import { TbLayoutSidebarLeftCollapse, TbLayoutSidebarRightCollapse } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '~/assets/img/logo.svg';
import { SideBarItems } from '~/components/molecules/SideBar/constants';
import { useAuthInfoStore } from '~/store/hooks';
import SideBarMenu from './SideBarMenu';

export interface LogoutProps {
  collapsedSideBarState: boolean;
}

const Profile = ({ collapsedSideBarState }: LogoutProps): React.ReactElement => {
  const { dispatchAuthLogout } = useAuthInfoStore();
  const { authState } = useAuthInfoStore();

  return (
    <ProfileElement>
      {collapsedSideBarState ? (
        <LogoutBtn
          onClick={() => {
            dispatchAuthLogout();
          }}
        >
          <div className='icon'>
            <BiLogOut size='1.5rem' />
          </div>
        </LogoutBtn>
      ) : (
        <UserInformation>
          <div className='info'>
            <div className='name'>{authState.authLogin.real_name || 'Không xác định'}</div>
            <div className='role'>{authState.authLogin.role_name || 'Super Admin'}</div>
          </div>
          <div className='controller'>
            <Link to='profile'>Hồ sơ</Link>
            <div
              className='logout'
              onClick={() => {
                dispatchAuthLogout();
              }}
            >
              <span>Đăng xuất</span>
            </div>
          </div>
        </UserInformation>
      )}
    </ProfileElement>
  );
};

const SideBar = (): JSX.Element => {
  const [collapsedSideBarState, setCollapsedSideBarState] = React.useState(false);
  const { authState } = useAuthInfoStore();

  const listSideBar = React.useMemo(() => {
    let SideBarItems_tmp = [...SideBarItems];

    SideBarItems_tmp = SideBarItems_tmp.filter((one) => !(one.path === '/roles'));

    if (authState?.authLogin?.role_slug !== 'sa') {
      SideBarItems_tmp = SideBarItems_tmp.filter(
        (one) =>
          !(
            one.path === '/settings' ||
            one.path === '/analytics' ||
            one.path === '/system-information'
          ),
      );
    }

    if (authState?.authLogin?.role_slug === 'user') {
      SideBarItems_tmp = SideBarItems_tmp.filter((one) => !(one.path === '/users'));
    }

    return SideBarItems_tmp;
  }, [authState?.authLogin?.role_slug]);

  const toggleSideBar = React.useCallback(() => {
    setCollapsedSideBarState(!collapsedSideBarState);
  }, [collapsedSideBarState]);

  return (
    <SidebarContainer className={collapsedSideBarState ? 'collapsed' : 'extend'}>
      <SidebarLogo className={collapsedSideBarState ? 'inactive' : 'active'}>
        <div className='left'>
          <Link to='/'>
            <img src={Logo} alt='logo' />
          </Link>
        </div>
        <div className='right'>
          {/* <div className='user-information' onClick={() => navigate('/profile')}>
            <div className='name'>{authState.authLogin.real_name || 'Không xác định'}</div>
            <div className='role'>{authState.authLogin.role_name || 'Super Admin'}</div>
          </div> */}
          <Tooltip title={collapsedSideBarState ? 'Mở rộng' : 'Thu gọn'} placement='right'>
            <CollapsedMenuBtn onClick={toggleSideBar}>
              {collapsedSideBarState ? (
                <TbLayoutSidebarRightCollapse />
              ) : (
                <TbLayoutSidebarLeftCollapse />
              )}
            </CollapsedMenuBtn>
          </Tooltip>
        </div>
      </SidebarLogo>
      <SideBarMenu listItems={listSideBar} collapsedSideBarState={collapsedSideBarState} />
      <Profile collapsedSideBarState={collapsedSideBarState} />
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  width: 325px;
  height: 100%;
  position: relative;
  background-color: #fafafa;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: #737373;
  border-top-left-radius: 10px;
  transition: 0.3s;
  border-bottom-right-radius: 10px;

  &.collapsed {
    width: 75px;
  }

  &.extend {
    width: 325px;
  }
`;

const SidebarLogo = styled.div`
  height: 56px;
  display: flex;
  margin-bottom: 40px;
  padding: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.inactive {
    .left {
      display: none;
    }

    .right {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .left {
    width: 100px;
    height: 100%;
    position: relative;
    margin-left: 6px;

    a,
    img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    .user-information {
      width: fit-content;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;

      .name {
        font-size: 1rem;
        font-weight: 600;
        color: #666fe8;
        padding: 2px 0;
        text-rendering: optimizeSpeed;
        width: 165px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        text-align: center;

        &:hover {
          transform: scale(1.03);
          padding: 2px 0;
        }
      }

      .role {
        font-size: 0.85rem;
        padding: 2px 0;
        color: #455560;
        text-align: center;
      }
    }
  }
`;

const ProfileElement = styled.div`
  position: absolute;
  /* bottom: 2.34%; */
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
`;

const LogoutBtn = styled.div`
  /* display: flex;
  flex-direction: row;
  padding: 16px 24px;
  align-items: flex-start;
  cursor: pointer;
  transition: 0.3s; */

  text-rendering: optimizeLegibility;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.7px;
  list-style: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  color: unset;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  height: 56px;
  display: flex;
  padding: 0 24px;
  transition: color 0.3s ease 0s;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: #666fe8;
    background-color: #f4f5f9b3;
  }

  .icon {
    text-rendering: optimizeLegibility;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.7px;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    min-width: 56px;
    flex-shrink: 0;
    display: inline-flex;
  }

  span {
    margin-left: 18px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    list-style: none;
    color: unset;
    text-align: left;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    margin: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    display: block;
    font-weight: 600;
    text-transform: capitalize;
    white-space: nowrap;
  }
`;

const UserInformation = styled.div`
  width: 100%;
  color: #2e384d;
  padding: 0 8px;
  font-size: 14px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 8px 0;
    overflow: hidden;

    .name {
      font-weight: 600;
      color: #666fe8;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
      font-size: 16px;
    }

    .role {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: start;
    }
  }

  .controller {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    padding: 8px 0;
    flex-direction: column;
    align-items: flex-end;
    width: fit-content;
    min-width: fit-content;

    a,
    .logout {
      text-align: start;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: #666fe8;
      }
    }

    .logout {
      display: flex;
      flex-direction: row;
      text-align: end;
      padding-top: 1px;

      .icon {
        margin-right: 5px;
      }
    }
  }
`;

const CollapsedMenuBtn = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: relative;
  font-size: 26px;
  cursor: pointer;
  color: #c9c4c4;
  /* color: #2e384d; */
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #737373;
  }
`;

export default SideBar;
