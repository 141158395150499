import { Middleware } from '@reduxjs/toolkit';
import { createLoadingMiddleware } from 'react-redux-loading-hook';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { RootState } from '~/store/types';

export const getLoadingState = (state: RootState) => state.loadingState;
export const loadingMiddleware = createLoadingMiddleware(getLoadingState);

const middleware: Middleware[] = [thunk, loadingMiddleware];

if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger({ collapsed: true }));
}

export default middleware;
