import { IPermissionRequest, IPermissionResponse, IListPermissionResponse } from '~/types/';

export enum PermissionActionType {
  GET_LIST_PERMISSIONS_REQUEST = 'permission/GET_LIST_PERMISSIONS_REQUEST',
  GET_LIST_PERMISSIONS_SUCCESS = 'permission/GET_LIST_PERMISSIONS_SUCCESS',
  GET_LIST_PERMISSIONS_FAIL = 'permission/GET_LIST_PERMISSIONS_FAIL',
  CREATE_PERMISSION_REQUEST = 'permission/CREATE_PERMISSION_REQUEST',
  CREATE_PERMISSION_SUCCESS = 'permission/CREATE_PERMISSION_SUCCESS',
  CREATE_PERMISSION_FAIL = 'permission/CREATE_PERMISSION_FAIL',
}

export type PermissionInfo = IPermissionRequest;

export interface ListPermission {
  list?: IPermissionResponse[];
  pagination?: {
    total?: number;
    current?: number;
    pageSize?: number;
  };
}

export interface GetListPermissionsRequest {
  type: PermissionActionType.GET_LIST_PERMISSIONS_REQUEST;
}

export interface GetListPermissionsSuccess {
  type: PermissionActionType.GET_LIST_PERMISSIONS_SUCCESS;
  payload: IListPermissionResponse;
}

export interface GetListPermissionsFail {
  type: PermissionActionType.GET_LIST_PERMISSIONS_FAIL;
  payload: {
    error: boolean;
    message: string;
  };
}

export type PermissionAction =
  | GetListPermissionsRequest
  | GetListPermissionsSuccess
  | GetListPermissionsFail;

export interface PermissionState {
  listPermissions: ListPermission;
}
