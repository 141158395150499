import {
  SampleInfoAction,
  SampleInfoActionType,
  SampleInfoState,
} from '~/store/ducks/sampleInfo/types';

const initialState: SampleInfoState = {
  error: undefined,
  listSampleInfo: [],
};

export default function sampleInfoReducer(
  state = initialState,
  action: SampleInfoAction,
): SampleInfoState {
  switch (action.type) {
    case SampleInfoActionType.GET_LIST_SAMPLE_INFO_SUCCESS: {
      return {
        ...state,
        listSampleInfo: action.payload.listSampleInfo,
      };
    }

    case SampleInfoActionType.GET_LIST_SAMPLE_INFO_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
