import Box from '@mui/material/Box';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FiSave } from 'react-icons/fi';
import styled from 'styled-components';

import Button from '~/components/atoms/Button';
import Empty from '~/components/atoms/Empty';
import TextInput from '~/components/atoms/Input/TextInput';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import { useLoading } from '~/hooks';
import { useStationConfigStore, useStationStore } from '~/store/hooks';
import { getStationConfigMapping, getStationCreateId } from '~/store/selectors';
import { IStationAssociation, IStationConfigUpdateRequest } from '~/types';
import { listConfigMappingLoadingModule, stationDataConfigUpdateLoadingModule } from '~/utils';

const StationDataConfig = () => {
  const { t } = useTranslation();
  const isLoadingConfigMapping = useLoading(listConfigMappingLoadingModule);
  const isLoadingStationConfigUpdate = useLoading(stationDataConfigUpdateLoadingModule);
  const { stationState } = useStationStore();
  const { dispatchUpdateStationDataConfig } = useStationStore();
  const { stationConfigState, dispatchGetListStationConfigMapping } = useStationConfigStore();
  const stationConfigMapping = getStationConfigMapping(stationConfigState);
  const stationCreateId = getStationCreateId(stationState);
  const [stationConfigTemp, setStationConfigTem] = React.useState<IStationAssociation[]>([]);
  const [stationConfig, setStationConfigMapping] = React.useState<IStationAssociation[]>([]);

  const startIconComponent = React.useMemo(() => {
    if (isLoadingStationConfigUpdate) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
    return <FiSave />;
  }, [isLoadingStationConfigUpdate]);

  const renderLoadingConfigMapping = React.useMemo(() => {
    return (
      <LoadingSpinSpinning>
        <LoadingSpinDot>
          <CircularProgressLoading size={28} color='#666FE8' />
        </LoadingSpinDot>
      </LoadingSpinSpinning>
    );
  }, []);

  const renderEmptyConfigMapping = React.useMemo(() => {
    return (
      <LoadingSpinSpinning>
        <LoadingSpinDot>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </LoadingSpinDot>
      </LoadingSpinSpinning>
    );
  }, []);

  const stationConfigChange = React.useMemo(() => {
    let disable = true;
    const configCreateChange: IStationAssociation[] = [];
    const configUpdateChange: IStationAssociation[] = [];

    stationConfig.forEach((item) => {
      stationConfigTemp.forEach((temp) => {
        const compareId = item.id === temp.id;
        const compareValue = item.value !== temp.value || item.reason !== temp.reason;
        const isErrorValue = item.value === '' && item.reason.length > 0;
        const isErrorReason = item.reason === '' && item.value.length > 0;
        if (compareId && compareValue && item.isCreate && !isErrorValue && !isErrorReason) {
          configCreateChange.push(item);
        } else if (compareId && compareValue && !isErrorValue && !isErrorReason) {
          configUpdateChange.push(item);
        }
      });
    });

    if (configCreateChange.length > 0 || configUpdateChange.length > 0) {
      disable = false;
    }

    return {
      disable: disable,
      stationConfigUpdate: configUpdateChange,
      stationConfigCreate: configCreateChange,
    };
  }, [stationConfig, stationConfigTemp]);

  const fetchStationDataConfig = React.useCallback(async () => {
    if (stationCreateId) {
      await dispatchGetListStationConfigMapping(stationCreateId);
    }
  }, [dispatchGetListStationConfigMapping, stationCreateId]);

  const handleChangeValueStation = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: IStationAssociation) => {
      setStationConfigMapping((prevState) => {
        const newState = prevState.map((config) => {
          if (config.id === item.id) {
            return { ...config, value: event.target.value };
          }
          return config;
        });
        return newState;
      });
    },
    [],
  );

  const handleChangeReasonStation = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: IStationAssociation) => {
      setStationConfigMapping((prevState) => {
        const newState = prevState.map((config) => {
          if (config.id === item.id) {
            return { ...config, reason: event.target.value };
          }
          return config;
        });
        return newState;
      });
    },
    [],
  );

  const renderStationDataConfig = React.useCallback(() => {
    if (isLoadingConfigMapping) return renderLoadingConfigMapping;
    else if (stationConfig.length === 0) return renderEmptyConfigMapping;
    return stationConfig.map((item) => {
      const isErrorValue = item.value === '' && item.reason.length > 0;
      const isErrorReason = item.reason === '' && item.value.length > 0;
      return (
        <StationConfigContainer key={item.id}>
          <StationConfigRowItem>
            <Title>{item.name}</Title>
            <TextInput
              value={item.value}
              errorBorder={isErrorValue}
              textError={isErrorValue ? '*Không được để trống' : undefined}
              handleChange={(event) => handleChangeValueStation(event, item)}
            />
          </StationConfigRowItem>
          <StationConfigRowItem>
            <Title>{t('reason')}</Title>
            <TextInput
              value={item.reason}
              errorBorder={isErrorReason}
              textError={isErrorReason ? '*Không được để trống' : undefined}
              handleChange={(event) => handleChangeReasonStation(event, item)}
            />
          </StationConfigRowItem>
        </StationConfigContainer>
      );
    });
  }, [
    t,
    isLoadingConfigMapping,
    renderLoadingConfigMapping,
    stationConfig,
    renderEmptyConfigMapping,
    handleChangeValueStation,
    handleChangeReasonStation,
  ]);

  const handleUpdateStationConfig = React.useCallback(() => {
    const { stationConfigCreate, stationConfigUpdate } = stationConfigChange;
    const paramsCreate: IStationConfigUpdateRequest[] = stationConfigCreate.map((item) => {
      return {
        station_config_id: item.id,
        station_id: stationCreateId,
        value: item.value,
        reason: item.reason,
      };
    });
    const paramsUpdate: IStationConfigUpdateRequest[] = stationConfigUpdate.map((item) => {
      return {
        id: item.id,
        value: item.value,
        reason: item.reason,
        station_config_id: item.station_config_id,
        station_id: stationCreateId,
      };
    });
    const params = [...paramsUpdate, ...paramsCreate];
    dispatchUpdateStationDataConfig(params);
  }, [dispatchUpdateStationDataConfig, stationConfigChange, stationCreateId]);

  React.useEffect(() => {
    if (!isLoadingStationConfigUpdate) fetchStationDataConfig();
  }, [fetchStationDataConfig, isLoadingStationConfigUpdate]);

  React.useEffect(() => {
    if (stationConfigMapping) {
      setStationConfigMapping(stationConfigMapping);
      setStationConfigTem(stationConfigMapping);
    }
  }, [stationConfigMapping]);

  return (
    <StationConfigWrapper>
      <StationConfig>
        <HeaderContainer>
          <Header>{t('setting')} cấu hình trạm</Header>
          <RowItem>
            <Button
              type='submit'
              variant='contained'
              startIcon={startIconComponent}
              disabled={stationConfigChange.disable}
              onClick={handleUpdateStationConfig}
            >
              {isLoadingStationConfigUpdate
                ? t('station.action.saving-setting')
                : t('station.action.save-setting')}
            </Button>
          </RowItem>
        </HeaderContainer>
        <ContentContainer>{renderStationDataConfig()}</ContentContainer>
      </StationConfig>
    </StationConfigWrapper>
  );
};

const StationConfigWrapper = styled.div`
  width: 100%;
  height: 50%;
  overflow: hidden;
  padding: 2px 2px 8px 8px;
`;

const StationConfig = styled(Box)`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 12px;
`;

const Header = styled.h2`
  color: #8798ad;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
`;

const RowItem = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    text-align: right;
  }
`;

const Title = styled.div`
  color: #8d9aa9;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 16px;
`;

const ContentContainer = styled.div`
  padding: 24px;
  padding-top: 12px;
  overflow-x: hidden;
`;

const StationConfigContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StationConfigRowItem = styled.div`
  margin-bottom: 20px;
  width: calc(50% - 20px);
`;

const LoadingSpinSpinning = styled.div`
  display: block;
  height: 100%;
  left: 0;
  max-height: 400px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
`;

const LoadingSpinDot = styled.span`
  left: 50%;
  margin: -10px;
  position: absolute;
  top: 50%;
`;

export default React.memo(StationDataConfig);
