import { LoadingModule } from 'react-redux-loading-hook';
import { SampleInfoActionType } from '~/store/ducks/sampleInfo/types';
import { StationActionType } from '~/store/ducks/station/types';
import { UserActionType } from '~/store/ducks/user/types';
import { RoleActionType } from '~/store/ducks/role/types';
import { StationConfigActionType } from '~/store/ducks/stationConfig/types';
import { AnalyticActionType } from '~/store/ducks/analytic/types';
import { StationThresholdActionType } from '~/store/ducks/stationThreshold/types';

export const stationListLoadingModule = new LoadingModule({
  name: 'stationList',
  actionTriplets: [
    [
      StationActionType.GET_LIST_STATION,
      StationActionType.GET_LIST_STATION_SUCCESS,
      StationActionType.GET_LIST_STATION_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const sampleInfoListLoadingModule = new LoadingModule({
  name: 'sampleInfoList',
  actionTriplets: [
    [
      SampleInfoActionType.GET_LIST_SAMPLE_INFO,
      SampleInfoActionType.GET_LIST_SAMPLE_INFO_SUCCESS,
      SampleInfoActionType.GET_LIST_SAMPLE_INFO_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationDetailLoadingModule = new LoadingModule({
  name: 'stationDetail',
  actionTriplets: [
    [
      StationActionType.GET_STATION_DETAIL,
      StationActionType.GET_STATION_DETAIL_SUCCESS,
      StationActionType.GET_STATION_DETAIL_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const listChartDetailLoadingModule = new LoadingModule({
  name: 'listChartDetail',
  actionTriplets: [
    [
      StationActionType.GET_STATION_CHART_INFO,
      StationActionType.GET_STATION_CHART_INFO_SUCCESS,
      StationActionType.GET_STATION_CHART_INFO_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const listConfigDefaultLoadingModule = new LoadingModule({
  name: 'listConfigDefault',
  actionTriplets: [
    [
      StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT,
      StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_SUCCESS,
      StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationDataConfigLoadingModule = new LoadingModule({
  name: 'stationDataConfig',
  actionTriplets: [
    [
      StationActionType.GET_STATION_DATA_CONFIG,
      StationActionType.GET_STATION_DATA_CONFIG_SUCCESS,
      StationActionType.GET_STATION_DATA_CONFIG_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationDataConfigUpdateLoadingModule = new LoadingModule({
  name: 'stationDataConfigUpdate',
  actionTriplets: [
    [
      StationActionType.UPDATE_STATION_DATA_CONFIG,
      StationActionType.UPDATE_STATION_DATA_CONFIG_SUCCESS,
      StationActionType.UPDATE_STATION_DATA_CONFIG_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationThresholdLoadingModule = new LoadingModule({
  name: 'stationThreshold',
  actionTriplets: [
    [
      StationActionType.GET_STATION_THRESHOLD,
      StationActionType.GET_STATION_THRESHOLD_SUCCESS,
      StationActionType.GET_STATION_THRESHOLD_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationThresholdUpdateLoadingModule = new LoadingModule({
  name: 'stationThresholdUpdate',
  actionTriplets: [
    [
      StationActionType.UPDATE_STATION_THRESHOLD,
      StationActionType.UPDATE_STATION_THRESHOLD_SUCCESS,
      StationActionType.UPDATE_STATION_THRESHOLD_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationUpdateLoadingModule = new LoadingModule({
  name: 'stationUpdate',
  actionTriplets: [
    [
      StationActionType.UPDATE_STATION,
      StationActionType.UPDATE_STATION_SUCCESS,
      StationActionType.UPDATE_STATION_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationCreateLoadingModule = new LoadingModule({
  name: 'stationCreate',
  actionTriplets: [
    [
      StationActionType.CREATE_STATION,
      StationActionType.CREATE_STATION_SUCCESS,
      StationActionType.CREATE_STATION_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationDeleteLoadingModule = new LoadingModule({
  name: 'stationDelete',
  actionTriplets: [
    [
      StationActionType.DELETE_STATION,
      StationActionType.DELETE_STATION_SUCCESS,
      StationActionType.DELETE_STATION_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const userListLoadingModule = new LoadingModule({
  name: 'listUser',
  actionTriplets: [
    [
      UserActionType.GET_LIST_USER_REQUEST,
      UserActionType.GET_LIST_USER_SUCCESS,
      UserActionType.GET_LIST_USER_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const roleListLoadingModule = new LoadingModule({
  name: 'listRole',
  actionTriplets: [
    [
      RoleActionType.GET_LIST_ROLE_REQUEST,
      RoleActionType.GET_LIST_ROLE_SUCCESS,
      RoleActionType.GET_LIST_ROLE_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationDeviceListLoadingModule = new LoadingModule({
  name: 'stationDeviceList',
  actionTriplets: [
    [
      StationActionType.GET_LIST_STATION_DEVICE,
      StationActionType.GET_LIST_STATION_DEVICE_SUCCESS,
      StationActionType.GET_LIST_STATION_DEVICE_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationDeviceCreateLoadingModule = new LoadingModule({
  name: 'stationDeviceCreate',
  actionTriplets: [
    [
      StationActionType.CREATE_STATION_DEVICE,
      StationActionType.CREATE_STATION_DEVICE_SUCCESS,
      StationActionType.CREATE_STATION_DEVICE_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationDeviceDeleteLoadingModule = new LoadingModule({
  name: 'stationDeviceDelete',
  actionTriplets: [
    [
      StationActionType.DELETE_STATION_DEVICE,
      StationActionType.DELETE_STATION_DEVICE_SUCCESS,
      StationActionType.DELETE_STATION_DEVICE_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationHistoryListLoadingModule = new LoadingModule({
  name: 'stationHistoryList',
  actionTriplets: [
    [
      StationActionType.GET_LIST_STATION_HISTORY,
      StationActionType.GET_LIST_STATION_HISTORY_SUCCESS,
      StationActionType.GET_LIST_STATION_HISTORY_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationHistoryCreateLoadingModule = new LoadingModule({
  name: 'stationHistoryCreate',
  actionTriplets: [
    [
      StationActionType.CREATE_STATION_HISTORY,
      StationActionType.CREATE_STATION_HISTORY_SUCCESS,
      StationActionType.CREATE_STATION_HISTORY_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const stationHistoryDeleteLoadingModule = new LoadingModule({
  name: 'stationHistoryDelete',
  actionTriplets: [
    [
      StationActionType.DELETE_STATION_HISTORY,
      StationActionType.DELETE_STATION_DEVICE_SUCCESS,
      StationActionType.DELETE_STATION_HISTORY_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const analyticListLoadingModule = new LoadingModule({
  name: 'analyticList',
  actionTriplets: [
    [
      AnalyticActionType.GET_LIST_ANALYTIC,
      AnalyticActionType.GET_LIST_ANALYTIC_SUCCESS,
      AnalyticActionType.GET_LIST_ANALYTIC_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const analyticDeleteLoadingModule = new LoadingModule({
  name: 'analyticDelete',
  actionTriplets: [
    [
      AnalyticActionType.DELETE_ANALYTIC,
      AnalyticActionType.DELETE_ANALYTIC_SUCCESS,
      AnalyticActionType.DELETE_ANALYTIC_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const listConfigMappingLoadingModule = new LoadingModule({
  name: 'listConfigMapping',
  actionTriplets: [
    [
      StationConfigActionType.GET_STATION_CONFIG_MAPPING,
      StationConfigActionType.GET_STATION_CONFIG_MAPPING_SUCCESS,
      StationConfigActionType.GET_STATION_CONFIG_MAPPING_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const listThresholdMappingLoadingModule = new LoadingModule({
  name: 'listThresholdMapping',
  actionTriplets: [
    [
      StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING,
      StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_SUCCESS,
      StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});

export const loadingDownLoadStationMonthlyLoadingModule = new LoadingModule({
  name: 'loadingDownLoadStationMonthly',
  actionTriplets: [
    [
      StationActionType.DOWNLOAD_FILE_STATION_MONTHLY,
      StationActionType.DOWNLOAD_FILE_STATION_MONTHLY_SUCCESS,
      StationActionType.DOWNLOAD_FILE_STATION_MONTHLY_FAIL,
    ],
  ],
  defaultErrorAction: 'errorAction',
});
