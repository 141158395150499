import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FiFilePlus } from 'react-icons/fi';

import TextButton from '~/components/atoms/Button';
import DataTable from '~/components/molecules/DataTable';
import CustomUploadAnalyticModal from '~/components/organism/Modal/CustomUploadAnalyticModal';
import { useLoading } from '~/hooks';
import useAnalyticTable from '~/pages/Analytic/constants';
import { useAnalyticStore } from '~/store/hooks';
import { IAnalyticParamsRequestList } from '~/types';
import { analyticDeleteLoadingModule, analyticListLoadingModule } from '~/utils';

export interface AnalyticPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const AnalyticPage = (props: AnalyticPropsType): JSX.Element => {
  const { onConfirmDelete } = props;
  const { t } = useTranslation();
  const isLoading = useLoading(analyticListLoadingModule);
  const isLoadingAnalyticDelete = useLoading(analyticDeleteLoadingModule);
  const { columns, isModalAnalyticOpen, handleOpenModalAnalytic, handleCloseModalAnalytic } =
    useAnalyticTable({ onConfirmDelete });
  const { analyticState, dispatchGetListAnalytic } = useAnalyticStore();
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const handleChangePage = React.useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleAddAnalytic = React.useCallback(() => {
    handleOpenModalAnalytic();
  }, [handleOpenModalAnalytic]);

  const fetchAnalyticList = React.useCallback(
    async (params: IAnalyticParamsRequestList) => {
      await dispatchGetListAnalytic(params);
    },
    [dispatchGetListAnalytic],
  );

  const fetchAnalyticListCallBack = React.useCallback(() => {
    fetchAnalyticList({ current: currentPage, pageSize: 10 });
  }, [currentPage, fetchAnalyticList]);

  React.useEffect(() => {
    fetchAnalyticList({ current: currentPage, pageSize: 10 });
  }, [currentPage, isLoadingAnalyticDelete, fetchAnalyticList]);

  return (
    <React.Fragment>
      <AnalyticContainer>
        <Title>{t('sidebar.analytics')}</Title>
        <AnalyticHeader>
          <ActionButtonContainer>
            <TextButton
              color='primary'
              variant='contained'
              startIcon={<FiFilePlus />}
              onClick={handleAddAnalytic}
            >
              {t('analytic.action.add_analytic')}
            </TextButton>
          </ActionButtonContainer>
        </AnalyticHeader>
        <DataTable
          isFetching={isLoading}
          data={analyticState.listAnalytic.list}
          columns={columns}
          totalPage={analyticState.listAnalytic.pagination.totalPage || 1}
          handleChangePage={handleChangePage}
        />
      </AnalyticContainer>
      {isModalAnalyticOpen && (
        <CustomUploadAnalyticModal
          opened={isModalAnalyticOpen}
          fetchAnalyticListCallBack={fetchAnalyticListCallBack}
          handleClose={handleCloseModalAnalytic}
        />
      )}
    </React.Fragment>
  );
};

const AnalyticContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;

const Title = styled.div`
  color: #455560;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const AnalyticHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default AnalyticPage;
