import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import TextButton from '~/components/atoms/Button';
import DatePickerMonthInput from '~/components/atoms/Input/DatePickerMonthInline';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import ModalContainer from '~/components/molecules/Container/ModalContainer';
import { BrandColors } from '~/constants';
import { moment as dayjs } from '~/plugins';

interface Props {
  isLoading?: boolean;
  opened: boolean;
  width?: string;
  height?: string;
  handleClose: () => void;
  handleDownLoadReport: (date: string) => void;
}

const CustomSelectTimeModal = ({
  isLoading = false,
  opened = false,
  width = '280px',
  height = '280px',
  handleClose,
  handleDownLoadReport,
}: Props) => {
  const { t } = useTranslation();
  const [monthDate, setMonthDate] = React.useState<string>(dayjs().toString());

  const startIcon = React.useMemo(() => {
    if (isLoading) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
  }, [isLoading]);

  const handleCloseModal = React.useCallback(() => {
    handleClose();
    setTimeout(() => {
      setMonthDate(dayjs().toString());
    }, 300);
  }, [handleClose]);

  const handleMonthDateChange = React.useCallback((date: Date | null) => {
    setMonthDate(dayjs(date).toString());
  }, []);

  const handleSubmit = React.useCallback(() => {
    handleDownLoadReport && handleDownLoadReport(dayjs(monthDate).format('YYYY-MM'));
  }, [handleDownLoadReport, monthDate]);

  return (
    <ModalContainer opened={opened} width={width} height={height} handleClose={handleCloseModal}>
      <Title>Chọn thời gian export</Title>
      <Container>
        <Section>
          <DatePickerMonthInput
            inline={true}
            value={monthDate}
            handleChange={handleMonthDateChange}
          />
        </Section>
        <Bottom>
          <TextButton size='small' variant='outlined' onClick={handleCloseModal}>
            {t('analytic.action.cancel')}
          </TextButton>
          <TextButton
            size='small'
            startIcon={startIcon}
            disabled={isLoading}
            variant='contained'
            color='primary'
            onClick={handleSubmit}
          >
            Download
          </TextButton>
        </Bottom>
      </Container>
    </ModalContainer>
  );
};

const Title = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${BrandColors.under};
  background: #e0e0e0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-bottom: 1px solid #b1b1b2;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(400px - 35px);
`;

const Section = styled.div`
  height: calc(100% - 48px);
`;

const Bottom = styled.div`
  height: 48px;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #b1b1b2;
`;

export default React.memo(CustomSelectTimeModal);
