import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { SideBarRouters, SubMenuRouters } from './constants';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

export interface ItemPropsType {
  children?: React.ReactNode | React.ReactNode[];
  info: SideBarRouters;
  activePath: string;
  collapsedSideBarState?: boolean;
}

export interface SideBarMenuPropsType {
  children?: React.ReactNode | React.ReactNode[];
  listItems: SideBarRouters[];
  collapsedSideBarState?: boolean;
}

const Item = (props: ItemPropsType) => {
  const [subMenuStatus, setSubMenuStatus] = useState(true);
  const { info, activePath, collapsedSideBarState } = props;
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    // Trường hợp 1 có sub menu thì mở sub menu lên
    if (info?.subMenu) {
      setSubMenuStatus(!subMenuStatus);
    } else {
      // Trường hợp 2 không có sub menu thì navigate luôn theo route
      navigate(info?.path);
    }
  }, [info?.path, info?.subMenu, navigate, subMenuStatus]);

  const handleClickSubMenuBtn = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  // --icon - title
  return (
    <ItemElement className={`${activePath?.includes(info?.path) ? 'active' : 'inactive'}`}>
      <div className='main' onClick={handleClick}>
        <Tooltip title={info?.title} placement='right'>
          <div className='main-wrapper'>
            <div className='left'>
              <div className='icon'>{info?.icon && <info.icon />}</div>
            </div>
            <div className={`right ${collapsedSideBarState ? 'collapsed' : ''}`}>
              <div className='title'>{info?.title}</div>
              <div className={`sub-menu-icon`}>
                {subMenuStatus
                  ? info?.iconOpened && <info.iconOpened />
                  : info?.iconClosed && <info.iconClosed />}
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
      <div className={`sub ${subMenuStatus ? 'visible' : 'disabled'}`}>
        {subMenuStatus &&
          info?.subMenu &&
          info?.subMenu?.map((one: SubMenuRouters, index: any) => (
            <Tooltip title={one?.title} placement='right'>
              <div
                className={`one-sub ${activePath?.includes(one?.path) ? 'active' : 'inactive'}`}
                key={index}
                onClick={() => {
                  handleClickSubMenuBtn(one.path);
                }}
              >
                <div className='left'>
                  <div className='icon'>
                    <one.icon />
                  </div>
                </div>
                <div className={`right ${collapsedSideBarState ? 'collapsed' : ''}`}>
                  <div className='title'>{one?.title}</div>
                </div>
              </div>
            </Tooltip>
          ))}
      </div>
    </ItemElement>
  );
};

const SideBarMenu = (props: SideBarMenuPropsType) => {
  const { listItems, collapsedSideBarState } = props;
  const location = useLocation();

  return (
    <SideBarMenuElement>
      {listItems.map((item: any, index: any) => (
        <Item
          info={item}
          key={index}
          activePath={location.pathname}
          collapsedSideBarState={collapsedSideBarState}
        />
      ))}
    </SideBarMenuElement>
  );
};

export default SideBarMenu;

const SideBarMenuElement = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 100%;
`;

const ItemElement = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.active {
    .main {
      color: #666fe8;
      .main-wrapper {
        background-image: linear-gradient(to right, #666fe8, #939aee);
        color: #fff;
        /* background: white; */
      }
    }
  }

  &.inactive {
    .main {
      color: #2e384d;

      .main-wrapper {
        &:hover {
          color: #666fe8;
          background-color: #f4f5f9b3;
        }
      }
    }
  }

  .collapsed {
    /* width: 0 !important;
    overflow: hidden !important; */
    display: none !important;
  }

  .main {
    width: 100%;
    height: 40px;

    display: flex;
    flex-direction: row;

    display: flex;
    flex-direction: row;
    padding: 8px;
    margin-bottom: 8px;
    cursor: pointer;

    .main-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      font-weight: 500;
      font-size: 16px;
      align-items: center;
      border-radius: 30px;
      padding: 20px 8px;
      white-space: nowrap;

      & > .left {
        margin-left: 8px;
        font-size: 22px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 24px;
        height: 24px;
      }

      & > .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-left: 10px;

        & > .sub-menu-icon {
          margin-right: 4px;
          width: 20px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .sub {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;

    &.disabled {
      display: none;
    }

    &.visible {
      display: block;
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 26px;

    .one-sub {
      padding: 8px;
      display: flex;
      align-items: center;
      flex-direction: row;
      font-size: 15px;
      cursor: pointer;

      &.active {
        color: #666fe8;
      }

      &:hover {
        color: #666fe8;
      }

      .left {
        margin-right: 10px;
      }
    }
  }
`;
