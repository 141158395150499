import styled from 'styled-components';
import { getInputType } from '~/plugins/input';
import { useCallback } from 'react';

export interface InputPropsType {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  name?: string;
  require?: boolean;
  body?: any;
  value?: any;
  onChange?: any;
  validate?: boolean;
  extendValidate?: any;
}

const Input = (props: InputPropsType) => {
  const { title, name, body, value, onChange, validate, extendValidate } = props;

  const handleChange = useCallback(
    (e: any) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );

  return (
    <InputElement>
      <div className='header'></div>
      <div className='body'>
        {body ? (
          body
        ) : (
          <label htmlFor={`${name}-input`}>
            <input
              type={getInputType(name)}
              name={name}
              id={`${name}-input`}
              spellCheck='false'
              value={value}
              onChange={handleChange}
              placeholder={title}
            />
          </label>
        )}
      </div>
      <div className='footer'>
        {extendValidate && <div className='require'>{extendValidate}</div>}
        <div className={`warning ${validate ? 'hidden' : 'visible'}`}>
          Please retype your {name}.
        </div>
      </div>
    </InputElement>
  );
};

const InputElement = styled.div`
  width: 100%;
  height: fit-content;
  overflow: visible;

  // Ẩn nút tăng, giảm số khi type = number
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .header {
    width: 100%;
    // height: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    span {
      color: #8d9aa9;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.125714px;
    }

    padding: 8px 0;

    .require-btn {
      margin-right: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      letter-spacing: -0.125714px;
      color: #8d9aa9;
    }
  }

  .body {
    width: 100%;
    // height: 100%;
    z-index: 2;

    label {
      background: #fafafa;
      border: 1px solid #bfc5d2;
      border-radius: 5px;
      width: 100%;
      height: 40px;
      overflow: hidden;
      padding: 6px 12px;
      transition: 0.3s;
      display: block;

      &:focus-within {
        background-color: #e8e9f7;
      }

      input {
        font-style: normal;
        line-height: 21px;
        // display: flex;
        // align-items: center;
        color: #2e384d;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
      }
    }
  }

  .footer {
    z-index: 1;

    div.require {
      margin-top: 8px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      grid-template-columns: repeat(2, 1fr);
      grid-column: 2;
      display: grid;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
      grid: 2;
      align-items: center;
      justify-items: start;
      justify-content: center;
      align-content: stretch;

      span {
        font-size: 13px;
        padding: 5px 0;
        display: flex;

        svg {
          margin-right: 8px;
        }
      }
    }

    div.warning {
      animation: flyUp 0.5s ease 0s 1 alternate forwards;
      margin-left: 5px;
      color: #d63649;
      font-size: 12px;
    }
  }
`;

export default Input;
