import styled from 'styled-components';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <NotFoundPageElement>
      <div className='face'>
        <div className='band'>
          <div className='red'></div>
          <div className='white'></div>
          <div className='blue'></div>
        </div>
        <div className='eyes'></div>
        <div className='dimples'></div>
        <div className='mouth'></div>
      </div>

      <h1>Oops! Something went wrong!</h1>
      <Link to='/'>
        <div className='btn'>Return to Home</div>
      </Link>
    </NotFoundPageElement>
  );
};

export default NotFoundPage;

const NotFoundPageElement = styled.div`
  background-color: #f2eee8;
  width: 100vw;
  height: 100vh;
  position: fixed;

  *,
  *:before,
  *:after {
    box-sizing: content-box;
    transform: translate3d(0, 0, 0);
  }

  .face {
    width: 300px;
    height: 300px;
    border: 4px solid #383a41;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 0 auto;
    margin-top: 100px;

    @media screen and (max-width: 400px) {
      margin-top: 40px;
      transform: scale(0.8);
    }

    .band {
      width: 350px;
      height: 27px;
      border: 4px solid #383a41;
      border-radius: 5px;
      margin-left: -25px;
      margin-top: 50px;

      .red {
        height: calc(100% / 3);
        width: 100%;
        background-color: #eb6d6d;
      }

      .white {
        height: calc(100% / 3);
        width: 100%;
        background-color: #ffffff;
      }

      .blue {
        height: calc(100% / 3);
        width: 100%;
        background-color: #5e7fdc;
      }

      &:before {
        content: '';
        display: inline-block;
        height: 27px;
        width: 30px;
        background-color: rgba(#ffffff, 0.3);
        position: absolute;
        z-index: 999;
      }

      &:after {
        content: '';
        display: inline-block;
        height: 27px;
        width: 30px;
        background-color: rgba(#383a41, 0.3);
        position: absolute;
        z-index: 999;
        right: 0;
        margin-top: -27px;
      }
    }

    .eyes {
      width: 128px;
      margin: 0 auto;
      margin-top: 40px;

      &:before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 15px;
        border: 7px solid #383a41;
        margin-right: 20px;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        border-bottom: 0;
      }

      &:after {
        content: '';
        display: inline-block;
        width: 30px;
        height: 15px;
        border: 7px solid #383a41;
        margin-left: 20px;
        border-top-left-radius: 22px;
        border-top-right-radius: 22px;
        border-bottom: 0;
      }
    }

    .dimples {
      width: 180px;
      margin: 0 auto;
      margin-top: 15px;

      &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 80px;
        border-radius: 50%;
        background-color: rgba(#eb6d6d, 0.4);
      }

      &:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 80px;
        border-radius: 50%;
        background-color: rgba(#eb6d6d, 0.4);
      }
    }

    .mouth {
      width: 40px;
      height: 5px;
      border-radius: 5px;
      background-color: #383a41;
      margin: 0 auto;
      margin-top: 25px;
    }
  }

  h1 {
    font-weight: 800;
    color: #383a41;
    text-align: center;
    font-size: 2.5em;
    padding-top: 20px;

    @media screen and (max-width: 400px) {
      padding-left: 20px;
      padding-right: 20px;
      font-size: 2em;
    }
  }

  .btn {
    font-weight: 400;
    padding: 20px;
    background-color: rgba(94, 127, 220, 1);
    color: white;
    width: 320px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2em;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 80px;
    margin-bottom: 50px;
    transition: all 0.2s linear;

    &:hover {
      background-color: rgba(94, 127, 220, 0.8);
      transition: all 0.2s linear;
    }

    @media screen and (max-width: 400px) {
      margin: 0 auto;
      margin-top: 60px;
      margin-bottom: 50px;
      width: 200px;
    }
  }
`;
