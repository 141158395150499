import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, Route, Routes, Navigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { RootPath, SubPath } from '~/configs';
import { Events } from '~/constants/events';
import { EventEmitter } from '~/plugins/eventEmitter';
import { useAuthInfoStore, useToastStore } from '~/store/hooks';
import ConfirmDeleteDialog from '~/components/molecules/ConfirmDeleteDialog';
import RequireAuth from '~/middleware/auth';
import SystemInformation from '~/pages/SystemInformation';
import AddUser from '~/pages/User/sub-pages/AddUser';
import EditUser from '~/pages/User/sub-pages/EditUser';
import ListUser from '~/pages/User/sub-pages/ListUser';
import AddRole from '~/pages/Role/sub-pages/AddRole';
import EditRole from '~/pages/Role/sub-pages/EditRole';
import ListRole from '~/pages/Role/sub-pages/ListRole';
import StationCreate from '~/pages/Station/StationCreate';
import StationDetail from '~/pages/Station/StationDetail';
import StationList from '~/pages/Station/StationList';
import StationMap from '~/pages/Station/StationMap';
import StationUpdate from '~/pages/Station/StationUpdate';
import StationDevice from '~/pages/Station/StationDevice';
import StationHistory from '~/pages/Station/StationHistory';
import AnalyticPage from '~/pages/Analytic';
import Login from '~/pages/Login';
import NotFoundPage from '~/pages/NotFoundPage';
import SettingPage from '~/pages/Setting';
import ProfilePage from '~/pages/Profile';
import About from '~/pages/About';
// import Register from '~/pages/Register';

export const AppRoutes = (): React.ReactElement => {
  const timeoutRef = useRef<any>(null);
  const { dispatchCreateToast } = useToastStore();
  const { authState, dispatchAuthLogout, dispatchAuthRefreshTokenRequest } = useAuthInfoStore();

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState<any>({
    status: false,
    deleteName: '',
    callback: undefined,
  });

  // Xử lý token expired
  useEffect(() => {
    if (authState?.authLogin?.expires_at) {
      const now: number = Math.floor(new Date().getTime() / 1000);

      if (authState?.keepLogin) {
        if (now > authState?.authLogin?.expires_at - 10) {
          dispatchAuthRefreshTokenRequest();
        } else {
          timeoutRef.current = setTimeout(() => {
            dispatchAuthRefreshTokenRequest();
          }, (authState.authLogin.expires_at - (now + 10)) * 1000);

          EventEmitter.subscribe(
            Events.TOKEN_EXPIRED,
            () => {
              dispatchAuthRefreshTokenRequest();
            },
            1,
          );

          return () => {
            clearTimeout(timeoutRef.current);
            EventEmitter.unsubscribe(Events.TOKEN_EXPIRED);
          };
        }
      } else {
        if (now > authState?.authLogin?.expires_at) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'failed',
            message: 'Token hết hạn! Vui lòng đăng nhập lại!',
          });

          dispatchAuthLogout();
        } else {
          timeoutRef.current = setTimeout(() => {
            dispatchCreateToast({
              id: uuidv4(),
              toastType: 'failed',
              message: 'Token hết hạn! Vui lòng đăng nhập lại!',
            });

            dispatchAuthLogout();
          }, (authState.authLogin.expires_at - now) * 1000);

          EventEmitter.subscribe(
            Events.TOKEN_EXPIRED,
            () => {
              dispatchCreateToast({
                id: uuidv4(),
                toastType: 'failed',
                message: 'Token hết hạn! Vui lòng đăng nhập lại!',
              });

              dispatchAuthLogout();
            },
            1,
          );

          return () => {
            clearTimeout(timeoutRef.current);
            EventEmitter.unsubscribe(Events.TOKEN_EXPIRED);
          };
        }
      }
    }
  }, [
    authState?.authLogin?.access_token,
    dispatchAuthLogout,
    dispatchCreateToast,
    authState?.authLogin?.expires_at,
    authState?.keepLogin,
    dispatchAuthRefreshTokenRequest,
    timeoutRef,
  ]);

  const handleShowConfirmDeleteDialog = useCallback((deleteName?: string) => {
    return new Promise((resolve) => {
      const callback = (confirm: boolean) => {
        resolve(confirm);
        setShowConfirmDeleteDialog({
          status: false,
          deleteName: undefined,
          callback: undefined,
        });
      };

      setShowConfirmDeleteDialog({
        status: true,
        deleteName,
        callback,
      });
    });
  }, []);

  if (authState?.authLogin?.access_token) {
    return (
      <React.Fragment>
        <Routes>
          <Route path={RootPath.Login} element={<Login />} />
          <Route path={RootPath.Home} element={<RequireAuth />}>
            {authState?.authLogin?.role_slug !== 'user' ? (
              <Route path={RootPath.User}>
                <Route
                  index
                  element={<ListUser onConfirmDelete={handleShowConfirmDeleteDialog} />}
                />
                <Route
                  path={SubPath.listUser}
                  element={<ListUser onConfirmDelete={handleShowConfirmDeleteDialog} />}
                />
                <Route path={SubPath.addUser} element={<AddUser />} />
                <Route
                  path={SubPath.editUser}
                  element={<EditUser onConfirmDelete={handleShowConfirmDeleteDialog} />}
                />
              </Route>
            ) : (
              <Route path={RootPath.User}>
                <Route index element={<Navigate to='/' replace />} />
                <Route
                  path={SubPath.editUser}
                  element={<EditUser onConfirmDelete={handleShowConfirmDeleteDialog} />}
                />
                <Route path='*' element={<Navigate to='/' replace />} />
              </Route>
            )}

            {authState?.authLogin?.role_slug === 'sa' && (
              <React.Fragment>
                <Route path={RootPath.SystemInformation} element={<SystemInformation />} />
                <Route path={RootPath.Role}>
                  <Route index element={<ListRole />} />
                  <Route path={SubPath.listRole} element={<ListRole />} />
                  <Route path={SubPath.addRole} element={<AddRole />} />
                  <Route path={SubPath.editRole} element={<EditRole />} />
                </Route>
                <Route
                  path={RootPath.Settings}
                  element={<SettingPage onConfirmDelete={handleShowConfirmDeleteDialog} />}
                />
                <Route
                  path={RootPath.Analytics}
                  element={<AnalyticPage onConfirmDelete={handleShowConfirmDeleteDialog} />}
                />
              </React.Fragment>
            )}
            <Route path={RootPath.Stations} element={<Outlet />}>
              <Route index element={<Navigate to={SubPath.stationMap} replace={true} />} />
              <Route
                path={SubPath.listStation}
                element={<StationList onConfirmDelete={handleShowConfirmDeleteDialog} />}
              />
              <Route
                path={SubPath.stationMap}
                element={<StationMap onConfirmDelete={handleShowConfirmDeleteDialog} />}
              />
              <Route path={SubPath.addStation} element={<StationCreate />} />
              <Route path={SubPath.detailStation} element={<StationDetail />} />
              <Route path={SubPath.updateStation} element={<StationUpdate />} />
              <Route
                path={SubPath.deviceStation}
                element={<StationDevice onConfirmDelete={handleShowConfirmDeleteDialog} />}
              />
              <Route
                path={SubPath.historyStation}
                element={<StationHistory onConfirmDelete={handleShowConfirmDeleteDialog} />}
              />
            </Route>
            <Route path={RootPath.Profile} element={<ProfilePage />} />
            <Route path={RootPath.About} element={<About />} />
          </Route>
          <Route path='*' element={<NotFoundPage />} />
          <Route index element={<Navigate to={RootPath.Stations} replace={true} />} />
        </Routes>
        <ConfirmDeleteDialog
          open={showConfirmDeleteDialog?.status}
          onConfirm={showConfirmDeleteDialog?.callback}
          deleteName={showConfirmDeleteDialog?.deleteName}
        />
      </React.Fragment>
    );
  } else {
    return (
      <Routes>
        <Route index element={<Login />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    );
  }
};
