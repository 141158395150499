import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { I18nMessages, I18nSupportLanguage } from '~/locales';
import enMessages from '~/locales/messages/en.json';
import viMessages from '~/locales/messages/vi.json';
import { store } from '~/store/ducks/rootStore';
import { getSelectLanguage } from '~/store/selectors';

export const translationsJson: I18nMessages<I18nSupportLanguage> = {
  en: { translation: enMessages },
  vi: { translation: viMessages },
};

const languageDetector = (): I18nSupportLanguage => {
  return getSelectLanguage(store.getState().i18nLanguage);
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: translationsJson,
    lng: languageDetector(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
