import { I18nSupportLanguage } from '~/locales';

export interface ISetLanguage {
  lang: I18nSupportLanguage;
}

export enum I18nActionType {
  SET_LANGUAGE = 'i18n/SET_LANGUAGE',
}

export interface I18nSetLanguage {
  type: I18nActionType.SET_LANGUAGE;
  payload: {
    lang: I18nSupportLanguage;
  };
}

export type I18nAction = I18nSetLanguage;

export interface I18nState {
  lang: I18nSupportLanguage;
}
