import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createStation,
  createStationDevice,
  createStationHistory,
  deleteStation,
  deleteStationDevice,
  deleteStationHistory,
  downloadFileReportStation,
  downloadFileReportStationData,
  downloadFileReportStationMonthly,
  downloadSensorReportData,
  downloadSensorChartReportData,
  getAllListStation,
  getListStation,
  getStationChartInfo,
  getStationConfigData,
  getStationDetail,
  getStationDevice,
  getStationHistory,
  getStationThreshold,
  updateStation,
  updateStationDataConfig,
  updateStationDevice,
  updateStationHistory,
  updateStationThreshold,
} from '~/store/ducks/station/actions';
import { RootState } from '~/store/types';
import {
  IDownloadSensorReportData,
  IStation,
  IStationConfigUpdateRequest,
  IStationDetail,
  IStationDevice,
  IStationDeviceAddRequest,
  IStationDeviceParamsRequestList,
  IStationHistory,
  IStationHistoryAddRequest,
  IStationHistoryParamsRequestList,
  IStationParamsRequestList,
  IStationReportData,
  IStationReportMonthly,
  IStationRequestData,
  IStationThresholdUpdateRequest,
} from '~/types';

interface FuncType {
  stationState: RootState['stations'];
  dispatchGetListStation: (params: IStationParamsRequestList) => Promise<void>;
  dispatchGetAllListStation: () => Promise<void>;
  dispatchGetStationDetail: (stationId: IStation['id']) => Promise<void>;
  dispatchGetStationChartInfo: (params: IStationRequestData) => Promise<void>;
  dispatchGetStationDataConfig: (stationId: IStation['id']) => Promise<void>;
  dispatchUpdateStationDataConfig: (params: IStationConfigUpdateRequest[]) => Promise<void>;
  dispatchGetStationThreshold: (stationId: IStation['id']) => Promise<void>;
  dispatchUpdateStationThreshold: (params: IStationThresholdUpdateRequest[]) => Promise<void>;
  dispatchUpdateStation: (params: IStationDetail) => Promise<void>;
  dispatchCreateStation: (params: IStationDetail) => Promise<void>;
  dispatchDeleteStation: (stationId: IStation['id']) => Promise<void>;
  dispatchGetListStationDevice: (params: IStationDeviceParamsRequestList) => Promise<void>;
  dispatchCreateStationDevice: (
    params: IStationDeviceAddRequest,
    handleCallbackFunction: () => void,
  ) => Promise<void>;
  dispatchUpdateStationDevice: (
    params: IStationDeviceAddRequest,
    handleCallbackFunction: () => void,
  ) => Promise<void>;
  dispatchDeleteStationDevice: (stationDeviceId: IStationDevice['id']) => Promise<void>;
  dispatchGetListStationHistory: (params: IStationHistoryParamsRequestList) => Promise<void>;
  dispatchCreateStationHistory: (
    params: IStationHistoryAddRequest,
    handleCallbackFunction: () => void,
  ) => Promise<void>;
  dispatchUpdateStationHistory: (
    params: IStationHistoryAddRequest,
    handleCallbackFunction: () => void,
  ) => Promise<void>;
  dispatchDeleteStationHistory: (stationHistoryId: IStationHistory['id']) => Promise<void>;
  dispatchDownLoadReportMonthly: (
    params: IStationReportMonthly,
    handleResetTable: () => void,
  ) => Promise<void>;
  dispatchDownLoadReportData: (
    params: IStationReportData,
    handleCloseModal: () => void,
  ) => Promise<void>;
  dispatchDownLoadSensorReportData: (params: IDownloadSensorReportData) => Promise<void>;
  dispatchDownloadSensorChartReportData: (params: IDownloadSensorReportData) => Promise<void>;
  dispatchDownLoadReportStation: (station: IStation) => Promise<void>;
}

const useStationStore = (): FuncType => {
  const dispatch = useDispatch();

  const stationState = useSelector<RootState, RootState['stations']>((state) => {
    return state.stations;
  });

  const dispatchGetListStation = React.useCallback(
    async (params: IStationParamsRequestList) => {
      await dispatch(getListStation(params));
    },
    [dispatch],
  );

  const dispatchGetAllListStation = React.useCallback(async () => {
    await dispatch(getAllListStation());
  }, [dispatch]);

  const dispatchGetStationDetail = React.useCallback(
    async (stationId: IStation['id']) => {
      await dispatch(getStationDetail(stationId));
    },
    [dispatch],
  );

  const dispatchGetStationChartInfo = React.useCallback(
    async (params: IStationRequestData) => {
      await dispatch(getStationChartInfo(params));
    },
    [dispatch],
  );

  // delete
  const dispatchGetStationDataConfig = React.useCallback(
    async (stationId: IStation['id']) => {
      await dispatch(getStationConfigData(stationId));
    },
    [dispatch],
  );

  const dispatchUpdateStationDataConfig = React.useCallback(
    async (params: IStationConfigUpdateRequest[]) => {
      await dispatch(updateStationDataConfig(params));
    },
    [dispatch],
  );

  const dispatchGetStationThreshold = React.useCallback(
    async (stationId: IStation['id']) => {
      await dispatch(getStationThreshold(stationId));
    },
    [dispatch],
  );

  const dispatchUpdateStationThreshold = React.useCallback(
    async (params: IStationThresholdUpdateRequest[]) => {
      await dispatch(updateStationThreshold(params));
    },
    [dispatch],
  );

  const dispatchUpdateStation = React.useCallback(
    async (params: IStationDetail) => {
      await dispatch(updateStation(params));
    },
    [dispatch],
  );

  const dispatchCreateStation = React.useCallback(
    async (params: IStationDetail) => {
      await dispatch(createStation(params));
    },
    [dispatch],
  );

  const dispatchDeleteStation = React.useCallback(
    async (stationId: IStation['id']) => {
      await dispatch(deleteStation(stationId));
    },
    [dispatch],
  );

  const dispatchGetListStationDevice = React.useCallback(
    async (params: IStationDeviceParamsRequestList) => {
      await dispatch(getStationDevice(params));
    },
    [dispatch],
  );

  const dispatchCreateStationDevice = React.useCallback(
    async (params: IStationDeviceAddRequest, handleCallbackFunction: () => void) => {
      await dispatch(createStationDevice(params, handleCallbackFunction));
    },
    [dispatch],
  );

  const dispatchUpdateStationDevice = React.useCallback(
    async (params: IStationDeviceAddRequest, handleCallbackFunction: () => void) => {
      await dispatch(updateStationDevice(params, handleCallbackFunction));
    },
    [dispatch],
  );

  const dispatchDeleteStationDevice = React.useCallback(
    async (stationDeviceId: IStationDevice['id']) => {
      await dispatch(deleteStationDevice(stationDeviceId));
    },
    [dispatch],
  );

  const dispatchGetListStationHistory = React.useCallback(
    async (params: IStationHistoryParamsRequestList) => {
      await dispatch(getStationHistory(params));
    },
    [dispatch],
  );

  const dispatchCreateStationHistory = React.useCallback(
    async (params: IStationHistoryAddRequest, handleCallbackFunction: () => void) => {
      await dispatch(createStationHistory(params, handleCallbackFunction));
    },
    [dispatch],
  );

  const dispatchUpdateStationHistory = React.useCallback(
    async (params: IStationHistoryAddRequest, handleCallbackFunction: () => void) => {
      await dispatch(updateStationHistory(params, handleCallbackFunction));
    },
    [dispatch],
  );

  const dispatchDeleteStationHistory = React.useCallback(
    async (stationHistoryId: IStationHistory['id']) => {
      await dispatch(deleteStationHistory(stationHistoryId));
    },
    [dispatch],
  );

  const dispatchDownLoadReportStation = React.useCallback(
    async (station: IStation) => {
      await dispatch(downloadFileReportStation(station));
    },
    [dispatch],
  );

  const dispatchDownLoadReportMonthly = React.useCallback(
    async (params: IStationReportMonthly, handleResetTable: () => void) => {
      await dispatch(downloadFileReportStationMonthly(params, handleResetTable));
    },
    [dispatch],
  );

  const dispatchDownLoadReportData = React.useCallback(
    async (params: IStationReportData, handleCloseModal: () => void) => {
      await dispatch(downloadFileReportStationData(params, handleCloseModal));
    },
    [dispatch],
  );

  const dispatchDownLoadSensorReportData = React.useCallback(
    async (params: IDownloadSensorReportData) => {
      await dispatch(downloadSensorReportData(params));
    },
    [dispatch],
  );

  const dispatchDownloadSensorChartReportData = React.useCallback(
    async (params: IDownloadSensorReportData) => {
      await dispatch(downloadSensorChartReportData(params));
    },
    [dispatch],
  );

  return {
    stationState,
    dispatchGetListStation,
    dispatchGetAllListStation,
    dispatchGetStationDetail,
    dispatchGetStationChartInfo,
    dispatchGetStationDataConfig,
    dispatchUpdateStationDataConfig,
    dispatchGetStationThreshold,
    dispatchUpdateStationThreshold,
    dispatchUpdateStation,
    dispatchCreateStation,
    dispatchDeleteStation,
    dispatchGetListStationDevice,
    dispatchCreateStationDevice,
    dispatchUpdateStationDevice,
    dispatchDeleteStationDevice,
    dispatchGetListStationHistory,
    dispatchCreateStationHistory,
    dispatchUpdateStationHistory,
    dispatchDeleteStationHistory,
    dispatchDownLoadReportStation,
    dispatchDownLoadReportMonthly,
    dispatchDownLoadReportData,
    dispatchDownLoadSensorReportData,
    dispatchDownloadSensorChartReportData,
  };
};

export default useStationStore;
