import Tooltip from '@mui/material/Tooltip';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineEdit } from 'react-icons/ai';
import { FiSave, FiTrash2 } from 'react-icons/fi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { GiBackup } from 'react-icons/gi';
import { FcDataBackup } from 'react-icons/fc';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import TextButton from '~/components/atoms/Button';
import { moment } from '~/plugins';
import {
  deleteSampleInfo,
  deleteStationConfig,
  updateSampleInfo,
  updateServerConfig,
  updateStationConfig,
  backupData,
  backupInfo,
} from '~/store/actions';
import {
  useSampleInfoStore,
  useServerConfigStore,
  useStationConfigStore,
  useToastStore,
} from '~/store/hooks';
import { ISampleInfoRequest, IStationConfigRequest } from '~/types/api';
import AddPanel from './components/AddPanel';
import Input from './components/Input';

export interface SettingsPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete?: any;
}

const Setting = (props: SettingsPropsType) => {
  const { onConfirmDelete } = props;
  const { t } = useTranslation();
  const { serverConfigState, dispatchGetServerConfig } = useServerConfigStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [maxUser, setMaxUser] = useState('');
  const [editId, setEditId] = useState('');

  const { sampleInfoState, dispatchListGetSampleInfo } = useSampleInfoStore();
  const { stationConfigState, dispatchGetListStationConfig } = useStationConfigStore();

  const [showAddPanel, setShowAddPanel] = useState<any>({
    visible: false,
    type: '',
  });

  const { dispatchCreateToast } = useToastStore();

  useEffect(() => {
    const serverConfig = serverConfigState?.serverConfig;
    if (serverConfig) {
      setEmail(serverConfig?.email || '');
      setPassword(serverConfig?.email_pw || '');
      setPhone(serverConfig?.phone_number || '');
      setAddress(serverConfig?.address || '');
      setMaxUser(serverConfig?.max_user?.toString() || '');
    }
  }, [serverConfigState?.serverConfig]);

  const _handleChangeInput = (e: any) => {
    e.preventDefault();
    const name = e?.target?.name;
    const value = e?.target?.value;

    if (!value || !name) return;

    switch (name) {
      case 'email': {
        setEmail(value);

        break;
      }
      case 'password': {
        setPassword(value);

        break;
      }
      case 'phone': {
        setPhone(value);

        break;
      }
      case 'address': {
        setAddress(value);

        break;
      }
      case 'max_user': {
        setMaxUser(value);

        break;
      }
    }
  };

  const inputList1 = [
    {
      name: 'email',
      title: 'Email',
      require: false,
      value: email,
      onchange: _handleChangeInput,
      validate: true,
    },
    {
      name: 'max_user',
      title: 'Max User',
      require: false,
      value: maxUser,
      onchange: _handleChangeInput,
      validate: true,
    },
  ];

  const inputList2 = [
    {
      name: 'phone',
      title: 'Phone Number',
      require: false,
      value: phone,
      onchange: _handleChangeInput,
      validate: true,
    },
    {
      name: 'address',
      title: 'Address',
      require: false,
      value: address,
      onchange: _handleChangeInput,
      validate: true,
    },
  ];

  const fetchServerConfig = useCallback(() => {
    dispatchGetServerConfig();
  }, [dispatchGetServerConfig]);

  const fetchListView = useCallback(async () => {
    await dispatchListGetSampleInfo();
  }, [dispatchListGetSampleInfo]);

  const fetchListStationConfig = useCallback(async () => {
    await dispatchGetListStationConfig();
  }, [dispatchGetListStationConfig]);

  useEffect(() => {
    fetchServerConfig();
    fetchListView();
    fetchListStationConfig();
  }, [fetchServerConfig, fetchListView, fetchListStationConfig]);

  const handleUpdateServerConfig = useCallback(async () => {
    if (!serverConfigState?.serverConfig?.id) return;

    try {
      const resp = await updateServerConfig({
        id: serverConfigState?.serverConfig?.id,
        email,
        email_pw: password,
        phone_number: phone,
        address,
        max_user: Number(maxUser),
      });

      if (resp) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Thay đổi cấu hình server thành công!',
        });

        fetchServerConfig();
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [
    serverConfigState?.serverConfig?.id,
    email,
    password,
    phone,
    address,
    maxUser,
    dispatchCreateToast,
    fetchServerConfig,
  ]);

  const handleUpdateView = useCallback(
    async (data: ISampleInfoRequest) => {
      if (!data) return;

      try {
        const resp = await updateSampleInfo({
          id: data.id,
          name: data?.name || '',
          key: data?.key || '',
          unit: data?.unit || '',
          priority: data.priority ? parseInt((data as any).priority) : 0,
        });

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Thay đổi cấu hình view thành công!',
          });

          fetchListView();
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast, fetchListView],
  );

  const handleUpdateStationConfig = useCallback(
    async (data: IStationConfigRequest) => {
      if (!data) return;

      try {
        const resp = await updateStationConfig({
          id: data.id,
          name: data?.name || '',
          key: data?.key || '',
        });

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Thay đổi cấu hình trạm thành công!',
          });

          fetchListStationConfig();
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [fetchListStationConfig, dispatchCreateToast],
  );

  const handleClickEdit = (id: string) => {
    setEditId(id);
  };

  const handleClickSave = (type: string, id: string) => {
    if (!id) return;

    const name: any = document.querySelector(`#name-${id}`);
    const key: any = document.querySelector(`#key-${id}`);
    const unit: any = document.querySelector(`#unit-${id}`);
    const priority: any = document.querySelector(`#priority-${id}`);

    if (type === 'view') {
      const data = {
        id,
        name: name?.value || '',
        key: key?.value || '',
        unit: unit?.value || '',
        priority: priority?.value || '',
      };
      handleUpdateView(data);
    } else {
      const data = {
        id,
        name: name?.value || '',
        key: key?.value || '',
      };

      handleUpdateStationConfig(data);
    }

    setEditId('');
  };

  const handleClickDelete = useCallback(
    async (type: string, id: string) => {
      if (!id) return;

      const resultConfirmDelete = await onConfirmDelete(type);
      if (!resultConfirmDelete) return;

      if (type === 'view') {
        try {
          const resp = await deleteSampleInfo(id);

          if (resp) {
            dispatchCreateToast({
              id: uuidv4(),
              toastType: 'success',
              message: 'Xoá view thành công!',
            });

            fetchListView();
          }
        } catch (exception) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'failed',
            message: exception as string,
          });
        }
      } else {
        try {
          const resp = await deleteStationConfig(id);

          if (resp) {
            dispatchCreateToast({
              id: uuidv4(),
              toastType: 'success',
              message: 'Xoá cấu hình trạm thành công!',
            });

            fetchListStationConfig();
          }
        } catch (exception) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'failed',
            message: exception as string,
          });
        }
      }
    },
    [dispatchCreateToast, fetchListStationConfig, fetchListView, onConfirmDelete],
  );

  const handleShowAddPanel = (visible: boolean, type?: string) => {
    setShowAddPanel({
      visible,
      type,
    });
  };

  const handleBackupData = useCallback(async () => {
    try {
      const resp = await backupData();

      if (resp) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Backup data thành công!',
        });
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [dispatchCreateToast]);

  const handleBackupInfo = useCallback(async () => {
    try {
      const resp = await backupInfo();

      if (resp) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Backup info thành công!',
        });
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [dispatchCreateToast]);

  useEffect(() => {
    console.log('sampleInfoState?.listSampleInfo', sampleInfoState?.listSampleInfo);
  }, [sampleInfoState?.listSampleInfo]);

  return (
    <SettingContainer>
      <Header>
        <Title>{t('sidebar.settings')}</Title>
        <div className='controls'>
          <div className='left'></div>

          <div className='right'>
            <TextButton
              color='primary'
              variant='contained'
              onClick={() => {
                handleUpdateServerConfig();
              }}
            >
              Lưu cấu hình
            </TextButton>

            <TextButton
              color='inherit'
              variant='contained'
              startIcon={<IoMdAddCircleOutline />}
              onClick={() => {
                handleShowAddPanel(true, 'view');
              }}
            >
              Tạo View
            </TextButton>

            <TextButton
              color='inherit'
              variant='contained'
              startIcon={<IoMdAddCircleOutline />}
              onClick={() => {
                handleShowAddPanel(true, 'config');
              }}
            >
              Tạo Cấu Hình Trạm
            </TextButton>

            <TextButton
              color='inherit'
              variant='contained'
              startIcon={<FcDataBackup />}
              onClick={() => {
                handleBackupData();
              }}
            >
              Backup Data
            </TextButton>

            <TextButton
              color='inherit'
              variant='contained'
              startIcon={<GiBackup />}
              onClick={() => {
                handleBackupInfo();
              }}
            >
              Backup Info
            </TextButton>
          </div>
        </div>
      </Header>
      <Content>
        <div className='list-server-config-wrapper'>
          <div className='list-server-config'>
            <div className='title'>Cấu hình Server: </div>
            <div className='content'>
              <div className='col'>
                {inputList1.map((one, index) => (
                  <Input
                    title={one.title}
                    key={index}
                    name={one.name}
                    require={one.require}
                    value={one.value}
                    onChange={one.onchange}
                    validate={one.validate}
                  />
                ))}
              </div>
              <div className='col'>
                {inputList2.map((one, index) => (
                  <Input
                    title={one.title}
                    key={index}
                    name={one.name}
                    require={one.require}
                    value={one.value}
                    onChange={one.onchange}
                    validate={one.validate}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='list-setting-wrapper'>
          <div className='list-view-wrapper'>
            <div className='title'>View: </div>
            <div className='table'>
              <Table>
                <thead>
                  <tr>
                    <th>Tên View</th>
                    <th>Key</th>
                    <th>Đơn vị</th>
                    <th>Ưu tiên</th>
                    <th>Ngày tạo</th>
                    <th>Controls</th>
                  </tr>
                </thead>
                <tbody>
                  {sampleInfoState?.listSampleInfo?.map((one) =>
                    one.id === editId ? (
                      <tr key={one.id}>
                        <td>
                          <EditInput type='text' defaultValue={one.name} id={`name-${one.id}`} />
                        </td>
                        <td>
                          <EditInput type='text' defaultValue={one.key} id={`key-${one.id}`} />
                        </td>
                        <td>
                          <EditInput type='text' defaultValue={one.unit} id={`unit-${one.id}`} />
                        </td>
                        <td>
                          <EditInput
                            type='number'
                            defaultValue={one.priority}
                            id={`priority-${one.id}`}
                          />
                        </td>
                        <td>{moment(one.created_at).format('YYYY/MM/DD')}</td>
                        <td>
                          <div className='controls'>
                            <Tooltip title='Lưu thông tin'>
                              <div className='icon'>
                                <FiSave
                                  size={18}
                                  onClick={() => {
                                    handleClickSave('view', one.id);
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title='Xóa'>
                              <div className='icon'>
                                <FiTrash2
                                  size={18}
                                  onClick={() => {
                                    handleClickDelete('view', one.id);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr key={one.id}>
                        <td>{one.name}</td>
                        <td>{one.key}</td>
                        <td>{one.unit}</td>
                        <td>{one.priority}</td>
                        <td>{moment(one.created_at).format('YYYY/MM/DD')}</td>
                        <td>
                          <div className='controls'>
                            <Tooltip title='Cập nhật thông tin'>
                              <div className='icon'>
                                <AiOutlineEdit
                                  size={18}
                                  onClick={() => {
                                    handleClickEdit(one.id || '');
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title='Xóa'>
                              <div className='icon'>
                                <FiTrash2
                                  size={18}
                                  onClick={() => {
                                    handleClickDelete('view', one.id);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <div className='list-config-wrapper'>
            <div className='title'>Cấu hình trạm: </div>
            <div className='table'>
              <Table>
                <thead>
                  <tr>
                    <th>Tên Cấu hình</th>
                    <th>Key</th>
                    <th>Ngày tạo</th>
                    <th>Controls</th>
                  </tr>
                </thead>
                <tbody>
                  {stationConfigState?.stationListConfig?.map((one) =>
                    one.id === editId ? (
                      <tr key={one.id}>
                        <td>
                          <EditInput type='text' defaultValue={one.name} id={`name-${one.id}`} />
                        </td>
                        <td>
                          <EditInput type='text' defaultValue={one.key} id={`key-${one.id}`} />
                        </td>
                        <td>{moment(one.created_at).format('YYYY/MM/DD')}</td>
                        <td>
                          <div className='controls'>
                            <Tooltip title='Lưu thông tin'>
                              <div className='icon'>
                                <FiSave
                                  size={18}
                                  onClick={() => {
                                    handleClickSave('config', one.id);
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title='Xóa'>
                              <div className='icon'>
                                <FiTrash2
                                  size={18}
                                  onClick={() => {
                                    handleClickDelete('config', one.id);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr key={one.id}>
                        <td>{one.name}</td>
                        <td>{one.key}</td>
                        <td>{moment(one.created_at).format('YYYY/MM/DD')}</td>
                        <td>
                          <div className='controls'>
                            <Tooltip title='Cập nhật thông tin'>
                              <div className='icon'>
                                <AiOutlineEdit
                                  size={18}
                                  onClick={() => {
                                    handleClickEdit(one.id || '');
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip title='Xóa'>
                              <div className='icon'>
                                <FiTrash2
                                  size={18}
                                  onClick={() => {
                                    handleClickDelete('config', one.id);
                                  }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Content>
      {showAddPanel?.visible && (
        <AddPanel
          onShow={handleShowAddPanel}
          type={showAddPanel?.type}
          fetchListView={fetchListView}
          fetchListStationConfig={fetchListStationConfig}
        />
      )}
    </SettingContainer>
  );
};

const SettingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-family: 'Roboto', sans-serif;
  position: relative;
  flex-direction: column;

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }

  .icon {
    cursor: pointer;
    transition: 0.3;
    color: #8798ad;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #666fe8;
    }
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const Header = styled.div`
  margin-bottom: 24px;

  & > .controls {
    min-width: 100px;
    display: flex;
    justify-content: space-between;

    .right {
      display: flex;
      flex-direction: row;

      & > * {
        margin: 0 8px;

        .icon {
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          margin-right: 5px;
        }
      }
    }
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  & > .list-server-config-wrapper {
    width: 100%;
    height: 45%;

    & > .list-server-config {
      width: 100%;
      height: 100%;
      padding: 8px;
      display: flex;
      flex-direction: column;

      & > .title {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
      }

      & > .content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-shadow: 0px 0 5px rgba(46, 91, 255, 0.2);
        // box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
        border: 1px solid rgba(102, 111, 232, 0.08);
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: space-between;

        & > .col {
          width: 100%;
          height: 100%;
          padding: 0 8px;
        }
      }
    }
  }

  .list-setting-wrapper {
    width: 100%;
    height: 55%;
    display: flex;

    .list-view-wrapper {
      width: 55%;
      height: 100%;
      overflow: hidden;
      display: flex;
      padding: 8px;
      flex-direction: column;

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .table {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: overlay;
        box-shadow: 0px 0 5px rgba(46, 91, 255, 0.2);
        // box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
        border: 1px solid rgba(102, 111, 232, 0.08);
        border-radius: 3px;
      }
    }

    .list-config-wrapper {
      width: 45%;
      height: 100%;
      padding: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .table {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: visible;
        box-shadow: 0px 0 5px rgba(46, 91, 255, 0.2);
        // box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
        border: 1px solid rgba(102, 111, 232, 0.08);
        border-radius: 3px;
      }
    }
  }
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  margin: 8px 0;

  thead {
    tr {
      th {
        position: sticky;
        top: 0px;
        padding: 8px;
        background: white;
      }
    }
  }

  tbody {
    vertical-align: top;
  }

  tr {
    vertical-align: middle;

    & > th {
      padding: 8px;
      text-align: center;
      min-width: 90px;
      color: #8798ad;
    }

    & > td {
      padding: 8px;
      text-align: center;
      font-size: 13px;
      min-width: 90px;
      height: 50px;
      width: 200px;

      .controls {
        display: flex;
        align-items: center;
        min-width: 120px;
        justify-content: center;

        & > .icon {
          margin: 0 8px;
        }
      }
    }
  }
`;

const EditInput = styled.input`
  width: 100%;
  border-radius: 5px;
  padding: 3px 5px;
  outline: none;
  border: none;
  border: 1px solid gray;
  text-align: center;

  &:focus {
    border: 1px solid #666fe8;
  }

  &:focus-visible {
    border: 1px solid #666fe8;
  }
`;

export default Setting;
