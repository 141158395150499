import { I18nSetLanguageTransform } from '~/locales';
import { tuple } from '~/utils';

const I18nSupportLanguages = tuple('en', 'vi');
export type I18nSupportLanguage = typeof I18nSupportLanguages[number];

export type I18nMessages<T extends string> = {
  [key in T]: {
    translation: I18nSetLanguageTransform;
  };
};

export { default as I18Next } from '~/locales/i18n';
export * from '~/locales/languageTypes';
