import {
  StationConfigAction,
  StationConfigActionType,
  StationConfigState,
} from '~/store/ducks/stationConfig/types';

const initialState: StationConfigState = {
  error: undefined,
  stationListConfig: [],
  stationListConfigMapping: [],
};

export default function stationConfigReducer(
  state = initialState,
  action: StationConfigAction,
): StationConfigState {
  switch (action.type) {
    case StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_SUCCESS: {
      const listStationConfig = action.payload.listStationConfig.map((item) => {
        item.value = '';
        return item;
      });
      return {
        ...state,
        stationListConfig: listStationConfig,
      };
    }

    case StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationConfigActionType.GET_STATION_CONFIG_MAPPING_SUCCESS: {
      return {
        ...state,
        stationListConfigMapping: action.payload.listStationConfigMapping,
      };
    }

    case StationConfigActionType.GET_STATION_CONFIG_MAPPING_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
