import Axios from 'axios';
import { env } from '~/configs';
import { axiosTimeout } from '~/constants';
import { store } from '~/store/ducks/rootStore';
import { getAuthInfo } from '~/store/selectors';
import { EventEmitter } from '../eventEmitter';
import { Events } from '~/constants/events';

const WEB_API_BASE = String(env.WEB_API_BASE);

if (!WEB_API_BASE) throw new Error('Please set env `WEB_API_BASE`');

export const webAxiosCS = Axios.CancelToken.source();

const webAxios = Axios.create({
  baseURL: WEB_API_BASE,
  cancelToken: webAxiosCS.token,
  timeout: axiosTimeout,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    // "Access-Control-Expose-Headers": "Content-Disposition"
  },
});

webAxios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      const authInfo = getAuthInfo(store.getState().auth);

      if (authInfo?.access_token) {
        config.headers['Authorization'] = `Bearer ${authInfo?.access_token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

webAxios.interceptors.response.use(
  (response) => {
    if (response?.data?.error?.message?.includes('invalid token')) {
      EventEmitter.dispatch(Events.TOKEN_EXPIRED, response.data);
    }

    return response;
  },
  (error) => {
    throw error;
  },
);

export default webAxios;
