import * as React from 'react';
import { FaShareSquare } from 'react-icons/fa';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AmChartStation from '~/components/atoms/AmChartStation';
import ColumnChartStation from '~/components/atoms/AmChartStation/ColumnChart';
import TextButton from '~/components/atoms/Button';
import Empty from '~/components/atoms/Empty';
import DateInput from '~/components/atoms/Input/DateInput';
import SelectInput, { IFormOption } from '~/components/atoms/Input/SelectInput';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import { moment } from '~/plugins';
import { getDataOfSensor } from '~/store/ducks/station/actions';
import { useStationStore, useToastStore } from '~/store/hooks';
import { IDownloadSensorReportData, ISampleInfo } from '~/types';

type ChartInfoProps = {
  children?: React.ReactNode | React.ReactNode[];
  stationId: string;
  timeStart: any;
  timeEnd: any;
  onTimeStartChange: (time: any) => void;
  onTimeEndChange: (time: any) => void;
  listSensorAvailables: any;
};

const ChartInfo = ({
  listSensorAvailables,
  stationId,
  timeStart,
  timeEnd,
  onTimeStartChange,
  onTimeEndChange,
}: ChartInfoProps): JSX.Element => {
  const keyAmountOfRain = 'rafa';

  const [sensor, setSensor] = React.useState<IFormOption | null>(null);
  const [sensorInfo, setSensorInfo] = React.useState<ISampleInfo>();
  const [sensorData, setSensorData] = React.useState([]);
  const [type, setType] = React.useState(0);
  const [loadingExportCSV, setLoadingExportCSV] = React.useState<boolean>(false);

  const { dispatchCreateToast } = useToastStore();
  const { dispatchDownloadSensorChartReportData } = useStationStore();

  const stationChartOptions = React.useMemo(() => {
    const data: IFormOption[] = [];
    listSensorAvailables.forEach((item: any) => {
      data.push({ label: item?.name, value: item?.key });
    }) as unknown as IFormOption[];
    return data;
  }, [listSensorAvailables]);

  React.useMemo(() => {
    if (stationChartOptions.length > 0) {
      setSensor(stationChartOptions[0]);
    }
  }, [stationChartOptions]);

  const renderEmpty = React.useMemo(() => {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }, []);

  const startIconComponent = React.useMemo(() => {
    if (loadingExportCSV) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
    return <FaShareSquare />;
  }, [loadingExportCSV]);

  const renderAmChart = React.useMemo(() => {
    if (!sensorData || !sensorInfo) return renderEmpty;
    const lineSeriesData = sensorData
      .map((item: any) => {
        return {
          date: new Date(item.SamplingTime).getTime(),
          value: item.SensorData,
        };
      })
      .reverse();

    if (sensorInfo?.key === keyAmountOfRain) {
      return <ColumnChartStation type={type} data={lineSeriesData} chartInfo={sensorInfo} />;
    }
    return <AmChartStation type={type} data={lineSeriesData} chartInfo={sensorInfo} />;
  }, [renderEmpty, sensorData, sensorInfo, type]);

  const handleStartDateChange = React.useCallback(
    (date: Date | null) => {
      onTimeStartChange(date);
    },
    [onTimeStartChange],
  );

  const handleEndDateChange = React.useCallback(
    (date: Date | null) => {
      onTimeEndChange(date);
    },
    [onTimeEndChange],
  );

  const onChangeSelect = React.useCallback((option: IFormOption) => {
    setSensor(option);
  }, []);

  const handleUpdateData = React.useCallback(() => {
    if (moment(timeEnd)?.diff(moment(timeStart)) < 0) {
      return dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: 'Ngày kết thúc phải lớn hơn hoặc bằng Ngày bắt đầu',
      });
    }
    if (sensorInfo && stationId) {
      const params = {
        stationId,
        sensorId: sensorInfo.id || '',
        timeStart: timeStart,
        timeEnd: timeEnd,
      };
      getDataOfSensor(params).then((data: any) => {
        if (data.Data) {
          setType(data.Type || 0);
          setSensorData(data.Data);
        }
      });
    }
  }, [dispatchCreateToast, sensorInfo, stationId, timeEnd, timeStart]);

  const downloadSensorChartReportData = React.useCallback(
    (params: IDownloadSensorReportData) => {
      dispatchDownloadSensorChartReportData(params);
    },
    [dispatchDownloadSensorChartReportData],
  );

  const handleExportCSV = React.useCallback(() => {
    (async () => {
      if (stationId && sensorInfo) {
        try {
          setLoadingExportCSV(true);
          await downloadSensorChartReportData({
            stationId,
            sensorId: sensorInfo.id,
            time_start: timeStart,
            time_end: timeEnd,
          });
        } finally {
          setLoadingExportCSV(false);
        }
      }
    })();
  }, [sensorInfo, stationId, timeEnd, timeStart, downloadSensorChartReportData]);

  React.useEffect(() => {
    const sensorInfoTmp = listSensorAvailables.find((one: any) => one.key === sensor?.value);
    setSensorInfo(sensorInfoTmp);
  }, [sensor, listSensorAvailables]);

  React.useEffect(() => {
    handleUpdateData();
  }, [dispatchCreateToast, sensorInfo, stationId, handleUpdateData, timeEnd, timeStart]);

  return (
    <ChartInfoWrapper>
      <div className='controller1'>
        <div className='input-start-time'>
          <DateInput
            showTimeSelect={true}
            value={timeStart}
            width='180px'
            handleChange={handleStartDateChange}
            placeholder='Ngày bắt đầu'
            maxDate={moment().format()}
          />
        </div>
        <div className='input-end-time'>
          <DateInput
            showTimeSelect={true}
            value={timeEnd}
            width='180px'
            handleChange={handleEndDateChange}
            placeholder='Ngày kết thúc'
            maxDate={moment().format()}
          />
        </div>
        <div className='select-sensor'>
          <SelectInput
            placeholder='Chọn Sensor'
            placeholderFontSize='16px'
            selected={sensor}
            width='200px'
            options={stationChartOptions}
            handleChange={onChangeSelect}
          />
        </div>
        <div className='report-data'>
          <TextButton
            color='primary'
            variant='contained'
            onClick={handleExportCSV}
            startIcon={startIconComponent}
            disabled={loadingExportCSV}
          >
            Xuất dữ liệu
          </TextButton>
        </div>
      </div>
      <ChartInfoContainer>{renderAmChart}</ChartInfoContainer>
    </ChartInfoWrapper>
  );
};

const ChartInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .controller1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > div {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 4px;
    }

    .load-data,
    .report-data {
      white-space: nowrap;

      & * {
        font-size: 14px;
      }
    }
  }
`;

const ChartInfoContainer = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  /* min-height: 280px;
  max-height: 500px; */
  position: relative;
`;

export default ChartInfo;
