const getInputType = (name?: string) => {
  let type = 'text';
  switch (name) {
    case 'phone': {
      type = 'number';
      break;
    }
    case 'status': {
      type = 'number';
      break;
    }
    case 'password': {
      type = 'password';
      break;
    }
    case 'currentPassword': {
      type = 'password';
      break;
    }
    case 'newPassword': {
      type = 'password';
      break;
    }
    case 'email': {
      type = 'email';
      break;
    }
    case 'max_user': {
      type = 'number';
      break;
    }
    case 'code': {
      type = 'number';
      break;
    }
    case 'priority': {
      type = 'number';
      break;
    }
    default: {
      type = 'text';
    }
  }

  return type;
};

export { getInputType };
