import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServerConfig } from '~/store/ducks/serverConfig/actions';
import { RootState } from '~/store/types';

interface FuncType {
  serverConfigState: RootState['serverConfig'];
  dispatchGetServerConfig: () => Promise<void>;
}

const useServerConfigStore = (): FuncType => {
  const dispatch = useDispatch();

  const serverConfigState = useSelector<RootState, RootState['serverConfig']>((state) => {
    return state.serverConfig;
  });

  const dispatchGetServerConfig = React.useCallback(async () => {
    await dispatch(getServerConfig());
  }, [dispatch]);

  return {
    serverConfigState,
    dispatchGetServerConfig,
  };
};

export default useServerConfigStore;
