import {
  ServerConfigAction,
  ServerConfigActionType,
  ServerConfigState,
} from '~/store/ducks/serverConfig/types';

const initialState: ServerConfigState = {
  serverConfig: {
    id: undefined,
    email: undefined,
    email_pw: undefined,
    phone_number: undefined,
    address: undefined,
    max_user: undefined,
    create_at: undefined,
    updated_at: undefined,
  },
};

export default function stationConfigReducer(
  state = initialState,
  action: ServerConfigAction,
): ServerConfigState {
  switch (action.type) {
    case ServerConfigActionType.GET_SERVER_CONFIG_SUCCESS: {
      return {
        ...state,
        serverConfig: action.payload.serverConfig,
      };
    }

    // case ServerConfigActionType.GET_SERVER_CONFIG_FAIL: {
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //   };
    // }

    default: {
      return state;
    }
  }
}
