import { ThunkAction } from 'redux-thunk';
import { axios2 as axios } from '~/plugins';
import { RootAction, RootState } from '~/store/types';
import { IListRoleResponse, IRoleResponse, IListRoleRequest } from '~/types';
import { RoleActionType, RoleInfo } from './types';
import { v4 as uuidv4 } from 'uuid';
import { createToast } from '~/store/ducks/toast/actions';

export const getDetailRoleRequest = async (roleId: string) => {
  const res = await axios.get<IRoleResponse>(`/roles/${roleId}`);

  return res.data;
};

export const getListRolesRequest =
  (params?: IListRoleRequest): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({
        type: RoleActionType.GET_LIST_ROLE_REQUEST,
      });

      const res = await axios.get<IListRoleResponse>(`/roles`, { params });

      if (res.data?.pagination?.total && res.data?.pagination?.pageSize) {
        const totalPage = Math.ceil(res.data?.pagination?.total / res.data?.pagination?.pageSize);
        res.data.pagination.total = totalPage;
      }

      dispatch({
        type: RoleActionType.GET_LIST_ROLE_SUCCESS,
        payload: res.data,
      });
    } catch (exception) {
      dispatch({
        type: RoleActionType.GET_LIST_ROLE_FAIL,
        payload: {
          error: true,
          message: 'Lấy vai trò quyền thất bại',
        },
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Lấy vai trò quyền thất bại',
        }),
      );
    }
  };

export const createRoleRequest = async (roleInfo: RoleInfo) => {
  const res = await axios.post<IRoleResponse>(`/roles`, roleInfo);

  return res.data;
};

export const updateRoleRequest = async (roleInfo: RoleInfo) => {
  const res = await axios.put<IRoleResponse>(`/roles/${roleInfo?.id || ''}`, roleInfo);

  return res.data;
};

export const deleteRoleRequest = async (roleId: string) => {
  const res = await axios.delete<IRoleResponse>(`/roles/${roleId}`);

  return res.data;
};
