import { ThunkAction } from 'redux-thunk';
import { axios2 as axios } from '~/plugins';
import { UserActionType } from './types';
import { RootAction, RootState } from '~/store/types';
import {
  IListUserRequest,
  IListUserResponse,
  IUserInfo,
  IListViewOfStationRequest,
  IListViewOfStationResponse,
  IListConfigOfStationRequest,
  IListConfigOfStationResponse,
  IAddStationForUserRequest,
  IAddViewForStationRequest,
  IAddConfigForStationRequest,
} from '~/types/';
import { v4 as uuidv4 } from 'uuid';
import { createToast } from '~/store/ducks/toast/actions';

export const getUserById = async (userId: string) => {
  const res = await axios.get(`/users/${userId}`);

  return res.data;
};

export const getListUserRequest =
  (params: IListUserRequest): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: UserActionType.GET_LIST_USER_REQUEST });

      const res = await axios.get<IListUserResponse>('/users', { params });
      if (res.data?.pagination?.total && res.data?.pagination?.pageSize) {
        const totalPage = Math.ceil(res.data?.pagination?.total / res.data?.pagination?.pageSize);
        res.data.pagination.total = totalPage;
      } else if (res.data?.pagination) {
        res.data.pagination.total = 1;
      }

      const { list, pagination } = res.data;

      dispatch({
        type: UserActionType.GET_LIST_USER_SUCCESS,
        payload: {
          list,
          pagination,
        },
      });
    } catch (exception) {
      dispatch({
        type: UserActionType.GET_LIST_USER_FAIL,
        payload: {
          error: true,
          message: 'Lấy danh sách người dùng thất bại',
        },
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Lấy danh sách người dùng thất bại',
        }),
      );
    }
  };

export const addViewForStation = async (params: IAddViewForStationRequest) => {
  const res = await axios.post(`/user_station_permission`, params);

  return res.data;
};

export const addConfigForStation = async (params: IAddConfigForStationRequest) => {
  const res = await axios.post(`/user_station_permission`, params);

  return res.data;
};

export const addStationForUser = async (params: IAddStationForUserRequest) => {
  const res = await axios.post(`/user_station_relation`, {
    user_id: params?.user_id,
    station_id: params?.station_id,
  });

  return res.data;
};

export const deleteStationForUser = async (stationId: string) => {
  const res = await axios.delete(`/user_station_relation?id=${stationId}`);

  return res.data;
};

export const getListViewOfStation = async (params: IListViewOfStationRequest) => {
  const res = await axios.get<IListViewOfStationResponse>(
    `/user_station_permission/views?user_id=${params.user_id}&station_id=${params.station_id}`,
  );

  return res.data;
};

export const getListConfigOfStation = async (params: IListConfigOfStationRequest) => {
  const res = await axios.get<IListConfigOfStationResponse>(
    `/user_station_permission/configs?user_id=${params.user_id}&station_id=${params.station_id}`,
  );

  return res.data;
};

export const createUserRequest = async (userInfo: IUserInfo) => {
  const res = await axios.post<IUserInfo>(`/users`, userInfo);

  return res.data;
};

export const updateUserRequest = async (userInfo: IUserInfo) => {
  const res = await axios.put<IUserInfo>(`/users/${userInfo?.id}`, userInfo);

  return res.data;
};

export const deleteUserRequest = async (userId: string) => {
  const res = await axios.delete<IUserInfo>(`/users/${userId}`);

  return res.data;
};

export const deleteStationPermission = async (ids: string) => {
  const res = await axios.delete(`/user_station_permission?ids=${ids}`);

  return res.data;
};
