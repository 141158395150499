import * as ReactTable from '@tanstack/react-table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { BiUserPlus } from 'react-icons/bi';
import { FaShareSquare } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import TextButton from '~/components/atoms/Button';
import TextInput from '~/components/atoms/Input/TextInput';
import DataTable from '~/components/molecules/DataTable';
import CustomSelectTimeModal from '~/components/organism/Modal/CustomSelectTimeModal';
import CustomSelectTimeRangeModal from '~/components/organism/Modal/CustomSelectTimeRangeModal';
import StationInformationPanel from '~/components/organism/StationInformationPanel';

import { useSearchParams } from 'react-router-dom';
import { TableConfigs } from '~/constants';
import { useLoading } from '~/hooks';
import useStationTable from '~/pages/Station/StationList/constants';
import { moment as dayjs } from '~/plugins';
import { useStationConfigStore, useStationStore, useStationThresholdStore } from '~/store/hooks';
import { IStationParamsRequestList } from '~/types';
import {
  isAdmin,
  isSuperAdmin,
  loadingDownLoadStationMonthlyLoadingModule,
  stationDeleteLoadingModule,
  stationListLoadingModule,
} from '~/utils';

export interface StationPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

interface RefObject {
  toggleAllRowsSelected: (value: boolean) => void;
}

const StationPage = (props: StationPropsType) => {
  const { onConfirmDelete } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoading = useLoading(stationListLoadingModule);
  const isLoadingStationDelete = useLoading(stationDeleteLoadingModule);
  const isLoadingDownLoadStationMonthly = useLoading(loadingDownLoadStationMonthlyLoadingModule);
  const isRoleSuperAdmin = isSuperAdmin();
  const isRoleAdmin = isAdmin();

  const {
    stationState,
    dispatchGetListStation,
    dispatchGetAllListStation,
    dispatchDownLoadReportMonthly,
    dispatchDownLoadReportData,
  } = useStationStore();
  const { dispatchClearListStationConfig } = useStationConfigStore();
  const { dispatchClearListStationThreshold } = useStationThresholdStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const search = searchParams.get('search');

  const {
    columns,
    stationReportId,
    isModalReportDataOpen,
    handleCloseModalReportData,
    infoStationPanel,
    handleChangeDetailPanel,
  } = useStationTable({
    onConfirmDelete,
  });
  const [searchName, setSearchName] = React.useState<string>('');
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [isModalReportMonthlyOpen, setIsModalReportMonthlyOpen] = React.useState<boolean>(false);
  const [disableExport, setDisableExport] = React.useState<boolean>(true);
  const rowSelected = React.useRef<ReactTable.Row<any>[]>([]);
  const dataTableRef = React.useRef<RefObject>(null);

  React.useMemo(() => {
    if (search) setSearchName(search);
    else setSearchName('');
  }, [search]);

  React.useMemo(() => {
    if (page) setCurrentPage(Number(page));
    else setCurrentPage(1);
  }, [page]);

  const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
    setCurrentPage(1);
  }, []);

  const handleOnKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        searchParams.set('page', currentPage.toString());
        searchParams.set('search', searchName);
        setSearchParams(searchParams);
      }
    },
    [currentPage, searchName, searchParams, setSearchParams],
  );

  const handleOnClickSearch = React.useCallback(() => {
    searchParams.set('page', currentPage.toString());
    searchParams.set('search', searchName);
    setSearchParams(searchParams);
  }, [currentPage, searchName, searchParams, setSearchParams]);

  const handleExportMultipleStation = React.useCallback(() => {
    setIsModalReportMonthlyOpen(true);
  }, []);

  const handleCreateStation = React.useCallback(() => {
    navigate('/stations/create');
  }, [navigate]);

  const renderHeaderTable = React.useCallback((): JSX.Element => {
    return (
      <StationHeader>
        <TextInput
          width='300px'
          positionIcon='endAdornment'
          value={searchName}
          handleChange={handleInputChange}
          handleOnKeyDown={handleOnKeyDown}
        >
          <FiSearch className='custom-icon-search' onClick={handleOnClickSearch} />
        </TextInput>

        <ActionButtonContainer>
          {(isRoleSuperAdmin || isRoleAdmin) && (
            <TextButton
              color='inherit'
              variant='contained'
              startIcon={<FaShareSquare />}
              disabled={disableExport}
              onClick={handleExportMultipleStation}
            >
              Báo cáo tổng quan
            </TextButton>
          )}

          {isRoleSuperAdmin && (
            <TextButton
              color='primary'
              variant='contained'
              startIcon={<BiUserPlus />}
              onClick={handleCreateStation}
            >
              {t('station.action.add_station')}
            </TextButton>
          )}
        </ActionButtonContainer>
      </StationHeader>
    );
  }, [
    searchName,
    disableExport,
    isRoleSuperAdmin,
    isRoleAdmin,
    t,
    handleInputChange,
    handleOnKeyDown,
    handleOnClickSearch,
    handleCreateStation,
    handleExportMultipleStation,
  ]);

  const fetchStationItemsList = React.useCallback(
    async (params: IStationParamsRequestList) => {
      await dispatchGetListStation(params);
    },
    [dispatchGetListStation],
  );

  const fetchAllStationItemsList = React.useCallback(async () => {
    await dispatchGetAllListStation();
  }, [dispatchGetAllListStation]);

  const handleChangePage = React.useCallback(
    (page: number) => {
      setCurrentPage(page);
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const getSelectedRow = React.useCallback((rows: ReactTable.Row<any>[]) => {
    setDisableExport(!rows.length);
    rowSelected.current = rows;
  }, []);

  const handleCloseModalReportMonthly = React.useCallback(() => {
    setIsModalReportMonthlyOpen(false);
  }, []);

  const handleResetTable = React.useCallback(() => {
    if (dataTableRef.current) {
      dataTableRef.current.toggleAllRowsSelected(false);
      setDisableExport(true);
      handleCloseModalReportMonthly();
    }
  }, [handleCloseModalReportMonthly]);

  const handleDownLoadReportMonthly = React.useCallback(
    (date: string) => {
      dispatchDownLoadReportMonthly(
        {
          stationIds: rowSelected.current.map((item) => item.id).join(','),
          dateTime: dayjs(date).format(),
        },
        handleResetTable,
      );
    },
    [dispatchDownLoadReportMonthly, handleResetTable],
  );

  const handleDowLoadReportData = React.useCallback(
    (startMonthDate: string, endMonthDate: string, handleCloseModal: () => void) => {
      dispatchDownLoadReportData(
        {
          stationId: stationReportId,
          timeStart: dayjs(startMonthDate).format(),
          timeEnd: dayjs(endMonthDate).endOf('month').format(),
        },
        handleCloseModal,
      );
    },
    [dispatchDownLoadReportData, stationReportId],
  );

  React.useEffect(() => {
    fetchAllStationItemsList();
  }, [fetchAllStationItemsList]);

  React.useEffect(() => {
    dispatchClearListStationConfig();
    dispatchClearListStationThreshold();
    fetchStationItemsList({
      text: search ?? '',
      current: page ? parseInt(page) : 1,
      pageSize: TableConfigs.defaultPageSize,
    });
  }, [
    isLoadingStationDelete,
    page,
    search,
    dispatchClearListStationConfig,
    dispatchClearListStationThreshold,
    fetchStationItemsList,
  ]);

  return (
    <React.Fragment>
      <StationContainer>
        <DataTable
          ref={dataTableRef}
          isFetching={isLoading}
          data={stationState.listStation.list}
          columns={columns}
          totalPage={stationState.listStation.pagination.totalPage || 1}
          currentPage={currentPage}
          headerComponent={renderHeaderTable()}
          handleChangePage={handleChangePage}
          getSelectedRow={getSelectedRow}
        />
      </StationContainer>
      {isModalReportMonthlyOpen && (
        <CustomSelectTimeModal
          isLoading={isLoadingDownLoadStationMonthly}
          opened={isModalReportMonthlyOpen}
          handleClose={handleCloseModalReportMonthly}
          handleDownLoadReport={handleDownLoadReportMonthly}
        />
      )}
      {isModalReportDataOpen && (
        <CustomSelectTimeRangeModal
          opened={isModalReportDataOpen}
          handleClose={handleCloseModalReportData}
          handleDownLoadReport={handleDowLoadReportData}
        />
      )}
      {infoStationPanel.status && infoStationPanel.stationId && (
        <StationInformationPanel
          stationId={infoStationPanel.stationId}
          onClose={() => handleChangeDetailPanel(false, '')}
        />
      )}
    </React.Fragment>
  );
};

const StationContainer = styled.div`
  height: calc(100% - 460px);
  background: #fff;
`;

const StationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const ActionButtonContainer = styled.div`
  display: flex;

  .MuiButton-root ~ .MuiButton-root {
    margin-left: 16px;
  }
`;

export default React.memo(StationPage);
