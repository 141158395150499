import { ThunkAction } from 'redux-thunk';
import { axios } from '~/plugins';
import { StationThresholdActionType } from '~/store/ducks/stationThreshold/types';
import { RootAction, RootState } from '~/store/types';
import { IListSampleInfoResponse, IStationThreshold, IStationThresholdResponse } from '~/types/';

export const getListStationThresholdMapping =
  (stationId: string): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING });
      const response = await axios.get<IListSampleInfoResponse>('/sample_info', {
        params: { station_id: stationId },
      });
      const responseThreshold = await axios.get<IStationThresholdResponse>('/station_threshold', {
        params: { station_id: stationId },
      });
      const stationSampleInfo = response.data.Data;
      const stationThreshold = responseThreshold.data.Data;
      const StationThresholdMapping: IStationThreshold[] = stationSampleInfo.map((item) => {
        const findItem = stationThreshold.find((data) => data.sample_id === item.id);
        if (findItem)
          return {
            ...findItem,
            key: item.key,
            name: item.name,
            unit: item.unit,
          } as IStationThreshold;
        return {
          ...item,
          min: '',
          max: '',
          reason: '',
          isCreate: true,
        } as IStationThreshold;
      });
      dispatch({
        type: StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_SUCCESS,
        payload: { listStationThresholdMapping: StationThresholdMapping },
      });
    } catch (error) {
      dispatch({
        type: StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_FAIL,
        payload: { error: error as Error },
      });
    }
  };

export const clearListStationThreshold =
  (): ThunkAction<void, RootState, undefined, RootAction> => (dispatch) => {
    dispatch({
      type: StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_SUCCESS,
      payload: { listStationThresholdMapping: [] },
    });
  };
