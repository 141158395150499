import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TiTimes } from 'react-icons/ti';
import styled from 'styled-components';
import TextButton from '~/components/atoms/Button';
import ExtendValidate from './ExtendValidate';
import Input from './Input';

import { validatePassword } from '~/assets/validate';

export interface ChangePasswordPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onShow?: any;
  initData?: any;
  onUpdate?: any;
}

const ChangePassword = (props: ChangePasswordPropsType) => {
  const { onShow, initData, onUpdate } = props;
  const [password, setPassword] = useState<string>('');
  const [passwordValidState, setPasswordValidState] = useState<boolean>(true);

  const changePasswordPanelRef = useRef<any>(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (initData?.password) {
      setPassword(initData.password);
    }
  }, [initData?.password]);

  useEffect(() => {
    if (changePasswordPanelRef?.current && contentRef?.current) {
      changePasswordPanelRef.current.onclick = (e: any) => {
        if (e.target == changePasswordPanelRef.current) onShow(false);
      };
    }
  }, [onShow]);

  const _handleChangeInput = (e: any) => {
    e.preventDefault();
    const name = e?.target?.name || '';
    const value = e?.target?.value || '';

    switch (name) {
      case 'password': {
        setPassword(value);

        if (!passwordValidState) {
          setPasswordValidState(true);
        }

        break;
      }
      default: {
        setPassword(value);

        if (!passwordValidState) {
          setPasswordValidState(true);
        }

        break;
      }
    }
  };

  const handleClose = useCallback(() => {
    if (onShow) {
      onShow(false);
    }
  }, [onShow]);

  const inputList1 = [
    {
      name: 'password',
      title: 'New Password',
      require: true,
      value: password,
      validate: passwordValidState,
      onchange: _handleChangeInput,
      extendValidate: <ExtendValidate type='password' value={password} />,
    },
  ];

  const _handleClickUpdateUserBtn = useCallback(() => {
    const validatePasswordStatus = validatePassword(password);
    setPasswordValidState(validatePasswordStatus);

    if (validatePasswordStatus) {
      onUpdate({ password });
    }
  }, [onUpdate, password]);

  return (
    <AddPanelElement ref={changePasswordPanelRef}>
      <ContentElement>
        <div className='header'>
          <div className='left'>
            <div className='title'>Thay đổi mật khẩu</div>
          </div>
          <div className='right'>
            <div className='close-btn' onClick={handleClose}>
              <TiTimes size='26px' />
            </div>
          </div>
        </div>
        <div className='body'>
          <div className='col'>
            {inputList1.map((one, index) => (
              <Input
                title={one.title}
                key={index}
                name={one.name}
                require={one.require}
                value={one.value}
                onChange={one.onchange}
                validate={one.validate}
                extendValidate={one.extendValidate}
              />
            ))}
          </div>
        </div>
        <div className='footer'>
          <TextButton color='primary' variant='contained' onClick={_handleClickUpdateUserBtn}>
            Lưu mật khẩu mới
          </TextButton>
          <TextButton
            color='inherit'
            variant='contained'
            onClick={() => {
              handleClose();
            }}
          >
            Huỷ bỏ
          </TextButton>
        </div>
      </ContentElement>
    </AddPanelElement>
  );
};

const AddPanelElement = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: changeTransition 0.5s ease alternate forwards;

  @keyframes flyUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes changeTransition {
    0% {
      background: transparent;
    }
    100% {
      background: rgba(0, 0, 0, 0.6);
    }
  }
`;

const ContentElement = styled.div`
  background: white;
  color: rgb(49, 49, 49);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
  height: 450px;
  animation: flyUp 0.5s ease alternate forwards;

  .header {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 17px;
      font-weight: 600;
      margin-left: 8px;
      color: #313131;
    }

    .close-btn {
      padding: 0 5px;
      cursor: pointer;
    }
  }

  & > .body {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    & > .col {
      width: 100%;
      height: 100%;

      & > .title {
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
      }

      & > .content {
        padding: 8px;
        width: 100%;
        height: 100%;

        & > .table {
          width: 100%;
          height: 100%;
          background: #fafafa;
          border-radius: 5px;
          padding: 0 10px;
          overflow-x: hidden;
          overflow-y: overlay;

          & > .one-row {
            display: flex;
            color: #8d9aa9;
            justify-content: space-between;
            padding: 10px;
            font-size: 13px;
            align-items: center;
            border-bottom: 0.2px solid rgba(173, 173, 173, 0.5);
          }
        }
      }
    }
  }

  & > .footer {
    padding: 10px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > * {
      font-size: 14px;
    }

    & > *:first-child {
      margin-right: 10px;
    }
  }
`;

export default ChangePassword;
