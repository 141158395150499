import * as React from 'react';
import styled from 'styled-components';
import StationDataConfig from '~/pages/Station/StationUpdate/StationDataConfig';
import StationDataThreshold from '~/pages/Station/StationUpdate/StationDataThreshold';
import StationInfo from '~/pages/Station/StationUpdate/StationInfo';

const StationUpdate = () => {
  return (
    <StationContainer>
      <StationInfo />
      <StationSettings>
        <StationDataConfig />
        <StationDataThreshold />
      </StationSettings>
    </StationContainer>
  );
};

const StationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StationSettings = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 400px);
  height: 100%;
  overflow: hidden;
`;

export default React.memo(StationUpdate);
