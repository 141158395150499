import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { FiPlus } from 'react-icons/fi';

import TextButton from '~/components/atoms/Button';
import DataTable from '~/components/molecules/DataTable';
import CustomItemDeviceModal from '~/components/organism/Modal/CustomItemDeviceModal';
import useDeviceTable from '~/pages/Station/StationDevice/constants';
import { useLoading } from '~/hooks';
import { useStationStore } from '~/store/hooks';
import { IStationDeviceParamsRequestList } from '~/types';
import { stationDeviceDeleteLoadingModule, stationDeviceListLoadingModule } from '~/utils';
import { useSearchParams } from 'react-router-dom';
import { TableConfigs } from '~/constants';

export interface DevicePropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const Device = (props: DevicePropsType) => {
  const { onConfirmDelete } = props;
  const { t } = useTranslation();
  const { stationId } = useParams();
  const isLoading = useLoading(stationDeviceListLoadingModule);
  const isLoadingStationDeviceDelete = useLoading(stationDeviceDeleteLoadingModule);
  const {
    columns,
    isModalDeviceOpen,
    itemEdit,
    handleUpdateStationDevice,
    handleCloseModalUpdate,
  } = useDeviceTable({ onConfirmDelete });

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const { stationState, dispatchGetListStationDevice } = useStationStore();
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useMemo(() => {
    if (page) setCurrentPage(Number(page));
    else setCurrentPage(1);
  }, [page]);

  const handleChangePage = React.useCallback(
    (page: number) => {
      setCurrentPage(page);
      searchParams.set('page', page.toString());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleAddDevice = React.useCallback(() => {
    handleUpdateStationDevice(null);
  }, [handleUpdateStationDevice]);

  const fetchStationDeviceList = React.useCallback(
    async (params: IStationDeviceParamsRequestList) => {
      await dispatchGetListStationDevice(params);
    },
    [dispatchGetListStationDevice],
  );

  const fetchStationDeviceListCallBack = React.useCallback(() => {
    if (stationId) {
      fetchStationDeviceList({
        station_id: stationId,
        current: currentPage,
        pageSize: TableConfigs.defaultPageSize,
      });
    }
  }, [currentPage, stationId, fetchStationDeviceList]);

  React.useEffect(() => {
    if (stationId) {
      fetchStationDeviceList({
        station_id: stationId,
        current: currentPage,
        pageSize: TableConfigs.defaultPageSize,
      });
    }
  }, [currentPage, stationId, isLoadingStationDeviceDelete, fetchStationDeviceList]);

  return (
    <React.Fragment>
      <DeviceContainer>
        <Title>{t('sidebar.device')}</Title>
        <DeviceHeader>
          <ActionButtonContainer>
            <TextButton
              color='primary'
              variant='contained'
              startIcon={<FiPlus />}
              onClick={handleAddDevice}
            >
              {t('device.action.add_device')}
            </TextButton>
          </ActionButtonContainer>
        </DeviceHeader>
        <DataTable
          isFetching={isLoading}
          data={stationState.listDevice.list}
          columns={columns}
          totalPage={stationState.listDevice.pagination.totalPage || 1}
          currentPage={currentPage}
          handleChangePage={handleChangePage}
        />
      </DeviceContainer>
      {isModalDeviceOpen && (
        <CustomItemDeviceModal
          opened={isModalDeviceOpen}
          itemEdit={itemEdit}
          fetchStationDeviceListCallBack={fetchStationDeviceListCallBack}
          handleClose={handleCloseModalUpdate}
        />
      )}
    </React.Fragment>
  );
};

const DeviceContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;

const Title = styled.div`
  color: #455560;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const DeviceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .MuiButton-root ~ .MuiButton-root {
    margin-left: 16px;
  }
`;

export default Device;
