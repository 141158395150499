import { RoleState, RoleAction, RoleActionType } from '~/store/ducks/role/types';

const initState: RoleState = {
  listRole: {
    list: [],
    pagination: {
      total: 0,
      current: 0,
      pageSize: 0,
    },
  },
};

export default function RoleReducer(state = initState, action: RoleAction): RoleState {
  switch (action.type) {
    case RoleActionType.GET_LIST_ROLE_SUCCESS: {
      return {
        ...state,
        listRole: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
