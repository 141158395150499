import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import TextButton from '~/components/atoms/Button';
import DateInput from '~/components/atoms/Input/DateInput';
import SelectInput, { IFormOption } from '~/components/atoms/Input/SelectInput';
import TextInput from '~/components/atoms/Input/TextInput';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import ModalContainer from '~/components/molecules/Container/ModalContainer';
import { BrandColors } from '~/constants';
import { useLoading } from '~/hooks';
import { moment as dayjs } from '~/plugins';
import { useStationStore, useToastStore } from '~/store/hooks';
import { IStationDevice } from '~/types';
import { stationDeviceCreateLoadingModule } from '~/utils';

interface Props {
  opened: boolean;
  width?: string;
  height?: string;
  itemEdit: IStationDevice | null;
  fetchStationDeviceListCallBack: () => void;
  handleClose: () => void;
}

const CustomItemDeviceModal = ({
  opened = false,
  width = '545px',
  height = '600px',
  itemEdit,
  fetchStationDeviceListCallBack,
  handleClose,
}: Props) => {
  const { t } = useTranslation();
  const { stationId } = useParams();
  const isLoadingCreate = useLoading(stationDeviceCreateLoadingModule);
  const { dispatchCreateToast } = useToastStore();
  const { dispatchCreateStationDevice, dispatchUpdateStationDevice } = useStationStore();

  const titleModalDevice = React.useMemo(() => {
    if (itemEdit) {
      return t('device.action.update_device');
    }
    return t('device.action.add_device');
  }, [itemEdit, t]);

  const stationChartOptions = React.useMemo<IFormOption[]>(() => {
    return [
      { label: 'Active', value: true },
      { label: 'InActive', value: false },
    ];
  }, []);

  const getDefaultValueStatus = React.useMemo<IFormOption | null>(() => {
    if (itemEdit) {
      const findDefault = stationChartOptions.find((item) => {
        if (item.value === itemEdit.status) {
          const label = itemEdit.status ? 'Active' : 'InActive';
          return { label: label, value: itemEdit.status };
        }
      });
      return findDefault || null;
    }
    return { label: 'Active', value: true };
  }, [itemEdit, stationChartOptions]);

  const startIcon = React.useMemo(() => {
    if (isLoadingCreate) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
  }, [isLoadingCreate]);

  const [name, setName] = React.useState<string>('');
  const [serial, setSerial] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [startDate, setStartDate] = React.useState<string | null>(dayjs().toString());
  const [endDate, setEndDate] = React.useState<string | null>(null);
  const [defaultValue, setDefaultValue] = React.useState<IFormOption | null>(getDefaultValueStatus);
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);

  const handleNameInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setIsEdited(true);
  }, []);

  const handleSerialInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSerial(event.target.value);
      setIsEdited(true);
    },
    [],
  );

  const handleDescriptionInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
      setIsEdited(true);
    },
    [],
  );

  const handleStartDateChange = React.useCallback((date: Date | null) => {
    setStartDate(dayjs(date).toString());
    setIsEdited(true);
  }, []);

  const handleEndDateChange = React.useCallback(
    (date: Date | null) => {
      if (dayjs(date).valueOf() < dayjs(startDate).valueOf()) {
        return dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Ngày tháo phải lớn hơn hoặc bằng Ngày lắp',
        });
      }
      setEndDate(dayjs(date).toString());
      setIsEdited(true);
    },
    [dispatchCreateToast, startDate],
  );

  const onChangeSelect = React.useCallback((option: IFormOption) => {
    setDefaultValue(option);
    setIsEdited(true);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    handleClose();
    setTimeout(() => {
      setName('');
      setSerial('');
      setDescription('');
      setStartDate(dayjs().subtract(12, 'hour').toString());
      setEndDate(dayjs().toString());
      setDefaultValue(getDefaultValueStatus);
    }, 300);
  }, [getDefaultValueStatus, handleClose]);

  const handleCallbackFunction = React.useCallback(() => {
    handleCloseModal();
    fetchStationDeviceListCallBack();
  }, [fetchStationDeviceListCallBack, handleCloseModal]);

  const handleSubmit = React.useCallback(() => {
    const params = {
      id: itemEdit?.id ?? undefined,
      name: name,
      serial_number: serial,
      description: description,
      station_id: stationId ?? undefined,
      time_start: dayjs(startDate).format(),
      time_end: endDate ? dayjs(endDate).format() : null,
      status: defaultValue?.value,
    };
    if (params.id) dispatchUpdateStationDevice(params, handleCallbackFunction);
    else dispatchCreateStationDevice(params, handleCallbackFunction);
  }, [
    handleCallbackFunction,
    dispatchCreateStationDevice,
    dispatchUpdateStationDevice,
    defaultValue?.value,
    itemEdit?.id,
    description,
    endDate,
    name,
    serial,
    startDate,
    stationId,
  ]);

  React.useEffect(() => {
    if (
      isEdited &&
      name &&
      serial &&
      description &&
      name.length > 0 &&
      serial.length > 0 &&
      description.length > 0 &&
      dayjs(startDate).isValid()
    )
      setDisableSubmit(false);
  }, [description, endDate, isEdited, name, serial, startDate]);

  React.useEffect(() => {
    if (itemEdit) {
      setName(itemEdit.name);
      setSerial(itemEdit.serial_number);
      setDescription(itemEdit.description);
      setDefaultValue(getDefaultValueStatus);
      setStartDate(itemEdit.time_start || null);
      setEndDate(itemEdit.time_end || null);
      setIsEdited(false);
      setDisableSubmit(true);
    }
  }, [getDefaultValueStatus, itemEdit]);

  return (
    <ModalContainer opened={opened} width={width} height={height} handleClose={handleCloseModal}>
      <Title>{titleModalDevice}</Title>
      <Container>
        <Section>
          <RowItem>
            <Label>{t('device.table.name')}</Label>
            <TextInput autoFocus value={name} handleChange={handleNameInputChange} />
          </RowItem>

          <RowItem>
            <Label>{t('device.table.serial')}</Label>
            <TextInput value={serial} handleChange={handleSerialInputChange} />
          </RowItem>

          <RowItem>
            <Label>{t('device.table.description')}</Label>
            <TextInput value={description} handleChange={handleDescriptionInputChange} />
          </RowItem>

          <RowItem>
            <Label>{t('device.table.created_at')}</Label>
            <DateInput
              showTimeSelect={true}
              value={startDate}
              placeholder='Ngày bắt đầu'
              handleChange={handleStartDateChange}
            />
          </RowItem>

          <RowItem>
            <Label>{t('device.table.updated_at')}</Label>
            <DateInput
              showTimeSelect={true}
              value={endDate}
              placeholder='Ngày kết thúc'
              handleChange={handleEndDateChange}
            />
          </RowItem>

          <RowItem>
            <Label>{t('device.table.status')}</Label>
            <SelectInput
              placeholder={t('device.table.status')}
              placeholderFontSize='16px'
              selected={defaultValue}
              options={stationChartOptions}
              handleChange={onChangeSelect}
            />
          </RowItem>
        </Section>
        <Bottom>
          <TextButton size='small' variant='outlined' onClick={handleCloseModal}>
            {t('device.action.cancel')}
          </TextButton>
          <TextButton
            size='small'
            startIcon={startIcon}
            variant='contained'
            color='primary'
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            {titleModalDevice}
          </TextButton>
        </Bottom>
      </Container>
    </ModalContainer>
  );
};

const Title = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${BrandColors.under};
  background: #e0e0e0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-bottom: 1px solid #b1b1b2;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(600px - 35px);
`;

const Section = styled.div`
  flex: 1;
  height: calc(100% - 48px);
  padding: 24px;
  padding-right: 14px;
  overflow-y: scroll;
`;

const RowItem = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  color: #333333;
`;

const Bottom = styled.div`
  height: 48px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #b1b1b2;
`;

export default React.memo(CustomItemDeviceModal);
