import * as React from 'react';
import styled, { css } from 'styled-components';
import { rgba, darken } from 'polished';
import { GrayColors } from '~/constants';

type Props = React.PropsWithChildren<{
  fontSize: number;
  textColor?: string;
  bgColor?: string;
  clickable?: boolean;
  hasBorder?: boolean;
}>;

const CircleFrame = ({
  children,
  fontSize,
  textColor = GrayColors.G8,
  bgColor = 'transparent',
  clickable = false,
  hasBorder = false,
}: Props): JSX.Element => {
  return (
    <Circle
      fontSize={fontSize}
      textColor={textColor}
      bgColor={bgColor}
      clickable={clickable}
      hasBorder={hasBorder}
    >
      {children}
    </Circle>
  );
};

type IStyledCircle = Required<
  Pick<Props, 'fontSize' | 'bgColor' | 'textColor' | 'clickable' | 'hasBorder'>
>;

const Circle = styled.div<IStyledCircle>`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ fontSize }) => String(Math.floor(Number(fontSize) * 0.3)) + 'px'};
  border: ${({ hasBorder, textColor }) => (hasBorder ? `2px solid ${textColor}` : 'none')};
  border-radius: 50%;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ textColor }) => textColor};
  background-color: ${({ bgColor }) => bgColor};

  ${({ clickable, bgColor, textColor }) =>
    clickable &&
    css`
      transition: background-color 0.2s;

      &:hover {
        background-color: ${bgColor === 'transparent'
          ? rgba(textColor, 0.1)
          : darken(0.08, bgColor)};
      }
    `}

  & > svg {
    font-size: inherit;
  }
`;

export default CircleFrame;
