import { rgba } from 'polished';
import * as React from 'react';
import { MdOutlineAdd as AddIcon } from 'react-icons/md';
import Select, { components, MenuProps, OptionProps } from 'react-select';
import { StylesConfig } from 'react-select/dist/declarations/src/styles';
import styled from 'styled-components';
import { BackGroundColor, BrandColors, GrayColors, PhaseColors } from '~/constants';

export interface IFormOption<V = any, L = string> {
  label: L;
  value: V;
  color?: string;
  isDisabled?: boolean;
}

interface Props {
  className?: string;
  selected: IFormOption<any, any> | null;
  options: IFormOption<any, any>[];
  placeholder?: string;
  placeholderFontSize?: string;
  width?: string;
  readOnly?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  handleChange?: (selected: any) => void;
  handleOnMenuOpen?: () => void;
  handleOnMenuClose?: () => void;
  isAddButton?: boolean;
  isCustomSelected?: boolean;
  isCustomLabel?: boolean;
  handleAddClick?: () => void;
  closeMenuOnAdd?: boolean;
  addButtonText?: string;
  customStyles?: Partial<Record<keyof StylesConfig, React.CSSProperties>>;
}

const Menu = (props: MenuProps & { handleAddClick?: () => void; addButtonText?: string }) => {
  return (
    <React.Fragment>
      <components.Menu {...props}>
        <div>
          <div>{props.children}</div>
          <AddButton onClick={props.handleAddClick}>
            <AddIcon /> {props.addButtonText}
          </AddButton>
        </div>
      </components.Menu>
    </React.Fragment>
  );
};

const Option = (props: any) => {
  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <components.Option {...props} style={{ display: 'inline-flex' }}>
          <span>{props.data.label}</span>
          {props.data.phase && (
            <span
              style={{
                display: 'inline-flex',
                color: PhaseColors[props.data.value.phaseValue],
                marginLeft: '3px',
              }}
            >
              ({props.data.phase})
            </span>
          )}
        </components.Option>
      </div>
    </React.Fragment>
  );
};

const SingleValue = (props: any) => {
  return (
    <div>
      <components.SingleValue {...props} style={{ display: 'inline-flex' }}>
        <span>{props.data.label}</span>
        {props.data.phase && (
          <span
            style={{
              display: 'inline-flex',
              color: PhaseColors[props.data.value.phaseValue],
            }}
          >
            ({props.data.phase})
          </span>
        )}
      </components.SingleValue>
    </div>
  );
};

const SelectInput = ({
  className,
  selected,
  options,
  placeholder,
  placeholderFontSize = '1.3rem',
  width,
  readOnly = false,
  disabled = false,
  isSearchable = true,
  isClearable = false,
  menuPlacement = 'bottom',
  handleChange,
  isAddButton = false,
  isCustomSelected = false,
  isCustomLabel = false,
  handleAddClick,
  closeMenuOnAdd = false,
  handleOnMenuOpen,
  handleOnMenuClose,
  addButtonText,
  customStyles,
}: Props): JSX.Element => {
  const unchangable = disabled || readOnly;

  const styles = React.useMemo(() => {
    return makeCustomStyles(customStyles);
  }, [customStyles]);

  const props = React.useMemo(() => {
    return {
      width,
      isReadonly: readOnly,
      placeholderFontSize,
      closeMenuOnAdd,
    };
  }, [width, readOnly, placeholderFontSize, closeMenuOnAdd]);

  return (
    <Select
      id='select-input-value'
      className={className}
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            primary: BrandColors.secondary,
            primary25: rgba(BrandColors.secondary, 0.2),
          },
        };
      }}
      styles={styles}
      value={selected ?? null}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      isSearchable={isSearchable}
      menuPlacement={menuPlacement}
      isClearable={isClearable}
      maxMenuHeight={240}
      {...props}
      onChange={unchangable ? undefined : handleChange}
      onMenuOpen={handleOnMenuOpen}
      onMenuClose={handleOnMenuClose}
      components={
        isAddButton
          ? {
              Menu: (optionProps) => (
                <Menu
                  {...optionProps}
                  handleAddClick={handleAddClick}
                  addButtonText={addButtonText}
                />
              ),
            }
          : isCustomSelected && isCustomLabel
          ? {
              Option: (optionProps) => <Option {...optionProps} />,
              SingleValue: (optionProps) => <SingleValue {...optionProps} />,
            }
          : undefined
      }
    />
  );
};

const makeCustomStyles = (styles: Props['customStyles'] = {}): StylesConfig => {
  return {
    container: (provided: any, store: any) => ({
      ...provided,
      maxWidth: store.selectProps.width ?? 'none',
      width: store.selectProps.width ?? '100%',
      textAlign: 'left',
      ...(styles['container'] ?? {}),
    }),
    control: (provided: any, store: any) => ({
      ...provided,
      minHeight: '35px',
      height: '35px',
      borderColor: store.selectProps.isReadonly ? 'transparent' : GrayColors.G4,
      boxShadow: 'none',
      backgroundColor: store.selectProps.isDisabled ? GrayColors.G1 : '#fff',
      pointerEvents: store.selectProps.isReadonly || store.selectProps.isDisabled ? 'none' : 'auto',

      ':hover': {
        borderColor: BackGroundColor.Primary,
      },

      ':focus': {
        borderColor: BackGroundColor.Primary,
      },
      ...(styles['control'] ?? {}),
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      opacity: state.selectProps.isReadonly ? 0 : 1,
      fontSize: state.selectProps.placeholderFontSize
        ? state.selectProps.placeholderFontSize
        : '1.3rem',
      ...(styles['placeholder'] ?? {}),
    }),
    indicatorsContainer: (provided: any, store: any) => ({
      ...provided,
      display: store.selectProps.isReadonly ? 'none' : 'flex',

      '> [class*="indicatorContainer"]': {
        padding: '6px 8px',
      },
      ...(styles['indicatorsContainer'] ?? {}),
    }),
    menu: (provided: any, store: any) => ({
      ...provided,
      zIndex: 99,
      display: store.selectProps.closeMenuOnAdd ? 'none' : 'block',
      overflow: 'auto',
      ...(styles['menu'] ?? {}),
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      padding: '8px 10px',
      lineHeight: '1.4',
      color: optionColor(state),
      backgroundColor: state.isDisabled ? '#fff' : provided.backgroundColor,
      ...(styles['option'] ?? {}),
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: state.data.color || GrayColors.G8,
      ...(styles['singleValue'] ?? {}),
    }),
  };
};

const optionColor = (state: OptionProps<IFormOption>) => {
  if (state.isDisabled) {
    return GrayColors.G4;
  }
  return state.data.color || GrayColors.G8;
};

const AddButton = styled.button`
  background-color: ${GrayColors.G1};
  border: none;
  border-top: 1px solid ${GrayColors.G3};
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 35px;
  cursor: pointer;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export default React.memo(SelectInput);
