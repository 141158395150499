import styled from 'styled-components';

export interface CheckBoxPropsType {
  children?: React.ReactNode | React.ReactNode[];
  title?: string;
  value?: any;
  checked?: boolean;
  onChange?: any;
}

const CheckBox = (props: CheckBoxPropsType) => {
  const { title, value, checked, onChange } = props;

  const handleChange = (e: any) => {
    if (onChange) onChange(e);
  };

  return (
    <CheckBoxElement>
      <input type='checkbox' onChange={handleChange} value={value} checked={checked} />
      <div className='checkmark'></div>
      <span className='title'>{title ? title : <></>}</span>
    </CheckBoxElement>
  );
};

const CheckBoxElement = styled.label`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 16px;
  flex-direction: row;
  justify-content: space-between;

  .title {
    color: #8d9aa9;
    font-size: 12px;
    margin-left: 20px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    outline: 1px solid rgba(191, 197, 210, 1);
    border-radius: 3px;

    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover {
    .checkmark {
      background-color: #f4f4f4;
    }

    .title {
      color: #666fe8;
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 5px;
    top: 0px;
    width: 4px;
    height: 10px;
    border: solid #666fe8;
    border-radius: 0px;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default CheckBox;
