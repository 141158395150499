import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListUserRequest } from '~/store/ducks/user/actions';
import { RootState } from '~/store/types';
import { IListUserRequest } from '~/types';

interface FuncType {
  userState: RootState['user'];
  dispatchGetListUserRequest: (params: IListUserRequest) => Promise<void>;
}

const useUserStore = (): FuncType => {
  const dispatch = useDispatch();

  const userState = useSelector<RootState, RootState['user']>((state) => {
    return state.user;
  });

  const dispatchGetListUserRequest = React.useCallback(
    async (params: IListUserRequest) => {
      await dispatch(getListUserRequest(params));
    },
    [dispatch],
  );

  return {
    userState,
    dispatchGetListUserRequest,
  };
};

export default useUserStore;
