import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAnalytic,
  downloadFileAnalytic,
  getListAnalytic,
  uploadFileAnalytic,
} from '~/store/actions';
import { RootState } from '~/store/types';
import { IAnalytic, IAnalyticParamsRequestList } from '~/types';

interface FuncType {
  analyticState: RootState['analytic'];
  dispatchGetListAnalytic: (params: IAnalyticParamsRequestList) => Promise<void>;
  dispatchDownLoadAnalytic: (analytic: IAnalytic) => Promise<void>;
  dispatchUploadFileAnalytic: (
    fileUpload: any,
    handleCallbackFunction: () => void,
  ) => Promise<void>;
  dispatchDeleteAnalytic: (analyticId: IAnalytic['id']) => Promise<void>;
}

const useAnalyticStore = (): FuncType => {
  const dispatch = useDispatch();

  const analyticState = useSelector<RootState, RootState['analytic']>((state) => {
    return state.analytic;
  });

  const dispatchGetListAnalytic = React.useCallback(
    async (params: IAnalyticParamsRequestList) => {
      await dispatch(getListAnalytic(params));
    },
    [dispatch],
  );

  const dispatchDownLoadAnalytic = React.useCallback(
    async (analytic: IAnalytic) => {
      await dispatch(downloadFileAnalytic(analytic));
    },
    [dispatch],
  );

  const dispatchUploadFileAnalytic = React.useCallback(
    async (fileUpload: any, handleCallbackFunction: () => void) => {
      await dispatch(uploadFileAnalytic(fileUpload, handleCallbackFunction));
    },
    [dispatch],
  );

  const dispatchDeleteAnalytic = React.useCallback(
    async (analyticId: IAnalytic['id']) => {
      await dispatch(deleteAnalytic(analyticId));
    },
    [dispatch],
  );

  return {
    analyticState,
    dispatchGetListAnalytic,
    dispatchDownLoadAnalytic,
    dispatchUploadFileAnalytic,
    dispatchDeleteAnalytic,
  };
};

export default useAnalyticStore;
