import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import TextButton from '~/components/atoms/Button';
import DateInput from '~/components/atoms/Input/DateInput';
import DatePickerYearInput from '~/components/atoms/Input/DatePickerYearInput';
import { moment } from '~/plugins';
import { useStationStore, useToastStore } from '~/store/hooks';
import { IDownloadSensorReportData } from '~/types';
import ReportTypeSelector from './ReportTypeSelector';
import Table from './Table';

export interface ReportPropsType {
  children?: React.ReactNode | React.ReactNode[];
  listSensorAvailables: any;
  stationId: string;
}

const Report = (props: ReportPropsType) => {
  const { stationId, listSensorAvailables } = props;

  const [reportType, setReportType] = useState('year');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [startTime, setStartTime] = useState(moment().subtract(14, 'days').format());
  const [endTime, setEndTime] = useState(moment().format());

  const { dispatchDownLoadSensorReportData } = useStationStore();
  const { dispatchCreateToast } = useToastStore();

  const changeReportType = useCallback((type: 'year' | 'short_term') => {
    setReportType(type);
  }, []);

  const changeSelectedSensor = useCallback((arrayIdSensors: readonly string[]) => {
    setSelected(arrayIdSensors);
  }, []);

  const changeStartTime = useCallback((date: Date | null) => {
    setStartTime(moment(date).format());
  }, []);

  const changeEndTime = useCallback((date: Date | null) => {
    setEndTime(moment(date).format());
  }, []);

  const downLoadSensorReport = useCallback(
    (params: IDownloadSensorReportData) => {
      dispatchDownLoadSensorReportData(params);
    },
    [dispatchDownLoadSensorReportData],
  );

  const renderTimePicker = useMemo(() => {
    switch (reportType) {
      case 'short_term': {
        return (
          <TimePicker>
            <DateInput
              value={startTime}
              handleChange={changeStartTime}
              maxDate={moment(endTime).subtract(7, 'days').format()}
              showTimeSelect={true}
              width='168px'
              placeholder='Ngày bắt đầu'
            />
            <DateInput
              value={endTime}
              handleChange={changeEndTime}
              maxDate={moment().format()}
              showTimeSelect={true}
              width='168px'
              placeholder='Ngày kết thúc'
            />
          </TimePicker>
        );
      }
      case 'year': {
        return (
          <TimePicker>
            <DatePickerYearInput
              value={startTime}
              handleChange={changeStartTime}
              maxDate={moment(new Date(new Date().getFullYear(), 11, 31)).format()}
            />
          </TimePicker>
        );
      }
    }
  }, [reportType, startTime, endTime, changeStartTime, changeEndTime]);

  const exportData = useCallback(() => {
    if (selected?.length > 0 && stationId) {
      if (reportType === 'year') {
        selected.forEach((one) => {
          const year = moment(startTime).year();
          downLoadSensorReport({
            stationId,
            sensorId: one,
            type: reportType,
            time: year,
          });
        });
      } else if (reportType === 'short_term') {
        if (moment(endTime)?.diff(moment(startTime), 'days') < 7) {
          return dispatchCreateToast({
            id: uuidv4(),
            toastType: 'failed',
            message: 'Ngày bắt đầu phải trước ngày kết thúc ít nhất 7 ngày',
          });
        }

        selected.forEach((one) => {
          downLoadSensorReport({
            stationId,
            sensorId: one,
            type: reportType,
            time_start: moment(startTime).format(),
            time_end: moment(endTime).format(),
          });
        });
      }
    }
  }, [
    selected,
    stationId,
    reportType,
    startTime,
    downLoadSensorReport,
    endTime,
    dispatchCreateToast,
  ]);

  return (
    <ReportElement>
      <div className='report-wrapper'>
        <div className='title'>Chọn cảm biến: </div>
        <div className='table'>
          <Table
            data={listSensorAvailables}
            selected={selected}
            onChangeSelectedSensor={changeSelectedSensor}
          />
        </div>
        <div className='controller1'>
          <div className='select-report-type'>
            <div className='label'>Loại báo cáo: </div>
            <ReportTypeSelector value={reportType} onChange={changeReportType} />
          </div>
          <div className='select-time'>
            <div className='label'>Thời gian: </div>
            {renderTimePicker}
          </div>
        </div>
        <div className='report-data'>
          <TextButton
            color='primary'
            variant='contained'
            disabled={!selected?.length ? true : false}
            onClick={exportData}
          >
            Xuất báo cáo
          </TextButton>
        </div>
      </div>
    </ReportElement>
  );
};

export default Report;

const ReportElement = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  overflow: hidden;

  .controller1 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > div {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-right: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > .label {
        min-width: fit-content;
        margin-right: 8px;
        font-weight: 600;
        color: #666fe8;
      }
    }
  }

  .report-wrapper {
    padding: 8px;
    width: 100%;
    height: 100%;
    /* background: #f5f5f5; */
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;

    .title {
      padding: 8px;
      color: #666fe8;
      font-weight: 600;
      font-size: 16px;
    }

    .table {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      overflow: hidden;

      .select-report-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
      }

      .select-time {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
      }
    }
  }

  .report-data {
    margin-top: 10px;
    width: 100%;
    text-align: right;
  }
`;

const TimePicker = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  & > *:last-child {
    margin-left: 8px;
  }
`;
