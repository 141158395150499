import Button, { ButtonProps } from '@mui/material/Button';
import styled, { css } from 'styled-components';
import { BackGroundColor } from '~/constants';
import { tuple } from '~/utils';

const ColorTypes = tuple('inherit', 'primary', 'secondary', 'success', 'error', 'info', 'warning');
export type ColorType = typeof ColorTypes[number];

const SizeTypes = tuple('small', 'medium', 'large');
export type SizeType = typeof SizeTypes[number];

const VariantTypes = tuple('contained', 'outlined', 'text');
export type VariantType = typeof VariantTypes[number];

type Props = ButtonProps &
  React.PropsWithChildren<{
    buttonRef?: React.RefObject<HTMLButtonElement>;
    className?: string;
    color?: ColorType;
    size?: SizeType;
    variant?: VariantType;
    disabled?: boolean;
    disableElevation?: boolean;
    disableFocusRipple?: boolean;
    disableRipple?: boolean;
    fullWidth?: boolean;
    href?: string;
    startIcon?: React.ReactElement;
    endIcon?: React.ReactElement;
    handleClick?: () => void;
  }> &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const TextButton = ({
  children,
  buttonRef,
  className,
  color = 'primary',
  size = 'medium',
  variant = 'text',
  disabled = false,
  disableElevation = false,
  disableFocusRipple = false,
  disableRipple = false,
  fullWidth = false,
  href,
  startIcon,
  endIcon,
  handleClick,
  ...rest
}: Props): JSX.Element => {
  return (
    <ButtonBase
      ref={buttonRef}
      className={className}
      color={color}
      size={size}
      variant={variant}
      disabled={disabled}
      disableElevation={disableElevation}
      disableFocusRipple={disableFocusRipple}
      disableRipple={disableRipple}
      fullWidth={fullWidth}
      href={href}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={(event) => {
        event.stopPropagation();
        handleClick && handleClick();
      }}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
};

const setVariantStyle = (props: Props) => {
  if (props.color === 'inherit') {
    return css`
      color: ${BackGroundColor.Primary};
      background: #fff;
      border: 1px solid rgba(102, 111, 232, 0.08);
      box-shadow: 0px 5px 10px rgba(102, 111, 232, 0.2);
    `;
  }

  if (props.disabled) {
    return css`
      background-color: #c7caf3 !important;
      mix-blend-mode: normal;
      opacity: 0.9;
    `;
  }
};

const ButtonBase = styled(Button)<Props>`
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  ${(props) => setVariantStyle(props)}
`;

export default TextButton;
