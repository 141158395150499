import { ThunkAction } from 'redux-thunk';
import { axios2 as axios } from '~/plugins';
import { ServerConfigActionType } from '~/store/ducks/serverConfig/types';
import { RootAction, RootState } from '~/store/types';
import { IServerConfigResponse, IServerConfigData } from '~/types/';
import { v4 as uuidv4 } from 'uuid';
import { createToast } from '~/store/ducks/toast/actions';

export const getServerConfig =
  (): ThunkAction<void, RootState, undefined, RootAction> => async (dispatch) => {
    try {
      dispatch({ type: ServerConfigActionType.GET_SERVER_CONFIG });

      const res = await axios.get<IServerConfigResponse>('/server_config');

      dispatch({
        type: ServerConfigActionType.GET_SERVER_CONFIG_SUCCESS,
        payload: {
          serverConfig: res.data.Data,
        },
      });
    } catch (exception) {
      dispatch({
        type: ServerConfigActionType.GET_SERVER_CONFIG_FAIL,
        payload: {
          error: true,
          message: 'Lấy  cấu hình server thất bại',
        },
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Lấy  cấu hình server thất bại',
        }),
      );
    }
  };

export const backupInfo = async () => {
  const res = await axios.get(`/back_up/info`);

  return res.data;
};

export const backupData = async () => {
  const res = await axios.get(`/back_up/data`);

  return res.data;
};

export const updateServerConfig = async (serverConfig: IServerConfigData) => {
  const res = await axios.put(`/server_config`, serverConfig);

  return res.data;
};
