import styled from 'styled-components';
import { AiOutlineFundView } from 'react-icons/ai';
import TextButton from '~/components/atoms/Button';
import { useCallback } from 'react';

const SystemInformation = () => {
  const handleClickViewDetail = useCallback(() => {
    window.open(
      'https://e-monitoring.goodeye.com.vn/netdata/#menu_system_submenu_ram;after=-300;before=0;theme=slate;utc=Asia/Saigon',
      '_blank',
    );
    // window.location.href =
    //   'https://e-monitoring.goodeye.com.vn/netdata/#menu_system_submenu_ram;after=-300;before=0;theme=slate;utc=Asia/Saigon';
  }, []);
  return (
    <DashboardPageElement>
      <Header>
        <Title>Thông số hệ thống</Title>
        <div className='controls'>
          <div className='left'></div>

          <div className='right'>
            <TextButton
              color='primary'
              variant='contained'
              startIcon={<AiOutlineFundView size={22} />}
              onClick={handleClickViewDetail}
            >
              Xem chi tiết
            </TextButton>
          </div>
        </div>
      </Header>
      <iframe src={'chart.html'} height='100%' width='100%' />
    </DashboardPageElement>
  );
};

export default SystemInformation;

const DashboardPageElement = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  position: relative;
  flex-direction: column;
  overflow: hidden;

  iframe {
    border: none;
    outline: none;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding: 8px;
`;

const Header = styled.div`
  margin-bottom: 10px;

  & > .controls {
    display: flex;
    justify-content: space-between;

    .right {
      display: flex;
      flex-direction: row;

      & > div {
        margin: 0 8px;

        .icon {
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          margin-right: 5px;
        }
      }
    }
  }
`;
