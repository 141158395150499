import Tooltip from '@mui/material/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import i18next from 'i18next';
import * as React from 'react';
import { FiDownload, FiTrash2 } from 'react-icons/fi';
import styled from 'styled-components';
import { moment } from '~/plugins';
import { useAnalyticStore } from '~/store/hooks';
import { IAnalytic } from '~/types';

const ActionRow = styled.div`
  display: flex;
  justify-content: center;

  & > .action {
    cursor: pointer;
    margin-right: 16px;
    &:hover {
      color: #666fe8;
    }
  }
`;

export interface AnalyticTablePropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const useAnalyticTable = (props: AnalyticTablePropsType) => {
  const { onConfirmDelete } = props;
  const { dispatchDeleteAnalytic, dispatchDownLoadAnalytic } = useAnalyticStore();
  const [isModalAnalyticOpen, setIsModalAnalyticOpen] = React.useState<boolean>(false);

  const handleDownloadFile = React.useCallback(
    (item: IAnalytic) => {
      dispatchDownLoadAnalytic(item);
    },
    [dispatchDownLoadAnalytic],
  );

  const handleDeleteFile = React.useCallback(
    async (item: IAnalytic) => {
      const resultConfirmDelete = await onConfirmDelete(undefined);
      if (!resultConfirmDelete) return;

      dispatchDeleteAnalytic(item.id);
    },
    [dispatchDeleteAnalytic, onConfirmDelete],
  );

  const handleOpenModalAnalytic = React.useCallback(() => {
    setIsModalAnalyticOpen(true);
  }, []);

  const handleCloseModalAnalytic = React.useCallback(() => {
    setIsModalAnalyticOpen(false);
  }, []);

  const columns = React.useMemo<ColumnDef<IAnalytic>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: i18next.t('analytic.table.name'),
      },
      {
        accessorKey: 'created_at',
        header: i18next.t('analytic.table.created_at'),
        cell: (row) => {
          const created_at = row.getValue() as string;
          return moment(created_at).format('YYYY/MM/DD');
        },
      },
      {
        accessorKey: 'updated_at',
        header: i18next.t('analytic.table.updated_at'),
        cell: (row) => {
          const updated_at = row.getValue() as string;
          return moment(updated_at).format('YYYY/MM/DD');
        },
      },
      {
        accessorKey: '',
        header: i18next.t('analytic.table.action'),
        cell: ({ row: { original } }) => {
          return (
            <ActionRow>
              <Tooltip title='Download'>
                <span className='action'>
                  <FiDownload size='20px' onClick={() => handleDownloadFile(original)} />
                </span>
              </Tooltip>

              <Tooltip title='Xóa'>
                <span className='action'>
                  <FiTrash2
                    size='20px'
                    onClick={() => {
                      handleDeleteFile(original);
                    }}
                  />
                </span>
              </Tooltip>
            </ActionRow>
          );
        },
      },
    ];
  }, [handleDeleteFile, handleDownloadFile]);
  return {
    columns,
    isModalAnalyticOpen,
    handleOpenModalAnalytic,
    handleCloseModalAnalytic,
  };
};

export default useAnalyticTable;
