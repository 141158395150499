import * as React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { BiArrowFromTop, BiArrowFromBottom } from 'react-icons/bi';
import { useLoading } from '~/hooks';
import { useStationStore } from '~/store/hooks';
import { getStationDetail } from '~/store/selectors';
import { stationDetailLoadingModule } from '~/utils';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import StationMapDetail from '~/components/organism/StationMap';
import ChartInfo from '~/pages/Station/StationDetail/ChartInfo';
import TextButton from '~/components/atoms/Button';
import { locationDefault } from '~/constants';

const StationDetail = () => {
  const { stationId } = useParams();
  const isStationDetailLoading = useLoading(stationDetailLoadingModule);
  const { stationState, dispatchGetStationDetail } = useStationStore();
  const stationDetail = getStationDetail(stationState);
  const [visibleMapState, setVisibleMapState] = React.useState(false);

  const renderLoadingMap = React.useMemo(() => {
    return (
      <LoadingSpinSpinning>
        <LoadingSpinDot>
          <CircularProgressLoading color='#666FE8' size={28} />
        </LoadingSpinDot>
      </LoadingSpinSpinning>
    );
  }, []);

  const handleToggleMap = React.useCallback(() => {
    setVisibleMapState(!visibleMapState);
  }, [visibleMapState]);

  const renderMapDetail = React.useMemo(() => {
    if (isStationDetailLoading) return renderLoadingMap;
    return (
      <StationMapDetail
        station={stationDetail ? [stationDetail] : []}
        locationStation={
          stationDetail ? { Lat: stationDetail.lat, Lon: stationDetail.lon } : locationDefault
        }
      />
    );
  }, [isStationDetailLoading, renderLoadingMap, stationDetail]);

  const startIconComponent = React.useMemo(() => {
    if (visibleMapState) {
      return <BiArrowFromBottom size={20} color='#fff' />;
    }
    return <BiArrowFromTop size={20} color='#fff' />;
  }, [visibleMapState]);

  const renderToggleMap = React.useMemo(() => {
    return (
      <TextButton
        color='primary'
        variant='contained'
        startIcon={startIconComponent}
        onClick={handleToggleMap}
      >
        {visibleMapState ? 'Đóng bản đồ' : 'Mở bản đồ'}
      </TextButton>
    );
  }, [handleToggleMap, startIconComponent, visibleMapState]);

  const fetchStationDetail = React.useCallback(async () => {
    if (stationId) {
      await dispatchGetStationDetail(stationId);
    }
  }, [dispatchGetStationDetail, stationId]);

  React.useEffect(() => {
    fetchStationDetail();
  }, [fetchStationDetail]);

  return (
    <React.Fragment>
      <MapWrapper>
        <MapContainer className={visibleMapState ? '' : 'hidden'}>{renderMapDetail}</MapContainer>
      </MapWrapper>
      <ChartInfo renderToggleMap={renderToggleMap} />
    </React.Fragment>
  );
};

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;

  .hidden {
    height: 20px;
    visibility: hidden;
  }
`;

const MapContainer = styled.div`
  background: #f2f2f2;
  height: 300px;
  position: relative;
  transition: 0.3s;
  width: 100%;
`;

const LoadingSpinSpinning = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  z-index: 4;
`;

const LoadingSpinDot = styled.span`
  left: 50%;
  margin: -10px;
  position: absolute;
  top: 50%;
`;

export default StationDetail;
