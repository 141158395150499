import * as React from 'react';
import ReactDOM from 'react-dom';

type Props = React.PropsWithChildren<{
  targetID: string;
}>;

const Portal = ({ children, targetID }: Props): React.ReactPortal | null => {
  const [el, setEl] = React.useState<Element | null>(null);

  React.useEffect(() => {
    setEl(document.getElementById(targetID) as Element);
  }, [targetID]);

  return el && ReactDOM.createPortal(children, el);
};

export default Portal;
