import * as React from 'react';
import styled from 'styled-components';
import ButtonBase from '@mui/material/ButtonBase';
import { GrayColors } from '~/constants';
import CircleFrame from '~/components/atoms/Frame/CircleFrame';

type Props = React.PropsWithChildren<{
  size?: Size;
  textColor?: string;
  bgColor?: string;
  hasBorder?: boolean;
  href?: string;
  target?: string;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  handleClick?: () => void;
}>;

type Size = keyof typeof Size;

export const Size = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

const IconButton = ({
  children,
  size = Size.medium,
  textColor = GrayColors.G6,
  bgColor,
  hasBorder = false,
  href,
  target,
  readOnly = false,
  disabled = false,
  className,
  handleClick,
}: Props): JSX.Element => {
  const clickable = !readOnly;

  return (
    <Button
      href={href}
      target={target}
      readOnly={readOnly}
      disabled={disabled}
      className={className}
      onClick={(event) => {
        event.stopPropagation();
        handleClick && handleClick();
      }}
    >
      <CircleFrame
        fontSize={fontSize[size]}
        textColor={textColor}
        bgColor={bgColor}
        clickable={clickable}
        hasBorder={hasBorder}
      >
        {children}
      </CircleFrame>
    </Button>
  );
};

const fontSize: Record<Size, number> = {
  [Size.small]: 12,
  [Size.medium]: 18,
  [Size.large]: 20,
};

type IStyledButton = Pick<Props, 'readOnly' | 'href' | 'target'>;

const Button = styled(ButtonBase)<IStyledButton>`
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border-radius: 50%;
  line-height: 1;
  transition: background-color 0.2s;
  outline: 0;
  overflow: hidden;
  cursor: pointer;
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'auto')};

  &[disabled],
  &[aria-disabled='true'] {
    opacity: 0.6;
  }
`;

export default IconButton;
