import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  authCleanErrorInfo,
  authLoginRequest,
  authLogout,
  authRefreshToken,
  authSetKeepLogin,
} from '~/store/ducks/auth/actions';
import { RootState } from '~/store/types';
import { IAuthLoginRequest } from '~/types';
import { RootPath } from '~/configs';

interface FuncType {
  authState: RootState['auth'];
  dispatchAuthLoginRequest: (params: IAuthLoginRequest) => Promise<void>;
  dispatchAuthRefreshTokenRequest: () => void;
  dispatchAuthSetKeepLogin: (keepLogin: boolean) => void;
  dispatchAuthLogout: () => void;
  dispatchAuthCleanErrorInfo: () => void;
}

const useAuthInfoStore = (): FuncType => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector<RootState, RootState['auth']>((state) => {
    return state.auth;
  });

  const dispatchAuthLoginRequest = React.useCallback(
    async (params: IAuthLoginRequest) => {
      await dispatch(authLoginRequest(params));
    },
    [dispatch],
  );

  const dispatchAuthSetKeepLogin = React.useCallback(
    (keepLogin: boolean) => {
      dispatch(authSetKeepLogin(keepLogin));
    },
    [dispatch],
  );

  const dispatchAuthLogout = React.useCallback(() => {
    dispatch(authLogout());
    navigate(RootPath.Login);
  }, [dispatch, navigate]);

  const dispatchAuthCleanErrorInfo = React.useCallback(() => {
    dispatch(authCleanErrorInfo());
  }, [dispatch]);

  const dispatchAuthRefreshTokenRequest = React.useCallback(() => {
    dispatch(authRefreshToken());
  }, [dispatch]);

  return {
    authState,
    dispatchAuthLoginRequest,
    dispatchAuthSetKeepLogin,
    dispatchAuthLogout,
    dispatchAuthCleanErrorInfo,
    dispatchAuthRefreshTokenRequest,
  };
};

export default useAuthInfoStore;
