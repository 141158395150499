import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
// import { BsFileEarmarkPdfFill, BsFileEarmarkExcelFill } from 'react-icons/bs';

// function createRow(
//   time: any,
//   muaObs: any,
//   tocDoGio: any,
//   huongGio: any,
//   gioMax2s: any,
//   doAm: any,
//   nhietDo: any,
//   pin: any,
// ) {
//   return { time, muaObs, tocDoGio, huongGio, gioMax2s, doAm, nhietDo, pin };
// }

export default function StickyHeadTable(props: any) {
  const { data } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [columns, setColumns] = React.useState<any[]>([]);
  const [rows, setRows] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (data.columns) {
      setColumns(data.columns);
    }
    if (data.rows) {
      setRows(data.rows);
    }
  }, [data]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ControllerWrapper>
        {/* <Exports>
          <div className='export-options'>
            <div className='pdf'>
              <div className='icon'>
                <BsFileEarmarkPdfFill />
              </div>
              <div className='name'>Pdf</div>
            </div>
            <div className='excel'>
              <div className='icon'>
                <BsFileEarmarkExcelFill />
              </div>
              <div className='name'>Excel</div>
            </div>
          </div>
        </Exports> */}
        <TablePagination
          rowsPerPageOptions={[30]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
          }}
        />
      </ControllerWrapper>
      <TableWrapper>
        <TableContainer
          sx={{
            height: '100%',
            width: '100%',
            overflowX: 'scroll',
            overflowY: 'scroll',
            position: 'absolute',
            top: '0px',
            left: '0px',
          }}
        >
          <Table
            stickyHeader
            aria-label='sticky table'
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((one: any, index: any) => (
                  <TableCell
                    key={index}
                    align='left'
                    padding='normal'
                    sx={{ minWidth: 'fit-content', whiteSpace: 'nowrap' }}
                  >
                    {one.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
                return (
                  <TableRow hover key={row.code}>
                    {columns.map((column: any) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align='left'
                          sx={{ minWidth: 'fit-content', whiteSpace: 'nowrap' }}
                        >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </Paper>
  );
}

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const ControllerWrapper = styled.div`
  width: 100%;
  height: 52px;
  position: relative;
  z-index: 1;
  overflow: hidden;
`;

// const Exports = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: center;
//   position: absolute;
//   top: 0;
//   left: 0;
//   color: rgb(49, 49, 49);
//   font-size: 0.875rem;
//   font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
//   height: 100%;
//   z-index: 1;

//   .export-options {
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     & > div {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       padding: 7px 7px 4px 7px;
//       background: #666fe8;
//       color: white;
//       border-radius: 8px;
//       border-radius: 10px;
//       margin-right: 2px;
//       cursor: pointer;

//       .name {
//         margin-left: 3px;
//       }
//     }
//   }
// `;
