export interface EventEmitterInterface {
  _events: any;
  dispatch: (eventName: string, data: any) => void;
  subscribe: (eventName: string, callback: any, times?: number) => string | undefined;
  unsubscribe: (eventName: string, callback?: any, id?: string) => void | undefined;
}

export interface Event {
  id: string;
  times?: number;
  callback: (arg0: any) => any;
}

export const EventEmitter: EventEmitterInterface = {
  _events: {},
  dispatch(eventName: string, data: any) {
    if (!eventName || !this._events[eventName]) return;
    // if (typeof data === 'object') data = { ...data };

    this._events[eventName].forEach((oneEvent: Event) => {
      if (oneEvent.times || oneEvent.times === 0) {
        if (oneEvent.times > 0) {
          oneEvent.times = oneEvent.times - 1;
          oneEvent.callback(data);
        } else {
          this.unsubscribe(eventName, null, oneEvent.id);
        }
      } else {
        oneEvent.callback(data);
      }
    });
  },
  subscribe(eventName: string, callback: any, times?: number) {
    // Nếu muốn event chỉ subcribe 1 hoặc vài lần thì có thể set times cho nó. Ví dụ set times = 1 thì callback được subscribe 1 lần sau đó bị xoá đi
    // Nếu không set times thì nó sẽ luôn hoạt động

    if (!eventName || !callback) return;
    if (!this._events[eventName]) this._events[eventName] = [];

    const data = {
      id: Math.random().toString(36).substring(6),
      callback,
      times: times,
    };

    this._events[eventName].push(data);

    return data.id;
  },
  unsubscribe(eventName: string, callback?: any, id?: string) {
    if (!eventName || !this._events[eventName]) return;

    if (callback || id) {
      // Nếu như có truyền vào callback hoặc id thì xoá đi callback hoặc id đó khỏi event
      this._events[eventName] = this._events[eventName].filter(
        (oneEvent: Event) => !(oneEvent.id === id || oneEvent.callback === callback),
      );
    } else {
      // Nếu như không truyền vào callback hoặc id thì xoá luôn event đi khỏi list event listener
      delete this._events[eventName];
    }
  },
};
