import TextField, { TextFieldProps } from '@mui/material/TextField';
import { RefCallBack } from 'react-hook-form';
import styled from 'styled-components';
import { BackGroundColor, BorderColor, TextColor } from '~/constants';

export type InputFormProps = {
  value: any;
  invalid: boolean;
  error: any;
  onChange: (...event: any[]) => void;
  onBlur?: (...event: any[]) => void;
  comRef?: RefCallBack;
} & TextFieldProps;

const InputForm = (props: InputFormProps): JSX.Element => {
  const { value, invalid, error, onChange, onBlur, comRef, ...otherProps } = props;
  return (
    <Input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={comRef}
      error={invalid}
      helperText={error?.message}
      {...otherProps}
    />
  );
};

const Input = styled(TextField)`
  width: 100%;

  .MuiOutlinedInput-root {
    max-width: none;
    width: 100%;
    position: relative;
    background-color: ${BackGroundColor.Secondary};
    border-color: ${BorderColor.Secondary};
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
  }

  .MuiInputBase-input {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: normal;
    color: ${TextColor.Disable};
    background-color: transparent;
    line-height: 1.4;
    box-sizing: border-box;
    transition: border 0.2s;
    outline: 0;
    text-align: left;
    padding: 7px 15px;

    &:hover {
      border-color: #666fe8;
    }

    &:focus {
      border-color: #666fe8;
    }

    &[readonly] {
      pointer-events: none;
      border-color: transparent;
    }

    &[disabled] {
      pointer-events: none;
      background-color: ${TextColor.Hidden};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
`;

export default InputForm;
