import { IAnalytic, IGetAnalyticResponse, IPagination } from '~/types';

export enum AnalyticActionType {
  GET_LIST_ANALYTIC = 'analytic/GET_LIST_ANALYTIC',
  GET_LIST_ANALYTIC_SUCCESS = 'analytic/GET_LIST_ANALYTIC_SUCCESS',
  GET_LIST_ANALYTIC_FAIL = 'analytic/GET_LIST_ANALYTIC_FAIL',
  UPLOAD_FILE_ANALYTIC = 'analytic/UPLOAD_FILE_ANALYTIC',
  UPLOAD_FILE_ANALYTIC_SUCCESS = 'analytic/UPLOAD_FILE_ANALYTIC_SUCCESS',
  UPLOAD_FILE_ANALYTIC_FAIL = 'analytic/UPLOAD_FILE_ANALYTIC_FAIL',
  DOWNLOAD_FILE_ANALYTIC = 'analytic/DOWNLOAD_FILE_ANALYTIC',
  DOWNLOAD_FILE_ANALYTIC_SUCCESS = 'analytic/DOWNLOAD_FILE_ANALYTIC_SUCCESS',
  DOWNLOAD_FILE_ANALYTIC_FAIL = 'analytic/DOWNLOAD_FILE_ANALYTIC_FAIL',
  DELETE_ANALYTIC = 'analytic/DELETE_ANALYTIC',
  DELETE_ANALYTIC_SUCCESS = 'analytic/DELETE_ANALYTIC_SUCCESS',
  DELETE_ANALYTIC_FAIL = 'analytic/DELETE_ANALYTIC_FAIL',
}

export interface GetListAnalytic {
  type: AnalyticActionType.GET_LIST_ANALYTIC;
}

export interface GetListAnalyticSuccess {
  type: AnalyticActionType.GET_LIST_ANALYTIC_SUCCESS;
  payload: {
    listAnalytic: {
      list: IAnalytic[];
      pagination: IPagination;
    };
  };
}

export interface GetListAnalyticFail {
  type: AnalyticActionType.GET_LIST_ANALYTIC_FAIL;
  payload: {
    error: Error;
  };
}

export interface UploadFileAnalytic {
  type: AnalyticActionType.UPLOAD_FILE_ANALYTIC;
}

export interface UploadFileAnalyticSuccess {
  type: AnalyticActionType.UPLOAD_FILE_ANALYTIC_SUCCESS;
}

export interface UploadFileAnalyticFail {
  type: AnalyticActionType.UPLOAD_FILE_ANALYTIC_FAIL;
}

export interface DownLoadFileAnalytic {
  type: AnalyticActionType.DOWNLOAD_FILE_ANALYTIC;
}

export interface DownLoadFileAnalyticSuccess {
  type: AnalyticActionType.DOWNLOAD_FILE_ANALYTIC_SUCCESS;
}

export interface DownLoadFileAnalyticFail {
  type: AnalyticActionType.DOWNLOAD_FILE_ANALYTIC_FAIL;
}

export interface DeleteAnalytic {
  type: AnalyticActionType.DELETE_ANALYTIC;
}

export interface DeleteAnalyticSuccess {
  type: AnalyticActionType.DELETE_ANALYTIC_SUCCESS;
}

export interface DeleteAnalyticFail {
  type: AnalyticActionType.DELETE_ANALYTIC_FAIL;
}

export type AnalyticAction =
  | GetListAnalytic
  | GetListAnalyticSuccess
  | GetListAnalyticFail
  | UploadFileAnalytic
  | UploadFileAnalyticSuccess
  | UploadFileAnalyticFail
  | DownLoadFileAnalytic
  | DownLoadFileAnalyticSuccess
  | DownLoadFileAnalyticFail
  | DeleteAnalytic
  | DeleteAnalyticSuccess
  | DeleteAnalyticFail;

export interface AnalyticState {
  error?: Error;
  listAnalytic: IGetAnalyticResponse;
}
