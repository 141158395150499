import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';

export interface ReportTypeSelectorPropsType {
  children?: React.ReactNode | React.ReactNode[];
  value: any;
  onChange: (value: any) => void;
}

export default function ReportTypeSelector(props: ReportTypeSelectorPropsType) {
  const { value, onChange } = props;

  const handleChange = (event: any, newAlignment: any) => {
    if (newAlignment) {
      onChange(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      color='primary'
      value={value}
      exclusive
      onChange={handleChange}
      aria-label='Platform'
      style={{
        height: '34px',
      }}
    >
      <ToggleButton value='year' style={{ textTransform: 'none', fontSize: '16px' }}>
        Báo cáo năm
      </ToggleButton>
      <ToggleButton value='short_term' style={{ textTransform: 'none', fontSize: '16px' }}>
        Báo cáo thời đoạn
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
