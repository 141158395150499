import { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import i18next from 'i18next';
import { moment } from '~/plugins';
import { AiOutlineEdit } from 'react-icons/ai';
import { FiEye, FiSearch, FiTrash2 } from 'react-icons/fi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import TextButton from '~/components/atoms/Button';
import TextInput from '~/components/atoms/Input/TextInput';
import DataTable from '~/components/molecules/DataTable';
import { SubPath } from '~/configs';
import { deleteRoleRequest } from '~/store/ducks/role/actions';
import useRoleStore from '~/store/ducks/role/hook';
import { useDebounce, useLoading } from '~/hooks';

import { v4 as uuidv4 } from 'uuid';
import Chip from '~/components/atoms/Chip';
import { useToastStore } from '~/store/hooks';
import { IRoleResponse, IListRoleRequest } from '~/types';
import { TableConfigs } from '~/constants';
import { userListLoadingModule } from '~/utils';

const ListRole = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { dispatchCreateToast } = useToastStore();
  const isLoading = useLoading(userListLoadingModule);

  const { roleState, dispatchGetListRoles } = useRoleStore();

  const [searchName, setSearchName] = useState<string>('');
  const debouncedSearchName: string = useDebounce<string>(searchName, 300);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
    setCurrentPage(1);
  }, []);

  const handleChangePage = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    const page = searchParams.get('page');
    const search = searchParams.get('search');

    if (page && parseInt(page)) {
      setCurrentPage(parseInt(page));
    }

    if (search) {
      setSearchName(search);
      setCurrentPage(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPage && currentPage.toString() !== searchParams.get('page')) {
      searchParams.set('page', currentPage.toString());
    }

    if (searchName && searchName !== searchParams.get('search')) {
      searchParams.set('search', searchName);
    }

    if (!searchName && searchParams.get('search')) {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);
  }, [setSearchParams, handleChangePage, currentPage, searchName, searchParams]);

  const fetchListRole = useCallback(
    async (params: IListRoleRequest) => {
      await dispatchGetListRoles(params);
    },
    [dispatchGetListRoles],
  );

  useEffect(() => {
    fetchListRole({
      queryValue: debouncedSearchName,
      current: currentPage,
      pageSize: TableConfigs.defaultPageSize,
    });
  }, [currentPage, debouncedSearchName, fetchListRole]);

  const handleDeleteUser = useCallback(
    async (id: string) => {
      try {
        const resp = await deleteRoleRequest(id);

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Xoá role thành công!',
          });

          fetchListRole({
            queryValue: debouncedSearchName,
            current: currentPage,
            pageSize: TableConfigs.defaultPageSize,
          });
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    },
    [dispatchCreateToast, fetchListRole, currentPage, debouncedSearchName],
  );

  const renderHeaderTable = useCallback((): JSX.Element => {
    const _handleClickNewClientBtn = () => {
      navigate(SubPath.addRole);
    };

    return (
      <RoleHeader>
        <TextInput width='300px' value={searchName} handleChange={handleInputChange}>
          <FiSearch />
        </TextInput>

        <ActionButtonContainer>
          <TextButton
            color='primary'
            variant='contained'
            startIcon={<IoMdAddCircleOutline />}
            onClick={_handleClickNewClientBtn}
          >
            {t('role.action.add_role')}
          </TextButton>
        </ActionButtonContainer>
      </RoleHeader>
    );
  }, [searchName, handleInputChange, t, navigate]);

  const columns: ColumnDef<IRoleResponse, any>[] = [
    {
      accessorKey: 'name',
      header: i18next.t('role.table.name'),
    },
    {
      accessorKey: 'created_at',
      header: i18next.t('role.table.create_at'),
      cell: (row) => {
        const created_at = row.getValue() as string;
        return moment(created_at).format('YYYY/MM/DD');
      },
    },
    {
      accessorKey: 'status',
      header: i18next.t('role.table.status'),
      cell: (row) => {
        const status = row.getValue();
        if (status == 1) return <Chip type='success' label='Active' style={{ width: '120px' }} />;
        return <Chip type='warning' label='InActive' style={{ width: '120px' }} />;
      },
    },
    {
      accessorKey: '',
      header: i18next.t('role.table.actions'),
      cell: (props) => {
        const {
          row: { original },
        } = props;

        return (
          <ActionRow>
            <Tooltip title='Xem chi tiết'>
              <Link to={`edit/${original.id}`}>
                <FiEye size='20px' />
              </Link>
            </Tooltip>

            <Tooltip title='Cập nhật'>
              <Link to={`edit/${original.id}`}>
                <AiOutlineEdit size='20px' />
              </Link>
            </Tooltip>

            <Tooltip
              title='Xoá'
              onClick={() => {
                handleDeleteUser(original?.id || '');
              }}
            >
              <div>
                <FiTrash2 size='20px' />
              </div>
            </Tooltip>
          </ActionRow>
        );
      },
    },
  ];

  // const Chip2 = styled(Chip)`
  //  background: yellow;
  // `;

  return (
    <>
      <Title>{t('role.title.list_role')}</Title>

      <RoleContainer>
        <DataTable
          isFetching={isLoading}
          data={roleState?.listRole.list as any}
          columns={columns}
          headerComponent={renderHeaderTable()}
          totalPage={roleState?.listRole?.pagination?.total || 1}
          handleChangePage={handleChangePage}
        />
      </RoleContainer>
    </>
  );
};

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const RoleContainer = styled.div`
  height: 100%;
  background: #fff;
  margin-top: 10px;
`;

const RoleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 24px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  .MuiButton-root ~ .MuiButton-root {
    margin-left: 16px;
  }
`;

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 0 12px;
    cursor: pointer;
  }

  & > a {
    cursor: pointer;
  }
`;

export default ListRole;
