/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from '@mui/material/Box';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FiSave } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import Button from '~/components/atoms/Button';
import Empty from '~/components/atoms/Empty';
import TextInput from '~/components/atoms/Input/TextInput';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import { useLoading } from '~/hooks';
import { useStationStore, useStationThresholdStore } from '~/store/hooks';
import { getStationCreateId, getStationThresholdMapping } from '~/store/selectors';
import { IStationThreshold, IStationThresholdUpdateRequest } from '~/types';
import { listThresholdMappingLoadingModule, stationThresholdUpdateLoadingModule } from '~/utils';
import { isNumber } from '~/utils/common';

const StationDataThreshold = () => {
  const { t } = useTranslation();
  const isLoadingStationThreshold = useLoading(listThresholdMappingLoadingModule);
  const isLoadingThresholdUpdate = useLoading(stationThresholdUpdateLoadingModule);
  const { stationState } = useStationStore();
  const { dispatchUpdateStationThreshold } = useStationStore();
  const { stationThresholdState, dispatchGetListStationThresholdMapping } =
    useStationThresholdStore();
  const stationCreateId = getStationCreateId(stationState);
  const stationThresholdMapping = getStationThresholdMapping(stationThresholdState);
  const [stationThresholdTemp, setStationThresholdTemp] = React.useState<IStationThreshold[]>([]);
  const [stationThreshold, setStationThreshold] = React.useState<IStationThreshold[]>([]);

  const startIconComponent = React.useMemo(() => {
    if (isLoadingThresholdUpdate) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
    return <FiSave />;
  }, [isLoadingThresholdUpdate]);

  const renderLoadingConfigMapping = React.useMemo(() => {
    return (
      <LoadingSpinSpinning>
        <LoadingSpinDot>
          <CircularProgressLoading size={28} color='#666FE8' />
        </LoadingSpinDot>
      </LoadingSpinSpinning>
    );
  }, []);

  const renderEmptyConfigMapping = React.useMemo(() => {
    return (
      <LoadingSpinSpinning>
        <LoadingSpinDot>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </LoadingSpinDot>
      </LoadingSpinSpinning>
    );
  }, []);

  const fetchStationDataThreshold = React.useCallback(async () => {
    if (stationCreateId) {
      await dispatchGetListStationThresholdMapping(stationCreateId);
    }
  }, [dispatchGetListStationThresholdMapping, stationCreateId]);

  const handleChangeMinValueStation = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: IStationThreshold) => {
      setStationThreshold((prevState) => {
        const newState = prevState.map((threshold) => {
          if (threshold.id === item.id) {
            return { ...threshold, min: event.target.value };
          }
          return threshold;
        });
        return newState;
      });
    },
    [],
  );

  const handleChangeMaxValueStation = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: IStationThreshold) => {
      setStationThreshold((prevState) => {
        const newState = prevState.map((threshold) => {
          if (threshold.id === item.id) {
            return { ...threshold, max: event.target.value };
          }
          return threshold;
        });
        return newState;
      });
    },
    [],
  );

  const handleChangeReasonStation = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: IStationThreshold) => {
      setStationThreshold((prevState) => {
        const newState = prevState.map((threshold) => {
          if (threshold.id === item.id) {
            return { ...threshold, reason: event.target.value };
          }
          return threshold;
        });
        return newState;
      });
    },
    [],
  );

  const handleValidateReason = React.useCallback((item: IStationThreshold) => {
    let message = '';
    let isError = false;
    const isValidMinValue = item.min.length > 0 && Number(item.min);
    const isValidMaxValue = item.max.length > 0 && Number(item.max);

    if (item.reason === '' && (isValidMinValue || isValidMaxValue)) {
      message = '*Không được để trống';
      isError = true;
    }

    return {
      message: message,
      isError: isError,
    };
  }, []);

  const handleValidateMinValue = React.useCallback((item: IStationThreshold) => {
    let message = '';
    let isError = false;
    const isValidMaxValue = item.max.length > 0 && isNumber(item.max);

    if (item.min === '' && (item.reason.length > 0 || isValidMaxValue)) {
      message = '*Không được để trống';
      isError = true;
    } else if (item.min.length > 0 && !isNumber(item.min)) {
      message = '*Không phải là số';
      isError = true;
    }

    return {
      message: message,
      isError: isError,
    };
  }, []);

  const handleValidateMaxValue = React.useCallback((item: IStationThreshold) => {
    let message = '';
    let isError = false;
    const isValidMinValue = item.min.length > 0 && isNumber(item.min);

    if (item.max === '' && (item.reason.length > 0 || isValidMinValue)) {
      message = '*Không được để trống';
      isError = true;
    } else if (item.max.length > 0 && !isNumber(item.max)) {
      message = '*Không phải là số';
      isError = true;
    }

    return {
      message: message,
      isError: isError,
    };
  }, []);

  const stationConfigChange = React.useMemo(() => {
    let disable = true;
    const thresholdCreateChange: IStationThreshold[] = [];
    const thresholdUpdateChange: IStationThreshold[] = [];

    stationThreshold.forEach((item) => {
      stationThresholdTemp.forEach((temp) => {
        const compareId = item.id === temp.id;
        const compareValue =
          item.min !== temp.min || item.max !== temp.max || item.reason !== temp.reason;
        const isErrorReason = handleValidateReason(item);
        const isErrorMinValue = handleValidateMinValue(item);
        const isErrorMaxValue = handleValidateMaxValue(item);
        if (
          compareId &&
          compareValue &&
          item.isCreate &&
          !isErrorMinValue.isError &&
          !isErrorMaxValue.isError &&
          !isErrorReason.isError
        ) {
          thresholdCreateChange.push(item);
        } else if (
          compareId &&
          compareValue &&
          !isErrorMinValue.isError &&
          !isErrorMaxValue.isError &&
          !isErrorReason.isError
        ) {
          thresholdUpdateChange.push(item);
        }
      });
    });

    if (thresholdCreateChange.length > 0 || thresholdUpdateChange.length > 0) {
      disable = false;
    }

    return {
      disable: disable,
      stationThresholdUpdate: thresholdUpdateChange,
      stationThresholdCreate: thresholdCreateChange,
    };
  }, [
    handleValidateMaxValue,
    handleValidateMinValue,
    handleValidateReason,
    stationThreshold,
    stationThresholdTemp,
  ]);

  const renderStationDataThreshold = React.useCallback(() => {
    if (isLoadingStationThreshold) return renderLoadingConfigMapping;
    else if (stationThreshold.length === 0) return renderEmptyConfigMapping;
    return stationThreshold.map((item) => {
      const isErrorReason = handleValidateReason(item);
      const isErrorMinValue = handleValidateMinValue(item);
      const isErrorMaxValue = handleValidateMaxValue(item);
      return (
        <StationThresholdContainer key={item.id}>
          <StationThresholdRowItem>
            <Title>{item.name}</Title>
            <TextInput
              value={item.min}
              placeholder='Min Value'
              errorBorder={isErrorMinValue.isError}
              textError={isErrorMinValue.isError ? isErrorMinValue.message : undefined}
              handleChange={(event) => handleChangeMinValueStation(event, item)}
            />
          </StationThresholdRowItem>
          <StationThresholdRowItem>
            <Title></Title>
            <TextInput
              value={item.max}
              placeholder='Max Value'
              errorBorder={isErrorMaxValue.isError}
              textError={isErrorMaxValue.isError ? isErrorMaxValue.message : undefined}
              handleChange={(event) => handleChangeMaxValueStation(event, item)}
            />
          </StationThresholdRowItem>
          <StationThresholdRowItem>
            <Title>{t('reason')}</Title>
            <TextInput
              value={item.reason}
              errorBorder={isErrorReason.isError}
              textError={isErrorReason.isError ? isErrorReason.message : undefined}
              handleChange={(event) => handleChangeReasonStation(event, item)}
            />
          </StationThresholdRowItem>
        </StationThresholdContainer>
      );
    });
  }, [
    isLoadingStationThreshold,
    renderLoadingConfigMapping,
    renderEmptyConfigMapping,
    stationThreshold,
    t,
    handleValidateReason,
    handleValidateMinValue,
    handleValidateMaxValue,
    handleChangeMinValueStation,
    handleChangeMaxValueStation,
    handleChangeReasonStation,
  ]);

  const handleUpdateStationThreshold = React.useCallback(() => {
    const { stationThresholdUpdate, stationThresholdCreate } = stationConfigChange;
    const paramsCreate: IStationThresholdUpdateRequest[] = stationThresholdCreate.map((item) => {
      return {
        min: _.toNumber(item.min),
        max: _.toNumber(item.max),
        station_id: stationCreateId,
        sample_id: item.id,
        reason: item.reason,
      };
    });
    const paramsUpdate: IStationThresholdUpdateRequest[] = stationThresholdUpdate.map((item) => {
      return {
        id: item.id,
        min: _.toNumber(item.min),
        max: _.toNumber(item.max),
        station_id: stationCreateId,
        sample_id: item.sample_id,
        reason: item.reason,
      };
    });
    const params = [...paramsUpdate, ...paramsCreate];
    dispatchUpdateStationThreshold(params);
  }, [dispatchUpdateStationThreshold, stationConfigChange, stationCreateId]);

  React.useEffect(() => {
    if (!isLoadingThresholdUpdate) fetchStationDataThreshold();
  }, [fetchStationDataThreshold, isLoadingThresholdUpdate]);

  React.useEffect(() => {
    if (stationThresholdMapping) {
      setStationThreshold(stationThresholdMapping);
      setStationThresholdTemp(stationThresholdMapping);
    }
  }, [stationThresholdMapping]);

  return (
    <StationConfigWrapper>
      <StationConfig>
        <HeaderContainer>
          <Header>{t('station.action.check_threshold')} trạm</Header>
          <RowItem>
            <Button
              variant='contained'
              disabled={stationConfigChange.disable}
              startIcon={startIconComponent}
              onClick={handleUpdateStationThreshold}
            >
              {isLoadingThresholdUpdate
                ? t('station.action.saving-setting')
                : t('station.action.save-setting')}
            </Button>
          </RowItem>
        </HeaderContainer>
        <ContentContainer>{renderStationDataThreshold()}</ContentContainer>
      </StationConfig>
    </StationConfigWrapper>
  );
};

const StationConfigWrapper = styled.div`
  width: 100%;
  height: 50%;
  overflow: hidden;
  padding: 8px 2px 2px 8px;
`;

const StationConfig = styled(Box)`
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 12px;
`;

const Header = styled.h2`
  color: #8798ad;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const RowItem = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
    text-align: right;
  }
`;

const Title = styled.div`
  color: #8d9aa9;
  font-size: 12px;
  font-weight: 400;
  height: 14px;
  line-height: 14px;
  margin-bottom: 16px;
`;

const ContentContainer = styled.div`
  padding: 24px;
  padding-top: 12px;
  overflow-x: hidden;
`;

const StationThresholdContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StationThresholdRowItem = styled.div`
  margin-bottom: 20px;
  width: calc((100% / 3) - 20px);
`;

const LoadingSpinSpinning = styled.div`
  display: block;
  height: 100%;
  left: 0;
  max-height: 400px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
`;

const LoadingSpinDot = styled.span`
  left: 50%;
  margin: -10px;
  position: absolute;
  top: 50%;
`;

export default StationDataThreshold;
