import { Toast, ToastAction, ToastActionType, ToastState } from '~/store/ducks/toast/types';

const initialState: ToastState = {
  toasts: [],
};

const mergeItem = (list: Toast[], target: Toast): Toast[] => {
  const targetIndex = list.findIndex(({ id }) => id === target.id);
  if (targetIndex > -1) {
    return list.map((item, index) => {
      return index === targetIndex ? target : item;
    });
  }
  return list.concat(target);
};

export default function reducer(state = initialState, action: ToastAction): ToastState {
  switch (action.type) {
    case ToastActionType.TOAST_MERGE_ITEM: {
      const toasts = mergeItem(state.toasts, action.payload.toast);
      return {
        ...state,
        toasts,
      };
    }

    case ToastActionType.TOAST_DELETE_ITEM: {
      const toasts = state.toasts.filter(({ id }) => id !== action.payload.toastId);
      return {
        ...state,
        toasts,
      };
    }

    default:
      return state;
  }
}
