import { I18nAction, I18nActionType, I18nState } from '~/store/ducks/i18n/types';

const initialState: I18nState = {
  lang: 'vi',
};

export default function i18nReducer(state = initialState, action: I18nAction): I18nState {
  switch (action.type) {
    case I18nActionType.SET_LANGUAGE: {
      return {
        ...state,
        lang: action.payload.lang,
      };
    }

    default: {
      return state;
    }
  }
}
