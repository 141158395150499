import { IStationThreshold } from '~/types';

export enum StationThresholdActionType {
  GET_STATION_THRESHOLD_MAPPING = 'station/GET_STATION_THRESHOLD_MAPPING',
  GET_STATION_THRESHOLD_MAPPING_SUCCESS = 'station/GET_STATION_THRESHOLD_MAPPING_SUCCESS',
  GET_STATION_THRESHOLD_MAPPING_FAIL = 'station/GET_STATION_THRESHOLD_MAPPING_FAIL',
}

export interface GetStationThresholdMapping {
  type: StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING;
}

export interface GetStationThresholdMappingSuccess {
  type: StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_SUCCESS;
  payload: {
    listStationThresholdMapping: IStationThreshold[];
  };
}

export interface GetStationThresholdMappingFail {
  type: StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_FAIL;
  payload: {
    error: Error;
  };
}

export type StationThresholdAction =
  | GetStationThresholdMapping
  | GetStationThresholdMappingSuccess
  | GetStationThresholdMappingFail;

export interface StationThresholdState {
  error?: Error;
  stationListThresholdMapping: IStationThreshold[];
}
