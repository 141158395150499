import { ThunkAction } from 'redux-thunk';
import { axios2 as axios } from '~/plugins';
import { AuthActionType } from '~/store/ducks/auth/types';
import { RootAction, RootState } from '~/store/types';
import {
  IAuthLoginResponse,
  IAuthLoginRequest,
  IAuthRefreshTokenResponse,
  IAuthChangePasswordRequest,
  IAuthGetUserInfoResponse,
} from '~/types/';
import { v4 as uuidv4 } from 'uuid';
import { createToast } from '~/store/ducks/toast/actions';

export const authLoginRequest =
  (loginData: IAuthLoginRequest): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({
        type: AuthActionType.AUTH_LOGIN_REQUEST,
        payload: loginData,
      });

      const res = await axios.post<IAuthLoginResponse>('/login', {
        user_name: loginData?.username,
        password: loginData?.password,
      });

      dispatch({
        type: AuthActionType.AUTH_LOGIN_SUCCESS,
        payload: res.data,
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'success',
          message: 'Đăng nhập thành công!',
        }),
      );
    } catch (exception) {
      dispatch({
        type: AuthActionType.AUTH_LOGIN_FAIL,
        payload: {
          error: true,
          message: 'Đăng nhập không thành công',
        },
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Đăng nhập không thành công',
        }),
      );
    }
  };

export const authRefreshToken =
  (): ThunkAction<void, RootState, undefined, RootAction> => async (dispatch) => {
    try {
      dispatch({
        type: AuthActionType.AUTH_RERESH_TOKEN_REQUEST,
      });

      const res = await axios.post<IAuthRefreshTokenResponse>('/pub/refresh-token');

      dispatch({
        type: AuthActionType.AUTH_RERESH_TOKEN_SUCCESS,
        payload: res.data,
      });
    } catch (exception) {
      dispatch({
        type: AuthActionType.AUTH_LOGIN_FAIL,
        payload: {
          error: true,
          message: 'RefreshToken không thành công',
        },
      });

      dispatch(
        createToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'RefreshToken không thành công',
        }),
      );

      dispatch(authLogout());
    }
  };

export const authSetKeepLogin =
  (keepLogin: boolean): ThunkAction<void, RootState, undefined, RootAction> =>
  (dispatch) => {
    dispatch({
      type: AuthActionType.AUTH_SET_KEEP_LOGIN,
      payload: keepLogin,
    });
  };

export const authGetUserInfo = async () => {
  const res = await axios.get<IAuthGetUserInfoResponse>(`/pub/current/user`);

  return res.data;
};

export const authChangePassword = async (params: IAuthChangePasswordRequest) => {
  const res = await axios.put(`/pub/current/password`, params);

  return res.data;
};

export const authForgotPasswordGetOtp = async (params: { email: string }) => {
  const res = await axios.post(`/forget-password`, params);

  return res.data;
};

export const authForgotPasswordVerifyOtp = async (params: { otp: string; email: string }) => {
  const res = await axios.post(`/forget-password-verify`, params);

  return res.data;
};

export const authLogout = (): ThunkAction<void, RootState, undefined, RootAction> => (dispatch) => {
  dispatch({ type: AuthActionType.AUTH_LOGOUT });
};

export const authCleanErrorInfo =
  (): ThunkAction<void, RootState, undefined, RootAction> => (dispatch) => {
    dispatch({ type: AuthActionType.AUTH_CLEAN_ERROR_INFO });
  };
