// import { Outlet } from 'react-router';
import { ProfilePageElement } from './styles/index';

import UserInformation from './components/UserInformation';

const ProfilePage = () => {
  return (
    <ProfilePageElement>
      <UserInformation />
      {/* <Outlet /> */}
    </ProfilePageElement>
  );
};

export default ProfilePage;
