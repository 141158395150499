import * as React from 'react';
import styled from 'styled-components';
import SideBar from '~/components/molecules/SideBar/index3';

export interface BasicLayoutProps {
  children: React.ReactNode;
}

const BasicLayout = ({ children }: BasicLayoutProps) => {
  return (
    <LayoutComponent>
      <SideBar />
      <LayoutContent>
        <LayoutContentMain>{children}</LayoutContentMain>
      </LayoutContent>
    </LayoutComponent>
  );
};

const LayoutComponent = styled.div`
  width: 100vw;
  height: 100vh;
  color: #455560;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
`;

const LayoutContent = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
`;

const LayoutContentMain = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px 24px 34px 24px;
`;

export default BasicLayout;
