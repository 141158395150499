const validateEmail = (email) => {
  return email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
};

const validatePassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  return password?.match(regex);
};

const validateSpecialCharecters = (str) => {
  const regex = /^[!@#$%^&*()_+\-=[]{};':"\\|,.<>\/?]*$/;
  if (!str) return false;
  if (str.match(regex)) {
    return false;
  } else return true;
};

const validateEmpty = (str) => {
  if (!str?.trim()) return false;
  else return true;
};

const validateOnlyNumber = (str) => {
  const regex = /^\d+$/;
  if (str?.match(regex)) return true;
  else return false;
};

const validateLowerCaseLetter = (str) => {
  const regex = /[a-z]/g;
  if (str?.match(regex)) return true;
  else return false;
};
const validateUpperCaseLetter = (str) => {
  const regex = /[A-Z]/g;
  if (str?.match(regex)) return true;
  else return false;
};

const validateNumberLetter = (str) => {
  const regex = /[0-9]/g;
  if (str?.match(regex)) return true;
  else return false;
};

const validateMinimumCharacters = (str) => {
  if (str?.length >= 8) return true;
  else return false;
};

export {
  validateEmail,
  validatePassword,
  validateEmpty,
  validateSpecialCharecters,
  validateOnlyNumber,
  validateLowerCaseLetter,
  validateUpperCaseLetter,
  validateNumberLetter,
  validateMinimumCharacters,
};
