import { StationConfigState } from '~/store/ducks/stationConfig/types';
import { IStationAssociation, IStationConfig } from '~/types';

export const getStationListConfig = (state: StationConfigState): IStationConfig[] => {
  return state.stationListConfig;
};

export const getStationConfigMapping = (state: StationConfigState): IStationAssociation[] => {
  return state.stationListConfigMapping;
};
