import { useCallback, useEffect, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import DefaultAvatar from '~/assets/img/default_avatar.jpg';
import { authGetUserInfo } from '~/store/actions';
import { useToastStore } from '~/store/hooks';
import { IAuthGetUserInfoResponse } from '~/types/api';
import ChangePasswordPanel from './ChangePasswordPanel';
import { useNavigate } from 'react-router';

import {
  Content,
  Header,
  Panel,
  Title,
  UserInformationPanel,
} from '../styles/UserInformationStyles';

const UserInformation = () => {
  const [showChangePasswordPanel, setShowChangePasswordPanel] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IAuthGetUserInfoResponse>();

  const { dispatchCreateToast } = useToastStore();
  const navigate = useNavigate();

  const fetchUserInfo = useCallback(async () => {
    try {
      const resp = await authGetUserInfo();

      if (resp) {
        setUserInfo(resp);
      }
    } catch (exception) {
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'failed',
        message: exception as string,
      });
    }
  }, [dispatchCreateToast]);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  const _handleShowChangePasswordPanel = (visible: boolean) => {
    setShowChangePasswordPanel(visible);
  };

  return (
    <UserInformationPanel>
      <Header>
        <Title>Thông tin người dùng</Title>
      </Header>
      <Content>
        <Panel>
          <div className='left'>
            <div className='avatar'>
              <FaUserCircle size={20} />
              <img src={DefaultAvatar} alt='default-avatar' />
            </div>
            <div className='name'>{userInfo?.real_name || 'Không xác định'}</div>
            <div className='role'>{userInfo?.role?.name || 'unknown'}</div>
          </div>
          <div className='right'>
            <div className='information'>
              <div className='title'>Thông tin người dùng</div>
              <div className='one-row'>
                <div className='left'>Tên người dùng</div>
                <div className='right'>{userInfo?.user_name || 'unknown'}</div>
              </div>
              <div className='one-row'>
                <div className='left'>Tên đầy đủ</div>
                <div className='right'>{userInfo?.real_name || 'Không xác định'}</div>
              </div>
              <div className='one-row'>
                <div className='left'>Email</div>
                <div className='right'>{userInfo?.email || 'unknown'}</div>
              </div>
              <div className='one-row'>
                <div className='left'>Điện thoại</div>
                <div className='right'>{userInfo?.phone || 'unknown'}</div>
              </div>
              <div className='one-row'>
                <div className='left'>Vai trò</div>
                <div className='right'>{userInfo?.role?.name || 'unknown'}</div>
              </div>
            </div>
            <div className='actions'>
              <div className='title'>Cập nhật thông tin</div>
              <div className='one-row'>
                <div
                  className='change-password'
                  onClick={() => {
                    _handleShowChangePasswordPanel(true);
                  }}
                >
                  Cập nhật mật khẩu?
                </div>
              </div>
              <div className='one-row'>
                <div
                  className='more-setting'
                  onClick={() => {
                    navigate(`/users/edit/${userInfo?.user_id || ''}`);
                  }}
                >
                  Cập nhật thêm thông tin khác?
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Content>
      {showChangePasswordPanel && <ChangePasswordPanel onShow={_handleShowChangePasswordPanel} />}
    </UserInformationPanel>
  );
};

export default UserInformation;
