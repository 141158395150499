import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import TextButton from '~/components/atoms/Button';
import ModalContainer from '~/components/molecules/Container/ModalContainer';
import { BrandColors } from '~/constants';
import UploadFile from '~/pages/Analytic/UploadFile';
import { useAnalyticStore } from '~/store/hooks';

interface Props {
  opened: boolean;
  width?: string;
  height?: string;
  fetchAnalyticListCallBack: () => void;
  handleClose: () => void;
}

const CustomUploadAnalyticModal = ({
  opened = false,
  width = '600px',
  height = '400px',
  fetchAnalyticListCallBack,
  handleClose,
}: Props) => {
  const { t } = useTranslation();
  const { dispatchUploadFileAnalytic } = useAnalyticStore();
  const [fileUpload, setFileUpload] = React.useState<any>([]);
  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);

  const getUploadFile = React.useCallback((file: Array<any>) => {
    setFileUpload(file);
    setDisableSubmit(false);
  }, []);

  const handleCloseModal = React.useCallback(() => {
    handleClose();
    setFileUpload([]);
    setDisableSubmit(true);
  }, [handleClose]);

  const handleCallbackFunction = React.useCallback(() => {
    handleCloseModal();
    fetchAnalyticListCallBack();
  }, [fetchAnalyticListCallBack, handleCloseModal]);

  const handleSubmit = React.useCallback(() => {
    dispatchUploadFileAnalytic(fileUpload[0], handleCallbackFunction);
  }, [dispatchUploadFileAnalytic, fileUpload, handleCallbackFunction]);

  return (
    <ModalContainer opened={opened} width={width} height={height} handleClose={handleCloseModal}>
      <Title>Upload file phân tích</Title>
      <Container>
        <Section>
          <UploadFile
            handleGetUploadFile={getUploadFile}
            noClick={true}
            noKeyboard={true}
            maxFiles={1}
            file={fileUpload}
            accept={['doc', 'docx', 'xls', 'xlsx', 'csv']}
          />
        </Section>
        <Bottom>
          <TextButton size='small' variant='outlined' onClick={handleCloseModal}>
            {t('analytic.action.cancel')}
          </TextButton>
          <TextButton
            size='small'
            variant='contained'
            color='primary'
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            Upload
          </TextButton>
        </Bottom>
      </Container>
    </ModalContainer>
  );
};

const Title = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${BrandColors.under};
  background: #e0e0e0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-bottom: 1px solid #b1b1b2;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(400px - 35px);
`;

const Section = styled.div`
  flex: 1;
  height: calc(100% - 48px);
  padding: 24px;
  padding-right: 14px;
  overflow-y: scroll;
`;

const Bottom = styled.div`
  height: 48px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #b1b1b2;
`;

export default React.memo(CustomUploadAnalyticModal);
