import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled, { css } from 'styled-components';
import {
  BackGroundColor,
  BorderColor,
  TextColor,
  InputRound,
  InputFontSize,
  Space,
} from '~/constants';

interface Props {
  id?: string;
  value: string | null;
  placeholder?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  readOnly?: boolean;
  disabled?: boolean;
  resize?: React.CSSProperties['resize'];
  bordered?: boolean;
  minRows?: number;
  maxRows?: number;
  maxLength?: number;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleClick?: (event: React.MouseEvent<HTMLTextAreaElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
}

const TextareaInput = ({
  id,
  value,
  placeholder,
  width,
  height,
  readOnly = false,
  disabled = false,
  resize = 'none',
  bordered = true,
  minRows,
  maxRows,
  maxLength,
  handleChange,
  handleClick,
  handleBlur,
}: Props): JSX.Element => {
  const isChangable = React.useMemo(() => {
    return !disabled && !readOnly;
  }, [disabled, readOnly]);

  return (
    <Container width={width} height={height}>
      <Textarea
        id={id}
        value={value ?? ''}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        resize={resize}
        minRows={minRows}
        maxRows={maxRows}
        maxLength={maxLength}
        tabIndex={1}
        $bordered={bordered}
        onChange={isChangable ? handleChange : undefined}
        onClick={isChangable ? handleClick : undefined}
        onBlur={isChangable ? handleBlur : undefined}
      />
    </Container>
  );
};

const Container = styled.div<{
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
}>`
  max-width: ${({ width }) => width ?? 'none'};
  width: 100%;
  height: ${({ height }) => (height ? '100%' : 'auto')};
`;

const Textarea = styled(TextareaAutosize)<{
  resize?: React.CSSProperties['resize'];
  $bordered?: boolean;
}>`
  width: 100%;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding: ${Space.X2S} ${Space.M};
  color: ${TextColor.Normal};
  border-color: ${BorderColor.Dark};
  border-width: 1px;
  border-style: solid;
  border-radius: ${InputRound};
  font-size: ${InputFontSize};
  line-height: 1.4;
  box-sizing: border-box;
  transition: border 0.2s;
  outline: 0;
  resize: ${({ resize }) => resize};

  ${({ $bordered }) =>
    $bordered
      ? css`
          &:hover {
            border-color: ${BorderColor.Secondary};
          }

          &:focus {
            border-color: ${BorderColor.Secondary};
          }
        `
      : css`
          border: 0;
        `}

  &[readonly] {
    pointer-events: none;
    background-color: ${BackGroundColor.Disable};
  }

  &[disabled] {
    pointer-events: none;
    color: ${TextColor.Disabled};
    background-color: ${BackGroundColor.Disable};
  }
`;

export default React.memo(TextareaInput);
