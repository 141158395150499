import { ThunkAction } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import { axios } from '~/plugins';
import { AnalyticActionType } from '~/store/ducks/analytic/types';
import { createToast } from '~/store/ducks/toast/actions';
import { RootAction, RootState } from '~/store/types';
import { IAnalytic, IAnalyticParamsRequestList, IGetAnalyticResponse } from '~/types';

export const getListAnalytic =
  (params: IAnalyticParamsRequestList): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: AnalyticActionType.GET_LIST_ANALYTIC });

      const res = await axios.get<IGetAnalyticResponse>('/analysis', { params });
      const totalPage = Math.ceil(res.data.pagination.total / res.data.pagination.pageSize);
      res.data.pagination.totalPage = totalPage;
      dispatch({
        type: AnalyticActionType.GET_LIST_ANALYTIC_SUCCESS,
        payload: { listAnalytic: res.data },
      });
    } catch (error) {
      dispatch({
        type: AnalyticActionType.GET_LIST_ANALYTIC_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const downloadFileAnalytic =
  (analytic: IAnalytic): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: AnalyticActionType.DOWNLOAD_FILE_ANALYTIC });
      const response = await axios.get('/analysis/download/' + analytic.id, {
        responseType: 'blob',
      });
      saveAs(response.data, analytic.name);
    } catch (error) {
      dispatch({
        type: AnalyticActionType.DOWNLOAD_FILE_ANALYTIC_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const uploadFileAnalytic =
  (
    fileUpload: any,
    handleCallbackFunction: () => void,
  ): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: AnalyticActionType.UPLOAD_FILE_ANALYTIC });
      const formData = new FormData();
      formData.append('file', fileUpload);
      const response = await axios.post('/analysis', formData);
      if (response.data.status === 'OK') {
        dispatch({ type: AnalyticActionType.UPLOAD_FILE_ANALYTIC_SUCCESS });
        dispatch(createToast({ id: uuidv4(), toastType: 'success', message: 'Upload thành công' }));
        handleCallbackFunction();
      }
    } catch (error) {
      dispatch({
        type: AnalyticActionType.UPLOAD_FILE_ANALYTIC_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };

export const deleteAnalytic =
  (analyticId: IAnalytic['id']): ThunkAction<void, RootState, undefined, RootAction> =>
  async (dispatch) => {
    try {
      dispatch({ type: AnalyticActionType.DELETE_ANALYTIC });
      const res = await axios.delete('/analysis', {
        params: { id: analyticId },
      });
      if (res.data.status === 'OK') {
        dispatch({ type: AnalyticActionType.DELETE_ANALYTIC_SUCCESS });
        dispatch(
          createToast({ id: uuidv4(), toastType: 'success', message: 'Xóa file thành công' }),
        );
      }
    } catch (error) {
      dispatch({
        type: AnalyticActionType.DELETE_ANALYTIC_FAIL,
        payload: {
          error: error as Error,
        },
      });
    }
  };
