import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import TextButton from '~/components/atoms/Button';
import { RootPath } from '~/configs';
import CheckBox from '../components/CheckBox';
import Input from '../components/Input';

import { validateEmpty } from '~/assets/validate';
import { createRoleRequest } from '~/store/ducks/role/actions';
import { useToastStore } from '~/store/hooks';
import {
  ActionButtonContainer,
  AddUserContainer,
  Content,
  GeneralInfo,
  Header,
  Panel,
  Permission,
  Table,
  Title,
} from '../styles/AddRoleStyles';

const AddRole = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchCreateToast } = useToastStore();

  const [roleName, setRoleName] = useState('');
  const [roleSlug, setRoleSlug] = useState('');
  const [roleNameValidState, setRoleNameValidState] = useState(true);
  const [roleSlugValidState, setRoleSlugValidState] = useState(true);
  const [listPermissions, setListPermissions] = useState({
    user: {
      read: false,
      control: false,
      export: false,
      delete: false,
    },
  } as any);

  useEffect(() => {
    setListPermissions({
      user: {
        read: false,
        control: false,
        export: false,
        delete: false,
      },
    });
  }, []);

  const handleChangeRoleName = (e: any) => {
    const value = e?.target?.value || '';
    setRoleName(value);

    if (!roleNameValidState) {
      setRoleNameValidState(true);
    }
  };

  const handleChangeRoleSlug = (e: any) => {
    const value = e?.target?.value || '';
    setRoleSlug(value);

    if (!roleNameValidState) {
      setRoleSlugValidState(true);
    }
  };

  const generalInfoInputList = [
    {
      name: 'role-name',
      title: 'Tên vai trò',
      require: true,
      value: roleName,
      onchange: handleChangeRoleName,
      validate: roleNameValidState,
    },
    {
      name: 'role-slug',
      title: 'Định danh Slug',
      require: true,
      value: roleSlug,
      onchange: handleChangeRoleSlug,
      validate: roleSlugValidState,
    },
  ];

  const handleClickCreateRole = useCallback(async () => {
    const validateRoleName = validateEmpty(roleName);
    const validateRoleSlug = validateEmpty(roleSlug);

    setRoleNameValidState(validateRoleName);
    setRoleSlugValidState(validateRoleSlug);

    if (validateRoleName && validateRoleSlug) {
      const permissions = (() => {
        const arr = [];

        for (const [key, value] of Object.entries(listPermissions?.user)) {
          if (value) {
            arr.push(`user::${key}`);
          }
        }

        return arr;
      })();

      try {
        const resp = await createRoleRequest({
          name: roleName,
          status: 1,
          slug: roleSlug,
          permissions: permissions,
        });

        if (resp) {
          dispatchCreateToast({
            id: uuidv4(),
            toastType: 'success',
            message: 'Thêm role thành công!',
          });

          navigate(RootPath.Role);
        }
      } catch (exception) {
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: exception as string,
        });
      }
    }
  }, [listPermissions, roleName, roleSlug, dispatchCreateToast, navigate]);

  const handleClickCancelBtn = useCallback(() => {
    navigate(RootPath.Role);
  }, [navigate]);

  const checkTickAll = useCallback(
    (rootRole: string) => {
      const permissions = listPermissions[rootRole];
      if (permissions?.read && permissions?.control && permissions?.export && permissions?.delete) {
        return true;
      } else return false;
    },
    [listPermissions],
  );

  const handleTick = useCallback(
    (e: any) => {
      if (!e) return;
      const checked = e.target.checked;
      const slug = e.target.value;
      if (slug === 'user') {
        listPermissions.user = {
          read: checked,
          control: checked,
          export: checked,
          delete: checked,
        };
        setListPermissions({ ...listPermissions });
      } else if (slug?.includes('user')) {
        listPermissions.user[slug.replace('user::', '')] = checked;
        setListPermissions({ ...listPermissions });
      }
    },
    [listPermissions],
  );

  const rowData = useMemo(() => {
    return [
      {
        title: 'User',
        value: 'user',
        checked: checkTickAll('user'),
        onChange: handleTick,
      },
      {
        title: undefined,
        value: 'user::read',
        checked: listPermissions?.user?.read,
        onChange: handleTick,
      },
      {
        title: undefined,
        value: 'user::control',
        checked: listPermissions?.user?.control,
        onChange: handleTick,
      },
      {
        title: undefined,
        value: 'user::export',
        checked: listPermissions?.user?.export,
        onChange: handleTick,
      },
      {
        title: undefined,
        value: 'user::delete',
        checked: listPermissions?.user?.delete,
        onChange: handleTick,
      },
    ];
  }, [listPermissions, checkTickAll, handleTick]);

  return (
    <AddUserContainer>
      <Header>
        <Title>{t('role.title.add_role')}</Title>
      </Header>
      <Content>
        <Panel>
          <GeneralInfo>
            {generalInfoInputList?.map((one, index) => (
              <Input
                title={one.title}
                key={index}
                name={one.name}
                require={one.require}
                value={one.value}
                onChange={one.onchange}
                validate={one.validate}
              />
            ))}
          </GeneralInfo>
          <Permission>
            <Table>
              <thead>
                <tr>
                  <th>Quyền</th>
                  <th>Xem dữ liệu</th>
                  <th>Điều khiển</th>
                  <th>Xuất dữ liệu</th>
                  <th>Xoá</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {rowData?.map((one, index) => (
                    <td key={index}>
                      <div className='checkbox'>
                        <CheckBox
                          title={one.title}
                          value={one.value}
                          checked={one.checked}
                          onChange={one.onChange}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </Permission>
        </Panel>
        <ActionButtonContainer>
          <TextButton
            color='primary'
            variant='contained'
            onClick={() => {
              handleClickCreateRole();
            }}
          >
            {t('role.action.add_role')}
          </TextButton>
          <TextButton
            color='inherit'
            variant='contained'
            onClick={() => {
              handleClickCancelBtn();
            }}
          >
            {t('role.action.cancel')}
          </TextButton>
        </ActionButtonContainer>
      </Content>
    </AddUserContainer>
  );
};

export default AddRole;
