import { SyntheticEvent } from 'react';

export function onPromiseHookForm<T>(promise: (event: SyntheticEvent) => Promise<T>) {
  return (event: SyntheticEvent): void => {
    if (promise) {
      promise(event).catch((error) => {
        console.warn('Unexpected error', error);
      });
    }
  };
}
