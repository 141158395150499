import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { loadingStateReducer } from 'react-redux-loading-hook';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PersistConfig,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import middleware from '~/store/ducks/rootStore/middleware';
import * as reducers from '~/store/reducers';
import { RootState } from '~/store/types';

const rootReducer = combineReducers({
  ...reducers,
  loadingState: loadingStateReducer,
});

const persistConfig: PersistConfig<RootState> = {
  key: 'Stations Platform',
  version: 1,
  storage,
  whitelist: ['auth', 'i18nLanguage'],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { ignoredActions },
    }).concat(...middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
