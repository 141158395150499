import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearListStationThreshold,
  getListStationThresholdMapping,
} from '~/store/ducks/stationThreshold/actions';
import { RootState } from '~/store/types';

interface FuncType {
  stationThresholdState: RootState['stationThreshold'];
  dispatchGetListStationThresholdMapping: (stationIdd: string) => Promise<void>;
  dispatchClearListStationThreshold: () => void;
}

const useStationThresholdStore = (): FuncType => {
  const dispatch = useDispatch();

  const stationThresholdState = useSelector<RootState, RootState['stationThreshold']>((state) => {
    return state.stationThreshold;
  });

  const dispatchGetListStationThresholdMapping = React.useCallback(
    async (stationId: string) => {
      await dispatch(getListStationThresholdMapping(stationId));
    },
    [dispatch],
  );

  const dispatchClearListStationThreshold = React.useCallback(() => {
    dispatch(clearListStationThreshold());
  }, [dispatch]);

  return {
    stationThresholdState,
    dispatchGetListStationThresholdMapping,
    dispatchClearListStationThreshold,
  };
};

export default useStationThresholdStore;
