import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import copy from 'clipboard-copy';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiCopy, FiSave } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import DateInput from '~/components/atoms/Input/DateInput';
import SelectInput, { IFormOption } from '~/components/atoms/Input/SelectInput';
import TextareaInput from '~/components/atoms/Input/TextareaInput';

import Button from '~/components/atoms/Button';
import InputField from '~/components/atoms/FormFields/InputField';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import { useLoading } from '~/hooks';
import { moment as dayjs } from '~/plugins';
import { useStationStore, useToastStore } from '~/store/hooks';
import { getStationDetail } from '~/store/selectors';
import { IStationDetail } from '~/types';
import { onPromiseHookForm, stationUpdateLoadingModule } from '~/utils';

const StationInfoComponent = () => {
  const { t } = useTranslation();
  const { stationId } = useParams();
  const isLoadingStationUpdate = useLoading(stationUpdateLoadingModule);
  const { stationState, dispatchGetStationDetail, dispatchUpdateStation } = useStationStore();
  const stationDetail = getStationDetail(stationState);
  const { dispatchCreateToast } = useToastStore();

  const initialValues: IStationDetail = {
    id: '',
    email: '',
    name: '',
    lon: 0,
    lat: 0,
    address: '',
    token: '',
    sensor_available: [],
    actived_at: '',
    description: '',
    owner: '',
    share_location: '',
    manager: '',
    security: '',
    status: true,
    created_at: dayjs().toString(),
    updated_at: '',
    warning: -1,
  };

  const schema = yup.object().shape({
    name: yup.string().required('Tên trạm không được để trống'),
    address: yup.string().required('Vị trí không được để trống'),
    owner: yup.string().required('Chủ sở hữu không được để trống'),
    share_location: yup.string().required('Đơn vị đặt trạm không được để trống'),
    manager: yup.string().required('Đơn vị vận hành không được để trống'),
    security: yup.string().required('Đơn vị trông coi không được để trống'),
    lon: yup
      .number()
      .min(0, 'Kinh độ phải lớn hơn bằng 0')
      .typeError('Kinh độ phải lớn hơn bằng 0')
      .required('Kinh độ không được để trống'),
    lat: yup
      .number()
      .min(0, 'Vĩ độ phải lớn hơn bằng 0')
      .typeError('Vĩ độ phải lớn hơn bằng 0')
      .required('Vĩ độ không được để trống'),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const startIcon = React.useMemo(() => {
    if (isLoadingStationUpdate) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
    return <FiSave />;
  }, [isLoadingStationUpdate]);

  const stationStatusOptions = React.useMemo<IFormOption[]>(() => {
    return [
      { label: 'Active', value: true },
      { label: 'InActive', value: false },
    ];
  }, []);

  const onSubmit = methods.handleSubmit(
    React.useCallback(
      (formData: IStationDetail) => {
        const params = { ...formData };
        params.lat = Number(params.lat);
        params.lon = Number(params.lon);
        dispatchUpdateStation(params);
      },
      [dispatchUpdateStation],
    ),
  );

  const fetchStationDetail = React.useCallback(async () => {
    if (stationId) {
      await dispatchGetStationDetail(stationId);
    }
  }, [dispatchGetStationDetail, stationId]);

  const handleCopy = React.useCallback(
    (value: string, name: string) => {
      copy(value);
      dispatchCreateToast({
        id: uuidv4(),
        toastType: 'success',
        message: `Đã lưu ${name} vào clipboard!`,
      });
    },
    [dispatchCreateToast],
  );

  React.useEffect(() => {
    if (stationDetail) {
      methods.reset(stationDetail);
    }
  }, [methods, stationDetail]);

  React.useEffect(() => {
    fetchStationDetail();
  }, [fetchStationDetail]);

  return (
    <StationInfoWrapper>
      <StationInfo component='form' onSubmit={onPromiseHookForm(onSubmit)}>
        <HeaderContainer>
          <Header>{t('info')}</Header>
          <Button variant='contained' type='submit' startIcon={startIcon}>
            {isLoadingStationUpdate
              ? t('station.action.saving-info')
              : t('station.action.save-info')}
          </Button>
        </HeaderContainer>

        <ListItem>
          <RowItem>
            <Title>ID</Title>
            <InputField
              disabled
              name='id'
              control={methods.control}
              variant='outlined'
              size='small'
              InputProps={{
                endAdornment: (
                  <Tooltip title='Copy' sx={{ cursor: 'pointer' }}>
                    <InputAdornment position='start'>
                      <FiCopy onClick={() => handleCopy(methods.getValues('id'), 'ID')} />
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </RowItem>

          <RowItem>
            <Title>{t('station.name')}</Title>
            <InputField name='name' control={methods.control} variant='outlined' size='small' />
          </RowItem>

          <RowItem>
            <Title>{t('station.date')}</Title>
            <Controller
              control={methods.control}
              name='created_at'
              render={({ field: { onChange, value } }) => {
                return (
                  <DateInput
                    placeholder={t('station.date')}
                    showTimeSelect={true}
                    value={value}
                    handleChange={onChange as any}
                  />
                );
              }}
            />
          </RowItem>

          <RowItem>
            <Title>{t('station.location')}</Title>
            <InputField name='address' control={methods.control} variant='outlined' size='small' />
          </RowItem>

          <RowItem>
            <Title>{t('station.token')}</Title>
            <InputField
              name='token'
              control={methods.control}
              variant='outlined'
              size='small'
              disabled
              InputProps={{
                endAdornment: (
                  <Tooltip title='Copy' sx={{ cursor: 'pointer' }}>
                    <InputAdornment position='start'>
                      <FiCopy onClick={() => handleCopy(methods.getValues('token'), 'Token')} />
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </RowItem>

          <RowItem>
            <Title>{t('station.owner')}</Title>
            <InputField name='owner' control={methods.control} variant='outlined' size='small' />
          </RowItem>

          <RowItem>
            <Title>{t('station.share_location')}</Title>
            <InputField
              name='share_location'
              control={methods.control}
              variant='outlined'
              size='small'
            />
          </RowItem>

          <RowItem>
            <Title>{t('station.manager')}</Title>
            <InputField name='manager' control={methods.control} variant='outlined' size='small' />
          </RowItem>

          <RowItem>
            <Title>{t('station.security')}</Title>
            <InputField name='security' control={methods.control} variant='outlined' size='small' />
          </RowItem>

          <RowItem>
            <Title>{t('station.status')}</Title>
            <Controller
              control={methods.control}
              name='status'
              render={({ field: { onChange, value } }) => {
                const defaultValue =
                  stationStatusOptions.find((item) => item.value === value) || null;
                return (
                  <SelectInput
                    placeholder={t('station.status')}
                    placeholderFontSize='16px'
                    selected={defaultValue}
                    options={stationStatusOptions}
                    handleChange={(option: IFormOption) => onChange(option.value)}
                  />
                );
              }}
            />
          </RowItem>

          <RowItem>
            <Title>{t('station.longitude')}</Title>
            <InputField
              name='lon'
              type='number'
              control={methods.control}
              variant='outlined'
              size='small'
            />
          </RowItem>

          <RowItem>
            <Title>{t('station.latitude')}</Title>
            <InputField
              name='lat'
              type='number'
              control={methods.control}
              variant='outlined'
              size='small'
            />
          </RowItem>

          <RowItem>
            <Title>{t('station.note')}</Title>
            <Controller
              control={methods.control}
              name='description'
              render={({ field: { onChange, value } }) => {
                return (
                  <TextareaInput
                    value={value}
                    placeholder={t('station.note')}
                    handleChange={onChange}
                  />
                );
              }}
            />
          </RowItem>
        </ListItem>
      </StationInfo>
    </StationInfoWrapper>
  );
};

const StationInfoWrapper = styled.div`
  width: 450px;
  height: 100%;
  padding: 2px 8px 2px 2px;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

const StationInfo = styled(Box)`
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

const Header = styled.h2`
  color: #8798ad;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

const ListItem = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  padding: 24px;
  padding-top: 0;
`;

const RowItem = styled.div`
  padding: 8px 0;
  &:last-child {
    text-align: right;
    margin-bottom: 0;
  }
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8d9aa9;
  margin-bottom: 16px;
  text-align: start;
`;

export default StationInfoComponent;
