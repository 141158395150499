import { UserState, UserAction, UserActionType } from './types';

const initState: UserState = {
  listUser: {
    list: [],
    pagination: undefined,
  },
};

export default function UserReducer(state = initState, action: UserAction): UserState {
  switch (action.type) {
    case UserActionType.GET_LIST_USER_SUCCESS: {
      return {
        ...state,
        listUser: action.payload,
      };
    }

    case UserActionType.DELETE_USER_SUCCESS: {
      const listUser = { ...state.listUser };
      listUser.list = listUser.list?.filter((one) => one.id !== action.payload);

      return {
        ...state,
        listUser,
      };
    }

    default: {
      return state;
    }
  }
}
