import React, { memo } from 'react';

import {
  validateLowerCaseLetter,
  validateUpperCaseLetter,
  validateNumberLetter,
  validateMinimumCharacters,
} from '~/assets/validate';

import { TiTick, TiTimes } from 'react-icons/ti';

export interface ExtendValidateProps {
  value: string;
  type: 'password' | 'email';
  children?: React.ReactNode | React.ReactNode[];
}

const ExtendValidate = (props: ExtendValidateProps) => {
  const { value, type } = props;

  const _handleValidatePassword = (password: string) => {
    let arr = [];
    const checkLowerCase = validateLowerCaseLetter(password);
    const checkCapital = validateUpperCaseLetter(password);
    const checkNumber = validateNumberLetter(password);
    const checkMinimumCharacters = validateMinimumCharacters(password);

    arr = [
      {
        status: checkLowerCase,
        require: 'A lowercase letter',
      },
      {
        status: checkCapital,
        require: 'A capital (uppercase) letter',
      },
      {
        status: checkNumber,
        require: 'A number',
      },
      {
        status: checkMinimumCharacters,
        require: 'Minimum 8 characters',
      },
    ];

    return arr;
  };

  return (
    <>
      {type === 'password' &&
        _handleValidatePassword(value).map((one, index) => (
          <span key={index}>
            <>
              {one.status ? <TiTick size='16px' /> : <TiTimes size='16px' />}
              {one.require}
            </>
          </span>
        ))}
    </>
  );
};

export default memo(ExtendValidate);
