import { useState, useCallback, useEffect, useMemo } from 'react';
import { TiTimes } from 'react-icons/ti';
import styled from 'styled-components';
import Statistics from './Statistics';
import Data from './Data';
import ChartInfo from './ChartInfo';
import Report from './Report';
import DisplayModeSelector from './DisplayModeSelector';
import { useStationStore, useSampleInfoStore } from '~/store/hooks';
import { getStationDetail } from '~/store/selectors';
import { moment } from '~/plugins';
import { getSampleInfoList } from '~/store/selectors';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';

export interface StationInformationPanelPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onClose: () => void;
  stationId: string;
}

const StationInformationPanel = (props: StationInformationPanelPropsType) => {
  const { onClose, stationId } = props;

  const [displayMode, setDisplayMode] = useState('statistics');
  const { stationState, dispatchGetStationDetail } = useStationStore();
  const stationDetail = getStationDetail(stationState);
  const { sampleInfoState, dispatchListGetSampleInfo } = useSampleInfoStore();
  const sampleInfoList = getSampleInfoList(sampleInfoState);
  const [listSensorAvailables, setListSensorAvailables] = useState<any[]>([]);
  const [timeUpdated, setTimeUpdated] = useState<any>(undefined);
  const [timeStart, setTimeStart] = useState(moment().subtract(25, 'hour').format());
  const [timeEnd, setTimeEnd] = useState(moment().format());

  const fetchStationDetail = useCallback(async () => {
    if (stationId) {
      await dispatchGetStationDetail(stationId);
    }
  }, [dispatchGetStationDetail, stationId]);

  const handleTimeStartChange = useCallback((date: Date | null) => {
    setTimeStart(moment(date).format());
  }, []);

  const handleTimeEndChange = useCallback((date: Date | null) => {
    setTimeEnd(moment(date).format());
  }, []);

  const handleTimeUpdateChange = useCallback((date: Date | null) => {
    setTimeUpdated(moment(date).format('YYYY-MM-DD HH:mm:ss'));
  }, []);

  const isLoading = useMemo(() => {
    if (stationDetail?.name) return false;

    return true;
  }, [stationDetail]);

  useEffect(() => {
    fetchStationDetail();
  }, [fetchStationDetail, stationId]);

  const fetchSampleInfoList = useCallback(async () => {
    if (stationId) {
      await dispatchListGetSampleInfo(stationId);
    }
  }, [dispatchListGetSampleInfo, stationId]);

  useEffect(() => {
    fetchSampleInfoList();
  }, [fetchSampleInfoList, stationId]);

  useEffect(() => {
    // Lấy ra được list id sensorAvailables
    const listIdSensorAvailables = stationDetail?.sensor_available || [];

    const listSensorAvailables_tmp = sampleInfoList.filter((one) => {
      return listIdSensorAvailables.includes(one.id);
    });

    setListSensorAvailables(listSensorAvailables_tmp);
  }, [sampleInfoList, stationDetail]);

  const Content = useMemo(() => {
    switch (displayMode) {
      case 'statistics': {
        return (
          <Statistics
            listSensorAvailables={listSensorAvailables}
            stationId={stationId}
            onUpdateTime={handleTimeUpdateChange}
          />
        );
      }
      case 'data': {
        return (
          <Data
            listSensorAvailables={listSensorAvailables}
            stationId={stationId}
            onTimeStartChange={handleTimeStartChange}
            onTimeEndChange={handleTimeEndChange}
            timeStart={timeStart}
            timeEnd={timeEnd}
          />
        );
      }
      case 'chart': {
        return (
          <ChartInfo
            listSensorAvailables={listSensorAvailables}
            stationId={stationId}
            onTimeStartChange={handleTimeStartChange}
            onTimeEndChange={handleTimeEndChange}
            timeStart={timeStart}
            timeEnd={timeEnd}
          />
        );
      }
      case 'report': {
        return <Report stationId={stationId} listSensorAvailables={listSensorAvailables} />;
      }
      default: {
        return (
          <Statistics
            listSensorAvailables={listSensorAvailables}
            stationId={stationId}
            onUpdateTime={handleTimeUpdateChange}
          />
        );
      }
    }
  }, [
    displayMode,
    handleTimeEndChange,
    handleTimeStartChange,
    handleTimeUpdateChange,
    listSensorAvailables,
    stationId,
    timeEnd,
    timeStart,
  ]);

  return (
    <StationInformationPanelElement>
      <ContentElement>
        {isLoading ? (
          <LoadingSpinSpinning>
            <LoadingSpinDot>
              <CircularProgressLoading size={28} color='#666FE8' />
              Đang tải dữ liệu trạm
            </LoadingSpinDot>
          </LoadingSpinSpinning>
        ) : (
          <>
            <div className='header'>
              <div className='top'>
                <div className='left'>
                  <div className='title'>{stationDetail?.name}</div>
                  {timeUpdated && <div className='time-update'>{timeUpdated}</div>}
                </div>
                <div className='right'>
                  <div className='close-btn' onClick={onClose}>
                    <TiTimes size='26px' />
                  </div>
                </div>
              </div>
              <div className='description'>{stationDetail?.address}</div>
            </div>
            <div className='body'>
              <DisplayModeSelector onChange={setDisplayMode} value={displayMode} />
              {Content}
            </div>
            <div className='footer'></div>
          </>
        )}
      </ContentElement>
    </StationInformationPanelElement>
  );
};

export default StationInformationPanel;

const StationInformationPanelElement = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: changeTransition 0.5s ease alternate forwards;
  box-sizing: border-box;

  @keyframes flyUp {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes changeTransition {
    0% {
      background: transparent;
    }
    100% {
      background: rgba(0, 0, 0, 0.6);
    }
  }
`;

const ContentElement = styled.div`
  background: white;
  color: rgb(49, 49, 49);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 600px;
  min-height: 450px;
  width: 75%;
  height: 90%;
  animation: flyUp 0.5s ease alternate forwards;
  position: relative;
  box-sizing: border-box;

  & > .header {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;

    .top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;

        .title {
          font-size: 20px;
          font-weight: 600;
          padding-right: 8px;
          color: #313131;
        }

        .time-update {
          padding: 6px;
          background: #666fe8;
          color: white;
          font-size: 13px;
          font-weight: 600;
          border-radius: 10px;
        }
      }

      .close-btn {
        cursor: pointer;
      }
    }

    .description {
      font-family: sans-serif;
      text-align: start;
      padding: 5px 0;
    }
  }

  & > .body {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    flex-direction: column;
  }

  & > .footer {
    padding: 10px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;

    & > *:first-child {
      margin-right: 10px;
    }
  }
`;

const LoadingSpinSpinning = styled.div`
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinDot = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666fe8;
  font-weight: 500;
  font-size: 17px;

  & > *:first-child {
    margin-right: 8px;
  }
`;
