import styled from 'styled-components';

const AddUserContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-family: 'Roboto', sans-serif;
  position: relative;
  flex-direction: column;

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 8px;
`;

const Header = styled.div`
  margin-bottom: 24px;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
`;

const Panel = styled.div`
  width: 100%;
  height: 70%;
  background: #ffffff;
  border: 1px solid rgba(102, 111, 232, 0.08);
  box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
  border-radius: 3px;
  padding: 0 16px;
`;

const GeneralInfo = styled.div`
  display: flex;

  & > * {
    margin: 0 16px;
  }
`;

const Permission = styled.div`
  margin-top: 40px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  .MuiButton-root ~ .MuiButton-root {
    margin-left: 16px;
  }
`;

const Table = styled.table`
  width: 100%;
  height: fit-content;
  margin-top: 10px;

  tr {
    & > th {
      padding: 8px;
    }

    & > td {
      padding: 8px;

      .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export {
  AddUserContainer,
  Title,
  Header,
  Content,
  Panel,
  GeneralInfo,
  Permission,
  ActionButtonContainer,
  Table,
};
