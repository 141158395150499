import { StationState } from '~/store/ducks/station/types';
import {
  IStationChartDataDetail,
  IStationAssociation,
  IStationDetail,
  IStationThreshold,
} from '~/types';

export const getStationDetail = (state: StationState): IStationDetail | null => {
  return state.stationDetail;
};

export const getSensorAvailable = (state: StationState): IStationDetail['sensor_available'] => {
  if (state.stationDetail === null) return [];
  return state.stationDetail.sensor_available || [];
};

export const getListChartDetail = (state: StationState): IStationChartDataDetail | null => {
  return state.listChartDetail;
};

export const getStationConfigData = (state: StationState): IStationAssociation[] => {
  return state.listConfigData;
};

export const getStationThreshold = (state: StationState): IStationThreshold[] => {
  return state.listThresholdData;
};

export const getStationCreateId = (state: StationState): string => {
  return state.stationCreate;
};
