/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { TiPlusOutline, TiMinusOutline, TiPlus } from 'react-icons/ti';
import '../styles/AddMenu.scss';

export interface Map {
  key: any;
  value: any;
  id?: any;
}

export interface SelectMenuProps {
  data?: Map[];
  listSelected: Map[];
  onSelected?: any;
  width?: string | number;
  height?: string | number;
  children?: React.ReactNode | React.ReactNode[];
}

export interface OneItemProps {
  index: string;
  value: any;
  onSelect: any;
  children?: React.ReactNode | React.ReactNode[];
}

const OneItem = (props: OneItemProps) => {
  const { index, value, onSelect } = props;

  const handleClick = () => {
    if (onSelect) {
      onSelect({ key: index, value });
    }
  };

  return (
    <div className='one-item' id={`one-item-${index}`} onClick={handleClick}>
      <span>{value}</span>
      <TiPlus size='16px' color='#667080' />
    </div>
  );
};

export default function AddMenu(props: SelectMenuProps) {
  const { data, listSelected, onSelected } = props;
  const [dropdownState, setDropDownState] = useState(false);
  const selectMenuRef = useRef<HTMLElement>(null);

  const listNonSelected = useMemo(() => {
    if (listSelected && data) {
      return data.filter((one) => !listSelected?.find((two) => one.key == two.key));
    }
  }, [listSelected, data]);

  const closeDropDown = useCallback(
    (e: any) => {
      if (dropdownState && !selectMenuRef?.current?.contains(e.target)) {
        setDropDownState(false);
      }
    },
    [dropdownState, setDropDownState],
  );

  useEffect(() => {
    if (dropdownState) {
      document.addEventListener('click', closeDropDown);
    }

    return () => {
      document.removeEventListener('click', closeDropDown);
    };
  }, [dropdownState, closeDropDown]);

  const handleDropDown = useCallback((state: boolean) => {
    console.log('toggleDropDown', state);
    setDropDownState(state);
  }, []);

  const onSelect = useCallback((selected: any) => {
    onSelected(selected?.key);
    handleDropDown(false);
  }, []);

  const toggleDropDown = useCallback(() => {
    handleDropDown(!dropdownState);
  }, [dropdownState, handleDropDown]);

  return (
    <div
      className={`add-menu ${dropdownState ? 'active' : 'inactive'}`}
      ref={selectMenuRef as RefObject<HTMLDivElement>}
    >
      <div className='label' onClick={toggleDropDown}>
        <div className='selected-item'>Thêm trạm</div>
        <div className='icon'>
          <TiMinusOutline size='22px' color='#667080' display={dropdownState ? 'block' : 'none'} />
          <TiPlusOutline size='22px' color='#667080' display={dropdownState ? 'none' : 'block'} />
        </div>
      </div>
      <div className={`list-item ${dropdownState ? 'visible' : 'hidden'}`}>
        {listNonSelected?.map((one) => (
          <OneItem key={one.key} index={one.key} value={one.value} onSelect={onSelect} />
        ))}
      </div>
    </div>
  );
}
