import { IUserInfo } from '~/types/';

export enum UserActionType {
  GET_LIST_USER_REQUEST = 'user/GET_LIST_USER_REQUEST',
  GET_LIST_USER_SUCCESS = 'user/GET_LIST_USER_SUCCESS',
  GET_LIST_USER_FAIL = 'user/GET_LIST_USER_FAIL',
  GET_USER_BY_ID = 'user/GET_USER_BY_ID',
  CREATE_USER_REQUEST = 'user/CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'user/CREATE_USER_SUCCESS',
  CREATE_USER_FAIL = 'user/CREATE_USER_FAIL',
  DELETE_USER_REQUEST = 'user/DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'user/DELETE_USER_SUCCESS',
  DELETE_USER_FAIL = 'user/DELETE_USER_FAIL',
  UPDATE_USER_REQUEST = 'user/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL = 'user/UPDATE_USER_FAIL',
}

export type UserId = string;

export interface ListUser {
  list?: IUserInfo[];
  pagination?: {
    total?: number;
    current?: number;
    pageSize?: number;
  };
}

export interface GetListUserRequest {
  type: UserActionType.GET_LIST_USER_REQUEST;
}

export interface GetListUserSuccess {
  type: UserActionType.GET_LIST_USER_SUCCESS;
  payload: ListUser;
}

export interface GetListUserFail {
  type: UserActionType.GET_LIST_USER_FAIL;
  payload: {
    error: boolean;
    message: string;
  };
}

export interface CreateUserRequest {
  type: UserActionType.CREATE_USER_REQUEST;
  payload: IUserInfo;
}

export interface CreateUserSuccess {
  type: UserActionType.CREATE_USER_SUCCESS;
  payload: IUserInfo;
}

export interface CreateUserFail {
  type: UserActionType.CREATE_USER_FAIL;
  payload: {
    error: boolean;
    message: string;
  };
}

export interface DeleteUserRequest {
  type: UserActionType.DELETE_USER_REQUEST;
  payload: UserId;
}

export interface DeleteUserSuccess {
  type: UserActionType.DELETE_USER_SUCCESS;
  payload: UserId;
}

export interface DeleteUserFail {
  type: UserActionType.DELETE_USER_FAIL;
  payload: {
    error: boolean;
    message: string;
  };
}

export interface UserState {
  listUser: ListUser;
}

export type UserAction =
  | GetListUserRequest
  | GetListUserSuccess
  | GetListUserFail
  | CreateUserRequest
  | CreateUserSuccess
  | CreateUserFail
  | DeleteUserRequest
  | DeleteUserSuccess
  | DeleteUserFail;
