import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import StationMap from '~/components/organism/StationMap';
import { useStationStore } from '~/store/hooks';
import useStationTable from '~/pages/Station/StationList/constants';
import { locationDefault } from '~/constants';
import StationInformationPanel from '~/components/organism/StationInformationPanel';

export interface StationMapPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const StationMapComponent = (props: StationMapPropsType) => {
  const { onConfirmDelete } = props;
  const { stationState, dispatchGetAllListStation } = useStationStore();
  const { handleChangeDetailPanel, infoStationPanel } = useStationTable({
    onConfirmDelete,
  });

  const fetchAllStationItemsList = useCallback(async () => {
    await dispatchGetAllListStation();
  }, [dispatchGetAllListStation]);

  useEffect(() => {
    fetchAllStationItemsList();
  }, [fetchAllStationItemsList]);

  console.log('latlong', stationState.listAllStation.more_info);

  return (
    <MapContainer>
      <StationMap
        station={stationState.listAllStation.list}
        locationStation={stationState.listAllStation.more_info || locationDefault}
        onChangeDetailPanel={handleChangeDetailPanel}
      />
      {infoStationPanel.status && infoStationPanel.stationId && (
        <StationInformationPanel
          stationId={infoStationPanel.stationId}
          onClose={() => handleChangeDetailPanel(false, '')}
        />
      )}
    </MapContainer>
  );
};

export default StationMapComponent;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  background: #f2f2f2;
  border-radius: 8px;
  overflow: hidden;
`;
