import * as ReactTable from '@tanstack/react-table';
import * as React from 'react';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import MuiTable, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';
import Empty from '~/components/atoms/Empty';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';

interface TableConfigProps extends TableProps {
  data: any[];
  columns: ReactTable.ColumnDef<any>[];
  isFetching?: boolean;
  headerComponent?: JSX.Element;
  totalPage: number;
  currentPage?: number;
  handleChangePage: (page: number) => void;
  onClickRow?: (cell: ReactTable.Cell<any, unknown>, row: ReactTable.Row<any>) => void;
  getSelectedRow?: (rows: ReactTable.Row<any>[]) => void;
}

const DataTable = (
  {
    data,
    columns,
    isFetching,
    headerComponent,
    totalPage,
    currentPage,
    onClickRow,
    handleChangePage,
    getSelectedRow,
  }: TableConfigProps,
  ref: any,
): JSX.Element => {
  const [paginationPage, setPaginationPage] = React.useState<number>(1);

  React.useMemo(() => {
    currentPage && setPaginationPage(currentPage);
  }, [currentPage]);

  const memoizedData = React.useMemo(() => data, [data]);

  const memoizedColumns = React.useMemo(() => columns, [columns]);

  const memoisedHeaderComponent = React.useMemo<JSX.Element | undefined>(
    () => headerComponent,
    [headerComponent],
  );

  const { getHeaderGroups, getRowModel, getSelectedRowModel, toggleAllRowsSelected } =
    ReactTable.useReactTable({
      data: memoizedData,
      columns: memoizedColumns,
      getCoreRowModel: ReactTable.getCoreRowModel(),
      manualPagination: true,
      pageCount: totalPage,
    });

  const selectedRowData = getSelectedRowModel().flatRows.map((row) => row.original);

  React.useEffect(() => {
    if (getSelectedRow) {
      getSelectedRow(selectedRowData);
    }
  }, [selectedRowData, getSelectedRow]);

  const isNoData = React.useMemo<boolean | undefined>(() => {
    return !memoizedData || memoizedData.length === 0;
  }, [memoizedData]);

  const handlePageChange = React.useCallback(
    (_event: React.ChangeEvent<unknown>, currentPage: number) => {
      setPaginationPage(currentPage === 0 ? 1 : currentPage);
      handleChangePage(currentPage === 0 ? 1 : currentPage);
    },
    [handleChangePage],
  );

  const renderTableHead = React.useCallback((): JSX.Element[] => {
    return getHeaderGroups().map((headerGroup) => (
      <TableRow key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <TableCell key={header.id}>
            {header.isPlaceholder
              ? null
              : ReactTable.flexRender(header.column.columnDef.header, header.getContext())}
          </TableCell>
        ))}
      </TableRow>
    ));
  }, [getHeaderGroups]);

  const renderNodata = React.useCallback(() => {
    return (
      <StyledTableBody>
        <StyledTableRow>
          <TableCell colSpan={memoizedColumns.length}>
            <Box my={4} textAlign='center'>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Box>
          </TableCell>
        </StyledTableRow>
      </StyledTableBody>
    );
  }, [memoizedColumns.length]);

  const renderTableBody = React.useCallback((): JSX.Element => {
    if (!isNoData) {
      return (
        <StyledTableBody>
          {getRowModel().rows.map((row) => (
            <StyledTableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} onClick={() => onClickRow?.(cell, row)}>
                  {ReactTable.flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </StyledTableRow>
          ))}
        </StyledTableBody>
      );
    }
    return renderNodata();
  }, [getRowModel, isNoData, onClickRow, renderNodata]);

  const renderPagination = React.useCallback(() => {
    if (!isNoData) {
      return (
        <StyledPagination
          count={totalPage}
          page={paginationPage}
          onChange={handlePageChange}
          color='primary'
          $isFetching={isFetching}
        />
      );
    }
  }, [handlePageChange, isFetching, isNoData, paginationPage, totalPage]);

  React.useImperativeHandle(ref, () => ({ toggleAllRowsSelected }), [toggleAllRowsSelected]);

  return (
    <Paper elevation={0}>
      {memoisedHeaderComponent && memoisedHeaderComponent}
      <Box style={{ overflowX: 'auto', position: 'relative' }}>
        {isFetching && (
          <LoadingSpinSpinning>
            <LoadingSpinDot>
              <CircularProgressLoading color='#666FE8' size={28} />
            </LoadingSpinDot>
          </LoadingSpinSpinning>
        )}

        <StyledMuiTable $isFetching={isFetching}>
          <TableHead>{renderTableHead()}</TableHead>
          {renderTableBody()}
        </StyledMuiTable>
      </Box>
      {renderPagination()}
    </Paper>
  );
};

const LoadingSpinSpinning = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
`;

const LoadingSpinDot = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
`;

const StyledMuiTable = styled(MuiTable)<{ $isFetching: boolean | undefined }>`
  opacity: ${(props) => (props.$isFetching ? 0.5 : 1)};

  .MuiTableHead-root {
    background: #fafafa;
    color: #8798ad;
    font-size: 16px;
  }

  .MuiTableCell-head {
    font-size: 16px;
    padding: 15px 10px;
    color: #8798ad;
    text-align: center;
    text-transform: uppercase;
    line-height: unset;
    border-bottom: none;

    &:first-child {
      // padding-left: 24px;
      text-align: left;
    }

    &:last-child {
      // padding-right: 24px;
    }
  }

  .MuiTableCell-body {
    font-size: 16px;
    color: #8798ad;
    // text-transform: capitalize;
    padding: 15px 10px;
    text-align: center;
    border-bottom: 1px solid #eff2ff;

    &:first-child {
      color: #2e384d;
      text-align: left;
    }
  }
`;

const StyledTableBody = styled(TableBody)`
  position: relative;
`;

const StyledTableRow = styled(TableRow)`
  :hover {
    background-color: #f5f6fd;
  }
`;

const StyledPagination = styled(Pagination)<{ $isFetching: boolean | undefined }>`
  margin-top: 26px;
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.$isFetching ? 0.5 : 1)};

  .MuiPaginationItem-root {
    color: #8798ad;
    font-size: 16px;
  }

  .Mui-selected,
  .Mui-selected:hover {
    background: #eff2ff;
    color: #8798ad;
  }
`;

export default React.forwardRef(DataTable);
