import vi from 'date-fns/locale/vi';
import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdEvent as EventIcon } from 'react-icons/md';
import styled from 'styled-components';
import { BackGroundColor, GrayColors, TextColor, BorderColor } from '~/constants';

registerLocale('vi', vi);

interface Props {
  value: string | null;
  placeholder?: string;
  width?: string;
  minDate?: string | null;
  maxDate?: string | null;
  fontSize?: string;
  fontWeight?: 'normal' | 'bold';
  corner?: 'round' | 'square';
  color?: string;
  textAlign?: 'left' | 'right' | 'center';
  direction?: string;
  readOnly?: boolean;
  disabled?: boolean;
  showTimeSelect?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  handleChange?: (date: Date | null) => void;
}

const DatePickerMonthInput = ({
  value,
  placeholder,
  width,
  minDate,
  maxDate,
  fontSize = '16px',
  fontWeight = 'normal',
  corner = 'square',
  color = TextColor.Disable,
  textAlign,
  direction,
  readOnly = false,
  disabled = false,
  showTimeSelect = false,
  selectsStart = false,
  selectsEnd = false,
  handleChange,
}: Props): JSX.Element => {
  const [selected, setSelected] = React.useState(value ? new Date(value) : null);

  React.useEffect(() => {
    if (value && Date.parse(value)) {
      setSelected(new Date(value));
    } else {
      setSelected(null);
    }
  }, [value]);

  const handleSelectedChange = (date: Date | null) => {
    setSelected(date);
  };

  return (
    <Container width={width} readOnly={readOnly} disabled={disabled}>
      <Icon>
        <EventIcon />
      </Icon>
      <DatePicker
        locale='vi'
        showPopperArrow={false}
        showTimeSelect={showTimeSelect}
        selected={selected}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        dateFormat='yyyy'
        showYearPicker
        minDate={minDate ? new Date(minDate) : null}
        maxDate={maxDate ? new Date(maxDate) : null}
        placeholderText={placeholder ? placeholder : '0000'}
        readOnly={readOnly}
        disabled={disabled}
        strictParsing
        customInput={
          <Input
            readOnly={readOnly}
            disabled={disabled}
            fontSize={fontSize}
            fontWeight={fontWeight}
            corner={corner}
            color={color}
            textAlign={textAlign}
            direction={direction}
          />
        }
        onChange={handleSelectedChange}
        onCalendarClose={() => handleChange && handleChange(selected)}
        shouldCloseOnSelect={true}
      />
    </Container>
  );
};

type IStyledInput = Pick<Props, 'fontSize' | 'fontWeight' | 'corner' | 'textAlign' | 'direction'>;

const corners = {
  round: '20px',
  square: '5px',
};

const Container = styled.div<Pick<Props, 'width' | 'disabled' | 'readOnly'>>`
  max-width: ${({ width }) => width ?? 'none'};
  width: 100%;
  position: relative;
  background-color: ${({ disabled, readOnly }) => (!readOnly && disabled ? GrayColors.G1 : '#fff')};
  border-radius: 4px;

  & .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    padding-left: unset;
    padding-right: unset;
    width: 100px;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month-text {
    line-height: 35px;
  }

  .react-datepicker__year--container {
    .react-datepicker__year {
      .react-datepicker__year-wrapper {
        display: flex;
        flex-wrap: wrap;
        max-width: 140px;
        justify-content: space-around;

        & > .react-datepicker__year-text {
          padding: 6px 8px;
        }
      }
    }
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  padding: 0 6px;
  color: ${GrayColors.G5};
  font-size: 20px;

  & > svg {
    font-size: inherit;
  }
`;

const Input = styled.input<IStyledInput>`
  width: 100%;
  height: 100%;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  background-color: transparent;
  border-color: ${BorderColor.Secondary};
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ corner }) => corners[corner ?? 'round']};
  line-height: 1.4;
  box-sizing: border-box;
  transition: border 0.2s;
  outline: 0;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  direction: ${({ direction }) => direction};
  padding: 7px 10px 7px 30px;

  &:hover {
    border-color: ${BackGroundColor.Primary};
  }

  &:focus {
    border-color: ${BackGroundColor.Primary};
  }

  &[readonly] {
    pointer-events: none;
    border-color: transparent;
  }

  &[disabled] {
    pointer-events: none;
  }
`;

export default DatePickerMonthInput;
