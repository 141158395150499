import styled from 'styled-components';
import mainImg from '~/assets/img/about/main.png';
import anh_giai_phap from '~/assets/img/about/anh_giai_phap.jpg';
import anh_linh_vuc from '~/assets/img/about/anh_linh_vuc.png';
import anh_tam_nhin from '~/assets/img/about/anh_tam_nhin.png';
import anh_su_menh from '~/assets/img/about/anh_su_menh.png';
import { BsPhone, BsBuilding } from 'react-icons/bs';
import { FaXing } from 'react-icons/fa';
import { HiOutlineMailOpen } from 'react-icons/hi';

const About = () => {
  return (
    <AboutElement>
      <div className='header'>Giới thiệu</div>
      <div className='content'>
        <div className='information'>
          <div className='left'>
            <div className='title'>
              <h2>Công ty Trách nhiệm hữu hạn (TNHH)</h2>
              <h1>Khoa học Tự nhiên</h1>
            </div>
            <div className='main-image'>
              <img src={mainImg} alt='cong-ty-tnhh-khoa-hoc-tu-nhien' />
            </div>
          </div>
          <div className='right'>
            <div className='list-content'>
              <div className='one-content'>
                <div className='one-content-left'>
                  <div className='image'>
                    <img src={anh_tam_nhin} alt='' />
                  </div>
                </div>
                <div className='one-content-right'>
                  <div className='title'>Tầm nhìn</div>
                  <div className='description'>
                    Xây dựng Công ty TNHH Khoa học Tự nhiên, tạo hành lang pháp lý cho các liên kết
                    khoa học và ứng dụng khoa học vào cuộc sống một cách hữu ích và bền vững.
                  </div>
                </div>
              </div>
              <div className='one-content'>
                <div className='one-content-left'>
                  <div className='image'>
                    <img src={anh_su_menh} alt='' />
                  </div>
                </div>
                <div className='one-content-right'>
                  <div className='title'>Sứ mệnh</div>
                  <div className='description'>
                    Làm cầu nối giữa khoa học công nghệ với đời sống xã hội, hỗ trợ các nhà khoa học
                    trong và ngoài trường phát triển các nghiên cứu và ứng dụng khoa học, cùng nhau
                    đem khoa học phục vụ công cuộc xây dựng đất nước.
                  </div>
                </div>
              </div>
              <div className='one-content'>
                <div className='one-content-left'>
                  <div className='image'>
                    <img src={anh_linh_vuc} alt='' />
                  </div>
                </div>
                <div className='one-content-right'>
                  <div className='title'>Lĩnh vực</div>
                  <div className='description'>
                    Chúng tôi cung cấp các sản phẩm và dịch vụ có giá trị trong lĩnh vực quan trắc
                    đa thông số môi trường, khí tượng, thủy văn, địa chất giúp người dùng hiểu rõ
                    hơn về bản chất tự nhiên môi trường xung quanh.
                  </div>
                </div>
              </div>
              <div className='one-content'>
                <div className='one-content-left'>
                  <div className='image'>
                    <img src={anh_giai_phap} alt='' />
                  </div>
                </div>
                <div className='one-content-right'>
                  <div className='title'>Giải pháp</div>
                  <div className='description'>
                    Hệ thống các trạm, thiết bị quan trắc đa thông số của Goodeye được xây dựng dựa
                    trên một số giải pháp mang tính cốt lõi có cơ sở khoa học, dựa trên nền tảng
                    công nghệ ổn định và tiên tiến.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='contact-info'>
        <div className='list-contact-info'>
          <div className='one-contact-info'>
            <div className='image'>
              <BsPhone size={36} />
            </div>
            <div className='title'>PHONE</div>
            <div className='info'>04 3558 5840</div>
          </div>
          <div className='one-contact-info'>
            <div className='image'>
              <FaXing size={32} />
            </div>
            <div className='title'>FAX</div>
            <div className='info'>04 3858 3061</div>
          </div>
          <div className='one-contact-info'>
            <div className='image'>
              <BsBuilding size={34} />
            </div>
            <div className='title'>ADDRESS</div>
            <div className='info'>334 – Nguyễn Trãi, Thanh Xuân, Hà Nội</div>
          </div>
          <div className='one-contact-info'>
            <div className='image email'>
              <HiOutlineMailOpen size={32} />
            </div>
            <div className='title'>EMAIL</div>
            <div className='info'>ctykhtn@vnu.vn</div>
          </div>
        </div>
      </div>
    </AboutElement>
  );
};

const AboutElement = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > .header {
    width: 100%;
    height: 5%;
    font-size: 20px;
    font-weight: 600;
    padding: 8px;
  }

  & > .content {
    width: 100%;
    height: 70%;
    padding: 20px;

    .information {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 20px;
      display: flex;
      box-shadow: 0px 5px 10px rgba(46, 91, 255, 0.2);
      border-radius: 3px;

      & > .left {
        width: 35%;
        height: 100%;

        & > .title {
          & > h2 {
            font-family: 'Roboto', sans-serif;
            font-weight: 600;
            font-size: 18px;
            color: #666fe8;
          }

          & > h1 {
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: 700;
            color: #666fe8;
            margin-top: 8px;
          }
        }

        & > .main-image {
          max-width: 350px;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      & > .right {
        width: 65%;
        height: 100%;
        padding-left: 20px;

        .list-content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          .one-content {
            width: 100%;
            display: flex;
            height: 25%;
            overflow: hidden;
            padding: 8px;

            .one-content-left {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              .image {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                img {
                  display: flex;
                  position: relative;
                  height: 100%;
                  object-fit: contain;
                  border-radius: 3px;
                }
              }
            }

            .one-content-right {
              padding-left: 10px;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;

              .title {
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 23px;

                color: #666fe8;
              }

              .description {
                font-size: 14px;
                line-height: 20px;
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                text-rendering: optimizeSpeed;
              }
            }
          }
        }
      }
    }
  }

  & > .contact-info {
    width: 100%;
    height: 25%;
    position: relative;

    .list-contact-info {
      width: 100%;
      height: 100%;
      display: flex;

      .one-contact-info {
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .image {
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #666fe8;
        }

        .image.email {
          path {
            stroke-width: 1.6;
          }
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          font-family: Roboto, sans-serif;
          line-height: 28px;
          text-align: center;
          color: #666fe8;
          width: 100%;
        }

        .info {
          width: 100%;
          font-size: 14px;
          text-align: center;
          line-height: 25px;
          text-align: center;
          letter-spacing: 0.01em;
          opacity: 0.68;
        }
      }
    }
  }
`;

export default About;
