import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import TextButton from '~/components/atoms/Button';
import DateInput from '~/components/atoms/Input/DateInput';
import TextInput from '~/components/atoms/Input/TextInput';
import CircularProgressLoading from '~/components/atoms/Loading/CircularProgressLoading';
import ModalContainer from '~/components/molecules/Container/ModalContainer';
import { BrandColors } from '~/constants';
import { useLoading } from '~/hooks';
import { moment as dayjs } from '~/plugins';
import { useStationStore, useToastStore } from '~/store/hooks';
import { IStationHistory } from '~/types';
import { stationHistoryCreateLoadingModule } from '~/utils';

interface Props {
  opened: boolean;
  width?: string;
  height?: string;
  itemEdit: IStationHistory | null;
  fetchStationHistoryListCallBack: () => void;
  handleClose: () => void;
}

const CustomItemHistoryModal = ({
  opened = false,
  width = '545px',
  height = '508px',
  itemEdit,
  fetchStationHistoryListCallBack,
  handleClose,
}: Props) => {
  const { t } = useTranslation();
  const { stationId } = useParams();
  const isLoadingCreate = useLoading(stationHistoryCreateLoadingModule);
  const { dispatchCreateToast } = useToastStore();
  const { dispatchCreateStationHistory, dispatchUpdateStationHistory } = useStationStore();

  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [startDate, setStartDate] = React.useState<string | null>(dayjs().toString());
  const [endDate, setEndDate] = React.useState<string | null>(null);
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true);

  const titleModalHistory = React.useMemo(() => {
    if (itemEdit) {
      return t('history.action.update_history');
    }
    return t('history.action.add_history');
  }, [itemEdit, t]);

  const startIcon = React.useMemo(() => {
    if (isLoadingCreate) {
      return <CircularProgressLoading size={16} color='#fff' />;
    }
  }, [isLoadingCreate]);

  const handleNameInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setIsEdited(true);
  }, []);

  const handleDescriptionInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
      setIsEdited(true);
    },
    [],
  );

  const handleStartDateChange = React.useCallback(
    (date: Date | null) => {
      if (endDate && dayjs(endDate).valueOf() >= dayjs(date).valueOf()) {
        setStartDate(dayjs(date).toString());
        setIsEdited(true);
      } else if (endDate && dayjs(endDate).valueOf() < dayjs(date).valueOf()) {
        setDisableSubmit(true);
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu',
        });
      } else if (!endDate) {
        setStartDate(dayjs(date).toString());
      }
    },
    [dispatchCreateToast, endDate],
  );

  const handleEndDateChange = React.useCallback(
    (date: Date | null) => {
      if (date && dayjs(date).valueOf() >= dayjs(startDate).valueOf()) {
        setEndDate(dayjs(date).toString());
        setIsEdited(true);
      } else if (date && dayjs(date).valueOf() < dayjs(startDate).valueOf()) {
        setIsEdited(true);
        dispatchCreateToast({
          id: uuidv4(),
          toastType: 'failed',
          message: 'Ngày kết thúc phải lớn hơn hoặc bằng Ngày bắt đầu',
        });
      }
    },
    [dispatchCreateToast, startDate],
  );

  const handleCloseModal = React.useCallback(() => {
    handleClose();
    setTimeout(() => {
      setTitle('');
      setDescription('');
      setStartDate(dayjs().subtract(12, 'hour').toString());
      setEndDate(dayjs().toString());
    }, 300);
  }, [handleClose]);

  const handleCallbackFunction = React.useCallback(() => {
    handleCloseModal();
    fetchStationHistoryListCallBack();
  }, [fetchStationHistoryListCallBack, handleCloseModal]);

  const handleSubmit = React.useCallback(() => {
    const params = {
      id: itemEdit?.id ?? undefined,
      title: title,
      description: description,
      station_id: stationId ?? undefined,
      time_start: dayjs(startDate).format(),
      time_end: endDate ? dayjs(endDate).format() : null,
    };
    if (params.id) dispatchUpdateStationHistory(params, handleCallbackFunction);
    else dispatchCreateStationHistory(params, handleCallbackFunction);
  }, [
    handleCallbackFunction,
    dispatchCreateStationHistory,
    dispatchUpdateStationHistory,
    itemEdit?.id,
    description,
    endDate,
    title,
    startDate,
    stationId,
  ]);

  React.useEffect(() => {
    if (isEdited && title && title.length > 0 && endDate && dayjs(endDate).isValid()) {
      setDisableSubmit(false);
    }
  }, [endDate, isEdited, title]);

  React.useEffect(() => {
    if (itemEdit) {
      setTitle(itemEdit.title);
      setDescription(itemEdit.description);
      setStartDate(itemEdit.time_start || null);
      setEndDate(itemEdit.time_end || null);
      setIsEdited(false);
      setDisableSubmit(true);
    }
  }, [itemEdit]);

  return (
    <ModalContainer opened={opened} width={width} height={height} handleClose={handleCloseModal}>
      <Title>{titleModalHistory}</Title>
      <Container>
        <Section>
          <RowItem>
            <Label>{t('history.table.name')}</Label>
            <TextInput autoFocus value={title} handleChange={handleNameInputChange} />
          </RowItem>

          <RowItem>
            <Label>{t('history.table.description')}</Label>
            <TextInput value={description} handleChange={handleDescriptionInputChange} />
          </RowItem>

          <RowItem>
            <Label>{t('history.table.created_at')}</Label>
            <DateInput
              showTimeSelect={true}
              value={startDate}
              placeholder='Ngày bắt đầu'
              handleChange={handleStartDateChange}
            />
          </RowItem>

          <RowItem>
            <Label>{t('history.table.updated_at')}</Label>
            <DateInput
              showTimeSelect={true}
              value={endDate}
              placeholder='Ngày kết thúc'
              handleChange={handleEndDateChange}
            />
          </RowItem>
        </Section>
        <Bottom>
          <TextButton size='small' variant='outlined' onClick={handleCloseModal}>
            {t('history.action.cancel')}
          </TextButton>
          <TextButton
            size='small'
            startIcon={startIcon}
            variant='contained'
            color='primary'
            disabled={disableSubmit}
            onClick={handleSubmit}
          >
            {titleModalHistory}
          </TextButton>
        </Bottom>
      </Container>
    </ModalContainer>
  );
};

const Title = styled.div`
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${BrandColors.under};
  background: #e0e0e0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  border-bottom: 1px solid #b1b1b2;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(508px - 35px);
`;

const Section = styled.div`
  flex: 1;
  height: calc(100% - 48px);
  padding: 24px;
  padding-right: 14px;
  overflow-y: scroll;
`;

const RowItem = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  color: #333333;
`;

const Bottom = styled.div`
  height: 48px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #b1b1b2;
`;

export default React.memo(CustomItemHistoryModal);
