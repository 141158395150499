import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import ToastRecord from '~/components/atoms/ToastRecord';
import { useToastStore } from '~/store/hooks';

const ToastList = () => {
  const nodeRef = React.useRef(null);
  const {
    toastStore: { toasts },
  } = useToastStore();

  const sortedToasts = React.useMemo(() => {
    return [...toasts].reverse();
  }, [toasts]);

  console.log('sortedToasts', sortedToasts);
  return (
    <Container component={'aside'}>
      {sortedToasts.map((toast) => {
        return (
          <CSSTransition key={toast.id} nodeRef={nodeRef} timeout={400} classNames='slide'>
            <ToastRecord nodeRef={nodeRef} toast={toast} />
          </CSSTransition>
        );
      })}
    </Container>
  );
};

const Container = styled(TransitionGroup)`
  position: fixed;
  top: 0;
  right: 0;
  max-height: 100%;
  padding: 8px;
  overflow-x: hidden;
  z-index: 999999;

  & > * + * {
    margin-top: 12px;
  }
`;

export default React.memo(ToastList);
