/* eslint-disable react-hooks/exhaustive-deps */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { TiChevronLeftOutline, TiChevronRightOutline } from 'react-icons/ti';
import '../styles/SelectMenu.scss';

export interface Map {
  key: any;
  value: any;
  id?: any;
}

export interface SelectMenuProps {
  data?: Map[];
  init?: any;
  onChange?: any;
  width?: string | number;
  height?: string | number;
  children?: React.ReactNode | React.ReactNode[];
}

export interface OneItemProps {
  index: string;
  value: any;
  onSelect: any;
  children?: React.ReactNode | React.ReactNode[];
}

const OneItem = (props: OneItemProps) => {
  const { index, value, onSelect } = props;

  const handleClick = () => {
    if (onSelect) {
      onSelect({ key: index, value });
    }
  };

  return (
    <div className='one-item' id={`one-item-${index}`} onClick={handleClick}>
      {value}
    </div>
  );
};

export default function SelectMenu(props: SelectMenuProps) {
  const { data, onChange, init } = props;
  const [selected, setSelected] = useState({
    key: '',
    value: '',
  });
  const [dropdownState, setDropDownState] = useState(false);
  const selectMenuRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (data && data[0] && data[0]?.value) {
      if (!init) {
        setSelected({
          key: data[0]?.key,
          value: data[0]?.value,
        });
      } else {
        setSelected({
          key: init?.key,
          value: init?.value,
        });
      }
    }
  }, [data, init]);

  useEffect(() => {
    if (dropdownState) {
      document.addEventListener('click', closeDropDown);
    }

    return () => {
      document.removeEventListener('click', closeDropDown);
    };
  }, [dropdownState]);

  useEffect(() => {
    if (onChange) {
      onChange(selected);
    }

    if (dropdownState) {
      setDropDownState(false);
    }
  }, [selected]);

  const onSelect = (selected: any) => {
    setSelected(selected);
  };

  const toggleDropDown = () => {
    handleDropDown(!dropdownState);
  };

  const handleDropDown = (state: boolean) => {
    setDropDownState(state);
  };

  const closeDropDown = (e: any) => {
    if (dropdownState && !selectMenuRef?.current?.contains(e.target)) {
      setDropDownState(false);
    }
  };

  return (
    <div
      className={`select-menu ${dropdownState ? 'active' : 'inactive'}`}
      ref={selectMenuRef as RefObject<HTMLDivElement>}
    >
      <div className='label' onClick={toggleDropDown}>
        <div className='selected-item'>{selected?.value}</div>
        <div className='icon'>
          {dropdownState ? (
            <TiChevronLeftOutline
              size='22px'
              color='#667080'
              style={{
                transform: 'rotate(-90deg)',
              }}
            />
          ) : (
            <TiChevronRightOutline size='22px' color='#667080' />
          )}
        </div>
      </div>
      <div className={`list-item ${dropdownState ? 'visible' : 'hidden'}`}>
        {data?.map((one) => (
          <OneItem key={one.key} index={one.key} value={one.value} onSelect={onSelect} />
        ))}
      </div>
    </div>
  );
}
