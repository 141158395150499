import { locationDefault } from '~/constants/commons';
import { StationAction, StationActionType, StationState } from '~/store/ducks/station/types';

const initialState: StationState = {
  error: undefined,
  listStation: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 1,
      total: 1,
      totalPage: 1,
    },
  },
  listAllStation: {
    list: [],
    more_info: locationDefault,
    pagination: {
      current: 1,
      pageSize: 1,
      total: 1,
      totalPage: 1,
    },
  },
  listDevice: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 1,
      total: 1,
      totalPage: 1,
    },
  },
  listHistory: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 1,
      total: 1,
      totalPage: 1,
    },
  },
  listChartDetail: null,
  listConfigData: [],
  listThresholdData: [],
  stationDetail: null,
  stationCreate: '',
};

export default function stationReducer(state = initialState, action: StationAction): StationState {
  switch (action.type) {
    case StationActionType.GET_LIST_STATION: {
      return {
        ...state,
        stationCreate: '',
        listConfigData: [],
        listThresholdData: [],
        listChartDetail: null,
        stationDetail: null,
      };
    }

    case StationActionType.GET_LIST_STATION_SUCCESS: {
      return {
        ...state,
        listStation: {
          list: action.payload.listStation.list,
          pagination: action.payload.listStation.pagination,
        },
      };
    }

    case StationActionType.GET_LIST_STATION_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationActionType.GET_ALL_LIST_STATION_SUCCESS: {
      return {
        ...state,
        listAllStation: {
          list: action.payload.listStation.list,
          pagination: action.payload.listStation.pagination,
          more_info: action.payload.listStation.more_info,
        },
      };
    }

    case StationActionType.GET_ALL_LIST_STATION_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationActionType.GET_STATION_DETAIL: {
      return {
        ...state,
        stationDetail: null,
      };
    }

    case StationActionType.GET_STATION_DETAIL_SUCCESS: {
      return {
        ...state,
        stationDetail: action.payload.stationDetail,
      };
    }

    case StationActionType.GET_STATION_DETAIL_FAIL: {
      return {
        ...state,
        stationDetail: initialState.stationDetail,
        error: action.payload.error,
      };
    }

    case StationActionType.GET_STATION_CHART_INFO_SUCCESS: {
      return {
        ...state,
        listChartDetail: action.payload.listChartDetail,
      };
    }

    case StationActionType.GET_STATION_CHART_INFO_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationActionType.GET_STATION_DATA_CONFIG: {
      return {
        ...state,
        listConfigData: [],
      };
    }

    case StationActionType.GET_STATION_DATA_CONFIG_SUCCESS: {
      return {
        ...state,
        listConfigData: action.payload.listConfigData,
      };
    }

    case StationActionType.GET_STATION_DATA_CONFIG_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationActionType.GET_STATION_THRESHOLD: {
      return {
        ...state,
        listThresholdData: [],
      };
    }

    case StationActionType.GET_STATION_THRESHOLD_SUCCESS: {
      return {
        ...state,
        listThresholdData: action.payload.listThresholdData,
      };
    }

    case StationActionType.GET_STATION_THRESHOLD_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationActionType.CREATE_STATION_SUCCESS: {
      return {
        ...state,
        stationCreate: action.payload.stationId,
      };
    }

    case StationActionType.CREATE_STATION_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationActionType.GET_LIST_STATION_DEVICE_SUCCESS: {
      return {
        ...state,
        listDevice: {
          list: action.payload.listDevice.list,
          pagination: action.payload.listDevice.pagination,
        },
      };
    }

    case StationActionType.GET_LIST_STATION_DEVICE_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case StationActionType.GET_LIST_STATION_HISTORY_SUCCESS: {
      return {
        ...state,
        listHistory: {
          list: action.payload.listHistory.list,
          pagination: action.payload.listHistory.pagination,
        },
      };
    }

    case StationActionType.GET_LIST_STATION_HISTORY_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
