export interface Toast {
  id: string;
  toastType: ToastType;
  message: string;
  link?: string;
}

export enum ToastActionType {
  TOAST_MERGE_ITEM = 'toast/TOAST_MERGE_ITEM',
  TOAST_DELETE_ITEM = 'toast/TOAST_DELETE_ITEM',
}

export interface ToastMergeItemAction {
  type: ToastActionType.TOAST_MERGE_ITEM;
  payload: {
    toast: Toast;
  };
}

export interface ToastDeleteItemAction {
  type: ToastActionType.TOAST_DELETE_ITEM;
  payload: {
    toastId: Toast['id'];
  };
}

export type ToastAction = ToastMergeItemAction | ToastDeleteItemAction;

export interface ToastState {
  toasts: Toast[];
}

export const ToastType = {
  Failed: 'failed',
  Success: 'success',
} as const;

export type ToastType = typeof ToastType[keyof typeof ToastType];
