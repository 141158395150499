import i18next from 'i18next';
import { IconType } from 'react-icons';
import { BiBarChartAlt, BiCategoryAlt, BiCog, BiStation, BiUser } from 'react-icons/bi';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { RiShieldUserLine } from 'react-icons/ri';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { BsFillPinMapFill } from 'react-icons/bs';
import { ImList2 } from 'react-icons/im';

export interface SubMenuRouters {
  title: string;
  path: string;
  icon: IconType;
}

export interface SideBarRouters {
  title: string;
  path: string;
  icon: IconType;
  iconClosed?: any;
  iconOpened?: any;
  subMenu?: any;
}

export const SideBarItems: SideBarRouters[] = [
  {
    title: i18next.t('sidebar.stations'),
    path: '/stations',
    icon: BiStation,
    iconClosed: RiArrowDownSFill,
    iconOpened: RiArrowUpSFill,
    subMenu: [
      {
        title: 'Bản đồ trạm',
        path: '/stations/station-map',
        icon: BsFillPinMapFill,
      },
      {
        title: 'Danh sách trạm',
        path: '/stations/list-station',
        icon: ImList2,
      },
    ],
  },
  {
    title: i18next.t('sidebar.analytics'),
    path: '/analytics',
    icon: BiBarChartAlt,
  },
  {
    title: i18next.t('sidebar.users'),
    path: '/users',
    icon: BiUser,
  },
  {
    title: i18next.t('sidebar.systemInformation'),
    path: '/system-information',
    icon: BiCategoryAlt,
  },
  {
    title: i18next.t('sidebar.about'),
    path: '/about',
    icon: HiOutlineInformationCircle,
  },
  {
    title: i18next.t('sidebar.settings'),
    path: '/settings',
    icon: BiCog,
  },
  {
    title: i18next.t('sidebar.roles'),
    path: '/roles',
    icon: RiShieldUserLine,
  },
];
