import CheckBox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { ColumnDef } from '@tanstack/react-table';
import i18next from 'i18next';
import * as React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiDevices, BiHistory } from 'react-icons/bi';
import { CgData } from 'react-icons/cg';
import { FiEye, FiFileText, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Chip from '~/components/atoms/Chip';
import { moment } from '~/plugins';
import { useStationStore } from '~/store/hooks';
import { IStation } from '~/types';
import { isAdmin, isSuperAdmin } from '~/utils';

const ActionRow = styled.div`
  display: flex;
  justify-content: center;

  & > .action {
    cursor: pointer;
    margin-right: 16px;

    &:hover {
      color: #666fe8;
    }
  }
`;

export interface useStationPropsType {
  children?: React.ReactNode | React.ReactNode[];
  onConfirmDelete: any;
}

const useStationTable = (props: useStationPropsType) => {
  const { onConfirmDelete } = props;
  const isRoleSuperAdmin = isSuperAdmin();
  const isRoleAdmin = isAdmin();

  const { dispatchDeleteStation, dispatchDownLoadReportStation } = useStationStore();
  const [isModalReportDataOpen, setIsModalReportDataOpen] = React.useState<boolean>(false);
  const [stationReportId, setStationReportId] = React.useState<string>('');
  const [infoStationPanel, setInfoStationPanel] = React.useState({
    status: false,
    stationId: '',
  });

  const handleDeleteStation = React.useCallback(
    async (id: IStation['id']) => {
      const resultConfirmDelete = await onConfirmDelete(undefined);
      if (!resultConfirmDelete) return;

      await dispatchDeleteStation(id);
    },
    [dispatchDeleteStation, onConfirmDelete],
  );

  const handleDownloadFileReportStation = React.useCallback(
    (item: IStation) => {
      dispatchDownLoadReportStation(item);
    },
    [dispatchDownLoadReportStation],
  );

  const handleOpenModalReportData = React.useCallback((stationReportId: string) => {
    setIsModalReportDataOpen(true);
    setStationReportId(stationReportId);
  }, []);

  const handleCloseModalReportData = React.useCallback(() => {
    setIsModalReportDataOpen(false);
    setStationReportId('');
  }, []);

  const handleChangeDetailPanel = React.useCallback((status: boolean, stationId: string) => {
    if (!stationId) {
      setInfoStationPanel({ status: false, stationId: '' });
    } else {
      setInfoStationPanel({ status, stationId });
    }
  }, []);

  const columns = React.useMemo<ColumnDef<IStation>[]>(() => {
    const initialColumns: ColumnDef<IStation>[] = [
      {
        accessorKey: 'name',
        header: i18next.t('station.table.name'),
      },
      {
        accessorKey: 'created_at',
        header: i18next.t('station.table.create_at'),
        cell: (row) => {
          const created_at = row.getValue() as string;
          return moment(created_at).format('YYYY/MM/DD');
        },
      },
      {
        accessorKey: 'status',
        header: i18next.t('station.table.status'),
        cell: (row) => {
          const status = row.getValue();
          if (status) return <Chip type='success' label='Active' />;
          return <Chip type='warning' label='InActive' />;
        },
      },
      {
        accessorKey: '',
        header: i18next.t('station.table.action'),
        cell: ({ row: { original } }) => {
          return (
            <ActionRow>
              <Tooltip title='Xem chi tiết'>
                <span className='action' onClick={() => handleChangeDetailPanel(true, original.id)}>
                  <FiEye size='20px' />
                </span>
              </Tooltip>

              <Tooltip title='Cập nhật'>
                <Link to={`/stations/update/${original.id}`} className='action'>
                  <AiOutlineEdit size='20px' />
                </Link>
              </Tooltip>

              {isRoleSuperAdmin && (
                <Tooltip title='Thiết bị'>
                  <Link to={`/stations/device/${original.id}`} className='action'>
                    <BiDevices size='20px' />
                  </Link>
                </Tooltip>
              )}

              {isRoleSuperAdmin && (
                <Tooltip title='Lịch sử trạm'>
                  <Link to={`/stations/history/${original.id}`} className='action'>
                    <BiHistory size='20px' />
                  </Link>
                </Tooltip>
              )}

              {isRoleSuperAdmin && (
                <Tooltip title='Báo cáo trạm'>
                  <span
                    className='action'
                    onClick={() => handleDownloadFileReportStation(original)}
                  >
                    <CgData size='20px' />
                  </span>
                </Tooltip>
              )}

              <Tooltip title='Báo cáo dữ liệu'>
                <span className='action' onClick={() => handleOpenModalReportData(original.id)}>
                  <FiFileText size='20px' />
                </span>
              </Tooltip>

              {isRoleSuperAdmin && (
                <Tooltip title='Xóa'>
                  <span className='action'>
                    <FiTrash2
                      size='20px'
                      onClick={() => {
                        handleDeleteStation(original.id);
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            </ActionRow>
          );
        },
      },
    ];
    if (isRoleSuperAdmin || isRoleAdmin) {
      initialColumns.unshift({
        accessorKey: 'select',
        header: ({ table }) => {
          return (
            <React.Fragment>
              <CheckBox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            </React.Fragment>
          );
        },
        cell: ({ row }) => {
          return (
            <CheckBox
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      });
    }
    return initialColumns;
  }, [
    handleChangeDetailPanel,
    handleDeleteStation,
    handleDownloadFileReportStation,
    handleOpenModalReportData,
    isRoleAdmin,
    isRoleSuperAdmin,
  ]);

  return {
    columns,
    stationReportId,
    isModalReportDataOpen,
    handleCloseModalReportData,
    infoStationPanel,
    handleChangeDetailPanel,
  };
};

export default useStationTable;
