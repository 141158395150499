import { loadModules } from 'esri-loader';
import * as React from 'react';
import styled from 'styled-components';

import CircularProgressWithLabel from './CircularProgressWithLabel';

import blackMarker from '~/assets/img/blackMarker.svg';
import greenMarker from '~/assets/img/greenMarker.svg';
import redMarker from '~/assets/img/redMarker.svg';
import vietNamMarker from '~/assets/img/vietNamMarker.png';
import { env } from '~/configs';
import { ILocation, IStation } from '~/types';

interface MapProps {
  station: IStation[];
  locationStation: ILocation;
  onChangeDetailPanel?: (status: boolean, streamId: string) => void;
}

interface Point {
  geometry: {
    type: string;
    x: number;
    y: number;
  };
  attributes: {
    id: number;
    stationId: string;
    name: string;
    location: string;
    latitude: number;
    longitude: number;
  };
}

const MapComponent = ({ station, locationStation, onChangeDetailPanel }: MapProps): JSX.Element => {
  const [listInfo, setListInfo] = React.useState<any[]>([]);
  const mapContainer = React.useRef<HTMLHeadingElement>(null);

  const totalStation = React.useMemo(() => {
    return station.length;
  }, [station]);

  const totalStationActive = React.useMemo(() => {
    return station.filter((item) => item.warning === 0).length;
  }, [station]);

  const totalStationOverload = React.useMemo(() => {
    return station.filter((item) => item.warning === 1).length;
  }, [station]);

  const totalStationNoHaveData = React.useMemo(() => {
    return station.filter((item) => item.warning === 2).length;
  }, [station]);

  const stationTableNote = React.useMemo(() => {
    return [
      {
        name: 'Tổng số trạm',
        count: totalStation,
        symbol: '',
        color: '',
      },
      {
        name: 'Trạm hoạt động',
        count: totalStationActive,
        symbol: greenMarker,
        color: 'Xanh',
      },
      {
        name: 'Trạm không  có số liệu',
        count: totalStationNoHaveData,
        symbol: blackMarker,
        color: 'Đen',
      },
      {
        name: 'Trạm có số liệu vượt ngưỡng',
        count: totalStationOverload,
        symbol: redMarker,
        color: 'Đỏ',
      },
    ];
  }, [totalStation, totalStationActive, totalStationNoHaveData, totalStationOverload]);

  React.useEffect(() => {
    if (mapContainer.current) {
      loadModules([
        'esri/config',
        'esri/Map',
        'esri/views/MapView',
        'esri/layers/GraphicsLayer',
        'esri/layers/FeatureLayer',
      ])
        .then(([esriConfig, Map, MapView, GraphicsLayer, FeatureLayer]) => {
          esriConfig.apiKey = env.ESRICONFIG_API_KEY;

          const map = new Map({
            basemap: 'arcgis-navigation', //Basemap layer service
          });

          const view = new MapView({
            map: map,
            container: mapContainer.current,
            //center: [107.5053541, 16.4534606], //Longitude, latitude
            center: [locationStation.Lon, locationStation.Lat],
            zoom: 9,
          });

          view.on('click', function (event: any) {
            view.hitTest(event).then(function (response: any) {
              const graphic = response?.results[0]?.graphic;
              if (graphic?.layer?.queryFeatures) {
                graphic?.layer
                  ?.queryFeatures({
                    objectIds: [graphic.attributes.id],
                    outFields: ['stationId', 'name', 'location', 'latitude', 'longitude'],
                  })
                  .then(function (featureSet: any) {
                    const attributes = featureSet?.features[0]?.attributes;

                    if (onChangeDetailPanel && attributes?.stationId) {
                      onChangeDetailPanel(true, attributes.stationId);
                    }
                  });
              }
            });
          });

          const graphicsLayer = new GraphicsLayer();
          map.add(graphicsLayer);

          const popupTemplate = {
            title: 'Trạm: {name}',
            lastEditInfoEnabled: false,
            autoOpenEnabled: false, // Ngăn không cho popup tự động kích hoạt
            // actions: [
            //   { id: 'station-info', title: 'Thông tin', data: '{expression/stationIdBase64}' },
            // ],
            content: [
              {
                type: 'fields',
                fieldInfos: [
                  // { fieldName: 'stationId', label: 'Id Trạm' },
                  { fieldName: 'name', label: 'Tên trạm' },
                  { fieldName: 'location', label: 'Vị trí' },
                  { fieldName: 'longitude', label: 'Kinh độ' },
                  { fieldName: 'latitude', label: 'Vĩ độ' },
                ],
              },
            ],
          };

          // Pointer hoạt động bình thường
          const pointActive: Point[] = [];
          const stationActive = station.filter((item) => item.warning === 0);
          if (stationActive && stationActive.length > 0) {
            stationActive.forEach((item, index) => {
              pointActive.push({
                geometry: {
                  type: 'point',
                  x: item.lon,
                  y: item.lat,
                },
                attributes: {
                  id: Math.floor(Math.random() * Date.now()) + index + 1,
                  stationId: item.id,
                  name: item.name,
                  location: item.address,
                  latitude: item.lat,
                  longitude: item.lon,
                },
              });
            });
          }
          const trailheadsActive = new FeatureLayer({
            fields: [
              { name: 'id', alias: 'id', type: 'oid' },
              { name: 'stationId', alias: 'stationId', type: 'string' },
              { name: 'name', alias: 'name', type: 'string' },
              { name: 'location', alias: 'location', type: 'string' },
              { name: 'latitude', alias: 'latitude', type: 'geometry' },
              { name: 'longitude', alias: 'longitude', type: 'geometry' },
            ],
            source: pointActive,
            objectIdField: 'id',
            popupTemplate: popupTemplate,
            outFields: ['*'],
            renderer: {
              type: 'simple',
              symbol: {
                type: 'picture-marker',
                url: greenMarker,
                width: '35px',
                height: '35px',
              },
            },
          });

          map.add(trailheadsActive);

          // Trạm có số liệu vượt ngưỡng
          const pointOverload: Point[] = [];
          const stationOverload = station.filter((item) => item.warning === 1);
          if (stationOverload && stationOverload.length > 0) {
            stationOverload.forEach((item, index) => {
              pointOverload.push({
                geometry: {
                  type: 'point',
                  x: item.lon,
                  y: item.lat,
                },
                attributes: {
                  id: Math.floor(Math.random() * Date.now()) + index + 1,
                  stationId: item.id,
                  name: item.name,
                  location: item.address,
                  latitude: item.lat,
                  longitude: item.lon,
                },
              });
            });
          }
          const trailheadsOverload = new FeatureLayer({
            fields: [
              { name: 'id', alias: 'id', type: 'oid' },
              { name: 'stationId', alias: 'stationId', type: 'string' },
              { name: 'name', alias: 'name', type: 'string' },
              { name: 'location', alias: 'location', type: 'string' },
              { name: 'latitude', alias: 'latitude', type: 'geometry' },
              { name: 'longitude', alias: 'longitude', type: 'geometry' },
            ],
            source: pointOverload,
            objectIdField: 'id',
            popupTemplate: popupTemplate,
            outFields: ['*'],
            renderer: {
              type: 'simple',
              symbol: {
                type: 'picture-marker',
                url: redMarker,
                width: '35px',
                height: '35px',
              },
            },
          });

          map.add(trailheadsOverload);

          // Pointer have no data
          const pointNoData: Point[] = [];
          const stationNoData = station.filter((item) => item.warning === 2);
          if (stationNoData && stationNoData.length > 0) {
            stationNoData.forEach((item, index) => {
              pointNoData.push({
                geometry: {
                  type: 'point',
                  x: item.lon,
                  y: item.lat,
                },
                attributes: {
                  id: Math.floor(Math.random() * Date.now()) + index + 1,
                  stationId: item.id,
                  name: item.name,
                  location: item.address,
                  latitude: item.lat,
                  longitude: item.lon,
                },
              });
            });
          }
          const trailheadsNoData = new FeatureLayer({
            fields: [
              { name: 'id', alias: 'id', type: 'oid' },
              { name: 'stationId', alias: 'stationId', type: 'string' },
              { name: 'name', alias: 'name', type: 'string' },
              { name: 'location', alias: 'location', type: 'string' },
              { name: 'latitude', alias: 'latitude', type: 'geometry' },
              { name: 'longitude', alias: 'longitude', type: 'geometry' },
            ],
            source: pointNoData,
            objectIdField: 'id',
            outFields: ['*'],
            popupTemplate: popupTemplate,
            renderer: {
              type: 'simple',
              symbol: {
                type: 'picture-marker',
                url: blackMarker,
                width: '35px',
                height: '35px',
              },
            },
          });

          map.add(trailheadsNoData);

          view.on('pointer-move', function (event: any) {
            view.hitTest(event).then(function (response: any) {
              if (response?.results?.length) {
                const graphic = response.results.filter(function (result: any) {
                  // check if the graphic belongs to the layer of interest
                  return (
                    result.graphic.layer === trailheadsActive ||
                    result.graphic.layer === trailheadsOverload ||
                    result.graphic.layer === trailheadsNoData
                  );
                })[0]?.graphic;
                if (!graphic) {
                  if (view?.popup?.visible) {
                    view.popup.close();
                  }
                  return;
                }
                if (!view?.popup?.visible) {
                  view.popup.open({
                    location: graphic.geometry.centroid,
                    features: [graphic],
                  });
                }
              } else {
                view.popup.close();
              }
            });
          });

          const labelClass = {
            symbol: {
              type: 'text',
              haloColor: '#000000b3',
              font: {
                family: 'Arial',
                size: 8,
              },
            },
            labelPlacement: 'above-center',
            labelExpressionInfo: {
              expression: '$feature.name',
            },
          };
          const sovereigntyVietNam = new FeatureLayer({
            fields: [{ name: 'name', alias: 'Tên trạm', type: 'string' }],
            source: [
              {
                geometry: {
                  type: 'point',
                  x: 111.601944,
                  y: 16.533333,
                },
                attributes: {
                  Id: Math.floor(Math.random() * Date.now()) + 1,
                  name: 'QĐ Hoàng Sa',
                  location: 'Đà Nẵng',
                  latitude: 16.533333,
                  longitude: 111.601944,
                },
              },
              {
                geometry: {
                  type: 'point',
                  x: 111.931944,
                  y: 8.641667,
                },
                attributes: {
                  Id: Math.floor(Math.random() * Date.now()) + 2,
                  name: 'QĐ Trường Sa',
                  location: 'Khánh Hòa',
                  latitude: 8.641667,
                  longitude: 111.931944,
                },
              },
            ],
            objectIdField: 'Id',
            labelingInfo: [labelClass],
            renderer: {
              type: 'simple',
              symbol: {
                type: 'picture-marker',
                url: vietNamMarker,
                width: '35px',
                height: '35px',
              },
            },
          });
          map.add(sovereigntyVietNam);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [onChangeDetailPanel, locationStation, station]);

  React.useEffect(() => {
    const totalStation = stationTableNote[0]?.count;

    const newStationTableNote = stationTableNote?.map((one) => {
      return {
        ...one,
        percent: (one.count / totalStation) * 100,
      };
    });

    console.log('newStationTableNote', newStationTableNote);
    if (newStationTableNote) {
      setListInfo(newStationTableNote);
    }
  }, [stationTableNote]);

  return (
    <MapWrapper>
      <MapDescription>
        <div className='table-information'>
          {listInfo.map((one, index) => (
            <div className='one-information-wrapper' key={index}>
              <div className='content'>
                <div className='left'>
                  <div className='wrapper'>
                    <div className='name'>{one.name} </div>
                    <div className='value'>{one.count}</div>
                  </div>
                </div>
                <div className='right'>
                  <CircularProgressWithLabel value={one.percent} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </MapDescription>
      <MapContainer ref={mapContainer}></MapContainer>
    </MapWrapper>
  );
};

const MapWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  overflow: hidden;
`;

const MapContainer = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
`;

const MapDescription = styled.div`
  width: 300px;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  .table-information {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;

    .one-information-wrapper {
      width: 100%;
      height: 25%;
      padding: 8px 8px 0 8px;

      &:last-child {
        padding-bottom: 8px;
      }

      .content {
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;

        .left {
          width: 60%;
          height: 100%;
          position: relative;
          justify-content: center;
          align-items: center;
          display: flex;

          .wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: fit-content;
            overflow: hidden;
            justify-content: flex-end;

            .name {
              font-size: 14px;
              font-weight: 500;
              width: 100%;
              padding: 0 8px;
              text-align: center;
            }

            .value {
              white-space: nowrap;
              padding-top: 16px;
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
        }

        .right {
          width: 40%;
          height: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: fit-content;
          padding: 8px;
        }
      }
    }
  }
`;

export default React.memo(MapComponent);
