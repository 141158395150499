import { getAuthInfo } from '~/store/selectors';
import { store } from '~/store/ducks/rootStore';
import { StationPermission } from '~/constants';

export function cloneObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function isSuperAdmin() {
  const authInfo = getAuthInfo(store.getState().auth);
  return authInfo.role_slug === StationPermission.SupperAdmin;
}

export function isAdmin() {
  const authInfo = getAuthInfo(store.getState().auth);
  return authInfo.role_slug === StationPermission.Admin;
}

export function isUser() {
  const authInfo = getAuthInfo(store.getState().auth);
  return authInfo.role_slug === StationPermission.User;
}

export function isNumber(value: string | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
}
