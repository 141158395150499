import * as React from 'react';
import styled, { css } from 'styled-components';
import { BorderColor, BackGroundColor, TextColor } from '~/constants';

type Props = React.PropsWithChildren<{
  id?: string;
  type?: string;
  value: string | number | null;
  placeholder?: string;
  width?: string;
  inputRef?: any;
  readOnly?: boolean;
  disabled?: boolean;
  corner?: 'round' | 'square';
  required?: boolean;
  autoFocus?: boolean;
  autoComplete?: 'on' | 'current-password' | 'new-password';
  min?: string | number | undefined;
  max?: string | number | undefined;
  color?: string;
  bgColor?: string;
  fontSize?: string;
  fontWeight?: 'normal' | 'bold';
  direction?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick?: (event: any) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  textAlign?: 'left' | 'right' | 'center';
  handleFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleOnKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  positionIcon?: 'startAdornment' | 'endAdornment';
  errorBorder?: boolean;
  textError?: string | undefined;
}>;

const TextInput = ({
  children,
  id,
  type = 'text',
  value,
  placeholder,
  width,
  inputRef,
  readOnly = false,
  disabled = false,
  corner = 'square',
  required = false,
  autoFocus = false,
  autoComplete = 'on',
  min,
  max,
  color = TextColor.Disable,
  bgColor = BackGroundColor.Secondary,
  fontSize = '16px',
  fontWeight = 'normal',
  handleChange,
  handleClick,
  handleBlur,
  textAlign,
  handleFocus,
  handleOnKeyDown,
  handleOnPaste,
  direction,
  maxLength,
  positionIcon = 'startAdornment',
  errorBorder,
  textError,
}: Props): JSX.Element => {
  const hasIcon = !!children;
  const initialRef = React.useRef<HTMLInputElement>(null);
  const ref = inputRef ?? initialRef;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      ref.current?.blur();
    }
  };

  const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
    event.currentTarget.blur();
  };

  const unchangable = disabled || readOnly;

  return (
    <ContainerInput
      width={width}
      readOnly={readOnly}
      disabled={disabled}
      corner={corner}
      bgColor={bgColor}
    >
      {hasIcon && <Icon positionIcon={positionIcon}>{children}</Icon>}
      {textError && <ErrorMessage>{textError}</ErrorMessage>}
      <Input
        ref={ref}
        id={id}
        type={type}
        value={value ?? ''}
        placeholder={placeholder}
        hasIcon={hasIcon}
        readOnly={readOnly}
        disabled={disabled}
        corner={corner}
        required={required}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        min={min}
        max={max}
        tabIndex={1}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textAlign={textAlign}
        direction={direction}
        onChange={unchangable ? undefined : handleChange}
        onClick={unchangable ? undefined : handleClick}
        onBlur={unchangable ? undefined : handleBlur}
        onKeyDown={unchangable ? undefined : handleOnKeyDown}
        onKeyPress={unchangable ? undefined : handleKeyPress}
        onFocus={unchangable ? undefined : handleFocus}
        onPaste={unchangable ? undefined : handleOnPaste}
        onWheel={unchangable ? undefined : handleWheel}
        maxLength={maxLength}
        positionIcon={positionIcon}
        errorBorder={errorBorder}
      />
    </ContainerInput>
  );
};

type IStyledContainer = Pick<Props, 'width' | 'readOnly' | 'disabled' | 'corner' | 'bgColor'>;

const corners = {
  round: '20px',
  square: '5px',
};

export const ContainerInput = styled.div<IStyledContainer>`
  max-width: ${({ width }) => width ?? 'none'};
  width: 100%;
  position: relative;
  background-color: ${({ disabled, readOnly, bgColor }) =>
    !readOnly && disabled ? TextColor.Hidden : bgColor};
  border-radius: ${({ corner }) => corners[corner ?? 'round']};
  z-index: 1;
`;

type IStyledIcon = Pick<Props, 'positionIcon'>;

const setVariantIconStyle = (positionIcon: Props['positionIcon']) => {
  if (positionIcon === 'endAdornment') {
    return css`
      right: 0;
    `;
  }
  return css`
    left: 0;
  `;
};

const Icon = styled.div<IStyledIcon>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  padding: 0 15px;
  color: ${BackGroundColor.Disable};
  font-size: 20px;
  cursor: pointer;

  ${(props) => setVariantIconStyle(props.positionIcon)}
  & > svg {
    font-size: inherit;
  }
`;

const ErrorMessage = styled.span`
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${BackGroundColor.Error};
  top: -30px;
  right: 0;
`;

type IStyledInput = Pick<
  Props,
  | 'corner'
  | 'color'
  | 'fontSize'
  | 'fontWeight'
  | 'textAlign'
  | 'direction'
  | 'positionIcon'
  | 'errorBorder'
> & {
  hasIcon: boolean;
};

const setVariantInputStyle = (
  positionIcon: IStyledInput['positionIcon'],
  hasIcon: IStyledInput['hasIcon'],
) => {
  if (hasIcon) {
    if (positionIcon === 'startAdornment') {
      return css`
        padding: 7px 10px 7px 40px;
      `;
    }
    return css`
      padding: 7px 40px 7px 15px;
    `;
  }

  return css`
    padding: 7px 15px;
  `;
};

const Input = styled.input<IStyledInput>`
  width: 100%;
  height: 100%;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  background-color: transparent;
  border-color: ${({ errorBorder }) =>
    errorBorder ? BackGroundColor.Error : BorderColor.Secondary};
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ corner }) => corners[corner ?? 'round']};
  line-height: 1.4;
  box-sizing: border-box;
  transition: border 0.2s;
  outline: 0;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
  direction: ${({ direction }) => direction};

  ${(props) => setVariantInputStyle(props.positionIcon, props.hasIcon)}
  &:hover {
    border-color: ${({ errorBorder }) => (errorBorder ? BackGroundColor.Error : '#666fe8')};
  }

  &:focus {
    border-color: ${({ errorBorder }) => (errorBorder ? BackGroundColor.Error : '#666fe8')};
  }

  &[readonly] {
    pointer-events: none;
    border-color: transparent;
  }

  &[disabled] {
    pointer-events: none;
  }
`;

export default TextInput;
