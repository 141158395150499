import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export interface DisplayModeSelectorPropsType {
  children?: React.ReactNode | React.ReactNode[];
  value: any;
  onChange: (value: any) => void;
}

export default function DisplayModeSelector(props: DisplayModeSelectorPropsType) {
  const { value, onChange } = props;

  // React.useEffect(() => {
  //   if (!value) {
  //     onChange('statistics');
  //   }
  // }, [onChange, value]);

  const handleChange = (event: any, newAlignment: any) => {
    if (newAlignment) {
      onChange(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      color='primary'
      value={value}
      exclusive
      onChange={handleChange}
      aria-label='Platform'
      style={{
        height: '40px',
      }}
    >
      <ToggleButton value='statistics'>Thống kê</ToggleButton>
      <ToggleButton value='data'>Dữ liệu</ToggleButton>
      <ToggleButton value='chart'>Đồ thị</ToggleButton>
      <ToggleButton value='report'>Báo cáo</ToggleButton>
    </ToggleButtonGroup>
  );
}
