import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheck, MdClose as CloseIcon, MdErrorOutline as ErrorIcon } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { BackGroundColor, TextColor } from '~/constants';
import { Toast, ToastType } from '~/store/ducks/toast/types';
import { useToastStore } from '~/store/hooks';

interface Props {
  toast: Toast;
  nodeRef: React.RefObject<HTMLDivElement>;
}

const ToastRecord = ({ toast, nodeRef }: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchDeleteToast } = useToastStore();

  const iconElement = React.useMemo(() => {
    switch (toast.toastType) {
      case ToastType.Failed:
        return <ErrorIcon style={{ fontSize: '20px' }} />;
      case ToastType.Success:
        return <MdCheck style={{ fontSize: '20px' }} />;
      default:
        break;
    }
  }, [toast.toastType]);

  const handleActionClick = React.useCallback(() => {
    if (!toast.link) {
      return;
    }
    navigate(toast.link);
    dispatchDeleteToast(toast.id);
  }, [toast, navigate, dispatchDeleteToast]);

  const handleCloseClick = React.useCallback(() => {
    dispatchDeleteToast(toast.id);
  }, [toast, dispatchDeleteToast]);

  React.useEffect(() => {
    setTimeout(() => {
      dispatchDeleteToast(toast.id);
    }, 3000);
  }, [dispatchDeleteToast, toast.id]);

  return (
    <Container ref={nodeRef} toastType={toast.toastType}>
      <Icon>{iconElement}</Icon>

      <Content>
        <Message>{toast.message}</Message>
        {toast.link && <Action onClick={handleActionClick}>{t('confirm')}</Action>}
      </Content>

      <Close onClick={handleCloseClick}>
        <CloseIcon />
      </Close>
    </Container>
  );
};

const Container = styled.div<{ toastType: ToastType }>`
  display: flex;
  width: 400px;
  min-height: 50px;
  border-radius: 2px;

  ${({ toastType }) => {
    switch (toastType) {
      case ToastType.Failed:
        return css`
          color: ${TextColor.Primary};
          background-color: ${BackGroundColor.Error};
        `;

      case ToastType.Success:
        return css`
          color: ${TextColor.Primary};
          background-color: ${BackGroundColor.Success};
        `;
      default:
        return;
    }
  }};
`;

const Icon = styled.span`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${TextColor.Primary};
  margin: 12px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 8px 8px 8px 0;
`;

const Message = styled.div`
  font-size: 16px;
  line-height: 16px;
  word-break: break-all;
`;

const Action = styled.div`
  display: inline-block;
  margin-top: 8px;
  color: '#333333';
  font-size: 1.2rem;
  cursor: pointer;
`;

const Close = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  background-color: rgb(30 30 30 / 15%);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.7;
  }
`;

export default React.memo(ToastRecord);
