import { IStationAssociation, IStationConfig } from '~/types';

export enum StationConfigActionType {
  GET_STATION_LIST_CONFIG_DEFAULT = 'station/GET_STATION_LIST_CONFIG_DEFAULT',
  GET_STATION_LIST_CONFIG_DEFAULT_SUCCESS = 'station/GET_STATION_LIST_CONFIG_DEFAULT_SUCCESS',
  GET_STATION_LIST_CONFIG_DEFAULT_FAIL = 'station/GET_STATION_LIST_CONFIG_DEFAULT_FAIL',
  GET_STATION_CONFIG_MAPPING = 'station/GET_STATION_CONFIG_MAPPING',
  GET_STATION_CONFIG_MAPPING_SUCCESS = 'station/GET_STATION_CONFIG_MAPPING_SUCCESS',
  GET_STATION_CONFIG_MAPPING_FAIL = 'station/GET_STATION_CONFIG_MAPPING_FAIL',
}

export interface GetStationListDefaultConfig {
  type: StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT;
}

export interface GetStationListDefaultConfigSuccess {
  type: StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_SUCCESS;
  payload: {
    listStationConfig: IStationConfig[];
  };
}

export interface GetStationListDefaultConfigFail {
  type: StationConfigActionType.GET_STATION_LIST_CONFIG_DEFAULT_FAIL;
  payload: {
    error: Error;
  };
}

export interface GetStationConfigMapping {
  type: StationConfigActionType.GET_STATION_CONFIG_MAPPING;
}

export interface GetStationConfigMappingSuccess {
  type: StationConfigActionType.GET_STATION_CONFIG_MAPPING_SUCCESS;
  payload: {
    listStationConfigMapping: IStationAssociation[];
  };
}

export interface GetStationConfigMappingFail {
  type: StationConfigActionType.GET_STATION_CONFIG_MAPPING_FAIL;
  payload: {
    error: Error;
  };
}

export type StationConfigAction =
  | GetStationListDefaultConfig
  | GetStationListDefaultConfigSuccess
  | GetStationListDefaultConfigFail
  | GetStationConfigMapping
  | GetStationConfigMappingSuccess
  | GetStationConfigMappingFail;

export interface StationConfigState {
  error?: Error;
  stationListConfig: IStationConfig[];
  stationListConfigMapping: IStationAssociation[];
}
