import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListSampleInfo } from '~/store/ducks/sampleInfo/actions';
import { RootState } from '~/store/types';

interface FuncType {
  sampleInfoState: RootState['sampleInfo'];
  dispatchListGetSampleInfo: (stationId?: string) => Promise<void>;
}

const useSampleInfoStore = (): FuncType => {
  const dispatch = useDispatch();

  const sampleInfoState = useSelector<RootState, RootState['sampleInfo']>((state) => {
    return state.sampleInfo;
  });

  const dispatchListGetSampleInfo = React.useCallback(
    async (stationId?: string) => {
      await dispatch(getListSampleInfo(stationId));
    },
    [dispatch],
  );

  return {
    sampleInfoState,
    dispatchListGetSampleInfo,
  };
};

export default useSampleInfoStore;
