import { useCallback, useEffect, useState } from 'react';
import { BiDirections, BiWater } from 'react-icons/bi';
import { CiTempHigh } from 'react-icons/ci';
import { RiBattery2ChargeFill } from 'react-icons/ri';
import { TbWind } from 'react-icons/tb';
import { WiHumidity } from 'react-icons/wi';
import styled from 'styled-components';
import { getMostRecentDataOfSensor } from '~/store/ducks/station/actions';
import { moment } from '~/plugins';
import Empty from '~/components/atoms/Empty';

const statisticsSample = [
  {
    icon: <BiWater size={24} />,
    name: 'Lượng mưa',
    key: 'rafa',
    unit: 'mm',
  },
  {
    icon: <CiTempHigh size={24} />,
    name: 'Nhiệt độ',
    key: 'atem',
    unit: '°C',
  },
  {
    icon: <WiHumidity size={24} />,
    name: 'Độ ẩm',
    key: 'airh',
    unit: '%',
  },
  {
    icon: <TbWind size={24} />,
    name: 'Tốc độ gió',
    key: 'wspd',
    unit: 'm/s',
  },
  {
    icon: <BiDirections size={24} />,
    name: 'Hướng gió',
    key: 'wdir',
    unit: '°',
  },
  {
    icon: <RiBattery2ChargeFill size={24} />,
    name: 'Điện áp',
    key: 'v_in',
    unit: 'V',
  },
];

export interface StatisticsPropsType {
  children?: React.ReactNode | React.ReactNode[];
  listSensorAvailables: any;
  stationId: string;
  onUpdateTime: (date: Date | null) => void;
}

const Statistics = (props: StatisticsPropsType) => {
  const { listSensorAvailables, stationId, onUpdateTime } = props;

  const [infoSensor, setInfoSensor] = useState<any[]>([]);
  const [valueSensor, setValueSensor] = useState<any>({});

  useEffect(() => {
    if (listSensorAvailables?.length > 0 && stationId) {
      const listPromises: any[] = [];

      for (let i = 0; i < listSensorAvailables.length; i++) {
        const sensorId = listSensorAvailables[i].id;
        if (!sensorId) return;

        const promise = getMostRecentDataOfSensor(stationId, sensorId).then((response: any) => {
          const result = response.Data[0];
          if (!result) return;
          else {
            return {
              sensorId,
              value: result.SensorData,
              time: result.SamplingTime,
            };
          }
        });

        listPromises.push(promise);
      }

      Promise.all(listPromises).then((values) => {
        const valueSensor_tmp: any = {};
        let time: any = undefined;
        values.forEach((one) => {
          const sensorId = one?.sensorId;
          if (!sensorId) return;

          valueSensor_tmp[sensorId] = Number(one.value?.toFixed(2));

          if (!time && one.time) {
            time = moment(one.time);
          } else if (time && one.time) {
            if (!time?.diff(one.time)) {
              time = moment(one.time);
            }
          }
        });

        onUpdateTime(time);

        setValueSensor(valueSensor_tmp);
      });
    }
  }, [stationId, listSensorAvailables, onUpdateTime]);

  useEffect(() => {
    const infoSensor_tmp: any[] = JSON.parse(JSON.stringify(listSensorAvailables));
    infoSensor_tmp.forEach((one: any) => {
      // Thêm icon
      const sampleData = statisticsSample.find((two) => two.key === one.key);
      if (sampleData) {
        for (const [key, value] of Object.entries(sampleData)) {
          if (!one[key]) {
            one[key] = value;
          }
        }
      }
    });
    setInfoSensor(infoSensor_tmp);
  }, [listSensorAvailables]);

  const degreeToDirection = useCallback((degree: number) => {
    if (degree > 11.25 && degree <= 33.75) {
      return 'BĐB';
    } else if (degree > 33.75 && degree <= 56.25) {
      return 'ĐB';
    } else if (degree > 56.25 && degree <= 78.75) {
      return 'ĐĐB';
    } else if (degree > 78.75 && degree <= 101.25) {
      return 'Đông';
    } else if (degree > 101.25 && degree <= 123.75) {
      return 'ĐĐN';
    } else if (degree > 123.75 && degree <= 146.25) {
      return 'ĐN';
    } else if (degree > 146.25 && degree <= 168.75) {
      return 'NĐN';
    } else if (degree > 168.75 && degree <= 191.25) {
      return 'Nam';
    } else if (degree > 191.25 && degree <= 213.75) {
      return 'NTN';
    } else if (degree > 213.75 && degree <= 236.25) {
      return 'TN';
    } else if (degree > 236.25 && degree <= 258.75) {
      return 'TTN';
    } else if (degree > 258.75 && degree <= 281.25) {
      return 'Tây';
    } else if (degree > 281.25 && degree <= 303.75) {
      return 'TTB';
    } else if (degree > 303.75 && degree <= 326.25) {
      return 'TB';
    } else if (degree > 326.25 && degree <= 348.75) {
      return 'BTB';
    } else {
      return 'Bắc';
    }
  }, []);

  return (
    <>
      {infoSensor?.length > 0 ? (
        <StatisticsElement>
          {infoSensor.map((one, index) => (
            <OneStatisticElement key={index}>
              <OneStatisticHeaderElement>
                {one.icon && <div className='icon'>{one.icon}</div>}
                <div className='title'>{one.name}</div>
              </OneStatisticHeaderElement>
              <OneStatisticBodyElement>
                <div className='value'>
                  {typeof valueSensor[one.id] !== 'undefined' ? valueSensor[one.id] : '-'}
                </div>
                <div className='unit'>{one.unit}</div>
                {one.key === 'wdir' && (
                  <div className='note'>({degreeToDirection(valueSensor[one.id])})</div>
                )}
              </OneStatisticBodyElement>
            </OneStatisticElement>
          ))}
        </StatisticsElement>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export default Statistics;

const StatisticsElement = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  width: 100%;
  height: 250px;
  padding-top: 20px;
`;

const OneStatisticElement = styled.div`
  /* background: #f5f5f5;
  border-radius: 15px;
  font-family: sans-serif;
  position: relative;
  padding: 8px; */

  background: #e6eef6;
  border-radius: 15px;
  font-family: sans-serif;
  position: relative;
  padding: 8px;
  max-height: 150px;
`;

const OneStatisticHeaderElement = styled.div`
  position: relative;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  color: #666fe8;
  align-items: center;
  height: 20px;
  padding: 16px 8px 0 8px;

  .icon {
    padding-right: 10px;
  }
`;

const OneStatisticBodyElement = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: bold;

  .value {
    margin-right: 8px;
  }

  .unit {
    margin-right: 8px;
  }
`;
