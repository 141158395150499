import {
  StationThresholdAction,
  StationThresholdActionType,
  StationThresholdState,
} from '~/store/ducks/stationThreshold/types';

const initialState: StationThresholdState = {
  error: undefined,
  stationListThresholdMapping: [],
};

export default function stationConfigReducer(
  state = initialState,
  action: StationThresholdAction,
): StationThresholdState {
  switch (action.type) {
    case StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_SUCCESS: {
      return {
        ...state,
        stationListThresholdMapping: action.payload.listStationThresholdMapping,
      };
    }

    case StationThresholdActionType.GET_STATION_THRESHOLD_MAPPING_FAIL: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
